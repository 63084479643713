import React, {forwardRef, FunctionComponent} from 'react';
import {GadgetEditorProps} from '../models';
import GadgetContainer from '../../../../components/containers/gadget/GadgetContainer';
import LabEditLayout from './LabEditLayout';
import {LabPreview} from './LabPreview';
import {LabGadgetI18nStrings} from '../../../../context/course/models/I18n';
import {LabsGadgetConfig} from './models';

import './styles/labs-gadget.scss';

export type LabsGadgetProps = GadgetEditorProps<LabsGadgetConfig, LabGadgetI18nStrings>;

/**
 * You can use this gadget to add cloud based labs to your course.
 */
export const LabsGadget: FunctionComponent<LabsGadgetProps> = forwardRef<HTMLElement | undefined, LabsGadgetProps>((
  {
    id: gadgetId,
    isFullScreen,
    config
  }: LabsGadgetProps,
  ref
) => {
  return (
    <GadgetContainer id={gadgetId} ref={ref} width={isFullScreen ? '100%' : '673px'}>
      {
        isFullScreen
          ? <LabEditLayout gadgetId={gadgetId} config={config} />
          : <LabPreview id={gadgetId} config={config} />
      }
    </GadgetContainer>
  );
});
LabsGadget.displayName = 'LabsGadget';
