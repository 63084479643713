import colorPalette from './color-palette';

const brandedLight = {
  themeBorderPrimaryFocus: colorPalette.wave500,
  themeBackgroundPrimaryDefault: colorPalette.gray0,
  themeBackgroundAccentDefault: colorPalette.wave500,
  themeForegroundPrimaryDefault: colorPalette.gray700,
  themeForegroundSecondaryDefault: colorPalette.gray500,

  // Alert Component
  alertBackgroundColorLargeError: colorPalette.red250,

  // Loader Component
  loaderIndicatorBackgroundColor: colorPalette.wave400,
  loaderTrackBackgroundColor: colorPalette.gray200,

  // Modal Component
  modalBorderWidth: '0px',

  // Primary button
  buttonBackgroundColorPrimaryDefault: colorPalette.wave500,
  buttonBackgroundColorPrimaryDisabled: colorPalette.gray050,
  buttonBackgroundColorPrimaryHover: colorPalette.wave600,
  buttonBackgroundColorPrimaryPressed: colorPalette.wave700,
  buttonForegroundColorPrimaryDefault: colorPalette.gray0,
  buttonForegroundColorPrimaryDisabled: colorPalette.gray200,

  // Secondary button
  buttonBackgroundColorSecondaryDefault: colorPalette.gray050,
  buttonBackgroundColorSecondaryDisabled: colorPalette.gray050,
  buttonBackgroundColorSecondaryHover: colorPalette.gray300,
  buttonBackgroundColorSecondaryPressed: colorPalette.gray400,
  buttonForegroundColorSecondaryDefault: colorPalette.gray700,
  buttonForegroundColorSecondaryDisabled: colorPalette.gray300,

  // Tertiary Button
  buttonBorderColorTertiaryDefault: colorPalette.wave500,
  buttonForegroundColorTertiaryDefault: colorPalette.wave500,
  buttonBorderColorTertiaryHover: colorPalette.wave600,
  buttonBackgroundColorTertiaryHover: colorPalette.wave600,
  buttonForegroundColorTertiaryHover: colorPalette.gray0,
  buttonBorderColorTertiaryPressed: colorPalette.wave700,
  buttonBackgroundColorTertiaryPressed: colorPalette.wave700,
  buttonForegroundColorTertiaryPressed: colorPalette.gray0,

  // Link button
  buttonBackgroundColorLinkLightBackdropHover: colorPalette.gray200,
  buttonBackgroundColorLinkLightBackdropPressed: colorPalette.gray300,
  buttonForegroundColorLinkLightBackdropDefault: colorPalette.wave500,
  buttonForegroundColorLinkLightBackdropDisabled: colorPalette.gray200,
  buttonForegroundColorLinkLightBackdropHover: colorPalette.wave600,
  buttonForegroundColorLinkLightBackdropPressed: colorPalette.wave700,

  // Radio button
  radioButtonBackgroundColorSelectedDefault: colorPalette.wave500,
  radioButtonBackgroundColorSelectedDisabled: colorPalette.wave100,
  radioButtonBackgroundColorSelectedHover: colorPalette.wave600,
  radioButtonBackgroundColorSelectedPressed: colorPalette.wave700,
  radioButtonBackgroundColorUnselectedDefault: colorPalette.gray0,
  radioButtonBackgroundColorUnselectedDisabled: colorPalette.gray050,
  radioButtonBackgroundColorUnselectedHover: colorPalette.wave600,
  radioButtonBackgroundColorUnselectedPressed: colorPalette.wave700,
  radioButtonBorderColorSelectedDisabled: colorPalette.gray200,
  radioButtonBorderColorUnselectedDefault: colorPalette.gray500,
  radioButtonBorderColorUnselectedDisabled: colorPalette.gray200,
  radioButtonForegroundColorDefault: colorPalette.gray700,
  radioButtonForegroundColorDisabled: colorPalette.gray200,
  radioButtonIndicatorColorSelectedDefault: colorPalette.gray0,
  radioButtonIndicatorColorUnselectedDefault: colorPalette.gray0,
  radioButtonIndicatorColorUnselectedDisabled: colorPalette.gray050,
  radioButtonIndicatorColorUnselectedHover: colorPalette.wave500,
  radioButtonIndicatorColorUnselectedPressed: colorPalette.gray0,

  // checkboxes
  checkboxBackgroundColorSelectedDefault: colorPalette.wave500,
  checkboxBackgroundColorSelectedHover: colorPalette.wave600,
  checkboxBackgroundColorSelectedPressed: colorPalette.wave700,
  checkboxBackgroundColorUnselectedDefault: colorPalette.gray0,
  checkboxBackgroundColorUnselectedHover: colorPalette.wave600,
  checkboxBackgroundColorUnselectedPressed: colorPalette.wave700,
  checkboxIndicatorColorUnselectedHover: colorPalette.wave500,
  checkboxBorderColorUnselectedDefault: colorPalette.gray200,

  // pagination
  paginationArrowColor: colorPalette.wave500,
  paginationEllipseColorDefault: colorPalette.wave500,
  paginationEllipseColorDisabled: colorPalette.gray200,
  paginationIndicatorColorDefault: colorPalette.wave500,
  paginationIndicatorColorHover: colorPalette.wave600,
  paginationIndicatorColorPressed: colorPalette.wave700,
  paginationNumberColorDefault: colorPalette.wave500,
  paginationNumberColorDisabled: colorPalette.gray200,
  paginationNumberColorSelected: colorPalette.gray700,

  // tab
  tabIndicatorColorHover: colorPalette.wave500,
  tabIndicatorColorPressed: colorPalette.wave700,
  tabIndicatorColorSelected: colorPalette.wave500,
  tabForegroundColorHover: colorPalette.wave800,
  tabForegroundColorPressed: colorPalette.wave800,
  tabForegroundColorSelected: colorPalette.wave800,

  // link
  linkForegroundColorDefault: colorPalette.wave500,
  linkForegroundColorDisabled: colorPalette.wave200,
  linkForegroundColorHover: colorPalette.wave700,
  linkForegroundColorPressed: colorPalette.wave800
};

export default brandedLight;
