import {
  Content,
  Course,
  CourseModel,
  GadgetConfigAdapterRegistry,
  GadgetLearnerStateConverterRegistry,
  LessonViewMode,
  toCourseWithLessonsArray,
} from "@amzn/soju-ui-framework";
import { CourseLearnerSession } from "./CourseLearnerSession";
import { getCourseDetails } from "../graphql/queries";
import { CourseDetails } from "../graphql/model";
import { API, graphqlOperation } from "@aws-amplify/api";

export const LEARNER_SESSION_HEADER_NAME = "X-AMZN-SOJU-LEARNER-SESSION";
export const SOJU_CUSTOMER_HEADER_NAME = "X-AMZN-SOJU-CUSTOMER";

export abstract class BaseCoursePlayerApi {
  protected readonly learnerSession: CourseLearnerSession;
  protected readonly adapterRegistry: GadgetConfigAdapterRegistry;
  protected readonly converterRegistry: GadgetLearnerStateConverterRegistry;

  constructor(learnerSession: CourseLearnerSession) {
    this.learnerSession = learnerSession;
    this.adapterRegistry = new GadgetConfigAdapterRegistry();
    this.converterRegistry = new GadgetLearnerStateConverterRegistry();
  }

  getHeaders() {
    return {
      [SOJU_CUSTOMER_HEADER_NAME]: this.learnerSession.resourceId,
      "Content-Type": "application/json",
      Accept: "application/json",
      [LEARNER_SESSION_HEADER_NAME]: this.learnerSession.learnerToken,
    };
  }

  async loadCourse(courseId: string): Promise<Course> {
    const response = (await API.graphql(
      graphqlOperation(getCourseDetails, {
        request: {
          isDraft: this.learnerSession.isDraft,
          courseId: courseId,
        },
      }),
      {
        ...this.getHeaders(),
      },
    )) as {
      data: {
        getCourseDetails: CourseDetails;
      };
      error: object;
    };
    response.data.getCourseDetails.contents.forEach((content, contentIndex) => {
      content?.gadgets?.forEach((gadget, gadgetIndex) => {
        response.data.getCourseDetails.contents[contentIndex].gadgets[
          gadgetIndex
        ] = JSON.parse(gadget as string);
      });
    });
    const courseModel: CourseModel = {
      id: response.data.getCourseDetails.id,
      title: response.data.getCourseDetails.title,
      description: response.data.getCourseDetails.description || "",
      contents: response.data.getCourseDetails.contents as Content[],
      lessonViewMode: LessonViewMode.All,
    };
    return toCourseWithLessonsArray(
      courseModel,
      this.adapterRegistry,
      this.converterRegistry,
    );
  }
}
