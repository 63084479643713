import colorPalette from './color-palette';

const wave = {
  themeBorderPrimaryFocus: colorPalette.blue800,
  themeBackgroundPrimaryDefault: colorPalette.wave500,
  themeForegroundPrimaryDefault: colorPalette.gray0,
  buttonBackgroundColorSecondaryDefault: colorPalette.gray700,
  buttonBackgroundColorSecondaryHover: colorPalette.gray600,
  buttonBackgroundColorSecondaryPressed: colorPalette.gray500,
  buttonForegroundColorSecondaryDefault: colorPalette.gray0,
  buttonForegroundColorSecondaryDisabled: colorPalette.gray0,

  buttonBackgroundColorLinkDarkBackdropHover: colorPalette.wave400,
  buttonBackgroundColorLinkDarkBackdropPressed: colorPalette.wave300,
  buttonForegroundColorLinkDarkBackdropDefault: colorPalette.gray0,
  buttonForegroundColorLinkDarkBackdropDisabled: colorPalette.wave050,
  buttonForegroundColorLinkDarkBackdropHover: colorPalette.gray0,
  buttonForegroundColorLinkDarkBackdropPressed: colorPalette.gray0,
};

export default wave;
