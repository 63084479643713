import {atom, DefaultValue, selector, useRecoilValue} from 'recoil';
import {
  ContentType,
  Course,
  CourseLearnerState,
  CoursePosition,
  Gadget,
  GadgetLearnerState,
  Lesson,
  Module,
  ScrollToGadgetId
} from '../../context/course';
import {find} from 'lodash';
import {PlayerUIModes, SidebarState} from '../../components/containers/course-player/models/PlayerUIModes';
import {LessonViewMode} from '../../context/course/models/LessonViewMode';
import {CourseUpdateRequest, LessonUpdate, ModuleUpdate} from '../context/models/CourseUpdateRequest';
import {flattenContentsIntoLessons} from '../../utils/CourseUtils';

export const currentCourseDraftSkeleton = atom<CourseUpdateRequest>({
  key: 'CourseDraftSkeleton',
  default: {
    id: '',
    contents: [],
    lessonViewMode: LessonViewMode.Section
  }
});

export const currentCourseDraftGadgets = atom<Record<string, Gadget>>({
  key: 'CourseDraftGadgets',
  default: { }
});

export const currentCourseDraftLearnerState = atom<CourseLearnerState | null>({
  key: 'CourseDraftLearnerState',
  default: null
});

export const currentCourseDraftScrollToGadgetId = atom<ScrollToGadgetId | undefined>({
  key: 'CourseDraftScrollToGadgetId',
  default: undefined
});

export const authoringUIModes = atom<PlayerUIModes>({
  key: 'authoringUIModes',
  default: {sidebarState: SidebarState.EXPANDED}
});

export const currentCourseDraftFullScreenGadgetId = atom<string | undefined>({
  key: 'CourseDraftFullScreenGadgetId',
  default: undefined
});

export const currentCourseDraftLoaded = selector<boolean>( {
  key: 'CurrentCourseDraftLoaded',
  get: ({get}) => {
    return get(currentCourseDraftSkeleton).id !== '';
  }
});

export const currentCourseDraft = selector<Course>({
  key: 'CourseDraft',
  get: ({get}) => {
    const courseSkeleton = get(currentCourseDraftSkeleton);
    const courseGadgets = get(currentCourseDraftGadgets);
    const toLesson = (lessonUpdate: LessonUpdate) => {
      return {
        ...lessonUpdate,
        gadgets: lessonUpdate.gadgetIds.map(gadgetId => courseGadgets[gadgetId])
      } as Lesson;
    };
    const contents = courseSkeleton.contents.map(content => {
      switch (content.contentType) {
        case ContentType.LESSON:
          return toLesson(content as LessonUpdate);
        case ContentType.MODULE:
          return {
            ...content,
            lessons: (content as ModuleUpdate).lessons.map(it => toLesson(it))
          } as Module;
        default: {
          throw new Error('Invalid content type');
        }
      }
    });
    return {
      ...courseSkeleton,
      contents,
      lessons: flattenContentsIntoLessons(contents)
    } as Course;
  },
});

export const currentAuthoringUISidebarState = selector<SidebarState>({
  key: 'AuthoringUISidebarState',
  get: ({get}) => get(currentCourseDraftFullScreenGadgetId) ? SidebarState.NO_SIDEBAR : get(authoringUIModes)?.sidebarState,
  set: ({set, get}, sidebarState) => {
    set(authoringUIModes, {
      ...get(authoringUIModes),
      sidebarState: (sidebarState instanceof DefaultValue ? SidebarState.EXPANDED : sidebarState)
    });
  }
});

export const currentCourseDraftPosition = selector<CoursePosition | null>({
  key: 'CourseDraftPosition',
  get: ({get}) => get(currentCourseDraftLearnerState)?.coursePosition || null,
  set: ({set, get}, coursePosition) => {
    set(currentCourseDraftLearnerState, learnerState => {
      return {
        ...learnerState,
        updatedAt: new Date(),
        coursePosition: (coursePosition instanceof DefaultValue ? null : coursePosition)
      };
    });
  }
});

export const currentCourseDraftGadgetsLearnerState = selector<Record<string, GadgetLearnerState>>({
  key: 'CurrentCourseDraftGadgetsLearnerState',
  get: ({get}) => {
    return get(currentCourseDraftLearnerState)?.gadgetsState || {};
  },
  set: ({get,set}, gadgetsState) => {
    set(currentCourseDraftLearnerState, learnerState => { return {
      ...learnerState,
      updatedAt: new Date(),
      gadgetsState: gadgetsState instanceof DefaultValue ? {} : gadgetsState
    };});
  }
});

export const currentCourseDraftLesson = selector<Lesson | null>({
  key: 'CurrentCourseDraftLesson',
  get: ({get}) => {
    const coursePosition = get(currentCourseDraftLearnerState)?.coursePosition;
    const course = get(currentCourseDraft);
    return find(course?.lessons, {id: coursePosition?.lessonId}) || null;
  }
});

/**
 * React hook to use the current course draft
 */
export const useCurrentCourseDraft = (): Course | null => {
  return useRecoilValue(currentCourseDraft);
};

/**
 * React hook to use the current course learner state
 */
export const useCurrentCourseDraftGadgetsLearnerState = (): Record<string, GadgetLearnerState> => {
  return useRecoilValue(currentCourseDraftGadgetsLearnerState);
};

/**
 * React hook to use the learner current position on the course
 */
export const useCurrentCourseDraftPosition = (): CoursePosition | null => {
  return useRecoilValue(currentCourseDraftPosition);
};

/**
 * React hook to use the current lesson being edited
 */
export const useCurrentCourseDraftLesson = (): Lesson | null => {
  return useRecoilValue(currentCourseDraftLesson);
};

/**
 * React hook to know the Authoring UI sidebar state
 */
export const useAuthoringUISidebarState = (): SidebarState => {
  return useRecoilValue(currentAuthoringUISidebarState);
};

export const useCurrentCourseDraftScrollToGadgetId = (): ScrollToGadgetId | undefined => {
  return useRecoilValue(currentCourseDraftScrollToGadgetId);
};
