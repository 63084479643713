import React, {FunctionComponent, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';
import Icon from '@amzn/meridian/icon';
import plusKnockoutTokens from "@amzn/meridian-tokens/base/icon/plus-knockout"
import Select, { SelectOption } from '@amzn/meridian/select';

import {useCourseAuthoringContext} from '../../../../context';
import {LabsGadgetConfig, TScript, LabChallenge} from '../models';
import {CloudTypes} from '../models/CloudTypes';
import {AllowedAWSServices} from '../models/AllowedAWSServices';
import {LabTrackLifecycleScripts} from './LabTrackLifecycleScripts';
import {LabChallengesOverview} from '../challenges/LabChallengesOverview';

import './styles/lab-track-setup.scss';

export type LabTrackSetupProps = {
  gadgetId: string;
  config: LabsGadgetConfig;
  onEditChallenge: (challengeId: string) => void;
};

export const LabTrackSetup: FunctionComponent<LabTrackSetupProps> = (
  props: LabTrackSetupProps
) => {
  const {gadgetId, config, onEditChallenge} = props;
  const {title = '', cloudConfig, teaser, trackScripts, challenges} = config;
  const {updateGadget} = useCourseAuthoringContext();

  const [trackTitle, setTrackTitle] = useState<string>(title);
  const [type, setType] = useState<CloudTypes>(cloudConfig?.type);
  const [trackTeaser, setTrackTeaser] = useState<string>(teaser);
  const [servicePackages, setServicePackages] = useState<AllowedAWSServices[]>(cloudConfig?.SCP || []);

  // This builds the config object with the update state, needed when calling updateGadget
  const buildConfig = () => {
    return {
      title: trackTitle,
      teaser: trackTeaser,
      isSubmit: false,
      challenges,
      cloudConfig: {
        type: type,
        SCP: servicePackages,
        awsIamPolicy: cloudConfig?.awsIamPolicy
      },
      trackScripts: trackScripts,
      settings: {
        isRequiredForCertificate: true
      }
    } as LabsGadgetConfig;
  };

  const updateTrackTitle = (newTitle: string) => {
    setTrackTitle(newTitle);
    const newConfig = buildConfig();
    updateGadget(gadgetId, {
      ...newConfig,
      title: newTitle
    } as LabsGadgetConfig);
  };

  const updateType = (newType: CloudTypes) => {
    setType(newType);
    const newConfig = buildConfig();
    updateGadget(gadgetId, {
      ...newConfig,
      cloudConfig: {
        ...newConfig.cloudConfig,
        type: newType
      }
    } as LabsGadgetConfig);
  };

  const updateServicePackages = (newSCP: AllowedAWSServices[]) => {
    setServicePackages(newSCP);
    const newConfig = buildConfig();
    updateGadget(gadgetId, {
      ...newConfig,
      cloudConfig: {
        ...newConfig.cloudConfig,
        SCP: newSCP
      }
    } as LabsGadgetConfig);
  };

  const updateTrackTeaser = (newTeaser: string) => {
    setTrackTeaser(newTeaser);
    const newConfig = buildConfig();
    updateGadget(gadgetId, {
      ...newConfig,
      teaser: newTeaser
    } as LabsGadgetConfig);
  };

  const onAwsPolicyChange = (newPolicy: string) => {
    const newConfig = buildConfig();
    updateGadget(gadgetId, {
      ...newConfig,
      cloudConfig: {
        ...newConfig.cloudConfig,
        awsIamPolicy: newPolicy
      }
    } as LabsGadgetConfig);
  };

  const onTrackScriptChange = (newScript: TScript) => {
    const newConfig = buildConfig();
    const scriptIndex = findIndex(trackScripts, ['action', newScript.action]);
    const newTrackScripts = [...(newConfig?.trackScripts || [])];
    if(scriptIndex === -1) {
      newTrackScripts.push(newScript);
    } else {
      newTrackScripts[scriptIndex] = newScript;
    }
    updateGadget(gadgetId, {
      ...newConfig,
      trackScripts: newTrackScripts
    } as LabsGadgetConfig);
  };

  const addNewChallenge = () => {
    const newConfig = buildConfig();
    const newChallenge = {
      id: uuidv4(),
      title: 'New Exercise',
      teaser: '',
      timeToCompleteInMinutes: 30,
      challengeScripts: [],
      assignmentMarkDownText: ''
    } as LabChallenge;

    updateGadget(gadgetId, {
      ...newConfig,
      challenges: [
        ...(newConfig.challenges || []),
        newChallenge
      ]
    } as LabsGadgetConfig);
  };

  const deleteChallenge = (challengeId: string) => {
    const newConfig = buildConfig();
    const updatedChallenges = filter(newConfig.challenges, (iChallenge: LabChallenge) => iChallenge.id !== challengeId);

    updateGadget(gadgetId, {
      ...newConfig,
      challenges: updatedChallenges
    } as LabsGadgetConfig);
  }

  const reorderChallenge = (challengeId: string, newIndex: number) => {
    const newConfig = buildConfig();
  
    const reorderedChallenges = [...newConfig.challenges];

    // swap challenge order
    const fromIndex = findIndex(reorderedChallenges, it => it.id === challengeId);
    const element = reorderedChallenges[fromIndex];
    reorderedChallenges.splice(fromIndex, 1);
    reorderedChallenges.splice(newIndex, 0, element);

    updateGadget(gadgetId, {
      ...newConfig,
      challenges: reorderedChallenges
    } as LabsGadgetConfig);
  }

  return (
    <Box>
      <Row widths={['grid-6', 'grid-6']} height={'100%'} alignmentVertical='top'>
        <Box className='labTrackSetup__trackConfig' height={'100%'}>
          <Column spacing='500' spacingInset={'400 600'}>
            <Input
              data-testid='trackSetup-title'
              value={trackTitle}
              onChange={updateTrackTitle}
              size='small'
              placeholder='Enter value...'
              label='Title'
              helperText='Title is optional but recommended'
            />
            <Select
              data-testid='trackSetup-type'
              label='Type'
              value={type}
              onChange={updateType}
              placeholder='Select lab type'
            >
              <SelectOption value={CloudTypes.AWS} label={CloudTypes.AWS} />
            </Select>
            <Input
              data-testid='trackSetup-teaser'
              value={trackTeaser}
              onChange={updateTrackTeaser}
              size='small'
              placeholder='Optional concise description'
              label='Teaser'
            />
            <Select
              data-testid='trackSetup-servicePackages'
              label='AWS Service Packages'
              value={servicePackages}
              onChange={updateServicePackages}
            >
              <SelectOption value={AllowedAWSServices.cloud9} label={AllowedAWSServices.cloud9} />
              <SelectOption value={AllowedAWSServices.ec2} label={AllowedAWSServices.ec2} />
              <SelectOption value={AllowedAWSServices.s3} label={AllowedAWSServices.s3} />
            </Select>
            
            <LabTrackLifecycleScripts
              data-testid='trackSetup-lifecycleScripts'
              trackScripts={config.trackScripts}
              awsPolicy={config?.cloudConfig?.awsIamPolicy}
              onTrackScriptChange={onTrackScriptChange}
              onAwsPolicyChange={onAwsPolicyChange}
            />
          </Column>
        </Box>
        <Box height={'100%'} spacingInset={'500 600'} >
          <Column spacing='500'>
            <LabChallengesOverview
              gadgetId={gadgetId}
              challenges={challenges}
              onEditChallenge={onEditChallenge}
              deleteChallenge={deleteChallenge}
              reorderChallenge={reorderChallenge}
            />
            <Box>
              <Button type='tertiary' onClick={addNewChallenge}><Icon tokens={plusKnockoutTokens} /> Add new exercise</Button>
            </Box>
          </Column>
        </Box>
      </Row>
    </Box>
  );
};
export default LabTrackSetup;
