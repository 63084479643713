import React, {FunctionComponent, useEffect, useState} from 'react';
import {Delta} from 'quill';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import Box from '@amzn/meridian/box';
import Textarea from '@amzn/meridian/textarea';
import Card from '@amzn/meridian/card';
import Text from '@amzn/meridian/text';
import {SlideType} from './SlideshowGadget';
import {AssetModel, useCourseContext} from '../../../../context/course';
import {CustomQuill} from '../../quill/CustomQuill';
import {AssetDataModel, AssetType, AssetUploader, InvalidFile} from '../../asset-uploader/AssetUploader';
import {imageFileTypes} from '../../asset-uploader/Constants';
import {Image} from '../../../../components/image/Image';
import {SlideshowGadgetI18nStrings, WithI18nStringsProps} from '../../../../context/course/models/I18n';

import './styles/slideshow.scss';

const LABELS = {
  imageUploadPlaceholder: 'Drag file here or click to upload image',
  replaceImagePlaceholder: 'Change image'
};

export interface CarouselSlideProps extends WithI18nStringsProps<SlideshowGadgetI18nStrings> {
  slide: SlideType;
  onUpdate: (slide: SlideType) => void;
  onRejection: (invalidFiles: InvalidFile[]) => void;
}

const CarouselSlide: FunctionComponent<CarouselSlideProps> = (
  {
    slide,
    onUpdate,
    onRejection,
    i18nStrings
  }: CarouselSlideProps
) => {
  const {getAssetModel} = useCourseContext();
  const [asset, setAsset] = useState<AssetModel | undefined>(slide.image);
  const [imgAltText, setImgAltText] = useState<string>(slide.image?.assetMetadata?.altText as string || '');
  const supportedImageFileTypes = {'image/*': imageFileTypes};

  // When loading asset, fetches asset model via API
  useEffect(() => {
    (async () => {
      if (asset && !asset.location) {
        const fetchedAsset = await getAssetModel(asset.id);
        setAsset(fetchedAsset);
      }
    })();
  }, [asset, getAssetModel]);

  const updateAssetData = (assetData: AssetDataModel) => {
    const image: AssetModel = {
      id: assetData?.image?.assetId as string,
      type: AssetType.IMAGE,
      contentType: assetData?.image?.contentType as string
    };
    onUpdate({
      ...slide,
      image
    });
    setAsset(image);
  };

  const updateImgAltText = (newAltText: string) => {
    const updatedAssetMetadata = {
      ...slide?.image?.assetMetadata,
      altText: newAltText
    };
    const updatedImage = {
      ...slide?.image,
      assetMetadata: updatedAssetMetadata
    } as AssetModel;

    onUpdate({
      ...slide,
      image: updatedImage
    });
    setImgAltText(newAltText);
  };

  // Updates slide on quill change
  const handleRTEChange = (newContent: Delta) => {
    onUpdate({
      ...slide,
      content: newContent
    });
  };

  return (
    <div key={slide.id} className='carousel__slide'>
      <div className='carousel__slide__inner'>
        {asset?.location && <Image src={asset.location} alt={asset.assetMetadata?.altText as string} />}
        <Box spacingInset={'medium none'}>
          <Card spacingInset={'medium'}>
            <AssetUploader
              supportedFileTypes={supportedImageFileTypes}
              assetType={AssetType.IMAGE}
              onUploadFailure={noop}
              onUploadSuccess={noop}
              updateAssetData={updateAssetData}
              onRejection={onRejection}
              i18nStrings={i18nStrings?.assetUpload}
            >
              <Text type='b300' alignment='center'>
                {isEmpty(slide?.image) ? LABELS.imageUploadPlaceholder : LABELS.replaceImagePlaceholder}
              </Text>
            </AssetUploader>
          </Card>
        </Box>
        {asset?.location &&
          <Box spacingInset={'none none 400'}>
            <Textarea
              value={imgAltText}
              onChange={updateImgAltText}
              label={'Alternate image text'}
              helperText={'Alternate text is recommended for the best learner experience'}
            />
          </Box>}
        <CustomQuill
          defaultValue={slide.content || new Delta()}
          onChange={handleRTEChange}
          onFileRejection={onRejection}
        />
      </div>
    </div>
  );
};

export default CarouselSlide;
