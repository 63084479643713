import {ResponseChallenge} from './ResponseGadget';
import isEmpty from 'lodash/isEmpty';

export const allChallengesAnswered = (responses: string[], challenges: ResponseChallenge[]): boolean => {
  if (isEmpty(challenges)) {
    return false;
  }

  return responses.length === challenges.length &&
    challenges.every((challenge, index) => {
      const response = responses[index];
      return response && response.length > 0;
    });
};
