import { Stage } from "./stage";

export interface SojuConfig {
  proxyServiceEndpoint: string;
  region: string;
  apiKey: string; // Temporary. Will replace with OIDC integration
  clientEndpoint: string;
}

const configurations: { [key in Stage]: SojuConfig } = {
  dev: {
    proxyServiceEndpoint:
      "https://gatl3zwf4jb3bgiyzwzgg73woa.appsync-api.eu-west-1.amazonaws.com/graphql",
    region: "eu-west-1",
    apiKey: "da2-gz4lj22ywjaf3hdjviipcmgu34",
    clientEndpoint: "https://localhost:3000",
  },
  "dev-dub": {
    proxyServiceEndpoint:
      "https://gatl3zwf4jb3bgiyzwzgg73woa.appsync-api.eu-west-1.amazonaws.com/graphql",
    region: "eu-west-1",
    apiKey: "da2-gz4lj22ywjaf3hdjviipcmgu34",
    clientEndpoint: "https://soju.lingxue.people.aws.dev",
  },
  "alpha-iad": {
    proxyServiceEndpoint:
      "https://soxfk5engvg63hmyo3eg5e2qfi.appsync-api.us-east-1.amazonaws.com/graphql",
    region: "us-east-1",
    apiKey: "da2-v5fyrqyo3rgsrnfpwakprz4opm",
    clientEndpoint: "https://us-east-1.alpha.soju.training.aws.dev",
  },
  "alpha-dub": {
    proxyServiceEndpoint:
      "https://gatl3zwf4jb3bgiyzwzgg73woa.appsync-api.eu-west-1.amazonaws.com/graphql",
    region: "eu-west-1",
    apiKey: "da2-gz4lj22ywjaf3hdjviipcmgu34",
    clientEndpoint: "https://eu-west-1.alpha.soju.training.aws.dev",
  },
  "beta-iad": {
    proxyServiceEndpoint:
      "https://f67wny5p75h43knwdptqrekmgm.appsync-api.us-east-1.amazonaws.com/graphql",
    region: "us-east-1",
    apiKey: "da2-o3r5ed2qvjaxxbth3vsni2l37i",
    clientEndpoint: "https://us-east-1.beta.soju.training.aws.dev",
  },
  "beta-dub": {
    proxyServiceEndpoint:
      "https://42jhk2zrsfco3krd5ayzqfcp2y.appsync-api.eu-west-1.amazonaws.com/graphql",
    region: "eu-west-1",
    apiKey: "da2-rs5ngivbo5dpbkbwbzb6bsmzoe",
    clientEndpoint: "https://eu-west-1.beta.soju.training.aws.dev",
  },
  "gamma-iad": {
    proxyServiceEndpoint:
      "https://wpfzf6ggybbtxinjeiia75t3eq.appsync-api.us-east-1.amazonaws.com/graphql",
    region: "us-east-1",
    apiKey: "da2-ns3qug3gkzeb3owaej7olwsnc4",
    clientEndpoint: "https://us-east-1.gamma.soju.training.aws.dev",
  },
  "gamma-dub": {
    proxyServiceEndpoint:
      "https://nzwn4leqw5balczgzfxsdgfozi.appsync-api.eu-west-1.amazonaws.com/graphql",
    region: "eu-west-1",
    apiKey: "da2-ypdlr2zr5jbidbazfw2nwsy2le",
    clientEndpoint: "https://eu-west-1.gamma.soju.training.aws.dev",
  },
  "prod-iad": {
    proxyServiceEndpoint:
      "https://2nmhzojrtrhdhall62wu2npwkq.appsync-api.us-east-1.amazonaws.com/graphql",
    region: "us-east-1",
    apiKey: "da2-b2qdbsntybgsvdjqyoecybkvpm",
    clientEndpoint: "https://us-east-1.soju.training.aws.dev",
  },
  "prod-dub": {
    proxyServiceEndpoint:
      "https://zi3llzrthrdxjoxis3wrrudivy.appsync-api.eu-west-1.amazonaws.com/graphql",
    region: "eu-west-1",
    apiKey: "da2-q4f7xyalhzfjvhvxtswaggf7rq",
    clientEndpoint: "https://eu-west-1.soju.training.aws.dev",
  },
};

export function sojuConfig(stage: Stage = Stage["dev-dub"]): SojuConfig {
  return configurations[stage];
}
