import React, {FunctionComponent, Ref, useCallback, useEffect, useState} from 'react';
import Box from '@amzn/meridian/box';
import {detect} from 'detect-browser';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import {getDrmType} from '../../../../components/gadgets/video/VideoGadget';
import {ShakaPlayer} from '../../../../components/gadgets/video';
import {VideoUploadPlaceholder} from './VideoUploadPlaceholder';
import {AssetMetaData} from './VideoGadget';
import {AssetModel, useCourseContext} from '../../../../context/course';
import {ShakaPlayerProps, ShakaPlayerRef} from '../../../../components/gadgets/video/ShakaPlayer';
import {AssetDataModel, InvalidFile} from '../../asset-uploader/AssetUploader';
import {VideoGadgetI18nStrings} from '../../../../context/course/models/I18n';

import 'shaka-player/dist/controls.css';
import '../../../../components/gadgets/video/styles/shaka.scss';
import './styles/video-gadget.scss';

export interface VideoComponentProps {
  gadgetId: string;
  updateAssetData: (asset: AssetDataModel) => void;
  video?: { assetId: string } | undefined;
  videoCaptions?: { assetId: string } | undefined;
  videoMetadata: AssetMetaData | undefined;
  videoPlayerRef: Ref<ShakaPlayerRef> | undefined;
  onRejection: (invalidFiles: InvalidFile[], supportedFileTypes: string[]) => void;
  i18nStrings: VideoGadgetI18nStrings;
  transcriptFileGetUrl: string | undefined;
}

export enum videoProcessingWorkflowStatusType {
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR'
}

export const VideoComponent: FunctionComponent<VideoComponentProps> = (
  {
    gadgetId,
    videoPlayerRef,
    updateAssetData,
    video,
    videoCaptions,
    videoMetadata,
    onRejection,
    i18nStrings,
    transcriptFileGetUrl
  }: VideoComponentProps) => {

  const {getAssetModel} = useCourseContext();
  const browser = detect();

  const [fileSelected, setFileSelected] = useState<string | null>(null);
  const [isVideoAssetCreated, setIsVideoAssetCreated] = useState<boolean>(false);
  const [isVideoAvailable, setIsVideoAvailable] = useState<boolean>(false);
  const [videoPlaybackUrl, setVideoPlaybackUrl] = useState<string>('');
  const [videoSubtitleAsset, setSubtitleAsset] = useState<AssetModel>();


  useEffect(() => {
    setIsVideoAssetCreated(!isEmpty(video));
  }, [video?.assetId]);

  useEffect(() => {
    (async () => {
      if (videoCaptions) {
        setSubtitleAsset(await getAssetModel(videoCaptions.assetId));
      }
    })();
  }, [videoCaptions]);

  useEffect(() => {
    setIsVideoAvailable(!isEmpty(videoMetadata?.assetMetadata));

    if (videoMetadata?.assetMetadata) {
      const hlsEgressEndpoint = videoMetadata.assetMetadata?.hlsEgressEndpoint as string;
      const dashEgressEndpoint = videoMetadata.assetMetadata?.dashEgressEndpoint as string;
      setVideoPlaybackUrl(browser?.name === 'safari' ? hlsEgressEndpoint : dashEgressEndpoint);
    }
  }, [videoMetadata?.assetMetadata]);

  const onUploadStart = useCallback((fileName: string) => {
    setFileSelected(fileName);
  }, []);

  const videoProcessingStatus = videoMetadata?.assetMetadata?.videoProcessingWorkflowStatus as string;

  const shakaPlayerProps = {
    drmLicenseUrl: videoMetadata?.assetMetadata?.drmLicenseUrl,
    drmType: getDrmType(),
    getDrmRequest: videoMetadata?.assetMetadata?.getDrmRequest,
    ref: videoPlayerRef,
    videoCaptionsUrl: videoSubtitleAsset?.location || transcriptFileGetUrl,
    videoPlaybackUrl: videoPlaybackUrl,
    videoPlayerId: `playerId-${gadgetId}`,
    i18nStrings: i18nStrings,
  } as ShakaPlayerProps;

  return (
    <Box minHeight={'200px'}>
      {isVideoAssetCreated &&
      isVideoAvailable &&
      videoProcessingStatus?.toUpperCase() === videoProcessingWorkflowStatusType.COMPLETE ? (
          <ShakaPlayer {...shakaPlayerProps} />) : (
          <VideoUploadPlaceholder
            fileSelected={fileSelected || videoMetadata?.fileName || ''}
            isVideoAssetCreated={isVideoAssetCreated}
            onUploadStart={onUploadStart}
            onUploadFailure={noop}
            updateAssetData={updateAssetData}
            videoMetadata={videoMetadata}
            onRejection={onRejection}
            i18nStrings={i18nStrings.assetUpload}
          />
        )
      }
    </Box>
  );
};
