import {QuizQuestion} from '.';
import {isEmpty, keys, values} from 'lodash';

export const getAnswerLetterFromIndex = (index: number): string => {
  return (String.fromCharCode('A'.charCodeAt(0) + index));
};

export const allQuestionsAnswered = (questions: QuizQuestion[], choicesByQuestionId: Record<string, string[]> = {}): boolean => {
  if (isEmpty(questions)) {
    return false;
  }
  return keys(choicesByQuestionId).length === questions.length && !values(choicesByQuestionId).some(choices => isEmpty(choices));
};
