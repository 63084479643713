import React, {createRef, forwardRef, useEffect, useState} from 'react';
import {countBy, get, isEmpty, isNil, round, size} from 'lodash';
import {GadgetProps} from '../models';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import Theme from '@amzn/meridian/theme';
import brandedLightTokens from '../../../theme/branded-light';
import redoTokens from '@amzn/meridian-tokens/base/icon/redo';
import {
  AssessmentEvaluationResults,
  AssessmentEvaluationStatus,
  GadgetConfig,
  useCourseContext
} from '../../../context/course';
import {allChallengesAnswered} from './helpers';
import {ResponseGadgetQuestion} from './ResponseGadgetQuestion';
import {GradedLearnerState} from '../../../context/course/models/GradedLearnerState';
import {Evaluation} from '../../../context/course/models/Evaluation';
import {LearnerGadgetActivity} from '../../../activity/models/learner-activities';
import {LearnerVerb} from '../../../activity/models/verbs';
import {LearnerObject} from '../../../activity/models/objects';
import GadgetContainer from '../../containers/gadget/GadgetContainer';
import {AssessmentGadgetSettingsType} from '../../../authoring';
import {ScoreBreakdown} from '../../scoring/ScoreBreakdown';
import {ResponseGadgetI18nStrings} from '../../../context/course/models/I18n';

import './styles/response-gadget.scss';

export interface ResponseGadgetConfig extends GadgetConfig {
  /**
   * Additional instructions
   */
  instructions?: string,

  /**
   * a list of short answer question prompts, each with a list of possible correct responses
   */
  challenges: ResponseChallenge[],

  /**
   * Assessment gadget settings
   */
  settings: AssessmentGadgetSettingsType
}

export type ResponseChallenge = {
  /**
   * challenge ID
   */
  id: string,

  /**
   * a list of possible correct responses
   */
  answers: ResponseChallengeAnswer[],

  /**
   * the prompt for the response question
   */
  prompt: string
}

export type ResponseChallengeAnswer = {
  /**
   * Answer ID
   */
  id: string,
  /**
   * a list of possible correct responses
   */
  answer: string
}

export interface ResponseEvaluationResults extends AssessmentEvaluationResults {
  evaluations: Evaluation[];
}

export interface ResponseGadgetLearnerState extends GradedLearnerState<ResponseEvaluationResults> {
  /**
   * a list of learner-entered responses and whether they were correct or not
   */
  responses?: string[]
}

/**
 * Use this gadget to add an evaluation widget to your course.
 * This evaluation gadget is slightly different than the Quiz gadget; instead of only supporting multiple choice answers, the Response gadget allows the learner to enter free-form text answers.
 * Similar to the Quiz gadget, the response gadget sends the Learner state to the backend, the backend evaluates is and returns a ResponseEvaluationResults object.
 */
export const ResponseGadget = forwardRef<HTMLElement | undefined, ResponseGadgetProps>((props: ResponseGadgetProps, ref) => {
  const {
    id,
    type,
    config: {
      title,
      instructions,
      challenges,
      settings
    },
    learnerState,
    i18nStrings,
  } = props;
  const {
    responses,
    evaluationResults = {} as ResponseEvaluationResults,
  } = learnerState || {} as ResponseGadgetLearnerState;

  const {
    emitLearnerActivity,
  } = useCourseContext();

  const {
    evaluations,
  } = evaluationResults as ResponseEvaluationResults;
  const [isGraded, setIsGraded] = useState(false);
  const [learnerResponses, setLearnerResponses] = useState(responses || []);
  const areAllChallengesAnswered = allChallengesAnswered(learnerResponses, challenges);
  const [canSubmit, setCanSubmit] = useState(areAllChallengesAnswered);
  const [retried, setRetried] = useState(0);
  const {submitGadgetLearnerStateAssessment} = useCourseContext();

  const firstInputRef = createRef<HTMLInputElement>();
  const submitButtonRef = createRef<HTMLElement>();

  const isRequiredForCertificate = get(settings, 'isRequiredForCertificate', false);
  let learnerScore;
  let totalCorrectAnswers;
  const totalQuestions = challenges.length;
  if (isGraded && !isEmpty(evaluations)) {
    // Group by and count number of Correct and Incorrect answers
    const totalCount = countBy(evaluations);
    // Get number of Correct answers
    totalCorrectAnswers = totalCount[Evaluation.Correct] || 0;
    learnerScore = round((totalCorrectAnswers * 100) / size(evaluations));
  }

  const onAnswerChange = (index: number) => (newResponse: string) => {
    const newLearnerResponses = [...learnerResponses];
    newLearnerResponses[index] = newResponse;
    setLearnerResponses(newLearnerResponses);
  };

  const onClickSubmit = () => {
    if (canSubmit) {
      // disable button before sending event to avoid duplicated submissions
      setCanSubmit(false);

      submitGadgetLearnerStateAssessment<ResponseGadgetLearnerState>(id, type, {
        updatedAt: new Date(),
        responses: learnerResponses,
      });

      emitLearnerActivity(
        new LearnerGadgetActivity(
          LearnerVerb.SUBMITTED,
          LearnerObject.ASSESSMENT,
          id,
          type,
        )
      );
    }
  };

  useEffect(() => {
    setCanSubmit(allChallengesAnswered(learnerResponses, challenges));
  }, [learnerResponses, challenges]);

  useEffect(() => {
    setLearnerResponses(responses || []);
    setCanSubmit(true);
    setIsGraded(!isNil(evaluations));
  }, [responses, evaluations]);

  useEffect(() => {
    if (retried > 0) {
      firstInputRef.current?.focus();
    }
  }, [retried]);

  const onClickRetry = () => {
    setIsGraded(false);
    setRetried(retried + 1);
    setLearnerResponses([]);
  };

  const submitButtonText = isGraded ? <><Icon tokens={redoTokens} /> {i18nStrings.retakeQuiz}</> : i18nStrings.checkYourAnswers;

  return (
    <GadgetContainer ref={ref} id={id}>
      <Theme tokens={brandedLightTokens}>
        <div className='ResponseGadget'>
          <div className='ResponseGadget__header'>
            {title && <div className='ResponseGadget__header-title'>
              <Heading level={3}>
                {title}
              </Heading>
            </div>}
            {instructions && <Text type='b300' color='secondary'>
              {instructions}
            </Text>}
          </div>
          <div className='ResponseGadget__body'>
            {challenges && challenges.map((challenge, index) => {
              const response = learnerResponses[index] || '';
              const correct = evaluations && evaluations[index];
              const questionId = `response-${id}-${index}`;
              return <ResponseGadgetQuestion
                ref={index === 0 ? firstInputRef : undefined}
                id={questionId}
                key={questionId}
                onAnswerChange={onAnswerChange(index)}
                prompt={challenge.prompt}
                correctAnswers={challenge.answers}
                learnerAnswer={response}
                isGraded={isGraded}
                isCorrect={correct === Evaluation.Correct}
                shouldShowAnswers={!isRequiredForCertificate}
                i18nStrings={i18nStrings}
              />;
            })}
          </div>
          <div className='ResponseGadget__footer'>
            <Row widths={['fit', 'fill']}>
              <Button
                ref={submitButtonRef}
                size='large'
                type={isGraded ? 'tertiary' : 'primary'}
                onClick={isGraded ? onClickRetry : onClickSubmit}
                disabled={!areAllChallengesAnswered}
              >
                {submitButtonText}
              </Button>
              {isGraded && <ScoreBreakdown
                status={evaluationResults?.status || AssessmentEvaluationStatus.Error}
                numberCorrect={totalCorrectAnswers || 0}
                totalQuestions={totalQuestions}
                learnerScore={learnerScore || 0}
                passPercentage={get(settings, 'passPercentage', 0)}
                isRequiredForCertificate={isRequiredForCertificate}
                onJumpToFirstQuestion={() => firstInputRef.current?.focus()}
                i18nStrings={i18nStrings.scoreBreakdown}
              />}
            </Row>
          </div>
        </div>
      </Theme>
    </GadgetContainer>
  );
});
ResponseGadget.displayName = 'ResponseGadget';

export type ResponseGadgetProps = GadgetProps<ResponseGadgetConfig, ResponseGadgetLearnerState, ResponseGadgetI18nStrings>
