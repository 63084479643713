/**
 * Predefined Verbs and documentation can be found here - https://registry.tincanapi.com/#home/verbs
 * Please read the definitions/documentation above before using Verbs.
 */
export enum LearnerVerb {
  ANSWERED = 'ANSWERED',
  ASKED = 'ASKED',
  ATTEMPTED = 'ATTEMPTED',
  ATTENDED = 'ATTENDED',
  CLOSED = 'CLOSED',
  COMMENTED = 'COMMENTED',
  COMPLETED = 'COMPLETED',
  EXITED = 'EXITED',
  EXPERIENCED = 'EXPERIENCED',
  FAILED = 'FAILED',
  IMPORTED = 'IMPORTED',
  INITIALIZED = 'INITIALIZED',
  INTERACTED = 'INTERACTED',
  LAUNCHED = 'LAUNCHED',
  MASTERED = 'MASTERED',
  OPENED = 'OPENED',
  PASSED = 'PASSED',
  PREFERRED = 'PREFERRED',
  PROGRESSED = 'PROGRESSED',
  REGISTERED = 'REGISTERED',
  RESPONDED = 'RESPONDED',
  RESUMED = 'RESUMED',
  SCORED = 'SCORED',
  SELECTED = 'SELECTED',
  SHARED = 'SHARED',
  SUBMITTED = 'SUBMITTED',
  SUSPENDED = 'SUSPENDED',
  TERMINATED = 'TERMINATED',
  VIEWED = 'VIEWED',
  VOIDED = 'VOIDED',
}
