import React, {FunctionComponent} from 'react';
import find from 'lodash/find';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import infoTokens from '@amzn/meridian-tokens/base/icon/info';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import {ScriptModal} from '../ScriptModal';
import {ScriptAction, OtherScriptTypes, AllScriptTypes, TScript} from '../models/Script';

export type LabTrackLifecycleScriptsProps = {
  /**
   * Track scripts: CLEANUP and SETUP
   */
  trackScripts?: TScript[],
  /**
   * AWS account policy that will be stored as part of the cloud config object
   */
  awsPolicy?: string,

  /**
   * Callback to call when a track script is modified
   */
  onTrackScriptChange: (trackScript: TScript) => void,

  /**
   * Callback to call when the aws policy is modifed
   */
  onAwsPolicyChange: (policy: string) => void
};

export const LabTrackLifecycleScripts: FunctionComponent<LabTrackLifecycleScriptsProps> = (
  {
    trackScripts,
    awsPolicy,
    onTrackScriptChange,
    onAwsPolicyChange
  }: LabTrackLifecycleScriptsProps
) => {

  const [isScriptModalOpen, setIsScriptModalOpen] = React.useState<boolean>(false);
  const [activeScriptType, setActiveScriptType] = React.useState<AllScriptTypes>();

  const onScriptChange = (newScript: string) => {
    switch(activeScriptType) {
      case ScriptAction.SETUP:
        onTrackScriptChange({
          action: ScriptAction.SETUP,
          content: newScript
        } as TScript);
        break;
      case ScriptAction.CLEANUP:
        onTrackScriptChange({
          action: ScriptAction.CLEANUP,
          content: newScript
        } as TScript);
        break;
      case OtherScriptTypes.POLICY:
        onAwsPolicyChange(newScript);
        break;
    }
  };

  const openModal = (scriptType: AllScriptTypes) => {
    setActiveScriptType(scriptType);
    setIsScriptModalOpen(true);
  };

  // Gets the content that will be passed down to the Ace editor modal
  const getActiveContent = () => {
    let content;
    switch(activeScriptType) {
      case ScriptAction.SETUP:
        content = (find(trackScripts, ['action', ScriptAction.SETUP]) as TScript)?.content;
        break;
      case ScriptAction.CLEANUP:
        content = (find(trackScripts, ['action', ScriptAction.CLEANUP]) as TScript)?.content;
        break;
      case OtherScriptTypes.POLICY:
        content = awsPolicy;
        break;
    }

    return content;
  };

  return (
    <>
      <Box backgroundColor='secondary' spacingInset={'400 450'}>
        <Column>
          <Row>
            <Text type='h100'>Settings and lifecycle scripts</Text>
          </Row>

          <Box>
            <Column>
              <Row widths={['fill', 'fit']}>
                <Box>
                  <Text type='b200'><Icon tokens={infoTokens} /> <strong>Setup</strong> script not defined</Text>
                </Box>
                <Button
                  className='trackLifecycleScripts__editSetup'
                  onClick={() => openModal(ScriptAction.SETUP)}
                >
                  <Icon tokens={exportSmallTokens} /> Edit
                </Button>
              </Row>
              <Row widths={['fill', 'fit']}>
                <Box>
                  <Text type='b200'><Icon tokens={infoTokens} /> <strong>Cleanup</strong> script provided</Text>
                </Box>
                <Button
                  className='trackLifecycleScripts__editCleanup'
                  onClick={() => openModal(ScriptAction.CLEANUP)}
                >
                  <Icon tokens={exportSmallTokens} /> Edit
                </Button>
              </Row>
              <Row widths={['fill', 'fit']}>
                <Box>
                  <Text type='b200'><Icon tokens={infoTokens} /> <strong>AWS IAM</strong> script provided</Text>
                </Box>
                <Button
                  className='trackLifecycleScripts__editPolicy'
                  onClick={() => openModal(OtherScriptTypes.POLICY)}
                >
                  <Icon tokens={exportSmallTokens} /> Edit
                </Button>
              </Row>
            </Column>
          </Box>
        </Column>
      </Box>
      {isScriptModalOpen &&
        <ScriptModal
          isModalOpen={isScriptModalOpen}
          title='Edit Setup Script'
          onClose={() => setIsScriptModalOpen(false)}
          onChange={onScriptChange}
          content={getActiveContent()}
        />
      }
    </>
  );
};
