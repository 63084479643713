import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  brandedDarkTokens,
  Course,
  CourseApi,
  CoursePosition,
  InitialPosition,
} from "@amzn/soju-ui-framework";
import { useHistory, useParams } from "react-router-dom";

import "@amzn/soju-ui-framework/dist/umd/authoring.css";
import { SojuCourseApi } from "../api/SojuCourseApi";
import { SojuCourseAuthoringApi } from "../api/SojuCourseAuthoringApi";
import { useCoursesAppContext } from "../context/CoursesAppContextProvider";
import Theme from "@amzn/meridian/theme";
import { AppRoutes } from "../routing/AppRoutes";
import {
  AuthoringActionHooks,
  CourseAuthoringApi,
  CourseAuthoringUI,
} from "@amzn/soju-ui-framework/dist/umd/authoring";
import { IdentityContext } from "../context/IdentityProvider";

export const CourseAuthoringPage: FunctionComponent = () => {
  const {
    courseDraftId,
    lesson: lessonIndex,
    gadget: gadgetIndex,
  } = useParams() as {
    courseDraftId: string;
    lesson: string;
    gadget: string;
  };
  const userPrincipal = useContext(IdentityContext).hatId;
  const [course, setCourse] = useState<Course | null>(null);
  const [coursePosition, setCoursePosition] = useState<CoursePosition | null>(
    null,
  );

  const [courseApi, setCourseApi] = useState<CourseApi | null>(null);
  const [courseAuthoringApi, setCourseAuthoringApi] =
    useState<CourseAuthoringApi | null>(null);

  const { sojuApi } = useCoursesAppContext();

  useEffect(() => {
    (async () => {
      const editSession = await sojuApi.createCourseSession(
        userPrincipal,
        courseDraftId,
        true,
      );
      setCourseAuthoringApi(new SojuCourseAuthoringApi(editSession));
      setCourseApi(new SojuCourseApi(editSession));
    })();
  }, [courseDraftId, sojuApi]);

  // Build initial position only once during mount
  const [initialPosition] = useState<InitialPosition | undefined>(
    !lessonIndex
      ? undefined
      : ({
          lessonIndex: Number(lessonIndex),
          gadgetIndex: gadgetIndex ? Number(gadgetIndex) : undefined,
        } as InitialPosition),
  );

  const history = useHistory();

  useEffect(() => {
    if (course && coursePosition) {
      const currentLessonIndex = course.lessons.findIndex(
        (lesson) => lesson.id === coursePosition.lessonId,
      );
      if (currentLessonIndex >= 0) {
        const lessonNumber = currentLessonIndex + 1;
        const currentGadgetIndex = course.lessons[
          currentLessonIndex
        ].gadgets.findIndex((gadget) => gadget.id === coursePosition.gadgetId);

        if (currentGadgetIndex >= 0) {
          const gadgetNumber = currentGadgetIndex + 1;
          // update url path
          history.push(
            `/courses/edit/${courseDraftId}/lessons/${lessonNumber}/gadgets/${gadgetNumber}`,
          );
        } else {
          history.push(
            `/courses/edit/${courseDraftId}/lessons/${lessonNumber}`,
          );
        }
      }
    }
  }, [course, courseDraftId, coursePosition, history]);

  const authoringActionHooks = {
    proceedToPublish: () => {
      history.push(`/courses/publish/${courseDraftId}`);
    },
    previewDraftCourse: (lessonIndex?: number) => {
      const previewUrl = `/courses/preview/draft/${courseDraftId}`;
      const previewUrlWithIndex = `${previewUrl}/lessons/${lessonIndex || 1}`;
      window.open(previewUrlWithIndex, "_blank") ||
        window.location.replace(previewUrlWithIndex);
    },
  } as AuthoringActionHooks;

  if (courseApi && courseAuthoringApi) {
    return (
      <Theme tokens={brandedDarkTokens}>
        <CourseAuthoringUI
          courseDraftId={courseDraftId}
          courseApi={courseApi}
          courseAuthoringApi={courseAuthoringApi}
          authoringActionHooks={authoringActionHooks}
          initialPosition={initialPosition}
          onPositionChange={setCoursePosition}
          onCourseChange={setCourse}
        />
      </Theme>
    );
  }

  return null;
};
