import { config } from "../../configuration/gandalf-config";
import { Stage } from "../../configuration/stage";
import { CognitoJwtVerifier } from "aws-jwt-verify";

export const GANDALF_TOKEN_VERIFY_ERROR = "Couldn't verify gandalf idToken.";
export const GANDALF_MISSING_HAT_ID = "Gandalf token misses Hat Id";
export const EMPTY_IDENTITY = {
  idToken: "",
  hatId: "",
};
export interface Identity {
  idToken: string;
  hatId: string;
}

export class IdentityFactory {
  public static async buildIdentity(idToken: string): Promise<Identity> {
    if (idToken === "") {
      return EMPTY_IDENTITY;
    }
    const gandalfConfig = config(process.env.STAGE as Stage);
    const verifier = CognitoJwtVerifier.create({
      clientId: gandalfConfig.Auth.userPoolWebClientId,
      tokenUse: "id",
      userPoolId: gandalfConfig.Auth.userPoolId,
    });
    let result;
    try {
      result = await verifier.verify(idToken);
    } catch (e) {
      console.log(e);
      throw new Error(GANDALF_TOKEN_VERIFY_ERROR);
    }
    if (typeof result.hat_id !== "string") {
      throw new Error(`${GANDALF_MISSING_HAT_ID}: ${JSON.stringify(result)}`);
    }
    return {
      idToken: idToken,
      hatId: result.hat_id as string,
    };
  }
}
