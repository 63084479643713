import {FunctionComponent, ReactElement, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {noop} from 'lodash';

export interface ActiveGadgetListProps {

  /**
   * The scrollable container that contains the gadget list. If it is undefined or null,
   * scroll listening and active gadget updating is short circuited, as the calculations will not work.
   */
  scrollContainer?: HTMLElement | undefined | null,

  /**
   * An optional callback function that is called when the active gadget is changed
   * @param gadget the next active gadget
   */
  onActiveGadgetChanged?: (gadgetId: string | undefined) => void,

  /**
   * list of gadget ids in this lesson. used to optimize the IntersectionObserver so it stops observing
   * the previous lesson's gadgets and starts observing the current lesson's gadgets
   */
  gadgetIds: string[]
}

export const ActiveGadgetList: FunctionComponent<ActiveGadgetListProps> = ({
  children,
  onActiveGadgetChanged = noop,
  gadgetIds,
  scrollContainer
}) => {
  const [visibleGadgetIds, setVisibleGadgetIds] = useState<Set<string>>(new Set());
  const [/* activeGadgetId */, setActiveGadgetId] = useState<string | null>(null);

  // update the visible gadget id list when an element's intersection ratio crosses the threshold
  const observeElement = useCallback(entries => {
    setVisibleGadgetIds(prevVisibleGadgetIds => {
      const nextVisibleGadgetIds = new Set(prevVisibleGadgetIds);
      entries.forEach((entry: IntersectionObserverEntry) => {
        const entryGadgetId = entry.target.getAttribute('data-gadget-id') || '';
        if (entry.isIntersecting) {
          nextVisibleGadgetIds.add(entryGadgetId);
        } else if (prevVisibleGadgetIds.has(entryGadgetId)) {
          nextVisibleGadgetIds.delete(entryGadgetId);
        }
      });
      return new Set([...nextVisibleGadgetIds].filter(gadgetId => scrollContainer?.querySelector(`[data-gadget-id=${gadgetId}]`)));
    });
  }, [scrollContainer]);

  const [observer, setObserver] = useState<IntersectionObserver>();

  // Initialize Observer
  useEffect(() => {
    setObserver(new IntersectionObserver(observeElement, {
      root: scrollContainer,
      threshold: 0.0
    }));
  }, [observeElement, scrollContainer]);

  // Observer observes current gadgets
  useLayoutEffect(() => {
    if (observer) {
      observer.disconnect();
      const elems = scrollContainer?.querySelectorAll('[data-gadget-id]');
      elems?.forEach(elem => observer.observe(elem));
      return () => observer.disconnect();
    }
  }, [gadgetIds, observer, scrollContainer]);

  // When visible items change, calculate which is the top-most item
  useEffect(() => {
    const topMost = {
      id: '',
      top: Number.MAX_VALUE
    };
    visibleGadgetIds.forEach(gadgetId => {
      const entry = scrollContainer?.querySelector(`[data-gadget-id=${gadgetId}]`);
      // If the gadget is actually not in the dom, don't even consider it.
      if (entry) {
        const entryBounds = entry?.getBoundingClientRect();
        const containerBounds = scrollContainer?.getBoundingClientRect();
        const topPosition = (entryBounds?.top || 0) - (containerBounds?.top || 0);

        if (topPosition < topMost.top) {
          topMost.id = gadgetId;
          topMost.top = topPosition;
        }
      }
    });

    // call the callback if the active gadget id has changed
    setActiveGadgetId(prevActiveGadgetId => {
      if (prevActiveGadgetId !== topMost.id) {
        onActiveGadgetChanged(topMost.id);
      }
      return topMost.id;
    });
  }, [gadgetIds, onActiveGadgetChanged, scrollContainer, visibleGadgetIds]);

  return children as ReactElement;
};
