import {LearnerActivity} from './LearnerActivity';
import {LearnerActivityType} from './LearnerActivityType';

export class LearnerGadgetActivity extends LearnerActivity {
  public readonly gadgetType: string;
  public readonly gadgetId: string;
  public readonly lessonId?: string;

  constructor(
    verb: string,
    object: string,
    gadgetId: string,
    gadgetType: string,
    lessonId?: string,
  ) {
    super(LearnerActivityType.GADGET, verb, object);
    this.gadgetId = gadgetId;
    this.gadgetType = gadgetType;
    this.lessonId = lessonId;
  }
}
