import {SojuCourseApi} from './SojuCourseApi';
import {AssetModel} from '../../../context/course';

export class SojuCoursePreviewDraftApi extends SojuCourseApi {
  async getAssetModel(courseId: string, assetId: string): Promise<AssetModel> {
    const {data: assetModel} = await this.axiosInstance.get<AssetModel>(`/assets/${assetId}/model`);
    if (assetModel.type === 'VIDEO') {
      assetModel.assetMetadata = assetModel.assetMetadata
        ? this.toVideoAssetMetadata(assetId, assetModel.assetMetadata, `${this.apiConfig.baseUrl}/assets/drm_license`)
        : {};
    }
    return assetModel;
  }
}
