/**
 * Different UI overrides the player supports
 */
export interface PlayerUIModes {
  // Value for UI state of the sidebar
  sidebarState: SidebarState
}

/**
 * Different states the sidebar can have
 */
export enum SidebarState {
  EXPANDED = 'EXPANDED',
  COLLAPSED = 'COLLAPSED',
  NO_SIDEBAR = 'NO_SIDEBAR'
}
