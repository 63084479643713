import React, { FunctionComponent, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import AppLayout from "@amzn/meridian/app-layout";
import ResetScroll from "./components/app/ResetScroll";
import CurrentPage from "./routing/CurrentPage";
import GlobalErrorRecovery from "./components/app/GlobalErrorRecovery";
import { SojuApi } from "./api/SojuApi";
import { CoursesAppContextProvider } from "./context/CoursesAppContextProvider";
import { AppSideMenu } from "./components/app/SideMenu";
import { config } from "./configuration/gandalf-config";
import { Stage } from "./configuration/stage";
import IdentityProvider from "./context/IdentityProvider";

const sojuApi = new SojuApi();

const App: FunctionComponent = () => {
  const sideMenu = useState(true);

  return (
    <IdentityProvider config={config(process.env.STAGE as Stage)}>
      <CoursesAppContextProvider sojuApi={sojuApi} sideMenu={sideMenu}>
        <Router basename="/" forceRefresh={false}>
          <ResetScroll />
          <AppLayout
            backgroundColor={"alternatePrimary"}
            sidebarComponent={AppSideMenu}
            alwaysShowScrollbar={true}
          >
            <AppSideMenu />
            <ErrorBoundary FallbackComponent={GlobalErrorRecovery}>
              <CurrentPage />
            </ErrorBoundary>
          </AppLayout>
        </Router>
      </CoursesAppContextProvider>
    </IdentityProvider>
  );
};

export default App;
