/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import shaka from 'shaka-player/dist/shaka-player.ui';

/**
 * Extending Shaka UI PlayButton for adding new functionality
 */
export class PlayButton extends shaka.ui.PlayButton {

  static BUTTON_NAME = 'soju_play_pause';
  static playTooltipMessage = 'Play';
  static pauseTooltipMessage = 'Pause';

  static setPlayButtonTooltipMessages(play: string, pause: string) {
    this.playTooltipMessage = play;
    this.pauseTooltipMessage = pause;
  }

  button: HTMLButtonElement;

  constructor(parent: HTMLElement, controls: shaka.ui.Controls) {
    super(parent, controls);

    this.button = <HTMLButtonElement>parent.lastChild;
    this.button.ariaLabel = PlayButton.playTooltipMessage;

    this.button.classList.add('play-button', 'shaka-tooltip');

    // @ts-ignore
    this.eventManager.listen(controls.getVideoContainer(), 'keydown', async e => {
      if (e.key === ' ' && isVideoContainerOrSeekBarInFocus()) {
        e.preventDefault();
        // @ts-ignore
        if (this.video?.paused) {
          // @ts-ignore
          await this.video.play();
        } else {
          // @ts-ignore
          this.video.pause();
        }
      }
    });

    // @ts-ignore
    this.eventManager.listen(this.video, 'play', () => {
      this.button.classList.remove('play-button');
      this.button.classList.add('pause-button');
      this.button.ariaLabel = PlayButton.pauseTooltipMessage;
    });
    // @ts-ignore
    this.eventManager.listen(this.video, 'pause', () => {
      this.button.classList.remove('pause-button');
      this.button.classList.add('play-button');
      this.button.ariaLabel = PlayButton.playTooltipMessage;
    });
  }
}

const isVideoContainerOrSeekBarInFocus = () => {
  // return True if video container or seekbar is in focus.
  const classNames = ['shaka-seek-bar', 'shaka-video-container'];
  const classList = document.activeElement?.classList;
  return classNames.some(className => classList?.contains(className));
};

export class PlayButtonFactory {
  create(rootElement: HTMLElement, controls: shaka.ui.Controls) {
    return new PlayButton(rootElement, controls);
  }
}

shaka.ui.Controls.registerElement(PlayButton.BUTTON_NAME, new PlayButtonFactory());
