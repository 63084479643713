import {QuizGadget} from '../../components/gadgets/quiz';
import {VideoGadget} from '../../components/gadgets/video';
import {ReadingGadget} from '../../components/gadgets/reading';
import {SectionGadget} from '../../components/gadgets/section';
import {SlideshowGadget} from '../../components/gadgets/slideshow';
import {ResponseGadget} from '../../components/gadgets/response';
import {LabGadget} from '../../components/gadgets/lab';
import {MarkdownGadget} from '../../components/gadgets/markdown';
import {defaultI18nStrings} from '../../context/course/models/I18n';
import {GadgetRenderer} from './GadgetRenderer';
import {GadgetRenderingConfig} from './GadgetRenderingConfig';
import {GadgetType} from '../../context/course/soju/models/GadgetType';

/**
 * Default registry of supported Gadgets
 */
export const DEFAULT_GADGETS_REGISTRY = {
  [GadgetType.QUIZ]: {
    component: QuizGadget,
    iconUrl: 'https://m.media-amazon.com/images/G/01/courses/soju-player/gadget-icons/quiz-icon.png',
    defaultTitle: 'Quiz',
    i18nStrings: defaultI18nStrings.gadgets[GadgetType.QUIZ],
  } as GadgetRenderingConfig,
  [GadgetType.READING]: {
    component: ReadingGadget,
    iconUrl: 'https://m.media-amazon.com/images/G/01/courses/soju-player/gadget-icons/Gadget-Reading2x_1.png',
    defaultTitle: 'Reading',
    i18nStrings: defaultI18nStrings.gadgets[GadgetType.READING],
  } as GadgetRenderingConfig,
  [GadgetType.VIDEO]: {
    component: VideoGadget,
    iconUrl: 'https://m.media-amazon.com/images/G/01/courses/soju-player/gadget-icons/Gadget-Video2x.png',
    defaultTitle: 'Video',
    i18nStrings: { video: defaultI18nStrings.gadgets[GadgetType.VIDEO], markdown: defaultI18nStrings.gadgets[GadgetType.MARKDOWN] },
  } as GadgetRenderingConfig,
  [GadgetType.HEADER]: {
    component: SectionGadget,
    iconUrl: 'https://m.media-amazon.com/images/I/11bwThP1AVL.png',
    defaultTitle: 'Section',
    i18nStrings: defaultI18nStrings.gadgets[GadgetType.HEADER],
  } as GadgetRenderingConfig,
  [GadgetType.SLIDESHOW]: {
    component: SlideshowGadget,
    iconUrl: 'https://m.media-amazon.com/images/I/01BwQcbyMVL.png',
    defaultTitle: 'Slideshow',
    i18nStrings: defaultI18nStrings.gadgets[GadgetType.SLIDESHOW],
  } as GadgetRenderingConfig,
  [GadgetType.RESPONSE]: {
    component: ResponseGadget,
    iconUrl: 'https://m.media-amazon.com/images/I/01u8Elhy4vL.png',
    defaultTitle: 'Response',
    i18nStrings: defaultI18nStrings.gadgets[GadgetType.RESPONSE],
  } as GadgetRenderingConfig,
  [GadgetType.MARKDOWN]: {
    component: MarkdownGadget,
    iconUrl: 'https://m.media-amazon.com/images/G/01/courses/soju-player/gadget-icons/Gadget-Reading2x_1.png',
    defaultTitle: 'Markdown',
    i18nStrings: defaultI18nStrings.gadgets[GadgetType.MARKDOWN],
  } as GadgetRenderingConfig,
  [GadgetType.LAB]: {
    component: LabGadget,
    iconUrl: 'https://m.media-amazon.com/images/I/01u8Elhy4vL.png',
    defaultTitle: 'CloudLabs',
    i18nStrings: defaultI18nStrings.gadgets[GadgetType.LAB]
  } as GadgetRenderingConfig,
} as unknown as GadgetRenderer;
