import psl, {ParsedDomain} from 'psl';

/**
 * util function to test if a given link href is considered "internal" to amazon
 * @param href the link url
 */
export const linkIsInternal = (href: string) => {
  return _linkIsInternal(href, window.location.hostname);
};

/**
 * private implementation of linkIsInternal that accepts the current location's hostname as a parameter.
 * this makes unit testing this function easier. Do not import and use this directly.
 *
 * @param href the link url
 * @param currentLocationHostname the hostname of the current window location, i.e. window.location.hostname
 */
export const _linkIsInternal = (href: string, currentLocationHostname: string) => {
  try {
    const url = new URL(href || '');
    const {
      sld: urlSld
    } = psl.parse(url.hostname) as ParsedDomain;
    const {
      sld: currentLocationSld
    } = psl.parse(currentLocationHostname) as ParsedDomain;
    return urlSld === currentLocationSld;
  } catch (e) {
    return false;
  }
};
