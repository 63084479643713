/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import shaka from 'shaka-player/dist/shaka-player.ui';

/**
 * Extending Shaka UI PipButton for adding new functionality
 */
export class PipButton extends shaka.ui.PipButton {

  static BUTTON_NAME = 'soju_pip';
  static pipTooltipMessage = 'Picture in picture (i)';

  static setPipTooltipMessage(message: string) { this.pipTooltipMessage = message; }

  button: HTMLButtonElement;
  localVideo: HTMLMediaElement;

  constructor(parent: HTMLElement, controls: shaka.ui.Controls) {
    super(parent, controls);

    this.button = <HTMLButtonElement>parent.lastChild;
    // @ts-ignore
    // Remove default pip icon.
    this.button.removeChild(this.button.firstChild);
    this.button.classList.add('material-icons-round', 'shaka-tooltip', 'enter-pip');
    this.updateTooltip();

    this.localVideo = controls.getLocalVideo();

    // @ts-ignore
    this.eventManager.listen(this.localVideo, 'enterpictureinpicture', () => {
      this.button.classList.remove('enter-pip');
      this.button.classList.add('exit-pip');
      this.disableCaptions();
      this.updateTooltip();
    });

    // @ts-ignore
    this.eventManager.listen(this.localVideo, 'leavepictureinpicture', () => {
      this.button.classList.remove('exit-pip');
      this.button.classList.add('enter-pip');
      this.updateTooltip();
    });
  }

  disableCaptions() {
    // @ts-ignore
    if (this.player && this.player.isTextTrackVisible()) {
      // @ts-ignore
      this.player.setTextTrackVisibility(false);
    }
  }

  updateTooltip() {
    this.button.ariaLabel = PipButton.pipTooltipMessage;
  }
}


export class PipButtonFactory {
  create(rootElement: HTMLElement, controls: shaka.ui.Controls) {
    return new PipButton(rootElement, controls);
  }
}

shaka.ui.Controls.registerElement(PipButton.BUTTON_NAME, new PipButtonFactory());
