import {CoursePosition, DisplaySection, Lesson, useCourseContext} from '../../../context/course';
import React, {FunctionComponent, useCallback, useEffect, useState, useRef} from 'react';
import {head, noop, isEmpty} from 'lodash';
import {ConnectDragPreview} from 'react-dnd';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';
import Input from '@amzn/meridian/input';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Box from '@amzn/meridian/box';
import Modal, {ModalFooter} from '@amzn/meridian/modal';
import Menu, {MenuItem} from '@amzn/meridian/menu';
import chevronUpSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-up-small';
import chevronDownSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import menuMeatballTokens from '@amzn/meridian-tokens/base/icon/menu-meatball';
import viewTokens from '@amzn/meridian-tokens/base/icon/view';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import pencilTokens from '@amzn/meridian-tokens/base/icon/pencil';
import cx from 'classnames';
import {useCourseAuthoringContext} from '../../context';
import {GadgetSection} from '../../../components/navigation/sidebar/Sidebar';
import {defaultI18nStrings} from '../../../context/course/models/I18n';

/**
 * Lesson ToC section props
 */
interface LessonToCSectionProps {

  /**
   * Lesson for this section
   */
  lesson: Lesson;

  /**
   * Function to find the lesson index in the course
   */
  findLessonIndex: (lesson: Lesson) => number;

  /**
   * Moves the lesson to a new position
   * @param lessonId ID of the gadget to move
   * @param index new index
   */
  moveLesson: (lessonId: string, index: number) => void;

  /**
   * Whether this lesson should render expanded
   */
  isExpanded: boolean;

  /**
   * Learner's position in the course
   */
  coursePosition: CoursePosition | null;

  /**
   * optional callback that is called when this lesson section is clicked
   * @param lessonId
   */
  onClick?: (lessonId: string) => void;

  /**
   * optional callback that is called when a gadget section is clicked
   * @param gadgetId
   */
  onGadgetClick?: (gadgetId: string) => void;

  dragPreview?: ConnectDragPreview;
}

export const LessonToCSection: FunctionComponent<LessonToCSectionProps> = (
  {
    isExpanded: isActive,
    lesson,
    moveLesson,
    findLessonIndex,
    coursePosition,
    onClick = noop,
    onGadgetClick = noop,
    dragPreview
  }: LessonToCSectionProps
) => {

  const overflowMenuButtonRef = useRef();
  const [isExpanded, setIsExpanded] = useState(isActive);
  const [isOverflowMenuOpen, setOverflowMenuOpen] = useState(false);
  const [isLessonEditable, setLessonEditable] = useState(false);
  const [isDeletingLesson, setDeletingLesson] = useState(false);
  const [lessonTitle, setLessonTitle] = useState<string>(lesson.title);

  const {
    setCoursePosition,
  } = useCourseContext();

  const {
    removeLesson,
    updateContentTitle,
    previewDraftCourse
  } = useCourseAuthoringContext();

  // effect to open the section if the lesson becomes active
  useEffect(() => {
    if (isActive) {
      setIsExpanded(isActive);
    }
  }, [isActive]);

  useEffect(() => {
    // If new title comes from props means it was update from a different source
    // Update local state to make it match with what we have in Recoil
    if(lesson.title !== lessonTitle) {
      setLessonTitle(lesson.title);
    }
  }, [lesson?.title]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const onPreviewLesson = useCallback(() => {
    const lessonIndex = findLessonIndex(lesson);
    previewDraftCourse(lessonIndex + 1);
  }, [lesson, previewDraftCourse, findLessonIndex]);

  const onRemoveLessonClick = useCallback(() => {
    setOverflowMenuOpen(false);
    setDeletingLesson(true);
  }, [removeLesson, lesson.id]);

  const onEditLessonClick = useCallback(() => {
    setOverflowMenuOpen(false);
    setLessonEditable(true);
  }, [setOverflowMenuOpen, setLessonEditable]);


  const onUpdateLessonTitle = useCallback((newTitle: string) => {
    // update local state
    setLessonTitle(newTitle);

    // save title
    updateContentTitle(lesson.id, newTitle);
  }, [lesson.id, updateContentTitle]);

  const onLessonTitleClick = useCallback(() => {
    const gadgetId = head(lesson.gadgets)?.id;
    setCoursePosition({
      displaySection: DisplaySection.LESSON,
      lessonId: lesson.id,
      gadgetId: gadgetId || '',
      updatedAt: new Date()
    });
    onClick(lesson.id);
  }, [lesson.gadgets, lesson.id, onClick, setCoursePosition]);

  const chevronTokens = isExpanded ? chevronUpSmallTokens : chevronDownSmallTokens;

  const isLessonActive = lesson.id === coursePosition?.lessonId;

  const lessonContainerStyles = cx(
    'lesson-container',
    {
      'lesson-active': isLessonActive
    }
  );

  const gadgetSectionId = `${lesson.id}-gadgets`;

  return (
    <Box className={lessonContainerStyles}>
      <Box backgroundColor='secondary' ref={dragPreview}>
        <Row className='lesson-title' widths={['fill', 'fit', 'fit']} spacing={'xxsmall'} spacingInset={'200 100 200 300'}>
          {isLessonEditable
            ? <Input
              placeholder='Lesson title'
              value={lessonTitle}
              onChange={onUpdateLessonTitle}
              onBlur={() => setLessonEditable(false)}
              onKeyDown={e => e.key ==='Enter' && setLessonEditable(false)}
            />
            : 
            <div onClick={onLessonTitleClick}>
              <Text type='b200'>
                <strong>
                  <Link
                    aria-label={`go to lesson: ${lesson.title}`}
                    type='secondary'
                    onClick={onLessonTitleClick}
                  >
                    {lesson.title}
                  </Link>
                </strong>
              </Text>
            </div>
          }
          {!isEmpty(lesson.gadgets) && <Button
            label={isExpanded ? `collapse lesson contents for "${lesson.title}"` : `expand lesson contents for "${lesson.title}"`}
            aria-expanded={isExpanded ? 'true' : 'false'}
            aria-controls={gadgetSectionId}
            type='icon'
            size='small'
            onClick={toggleExpansion}
          >
            <Icon tokens={chevronTokens}/>
          </Button>}
          <Button
            label={`Show lesson options for "${lesson.title}"`}
            type='icon'
            size='small'
            onClick={() => setOverflowMenuOpen(true)}
            ref={overflowMenuButtonRef}
          >
            <Icon className='overflow-menu-icon' tokens={menuMeatballTokens}/>
          </Button>
          <Menu
            anchorNode={overflowMenuButtonRef.current}
            open={isOverflowMenuOpen}
            onClose={() => setOverflowMenuOpen(false)}
            position='right'
            alignment='top'
          >
            <MenuItem onClick={onPreviewLesson}>
              <Row widths={[24, 'fill']} spacing='400'>
                <Icon className='ContentHeader__menu-icon' tokens={viewTokens} /> <Text tag='span'>Preview</Text>
              </Row>
            </MenuItem>
            <MenuItem onClick={onRemoveLessonClick}>
              <Row widths={[24, 'fill']} spacing='400'>
                <Icon className='ContentHeader__menu-icon' tokens={trashTokens} /> <Text tag='span'>Delete</Text>
              </Row>
            </MenuItem>
            <MenuItem onClick={onEditLessonClick}>
              <Row widths={[24, 'fill']} spacing='400'>
                <Icon className='ContentHeader__menu-icon' tokens={pencilTokens} /> <Text tag='span'>Rename</Text>
              </Row>
            </MenuItem>
          </Menu>
        </Row>
      </Box>

      {isExpanded && !isEmpty(lesson.gadgets) && <div id={gadgetSectionId} className='gadgets-container show'>
        <ul className='gadget-list' aria-label={`lesson contents for "${lesson.title}"`} >
          {lesson.gadgets.map(gadget => <li key={`toc-${gadget.id}`}>
            <GadgetSection
              coursePosition={coursePosition}
              onClick={onGadgetClick}
              key={gadget.id}
              lessonId={lesson.id}
              gadget={gadget}
              i18nStrings={defaultI18nStrings.sidebar}
            />
          </li>)}
        </ul>
      </div>}
      <Modal open={isDeletingLesson} title='Delete Lesson'>
        <Text>
          Are you sure you want to delete <strong>&quot;{lesson.title}&quot;</strong>? This action cannot be undone.
        </Text>
        <ModalFooter>
          <Row spacing='medium' alignmentHorizontal='right'>
            <Button
              type='secondary'
              label='Cancel delete lesson'
              onClick={() => setDeletingLesson(false)}
            >Cancel</Button>
            <Button
              label='Confirm delete lesson'
              onClick={() => {
                removeLesson(lesson.id, findLessonIndex(lesson));
                setDeletingLesson(false);
              }}
            >Delete</Button>
          </Row>
        </ModalFooter>
      </Modal>
    </Box>
  );
};
