import React, {FunctionComponent} from 'react';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Loader from '@amzn/meridian/loader';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import get from 'lodash/get';

import CompleteCourseModal from './CompleteCourseModal';
import CourseStatusCheckbox from './CourseStatusCheckbox';

import '../styles/progressTracker.scss';
import {CourseProgress, ProgressStatus} from '../../../../context/course';
import {
  CompleteCourseModalI18nStrings,
  CourseStatusCheckboxI18nStrings,
  ProgressTrackerIndicatorI18nStrings, WithI18nStringsProps,
} from '../../../../context/course/models/I18n';

/**
 * Progress tracker indicator props
 */
interface ProgressTrackerIndicatorProps extends WithI18nStringsProps<{
  completeCourseModal: CompleteCourseModalI18nStrings;
  courseStatusCheckbox: CourseStatusCheckboxI18nStrings;
  progressTrackerIndicator: ProgressTrackerIndicatorI18nStrings;
}> {
  /**
   * Course title
   */
  courseTitle: string;

  /**
   * Learner's progress
   */
  courseProgress: CourseProgress | null;
}

class ProgressTrackerIndicator extends React.Component<ProgressTrackerIndicatorProps> {
  state = { isCourseCompleteModalOpen: false };

  componentDidUpdate(prevProps: ProgressTrackerIndicatorProps) {
    const prevPercentage = get(prevProps, 'courseProgress.completionPercentage', 0);
    const currentPercentage = get(this.props, 'courseProgress.completionPercentage', 0);
    const currentStatus = get(this.props, 'courseProgress.status', ProgressStatus.IN_PROGRESS);

    // @ts-ignore
    if(prevPercentage < 100 && currentPercentage === 100 && currentStatus !== ProgressStatus.COMPLETED) {
      this.setState({isCourseCompleteModalOpen: true});
    }
  }

  closeCourseCompleteModal = () => {
    this.setState({isCourseCompleteModalOpen: false});
  }

  render() {
    const { courseProgress, courseTitle, i18nStrings } = this.props;
    const { isCourseCompleteModalOpen } = this.state;
    const percentComplete = courseProgress?.completionPercentage || 0;
    const courseProgressStatus = courseProgress?.status || ProgressStatus.IN_PROGRESS;
    const isCourseCompleted = courseProgressStatus === ProgressStatus.COMPLETED;

    return (
      <React.Fragment>
        {isCourseCompleteModalOpen &&
          <CompleteCourseModal closeModal={this.closeCourseCompleteModal} i18nStrings={i18nStrings.completeCourseModal}>
            <div className={'progressTrackerIndicator__modal__completed__icon'} />
            <Heading level={4} alignment='center' type='h400'>{i18nStrings.progressTrackerIndicator.greatJob}</Heading>
            <Text alignment='center'>{i18nStrings.progressTrackerIndicator.youFinishedAllLessonsInCourse(courseTitle)}</Text>
            <Text alignment='center'>{i18nStrings.progressTrackerIndicator.wouldYouLikeMarkCourseComplete}</Text>
          </CompleteCourseModal>
        }
        {isCourseCompleted
          ? <CourseCompleteView i18nStrings={i18nStrings.courseStatusCheckbox} />
          : <DefaultView percentComplete={percentComplete} i18nStrings={i18nStrings} />}
      </React.Fragment>
    );
  }
}

const CourseCompleteView: FunctionComponent<{i18nStrings: CourseStatusCheckboxI18nStrings}> = ({i18nStrings}) => {
  return (
    <div className={'progressTrackerIndicator__container__completed'}>
      <Box
        className={'progressTrackerIndicator__container__completed__box'}
        backgroundColor='accent'
        spacingInset='200 400'
      >
        <Row widths={['fill', 'fit']} spacing='small' alignmentHorizontal={'center'}>
          <Text type='b100'><strong>{i18nStrings.courseCompleted}</strong></Text>
          <CourseStatusCheckbox isCourseCompleted={true} i18nStrings={i18nStrings}/>
        </Row>
      </Box>
    </div>
  );
};

const DefaultView: FunctionComponent<{
  percentComplete: number,
  i18nStrings: {
    courseStatusCheckbox: CourseStatusCheckboxI18nStrings;
    progressTrackerIndicator: ProgressTrackerIndicatorI18nStrings;
  }}> = ({
    percentComplete ,
    i18nStrings,
  }) => {
    return (
      <Row
        className={'progressTrackerIndicator__container'}
        widths={['fill', 'fit', 'fit']}
        spacing='small'
        spacingInset='200 none'
      >
        <Loader
          percentage={percentComplete}
          type='linear'
          showPercentageText={false}
        />
        <span className='progressTrackerIndicator__label'>{i18nStrings.progressTrackerIndicator.courseCompletionPercentage(percentComplete)}</span>
        <span aria-hidden><Text type='b100' color='secondary'><strong>{i18nStrings.progressTrackerIndicator.percentComplete(percentComplete)}</strong></Text></span>
        <CourseStatusCheckbox isCourseCompleted={false} i18nStrings={i18nStrings.courseStatusCheckbox}/>
      </Row>
    );
  };

export default ProgressTrackerIndicator;
