import React, {FunctionComponent} from 'react';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Column from '@amzn/meridian/column';
import Responsive from '@amzn/meridian/responsive';
import Row from '@amzn/meridian/row';
import Theme from '@amzn/meridian/theme';
import Text from '@amzn/meridian/text';
import menuTokens from '@amzn/meridian-tokens/base/icon/menu';
import arrowLeftSmallTokens from '@amzn/meridian-tokens/base/icon/arrow-left-small';
import arrowRightSmallTokens from '@amzn/meridian-tokens/base/icon/arrow-right-small';
import blueDarkTokens from '@amzn/meridian-tokens/theme/blue-dark';
import brandedDarkTokens from '../../../theme/branded-dark';
import {CoursePosition, DisplaySection, Lesson, useCourseContext} from '../../../context/course';
import {findIndex, noop} from 'lodash';
import {LearnerLessonActivity} from '../../../activity/models/learner-activities';
import {LearnerVerb} from '../../../activity/models/verbs';
import {LearnerObject} from '../../../activity/models/objects';
import {SidebarId} from '../sidebar/Sidebar';
import {CourseExitButton, CourseExitProps} from '../../containers/course-player/components/CourseExitButton';
import { defaultI18nStrings, TopNavI18nStrings, WithI18nStringsProps } from '../../../context/course/models/I18n';

// Consuming enum directly due Jest issue (https://github.com/kulshekhar/ts-jest/issues/281)
import {SidebarState} from '../../containers/course-player/models/PlayerUIModes';

import './topnav.scss';
import responsiveStyles from '../../../styles/responsive.module.scss';

export type LessonClickHandlerArgs = {
  id: string,
  index: number,
  title: string
}

/**
 * PlayerFooter props
 */
export interface TopNavProps extends WithI18nStringsProps<TopNavI18nStrings | undefined> {

  /**
   * Course lessons
   */
  lessons: Lesson[];

  /**
   * Learner's position in the course
   */
  coursePosition: CoursePosition | null;

  /**
   * Sidebar state
   */
  sidebarState: SidebarState;

  /**
   * props for the right-side button that is meant to provide an exit point from the course player
   */
  courseExitButtonProps?: CourseExitProps;

  /**
   * Callback when the learner clicks on the menu button
   */
  onClickMenu: () => void;

  /**
   * Callback when the learner clicks the next lesson button
   */
  onNextLessonClick?: (nextLesson: LessonClickHandlerArgs) => void;

  /**
   * Callback when the learner clicks the previous lesson button
   */
  onPreviousLessonClick?: (prevLesson: LessonClickHandlerArgs) => void;
}

/**
 * Player footer component
 */
export const TopNav: FunctionComponent<TopNavProps> = ({
  lessons,
  coursePosition,
  sidebarState,
  courseExitButtonProps,
  onClickMenu,
  onPreviousLessonClick = noop,
  onNextLessonClick = noop,
  i18nStrings = defaultI18nStrings.topNav,
}: TopNavProps) => {

  const {
    setCoursePosition,
    emitLearnerActivity,
  } = useCourseContext();

  const currentLessonIndex = findIndex(lessons, {id: coursePosition?.lessonId});

  const isFirstLesson = currentLessonIndex === 0;
  const isLastLesson = currentLessonIndex === lessons.length - 1;

  const onPrevLesson = () => {
    if (!isFirstLesson) {
      const previousLesson = lessons[currentLessonIndex - 1];
      const prevGadgetId = previousLesson.gadgets[0]?.id || '';
      setCoursePosition({
        displaySection: DisplaySection.LESSON,
        lessonId: previousLesson.id,
        gadgetId: prevGadgetId,
        updatedAt: new Date()
      });

      onPreviousLessonClick({
        id: previousLesson.id,
        index: currentLessonIndex - 1,
        title: previousLesson.title
      });

      // using lesson id that is coming previous
      emitLearnerActivity(new LearnerLessonActivity(
        LearnerVerb.OPENED,
        LearnerObject.LESSON,
        previousLesson.id,
      ));
    }
  };

  const onNextLesson = () => {
    if (!isLastLesson) {
      const nextLesson = lessons[currentLessonIndex + 1];
      const nextGadgetId = nextLesson.gadgets[0]?.id || '';
      setCoursePosition({
        displaySection: DisplaySection.LESSON,
        lessonId: nextLesson.id,
        gadgetId: nextGadgetId,
        updatedAt: new Date()
      });

      onNextLessonClick({
        id: nextLesson.id,
        index: currentLessonIndex + 1,
        title: nextLesson.title
      });

      // using lesson id that is coming next
      emitLearnerActivity(new LearnerLessonActivity(
        LearnerVerb.OPENED,
        LearnerObject.LESSON,
        nextLesson.id,
      ));
    }

  };

  const showSidebarButton = sidebarState !== SidebarState.NO_SIDEBAR;
  const sidebarExpanded = sidebarState === SidebarState.EXPANDED;
  const shouldRenderCourseExitButton = courseExitButtonProps && (courseExitButtonProps?.href || courseExitButtonProps?.onClick);
  const lessonTitleText = coursePosition?.displaySection === DisplaySection.COMPLETION_PAGE
    ? i18nStrings.congratulations
    : i18nStrings.lessonNumberAndTitle(currentLessonIndex + 1, lessons[currentLessonIndex]?.title || '');

  return (
    <Theme tokens={{
      ...blueDarkTokens,
      ...brandedDarkTokens,
      boxBorderRadiusFill: '0px'
    }}
    >
      <Box type='fill' spacingInset='400' className={'globalNavbar__wrapper'}>
        <header>
          <Row widths={showSidebarButton ? ['fit', 'fill', 'fit'] : ['fill']}>
            {
              showSidebarButton && <Button
                type='secondary'
                onClick={onClickMenu}
                aria-expanded={sidebarExpanded ? 'true' : 'false'}
                aria-controls={SidebarId}
                label={sidebarExpanded ? i18nStrings.collapseMenuLabel : i18nStrings.expandMenuLabel}
              >
                {sidebarExpanded
                  ? <span className={'globalNavbar__courseMenu__button__icon'}/>
                  : <Icon tokens={menuTokens}/> }
                <Responsive
                  query='max-width'
                  props={{
                    showLabel: {
                      default: true,
                      [responsiveStyles.tablet_breakpoint]: false
                    }
                  }}
                >
                  {props => props.showLabel &&
                    <Box>
                      {i18nStrings.courseMenu}
                    </Box>
                  }
                </Responsive>
              </Button>
            }
            <Column className={'globalNavbar__title'} alignmentHorizontal='center' alignmentVertical='center'
              spacing='none'>
              <Text type='b200' color='secondary' alignment='center' className={'globalNavbar__title__text'}>
                <strong>
                  {lessonTitleText}
                </strong>
              </Text>
              <Row className='LessonPagination'>
                <Button
                  className='LessonPagination__left-button'
                  type='icon'
                  size='small'
                  onClick={onPrevLesson}
                  disabled={isFirstLesson}
                  label={!isFirstLesson ? i18nStrings.goBackToLessonLabel(currentLessonIndex) : i18nStrings.noPreviousLessonLabel}
                >
                  <Icon tokens={arrowLeftSmallTokens}/>
                </Button>
                <span className='LessonPagination__label'>
                  <Text type='h100'>{i18nStrings?.lessonNumberOfTotal(currentLessonIndex + 1, lessons.length)}</Text>
                </span>
                <Button
                  className='LessonPagination__right-button'
                  type='icon'
                  size='small'
                  onClick={onNextLesson}
                  disabled={isLastLesson}
                  label={!isLastLesson ? i18nStrings.goForwardToLessonLabel(currentLessonIndex + 2) : i18nStrings.noNextLessonLabel}
                >
                  <Icon tokens={arrowRightSmallTokens}/>
                </Button>
              </Row>
            </Column>
            {shouldRenderCourseExitButton && <div className={'globalNavbar__course-exit'}>
              <CourseExitButton {...courseExitButtonProps} />
            </div>}
          </Row>
        </header>
      </Box>
    </Theme>
  );
};
