import React, {forwardRef, FunctionComponent, useCallback, useState} from 'react';
import {GadgetEditorProps} from '../models';
import {GadgetConfig} from '../../../../context/course';
import Box from '@amzn/meridian/box';
import Input from '@amzn/meridian/input';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import {useCourseAuthoringContext} from '../../../context/CourseAuthoringContextProvider';
import GadgetContainer from '../../../../components/containers/gadget/GadgetContainer';
import {ResponseGadgetI18nStrings} from '../../../../context/course/models/I18n';

/**
 * Section gadget props
 */
export type SectionGadgetProps = GadgetEditorProps<GadgetConfig, ResponseGadgetI18nStrings>

export const SectionGadget: FunctionComponent<SectionGadgetProps> = forwardRef<HTMLElement | undefined, SectionGadgetProps>((
  {
    id: gadgetId,
    config,
  }: SectionGadgetProps,
  ref
) => {
  const [title, setTitle] = useState<string | undefined>(config.title);

  const {updateGadget} = useCourseAuthoringContext();

  const onChangeHandler = useCallback((path, val) => {
    // update local state with new value
    setTitle(val);

    const newConfig = cloneDeep(config);

    // Merge new value into config
    set(newConfig, path, val);

    updateGadget(gadgetId, newConfig);
  }, [config, gadgetId, updateGadget]);

  return (
    <GadgetContainer id={gadgetId} ref={ref}>
      <Box width='100%'>
        <Input
          value={title}
          onChange={val => onChangeHandler('title', val)}
          type='text'
          placeholder={'Section name'}
          size='xlarge'
        />
      </Box>
    </GadgetContainer>
  );
});
SectionGadget.displayName = 'SectionGadget';
