import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Hub, HubCallback } from "@aws-amplify/core";
import { Identity } from "./utils/IdentityFactory";

export const MISSING_ID_TOKEN =
  "Sign in user session does not contain valid id token.";
export const MISSING_HAT_ID =
  "Sign in user session does not contain valid hat id.";

export class AuthApi {
  public static async getIdentity(): Promise<Identity> {
    const user = await Auth.currentAuthenticatedUser();
    if (
      !user ||
      !user.signInUserSession ||
      !user.signInUserSession.idToken ||
      !user.signInUserSession.idToken.jwtToken
    ) {
      throw new Error(MISSING_ID_TOKEN);
    }
    const idToken = user.signInUserSession.idToken;
    if (!idToken.payload || !idToken.payload.hat_id) {
      throw new Error(MISSING_HAT_ID);
    }
    return {
      idToken: idToken.jwtToken,
      hatId: idToken.payload.hat_id,
    };
  }

  public static async handleSignIn() {
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Cognito,
    });
  }

  public static async autoLogin(): Promise<Identity | void> {
    try {
      return await this.getIdentity();
    } catch (error) {
      if (error === "The user is not authenticated") {
        sessionStorage.setItem("redirectPath", window.location.pathname);
        await this.handleSignIn();
      } else {
        console.log(
          "Fail to get Id Token or Hat Id from authenticated user",
          error,
        );
      }
    }
  }

  public static async handleSignOut() {
    await Auth.signOut();
  }

  public static registerAuthListener(
    setIdentity: (props: Identity) => void,
  ): () => void {
    const listener: HubCallback = async ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          setIdentity(await this.getIdentity());
          break;
        case "signOut":
          setIdentity({ idToken: "", hatId: "" });
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    };
    Hub.listen("auth", listener);
    return () => {
      Hub.remove("auth", listener);
    };
  }
}
