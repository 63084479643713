import React, {forwardRef, FunctionComponent, useCallback, useEffect, useState} from 'react';
import map from 'lodash/map';
import {GadgetEditorProps} from '../models';
import Divider from '@amzn/meridian/divider';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Textarea from '@amzn/meridian/textarea';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import Theme from '@amzn/meridian/theme';
import brandedLightTokens from '../../../../theme/branded-light';
import {ResponseGadgetChallenge} from './ResponseGadgetChallenge';
import {useCourseAuthoringContext} from '../../../context';
import {ResponseChallenge, ResponseGadgetConfig} from '../../../../components/gadgets/response';
import {shortId} from '../../../../gadgets/utils/shortId';
import GadgetContainer from '../../../../components/containers/gadget/GadgetContainer';
import {
  AssessmentGadgetSettings,
  AssessmentGadgetSettingsType
} from '../../assessment-gadget-settings/AssessmentGadgetSettings';
import {ResponseGadgetI18nStrings} from '../../../../context/course/models/I18n';

const LABELS = {
  addQuestionLabel: 'Add a question',
  titlePlaceholder: 'Response gadget title (recommended)',
  titleAriaLabel: 'Response gadget title',
  instructionsLabel: 'Instructions (recommended)',
  instructionsHelperText: 'Response title and instructions are optional but recommended'
};

export type ResponseGadgetProps = GadgetEditorProps<ResponseGadgetConfig, ResponseGadgetI18nStrings>

/**
 * Use this gadget to add an evaluation widget to your course.
 * This evaluation gadget is slightly different than the Quiz gadget; instead of only supporting multiple choice answers, the Response gadget allows the learner to enter free-form text answers.
 * Similar to the Quiz gadget, the response gadget sends the Learner state to the backend, the backend evaluates is and returns a ResponseEvaluationResults object.
 */
export const ResponseGadget: FunctionComponent<ResponseGadgetProps> = forwardRef<HTMLElement | undefined, ResponseGadgetProps>((
  {
    id: gadgetId,
    config
  }: ResponseGadgetProps,
  ref
) => {
  const [title, setTitle] = useState<string | undefined>(config.title);
  const [instructions, setInstructions] = useState<string | undefined>(config.instructions);
  const [challenges, setChallenges] = useState<ResponseChallenge[]>(config.challenges || []);
  const [gadgetSettings, setGadgetSettings] = useState<AssessmentGadgetSettingsType>(config.settings);

  const addChallenge = useCallback(() => {
    setChallenges(currentChallenges => {
      return [...currentChallenges, generateEmptyChallenge()];
    });
  }, []);

  const onDeleteChallenge = useCallback((i: number) => {
    setChallenges(currentChallenges => {
      const newChallenges = [...currentChallenges];
      newChallenges.splice(i, 1);
      return newChallenges;
    });
  }, []);

  const onUpdateChallenge = useCallback((i: number, value: ResponseChallenge) => {
    setChallenges(currentChallenges => {
      const newChallenges = [...currentChallenges];
      newChallenges[i] = value;
      return newChallenges;
    });
  }, []);

  const {updateGadget} = useCourseAuthoringContext();

  useEffect(() => {
    updateGadget(gadgetId, {
      title,
      instructions,
      challenges,
      settings: gadgetSettings
    } as ResponseGadgetConfig);
  }, [title, instructions, challenges, updateGadget, gadgetId, gadgetSettings]);

  return (
    <GadgetContainer id={gadgetId} ref={ref}>
      <Theme tokens={{...brandedLightTokens}}>
        <>
          <Column spacing='small' spacingInset='none none xlarge none'>
            <Input
              aria-label={LABELS.titleAriaLabel}
              value={title}
              onChange={setTitle}
              type='text'
              placeholder={LABELS.titlePlaceholder}
              size='xlarge'
            />
            <Textarea
              value={instructions || ''}
              onChange={setInstructions}
              label={LABELS.instructionsLabel}
              size='medium'
              helperText={LABELS.instructionsHelperText}
            />
          </Column>

          <Column>
            <AssessmentGadgetSettings
              settings={gadgetSettings}
              updateAssessmentGadgetSettings={setGadgetSettings}
              gadgetName={'Response'}
            />
          </Column>

          <Column spacing='large'>
            {map(challenges, (challenge: ResponseChallenge, iChallenge: number) => {
              const challengeKey = `${gadgetId}-responseGadget-challenge-${challenge.id}`;
              return (
                <React.Fragment key={challengeKey}>
                  <ResponseGadgetChallenge
                    challengeKey={challengeKey}
                    index={iChallenge}
                    onDeleteChallenge={() => onDeleteChallenge(iChallenge)}
                    onUpdateChallenge={(val: ResponseChallenge) => onUpdateChallenge(iChallenge, val)}
                    {...challenge}
                  />
                  <Divider />
                </React.Fragment>
              );
            })}
            <Row alignmentHorizontal='right'>
              <Button onClick={addChallenge}>
                <Icon tokens={plusTokens} /> {LABELS.addQuestionLabel}
              </Button>
            </Row>
          </Column>
        </>
      </Theme>
    </GadgetContainer>
  );
});

const generateEmptyChallenge = () => {
  return {
    id: shortId(),
    prompt: '',
    answers: [{
      id: shortId(),
      answer: ''
    }]
  };
};
ResponseGadget.displayName = 'ResponseGadget';
