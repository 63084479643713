import React, {ClipboardEvent, FunctionComponent, PropsWithChildren, ReactNode, useState} from 'react';
import {ErrorCode, FileRejection, useDropzone} from 'react-dropzone';
import isEmpty from 'lodash/isEmpty';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';
import {useCourseAuthoringContext} from '../../context';
import {AssetModel} from '../../../context/course';
import {
  captionsFileTypes,
  downloadableFileTypes,
  imageFileTypes,
  textTranscriptFileTypes,
  videoFileTypes
} from './Constants';
import {CreateAssetRequest} from '../../context/models/CreateAssetRequest';
import {AxiosError} from 'axios';
import {
  AssetUploadI18nStrings,
  WithI18nStringsProps
} from '../../../context/course/models/I18n';
import {formatBytes} from '../../../utils/FileUtils';

export enum AssetType {
  CAPTIONS = 'CAPTIONS',
  IMAGE = 'IMAGE',
  SUBTITLE = 'SUBTITLE',
  TEXT_TRANSCRIPT = 'TEXT-TRANSCRIPT',
  VIDEO = 'VIDEO',
  DOWNLOADABLE = 'DOWNLOADABLE'
}

export interface AssetDataModel {
  video?: { assetId: string };
  image?: {
    assetId: string,
    contentType?: string,
    type?: string,
    title?: string
  };
  videoCaptions?: { assetId: string };
  downloadable?: {
    assetId: string,
    title?: string
    contentType?: string
  }
  videoTextTranscript?: { assetId: string };
}

export interface NoClickProps {
  label: string;
  uploadLabel: string;
  uploadOptionLabel: string;
}

export interface InvalidFile {
  file: File;
  error: {
    title: string,
    message: string
  };
}

export interface AssetUploaderProps extends WithI18nStringsProps<AssetUploadI18nStrings> {
  supportedFileTypes: Record<string, string[]>;
  maxSize?: number
  assetType?: string;
  children: ReactNode;
  updateAssetData: (asset: AssetDataModel) => void;
  onUploadStart?: (fileName: string) => void;
  onUploadSuccess: (fileUploadStatusMessage: {assetId: string, fileName: string}) => void;
  onUploadFailure: (invalidFiles: InvalidFile[]) => void;
  noClick?: NoClickProps;
  onRejection: (invalidFiles: InvalidFile[]) => void;
}

export enum Tags {
  CAPTIONS = 'captions',
  IMAGE = 'image',
  DOWNLOADABLE = 'downloadable',
  SUBTITLES = 'subtitles',
  VIDEO = 'video'
}

const getSupportedFileTypeExtensions = (supportedFileTypes: Record<string, string[]>): string[] => {
  return Object.values(supportedFileTypes).flatMap(value => value);
};
const constructFileTypeToAssetType = () => {
  const fileTypeToAssetType: Record<string, AssetType> = {};
  const convertFileTypesToAssetType = (fileTypes: string[], assetType: AssetType) => {
    fileTypes.forEach(value => fileTypeToAssetType[value] = assetType);
  };
  convertFileTypesToAssetType(videoFileTypes, AssetType.VIDEO);
  convertFileTypesToAssetType(captionsFileTypes, AssetType.SUBTITLE);
  convertFileTypesToAssetType(textTranscriptFileTypes, AssetType.SUBTITLE);
  convertFileTypesToAssetType(imageFileTypes, AssetType.IMAGE);
  convertFileTypesToAssetType(getSupportedFileTypeExtensions(downloadableFileTypes), AssetType.DOWNLOADABLE);
  return fileTypeToAssetType;
};
const fileTypeToAssetType = constructFileTypeToAssetType();

export const AssetUploader: FunctionComponent<AssetUploaderProps> = (
  {
    supportedFileTypes,
    maxSize,
    assetType,
    children,
    updateAssetData,
    onUploadStart,
    onUploadFailure,
    onUploadSuccess,
    noClick,
    onRejection,
    i18nStrings
  }: PropsWithChildren<AssetUploaderProps>) => {

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const {createAsset, uploadFileAssetToS3} = useCourseAuthoringContext();

  const onDrop = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (!isEmpty(acceptedFiles)) {
      // NOTE: should we handle multiple files in the future?
      await uploadAsset(acceptedFiles[0]);
    }
    if (!isEmpty(fileRejections)) {
      const errorMessage = (maxSize && fileRejections[0].errors.some(error => error.code === ErrorCode.FileTooLarge))
        ? i18nStrings.fileSizeTooLargeError(fileRejections[0].file.name, formatBytes(maxSize, 1))
        : i18nStrings.fileUploadErrorDueToUnsupportedFileType(fileRejections[0].file.name, getSupportedFileTypeExtensions(supportedFileTypes).join(', '));

      onRejection([{
        file: fileRejections[0].file,
        error: {
          title: i18nStrings.fileUploadErrorGenericTitle,
          message: errorMessage
        }
      }]);
    }
  };

  const {getRootProps, getInputProps, open} = useDropzone({
    onDrop,
    accept: supportedFileTypes,
    maxFiles: 1,
    maxSize,
    noClick: !isEmpty(noClick),
    // To not allow selection of multiple files until we support it.
    multiple: false,
    useFsAccessApi: false
  });

  const onPasteHandler = async (inputElement: ClipboardEvent<HTMLInputElement>) => {
    if (!isEmpty(inputElement.clipboardData.files)) {
      const file = inputElement.clipboardData.files[0];
      if (maxSize && file.size > maxSize) {
        onRejection([{
          file,
          error: {
            title: i18nStrings.fileUploadErrorGenericTitle,
            message: i18nStrings.fileSizeTooLargeError(file.name, formatBytes(maxSize, 1))
          }
        }]);
        return;
      }
      await uploadAsset(inputElement.clipboardData.files[0]);
    }
  };

  /**
   * Upload asset to S3.
   */
  const uploadFileToS3 = async (asset: AssetModel, file: File) => {
    await uploadFileAssetToS3(asset.location as string, file);
    const givenAssetType = assetType || asset.type;
    switch (givenAssetType) {
      case AssetType.IMAGE:
        updateAssetData({
          image: {
            assetId: asset.id,
            contentType: imageContentType(file.name),
            title: asset.title
          }
        });
        break;
      case AssetType.VIDEO:
        updateAssetData({video: {assetId: asset.id}});
        break;
      case AssetType.CAPTIONS:
        updateAssetData({videoCaptions: {assetId: asset.id}});
        break;
      case AssetType.TEXT_TRANSCRIPT:
        updateAssetData({videoTextTranscript: {assetId: asset.id}});
        break;
      case AssetType.DOWNLOADABLE:
        updateAssetData({
          downloadable: {
            assetId: asset.id,
            title: asset.title,
            contentType: asset.contentType
          }
        });
        break;
    }
  };

  const getExtension = (fileName: string) => {
    const fileNameSplit = fileName.split('.');
    return fileNameSplit[fileNameSplit.length - 1] || '';
  };

  const videoContentType = (fileName: string) => {
    return 'video/' + getExtension(fileName);
  };

  const imageContentType =(fileName: string) => {
    return 'image/' + getExtension(fileName);
  };

  const captionsContentType = (fileName: string) => {
    const extension = getExtension(fileName).toLowerCase();
    switch (extension) {
      case null:
      case undefined:
      case '':
      case 'txt':
        return 'text/plain';
      case 'vtt':
        return 'text/vtt';
      default:
        return null;
    }
  };

  // Extra check to filter invalid file types.
  const isValidFile = (supportedFileTypes: string[], fileExtension: string) => {
    return supportedFileTypes.includes(fileExtension.toLowerCase());
  };

  const prepareCreateAssetRequest = (file: File) => {
    const fileExtension = '.' + getExtension(file.name);
    const supportedFileTypeExtensions = getSupportedFileTypeExtensions(supportedFileTypes);
    if (!isValidFile(supportedFileTypeExtensions, fileExtension)) {
      onRejection([{
        file,
        error: {
          title: i18nStrings.fileUploadErrorGenericTitle,
          message: i18nStrings.fileUploadErrorDueToUnsupportedFileType(file.name, supportedFileTypeExtensions.join(', '))
        }
      }]);
      return null;
    }
    const assetType = fileTypeToAssetType[fileExtension];
    switch (assetType) {
      case AssetType.VIDEO:
        return {
          title: file.name,
          type: AssetType.VIDEO,
          tags: [Tags.VIDEO],
          contentType: file.type || videoContentType(file.name)
        } as CreateAssetRequest;
      case AssetType.IMAGE:
        return {
          title: file.name,
          type: AssetType.IMAGE,
          tags: [Tags.IMAGE],
          contentType: file.type || imageContentType(file.name),
        } as CreateAssetRequest;
      case AssetType.SUBTITLE:
        return {
          title: file.name,
          type: AssetType.SUBTITLE,
          tags: [Tags.SUBTITLES, Tags.CAPTIONS],
          contentType: file.type || captionsContentType(file.name),
        } as CreateAssetRequest;
      case AssetType.DOWNLOADABLE:
        return {
          title: file.name,
          type: AssetType.DOWNLOADABLE,
          tags: [Tags.DOWNLOADABLE],
          contentType: file.type,
        } as CreateAssetRequest;
    }
    onRejection([{
      file,
      error: {
        title: i18nStrings.fileUploadErrorGenericTitle,
        message: i18nStrings.fileUploadErrorDueToUnsupportedFileType(file.name, supportedFileTypeExtensions.join(', '))
      }
    }]);
    return null;
  };

  const uploadAsset = async (file: File) => {
    const createAssetRequest = prepareCreateAssetRequest(file);
    if (createAssetRequest !== null) {
      setIsUploading(true);
      if (onUploadStart) {
        onUploadStart(file?.name);
      }
      try {
        const assetModel = await createAsset(createAssetRequest);
        await uploadFileToS3(assetModel, file);
        onUploadSuccess({assetId: assetModel.id, fileName: file?.name});
      } catch (error) {
        onUploadFailure([{
          file,
          error: {
            title: i18nStrings.fileUploadErrorGenericTitle,
            message: (error as AxiosError).response?.status === 400
              ? i18nStrings.fileUploadErrorDueToCorruptedFile(file.name)
              : i18nStrings.fileUploadErrorWithRefresh
          }
        }]);
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <Box>
      <div {...getRootProps({
        onPaste: onPasteHandler
      })} >
        <input data-testid='drop-input' {...getInputProps()} />
        {children}
        {noClick &&
          <div onClick={open}>
            <Box type={'fill'} spacingInset={'400'} backgroundColor={'#F7FAFA'}>
              <Row alignmentHorizontal={'justify'}>
                <Column>
                  {isUploading ? (
                    <Row spacing={'200'}>
                      <Column>
                        <Loader size={'small'}/>
                      </Column>
                      <Column>
                        <Text type={'b200'}>
                          {noClick.uploadLabel}
                        </Text>
                      </Column>
                    </Row>
                  ) : (
                    <Text type={'b200'}>
                      {noClick.label}
                    </Text>
                  )}
                </Column>
                <Column>
                  <Button type='tertiary' size={'small'}>
                    {noClick.uploadOptionLabel}
                  </Button>
                </Column>
              </Row>
            </Box>
          </div>
        }
      </div>
    </Box>
  );
};
