import { useState, useEffect } from 'react';

// https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState

declare global {
  interface Document {
    msHidden?: any;
    webkitHidden?: any;
  }
}

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  } else {
    return undefined;
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  } else {
    return undefined;
  }
}

export function getIsDocumentHidden() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return !document[getBrowserDocumentHiddenProp()];
}

/**
 * Current limitation is that this doesn't catch alt + tabbing, but it will catch:
 * - Switching browser tabs
 * - User agent is minimized
 * - User agent is moved off-screen
 * - Operating system lock screen covers user agent
 */
export function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    if (visibilityChange) {
      document.addEventListener(visibilityChange, onVisibilityChange, false);

      return () => {
        document.removeEventListener(visibilityChange, onVisibilityChange);
      };
    } else {
      console.log('Page Visibility API is not supported on current browser');
    }
  });

  return isVisible;
}
