import {Content, ContentType, Course, CourseApi, CourseModel, Gadget, Lesson, Module} from '../context/course';
import {find, merge} from 'lodash';
import {GadgetConfigAdapterRegistry} from '../context/course/soju/adapters/GadgetConfigAdapterRegistry';
import {
  GadgetLearnerStateConverterRegistry
} from '../context/course/soju/converters/GadgetLearnerStateConverterRegistry';
import {GadgetRenderer} from '../gadgets/registry';
import {GadgetI18nStringSConfig} from '../context/course/models/I18n';
import {GadgetType} from '../context/course/soju/models/GadgetType';
import {AxiosError} from 'axios';

/**
 * takes a list of Contents (which can be Lessons or Modules containing Lessons) and flattens it
 * into a single list of only Lessons. Preserves the relative order of all the lessons, but removes
 * the Module superstructures.
 *
 * @param contents array of Contents to be flattened
 * @returns {array} the flattened array of lessons
 */
export const flattenContentsIntoLessons = (contents: Content[]): Lesson[] => {
  return contents.flatMap(content => {
    if (content.contentType === ContentType.MODULE) {
      return (content as Module).lessons;
    } else {
      return [content as Lesson];
    }
  });
};

export const HEADER_GADGET_TYPE = 'amzn/header';
export function isHeaderGadget(type: string) {
  return type === HEADER_GADGET_TYPE;
}

export function getAssetModelForLearner(courseId: string, courseApi: CourseApi) {
  return async (assetId: string) => {
    try {
      const assetModel = await courseApi.getAssetModel(courseId, assetId);
      if (assetModel.type === 'VIDEO') {
        return assetModel;
      }
      return (assetModel.status === 'AVAILABLE') ? assetModel : Promise.resolve(undefined);
    } catch (error) {
      // If asset was not found
      if ((error as AxiosError).response?.status === 404) {
        return Promise.resolve(undefined);
      }
      // Clients/Gadgets can handle error as they want
      throw error;
    }
  };
}

export async function getCloudLabEmbedInfo(courseId: string, gadgetId: string, courseApi: CourseApi) {
  try {
    return await courseApi.getCloudLabEmbedInfo(courseId, gadgetId);
  } catch(error) {
    return Promise.resolve(undefined);
  }
}

export function getLessonIdFromGadgetId(lessons: Lesson[], gadgetId: string): string {
  const parentLesson = find(lessons, lesson => {
    const gadget = find(lesson.gadgets, { 'id': gadgetId });
    if(gadget) {
      return true;
    }
  }) as Lesson;
  return parentLesson?.id;
}

/**
 * Converts the gadgets inside a Lesson using the adapter/registry passed.
 * @param lesson lesson with the gadgets to convert
 * @param adapterRegistry Gadget's config adapter
 * @param converterRegistry Gadget's learner state converter
 */
export const convertGadgetsInLesson = (
  lesson: Lesson,
  adapterRegistry: GadgetConfigAdapterRegistry,
  converterRegistry: GadgetLearnerStateConverterRegistry
): Lesson => {
  const gadgets = lesson.gadgets.map((gadget: Gadget): Gadget => {
    let updateGadget: Gadget = gadget;
    if (adapterRegistry.shouldAdaptGadgetType(updateGadget.type)) {
      updateGadget = {
        ...updateGadget,
        config: adapterRegistry.adaptConfig(updateGadget.type, updateGadget.config),
      };
    }

    if (updateGadget.learnerState && converterRegistry.shouldConvertGadgetType(updateGadget.type)) {
      updateGadget = {
        ...updateGadget,
        learnerState: converterRegistry.toGadgetLearnerState(updateGadget.type, updateGadget.learnerState),
      };
    }

    return updateGadget;
  });
  return {
    ...lesson,
    gadgets,
  };
};

/**
 * Converts a CourseModel to a Course, flattening the lessons inside the contents of the course
 * @param courseModel course model to convert
 * @param adapterRegistry Gadget's config adapter
 * @param converterRegistry Gadget's learner state converter
 */
export const toCourseWithLessonsArray = (
  courseModel: CourseModel,
  adapterRegistry: GadgetConfigAdapterRegistry,
  converterRegistry: GadgetLearnerStateConverterRegistry
): Course => {
  const convertedContents = courseModel.contents.map((content: Content) => {
    if (content.contentType === ContentType.LESSON && (content as Lesson).gadgets) {
      return convertGadgetsInLesson(content as Lesson, adapterRegistry, converterRegistry);
    } else {
      const convertedLessonsInModule = (content as Module).lessons?.map(
        lesson => convertGadgetsInLesson(lesson, adapterRegistry, converterRegistry)
      );
      return {
        ...content,
        lessons: convertedLessonsInModule
      } as Module;
    }
  });

  return {
    id: courseModel.id,
    title: courseModel.title,
    description: courseModel.description,
    lessonViewMode: courseModel.lessonViewMode,
    contents: convertedContents,
    lessons: flattenContentsIntoLessons(convertedContents),
  };
};

/**
 * Merges strings into the gadget rendering config
 * @param gadgetRenderingConfig
 * @param i18nStrings
 */
export const mergeI18nStringsWithConfig = <GR extends GadgetRenderer = GadgetRenderer>(gadgetRenderingConfig: GadgetRenderer, i18nStrings?: GadgetI18nStringSConfig): GR => {
  if (!i18nStrings) {
    return gadgetRenderingConfig as GR;
  }
  Object.keys(gadgetRenderingConfig).forEach(function(key) {
    if (i18nStrings[key as GadgetType]) {
      gadgetRenderingConfig[key] = merge(
        gadgetRenderingConfig[key],
        {i18nStrings: i18nStrings[key as GadgetType]}
      );
    }
  });
  return gadgetRenderingConfig as GR;
};
