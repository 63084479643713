import React, {FunctionComponent, useEffect, useState} from 'react';
import axios from 'axios';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import {parse} from 'subtitle';
import {CaptionsAndTextTranscriptUpload} from './CaptionsAndTextTranscriptUpload';
import {VideoTranscript} from '../../../../components/gadgets/video/VideoTranscript';
import {AssetModel, useCourseContext} from '../../../../context/course';
import {AssetDataModel, AssetType, InvalidFile} from '../../asset-uploader/AssetUploader';
import {LABELS} from './Constants';
import {captionsFileTypes, textTranscriptFileTypes} from '../../asset-uploader/Constants';
import {VideoGadgetI18nStrings} from '../../../../context/course/models/I18n';
import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import checkKnockoutTokens from '@amzn/meridian-tokens/base/icon/check-knockout';
import alertKnockoutTokens from '@amzn/meridian-tokens/base/icon/alert-knockout';
import infoToken from '@amzn/meridian-tokens/base/icon/info';
import fileDownload from 'js-file-download';
import './styles/video-gadget.scss';
import {AssetMetaData} from './VideoGadget';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';
import {VideoTranscriptionStatus} from '../../../../context/course';

export interface CaptionsAndTranscriptProps {
  gadgetId: string;
  updateAssetData: (asset: AssetDataModel) => void;
  videoCaptions: { assetId: string } | undefined;
  isVideoMetadataAvailable: boolean;
  videoTextTranscript: { assetId: string } | undefined;
  videoElement: HTMLVideoElement;
  onRejection: (invalidFiles: InvalidFile[]) => void;
  i18nStrings: VideoGadgetI18nStrings;
  videoMetadata: AssetMetaData | undefined;
  updateTranscriptFileGetUrl: (transcriptFileGetUrl: string) => void;
}

export const TranscriptError: FunctionComponent = () => {
  return (
    <div className='transcript_error_container'>
      {LABELS.captionsFetchErrorMessage}
    </div>
  );
};

export const CaptionsAndTranscript: FunctionComponent<CaptionsAndTranscriptProps> = (
  {
    gadgetId,
    updateAssetData,
    videoCaptions,
    isVideoMetadataAvailable,
    videoTextTranscript,
    videoElement,
    onRejection,
    i18nStrings,
    videoMetadata,
    updateTranscriptFileGetUrl
  }: CaptionsAndTranscriptProps) => {

  const {getAssetModel} = useCourseContext();

  const [transcript, setTranscript] = useState<string | null>(null);
  const [isValidTranscript, setIsValidTranscript] = useState<boolean>(true);
  const [assetLocation, setAssetLocation] = useState<string>();
  const [assetTitle, setAssetTitle] = useState<string>();

  const supportedCaptionsFileTypes = {'text/vtt': captionsFileTypes};
  const supportedTextTranscriptFileTypes = {'text/*': textTranscriptFileTypes};

  const videoTranscriptionStatus = videoMetadata?.assetMetadata?.videoTranscriptionStatus;

  const downloadCaptionsFile = () => {
    const autoGenerateCaptionsLocation: string | undefined = videoMetadata?.assetMetadata?.transcriptFileGetUrl;
    const manualUploadedCaptionsLocation: string | undefined = assetLocation;
    const manualUploadedCaptionsTitle: string | undefined = assetTitle;

    if(videoTranscriptionStatus && !assetLocation){
      window.open(autoGenerateCaptionsLocation, '_blank');
    }
    else{
      if(manualUploadedCaptionsLocation){
        axios.get(manualUploadedCaptionsLocation, {
          responseType: 'blob',
        })
          .then(res => {
            if(manualUploadedCaptionsTitle){
              fileDownload(res.data, manualUploadedCaptionsTitle);
            }
          });
      }
    }
  };

  useEffect(()=> {
    const transcriptFileGetUrl = videoMetadata?.assetMetadata?.transcriptFileGetUrl;
    if(transcriptFileGetUrl){
      updateTranscriptFileGetUrl(transcriptFileGetUrl);
      axios.get(transcriptFileGetUrl)
        .then(response => {
          setTranscript(response.data);
          setIsValidTranscript(true);
        })
        .catch(error => {
          setTranscript(null);
        });
    }
  },[videoMetadata?.assetMetadata?.transcriptFileGetUrl]);

  useEffect(() => {
    (async () => {
      const assetId = videoCaptions?.assetId || videoTextTranscript?.assetId;
      if (assetId) {
        await getAssetModel(assetId)
          .then((asset: AssetModel | undefined) => {
            if (asset?.location) {
              setAssetLocation(asset.location);
              setAssetTitle(asset.title);
              axios.get(asset.location)
                .then(response => {
                  try {
                    if (videoCaptions?.assetId) {
                      // Check for parse errors.
                      parse(response.data);
                    }
                    setTranscript(response.data);
                    setIsValidTranscript(true);
                  } catch (err) {
                    if (videoCaptions?.assetId) {
                      setIsValidTranscript(false);
                    }
                    setTranscript(null);
                  }
                });
            }
          });
      }
    })();
  }, [getAssetModel, videoCaptions?.assetId, videoTextTranscript?.assetId]);


  return (
    <Column spacing={'small'}>

      { !isVideoMetadataAvailable &&
          <Row alignmentHorizontal='center'>
            <Box className='placeholder_message' >
              <Text type={'b300'}>{i18nStrings.uploadVideoMessage}</Text>
            </Box>
          </Row>
      }

      { videoTranscriptionStatus === VideoTranscriptionStatus.IN_PROGRESS &&
          <Row alignmentHorizontal='center'>
            <Loader size='small' />
            <Box className='placeholder_message'>
              <Text type={'b300'}>{i18nStrings.autoGeneratingCaptions}</Text>
            </Box>
          </Row>
      }

      {
        (videoTranscriptionStatus === VideoTranscriptionStatus.COMPLETED || videoCaptions?.assetId || videoTextTranscript?.assetId) &&
        <Box>
          <CaptionsAndTextTranscriptUpload
            supportedFileTypes={supportedCaptionsFileTypes}
            assetType={AssetType.CAPTIONS}
            updateAssetData={updateAssetData}
            uploadPlaceholder={LABELS.captionUploadPlaceholder}
            onRejection={onRejection}
            i18nStrings={i18nStrings.assetUpload}
          >
            {(videoTranscriptionStatus === VideoTranscriptionStatus.COMPLETED || videoCaptions?.assetId || videoTextTranscript?.assetId) &&
                <Row data-testid='downloadCaptionsRow' alignmentHorizontal='center'>
                  <Box className='upload_tick'>
                    <Icon className={'check-knockout-icon'}
                      tokens={checkKnockoutTokens}
                    />
                  </Box>
                  <Box>
                    <Text>{i18nStrings.captionFileAttached}</Text>
                  </Box>
                  <Link
                    data-testid='button'
                    propagateClickEvent={false}
                    onClick={downloadCaptionsFile}
                    type='primary'
                  >{i18nStrings.download}
                  </Link>
                </Row>
            }
          </CaptionsAndTextTranscriptUpload>
        </Box>
      }

      {
        (videoTranscriptionStatus === VideoTranscriptionStatus.FAILED) && !transcript &&
        <Column>
          <Row>
            <Icon
              className={'alert-knockout-icon'}
              tokens={alertKnockoutTokens}
            />
            <Text type={'b200'}>
              {i18nStrings.autoGeneratedTranscriptErrorMessage}
              <br/>
              <Text color={'error'}>Error message: {videoMetadata?.assetMetadata?.videoTranscriptionErrorMessage}</Text>
            </Text>
          </Row>
          <Row alignmentHorizontal='center'>
            <CaptionsAndTextTranscriptUpload
              supportedFileTypes={supportedCaptionsFileTypes}
              assetType={AssetType.CAPTIONS}
              updateAssetData={updateAssetData}
              uploadPlaceholder={LABELS.captionUploadPlaceholder}
              onRejection={onRejection}
              i18nStrings={i18nStrings.assetUpload}
            >
              {
                (videoTranscriptionStatus === VideoTranscriptionStatus.FAILED)  &&
                  <Row alignmentHorizontal='center'>
                    <Icon
                      className={'info-knockout-icon'}
                      tokens={infoToken}
                    />
                    <Text color={'error'}>{i18nStrings.captionFileRequired}</Text>
                  </Row>
              }
            </CaptionsAndTextTranscriptUpload>
          </Row>
        </Column>
      }

      {
        (videoTranscriptionStatus === VideoTranscriptionStatus.COMPLETED || (isVideoMetadataAvailable && (videoCaptions?.assetId || videoTextTranscript?.assetId))) &&
        <Box>
          {transcript ?
            (<VideoTranscript
              contentId={`video-transcript-${gadgetId}`}
              transcriptContent={transcript}
              transcriptType={(videoCaptions || videoMetadata?.assetMetadata?.transcriptFileGetUrl) ? 'vtt' : 'text'}
              videoElement={videoElement}
              i18nStrings={i18nStrings}
            />) : !isValidTranscript ? <TranscriptError/> : null
          }
        </Box>
      }
    </Column>
  );
};
