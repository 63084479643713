import React, {FunctionComponent} from 'react';

import './styles/AriaDescription.scss';

export interface AriaDescriptionProps {
  description: string,
  id: string
}

export const AriaDescription: FunctionComponent<AriaDescriptionProps> = ({description, id}) => {
  return <span id={id} className="AriaDescription" aria-hidden>{description}</span>;
};

export const WithAriaDescription: FunctionComponent<AriaDescriptionProps> = ({
  description,
  id,
  children
}) => {
  React.Children.only(children);
  if (React.isValidElement(children)) {
    const clonedChildren = React.cloneElement(children, {
      ...children.props,
      'aria-describedby': id
    });
    return <>
      {clonedChildren}
      <AriaDescription description={description} id={id} />
    </>;
  } else {
    return null;
  }
};
