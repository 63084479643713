import React, {FunctionComponent, memo, useCallback, useEffect, useState} from 'react';
import {head, noop} from 'lodash';
import Button from '@amzn/meridian/button';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Checkbox from '@amzn/meridian/checkbox';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Theme from '@amzn/meridian/theme';
import Tooltip from '@amzn/meridian/tooltip';
import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import chevronUpSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-up-small';
import chevronDownSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import blueDarkTokens from '@amzn/meridian-tokens/theme/blue-dark';
import brandedDarkTokens from '../../../theme/branded-dark';
import waveTokens from '../../../theme/wave';
import cx from 'classnames';
import {DEFAULT_GADGETS_REGISTRY} from '../../../gadgets/registry';
import FeedbackWidget from '../../feedback/sidebar';
import {
  Course,
  CoursePosition,
  CourseProgress,
  DisplaySection,
  Gadget,
  Lesson,
  ProgressStatus,
  useCourseContext
} from '../../../context/course';

import ProgressTrackerIndicator from './progress_tracker/ProgressTrackerIndicator';
import {LearnerGadgetActivity, LearnerLessonActivity} from '../../../activity/models/learner-activities';
import {LearnerVerb} from '../../../activity/models/verbs';
import {LearnerObject} from '../../../activity/models/objects';
import {
  defaultI18nStrings,
  FeedbackI18nStrings,
  SidebarI18nStrings,
  WithI18nStringsProps
} from '../../../context/course/models/I18n';

import './styles/sidebar.scss';

/**
 * Sidebar props
 */
export interface SidebarProps {

  /**
   * Current course
   */
  course: Course;

  /**
   * Learner's position in the course
   */
  coursePosition: CoursePosition | null;

  /**
   * Learner's progress
   */
  courseProgress: CourseProgress | null;

  /**
   * Boolean to enable the Progress Tracker Widget
   */
  enableCourseProgress?: boolean;

  /**
   * Boolean to enable the Feedback Widget
   */
  enableFeedbackWidget?: boolean;

  /**
   * optional callback that is called when a lesson section is clicked
   * @param lessonId
   */
  onLessonClick?: (lessonId: string) => void;

  /**
   * optional callback that is called when a gadget section is clicked
   * @param gadgetId
   */
  onGadgetClick?: (gadgetId: string) => void;

  /**
   * Static text for Sidebar component
   */
  i18nStrings?: {sidebar: SidebarI18nStrings, feedback: FeedbackI18nStrings};
}

/**
 * Lesson section props
 */
interface LessonSectionProps extends WithI18nStringsProps<SidebarI18nStrings> {
  /**
   * Lesson for this section
   */
  lesson: Lesson;

  /**
   * Whether or not this lesson should render expanded
   */
  isExpanded: boolean;

  /**
   * Current lesson progress status
   */
  progressStatus: ProgressStatus;

  /**
   * Learner's position in the course
   */
  coursePosition: CoursePosition | null;

  /**
   * optional callback that is called when this lesson section is clicked
   * @param lessonId
   */
  onClick?: (lessonId: string) => void;

  /**
   * optional callback that is called when a gadget section is clicked
   * @param gadgetId
   */
  onGadgetClick?: (gadgetId: string) => void;
}

/**
 * Gadget section props
 */
export interface GadgetSectionProps extends WithI18nStringsProps<SidebarI18nStrings> {

  /**
   * ID of the lesson the gadget belongs to
   */
  lessonId: string,

  /**
   * Gadget
   */
  gadget: Gadget;

  /**
   * Learner's position in the course
   */
  coursePosition: CoursePosition | null;

  /**
   * optional callback that is called when this gadget section is clicked
   * @param gadgetId
   */
  onClick?: (gadgetId: string) => void,

}

export const SidebarId = 'course-toc-id';

/**
 * Sidebar for the course player
 */
export const Sidebar: FunctionComponent<SidebarProps> = (
  {
    course: {
      title,
      lessons
    },
    courseProgress,
    coursePosition,
    enableCourseProgress = true,
    enableFeedbackWidget = true,
    onGadgetClick,
    onLessonClick,
    i18nStrings = {sidebar: defaultI18nStrings.sidebar, feedback: defaultI18nStrings.feedback},
  }: SidebarProps
) => {

  return (
    <Theme
      tokens={{
        ...blueDarkTokens,
        ...brandedDarkTokens
      }}
    >
      <Column className='sidebar-container' spacingInset='300 400' heights={['fit', 'fit', 'fill', 'fit']}>
        <Row className='sidebar-header' widths={'fill'}>
          <Heading level={2} type='h100'>{title}</Heading>
        </Row>
        {
          enableCourseProgress && <ProgressTrackerIndicator courseProgress={courseProgress} courseTitle={title} i18nStrings={i18nStrings.sidebar}/>
        }
        <Column spacing='300' className='lessons-container' overflowY='scroll' spacingInset='none none 400 none'>
          <nav aria-label={i18nStrings.sidebar.tableOfContentsLabel}>
            <ul className='lesson-list' aria-label={i18nStrings.sidebar.lessonsInTitleLabel(title)}>
              {lessons.map(lesson => <li key={`toc-${lesson.id}`}>
                <LessonSection
                  coursePosition={coursePosition}
                  onClick={onLessonClick}
                  onGadgetClick={onGadgetClick}
                  key={lesson.id}
                  lesson={lesson}
                  isExpanded={coursePosition?.lessonId === lesson.id}
                  progressStatus={courseProgress?.lessonStatusById
                    ? courseProgress?.lessonStatusById[lesson.id]
                    : ProgressStatus.NOT_STARTED}
                  i18nStrings={i18nStrings.sidebar}
                />
              </li>
              )}
            </ul>
          </nav>
        </Column>
        {
          enableFeedbackWidget && <FeedbackWidget
            descriptionMaxLength={150}
            topics={[
              i18nStrings.feedback.courseContentFeedbackTopicOption,
              i18nStrings.feedback.playerExperienceFeedbackTopicOption,
              i18nStrings.feedback.otherFeedbackTopicOption
            ]}
            i18nStrings={i18nStrings.feedback}
          />
        }
      </Column>
    </Theme>
  );
};

/**
 * Renders a lesson section in the Sidebar
 */
const LessonSection: FunctionComponent<LessonSectionProps> = memo((
  {
    isExpanded: isActive,
    lesson,
    progressStatus,
    coursePosition,
    onClick = noop,
    onGadgetClick = noop,
    i18nStrings,
  }: LessonSectionProps
) => {

  const isProgressCompleted: (progressStatus: ProgressStatus) => boolean = progressStatus => {
    return progressStatus === ProgressStatus.COMPLETED;
  };

  const [isExpanded, setIsExpanded] = useState(isActive);
  // https://sim.amazon.com/issues/ALT-7139
  // Normally, we would use progress status to determine if a lesson is completed but because
  // meridian doesn't prevent default and setting lesson status has a delay, we get a bug where
  // when marking a lesson complete, the checkbox will flicker on and off.
  // https://paste.amazon.com/show/luubrand/1647380508
  const [isCompleted, setIsCompleted] = useState(isProgressCompleted(progressStatus));

  const {
    setCoursePosition,
    setLessonProgress,
    emitLearnerActivity,
  } = useCourseContext();

  // effect to open the section if the lesson becomes active
  useEffect(() => {
    if (isActive) {
      setIsExpanded(isActive);
    }
  }, [isActive]);

  useEffect(() => {
    setIsCompleted(isProgressCompleted(progressStatus));
  }, [progressStatus]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const onLessonTitleClick = useCallback(e => {
    const gadgetId = head(lesson.gadgets)?.id;
    setCoursePosition({
      displaySection: DisplaySection.LESSON,
      lessonId: lesson.id,
      gadgetId: gadgetId || '',
      updatedAt: new Date()
    });
    onClick(lesson.id);

    emitLearnerActivity(
      new LearnerLessonActivity(
        LearnerVerb.OPENED,
        LearnerObject.LESSON,
        lesson.id,
      )
    );
  }, [emitLearnerActivity, lesson.gadgets, lesson.id, onClick, setCoursePosition]);

  const chevronTokens = isExpanded ? chevronUpSmallTokens : chevronDownSmallTokens;

  const isLessonActive = lesson.id === coursePosition?.lessonId;

  const toggleLessonStatus = useCallback((completed: boolean) => {
    setLessonProgress(
      lesson.id,
      completed ? ProgressStatus.COMPLETED : ProgressStatus.IN_PROGRESS
    );

    setIsCompleted(completed);

    emitLearnerActivity(
      new LearnerLessonActivity(
        completed ? LearnerVerb.COMPLETED : LearnerVerb.RESUMED,
        LearnerObject.LESSON,
        lesson.id,
      )
    );
  }, [emitLearnerActivity, lesson.id, setLessonProgress]);

  const lessonContainerStyles = cx(
    'lesson-container',
    isLessonActive ? 'lesson-active' : undefined
  );

  const gadgetSectionId = `${lesson.id}-gadgets`;
  const checkboxId = `${lesson.id}-mark-as-complete`;

  return (
    <Box className={lessonContainerStyles}>
      <div className='lesson-container-header'>
        <Row className='lesson-title' widths={['fit', 'fill', 'fit']} alignmentVertical='stretch' spacing='none'>
          <Column alignmentVertical='center' spacingInset='200 0 200 300'>
            <Tooltip position='top' title={isCompleted ? i18nStrings.markLessonIncomplete : i18nStrings.markLessonComplete}>
              <span>
                <label className='lesson-checkbox-label' htmlFor={checkboxId}>{i18nStrings.lessonCompleted(lesson.title)}</label>
                <Checkbox name={checkboxId} id={checkboxId} checked={isCompleted} onChange={toggleLessonStatus} />
              </span>
            </Tooltip>
          </Column>
          <Column alignmentVertical='center'>
            <div className='lesson-title-container' onClick={onLessonTitleClick}>
              <Text type='b200'><strong>
                <Link
                  aria-label={i18nStrings.goToLessonLabel(lesson.title)}
                  type='secondary'
                  onClick={onLessonTitleClick}
                >
                  {lesson.title}
                </Link>
              </strong></Text>
            </div>
          </Column>
          <Column alignmentVertical='center' spacingInset='200 100 200 0'>
            <Button
              label={isExpanded
                ? i18nStrings.collapseLessonsContentsLabel(lesson.title)
                : i18nStrings.expandLessonContentsLabel(lesson.title)}
              aria-expanded={isExpanded ? 'true' : 'false'}
              aria-controls={gadgetSectionId}
              type='icon'
              size='small'
              onClick={toggleExpansion}
            >
              <Icon tokens={chevronTokens}/>
            </Button>
          </Column>
        </Row>
      </div>

      {isExpanded && <div id={gadgetSectionId} className='gadgets-container show'>
        <ul className='gadget-list' aria-label={i18nStrings.lessonContentsLabel(lesson.title)} >
          {lesson.gadgets.map(gadget => <li key={`toc-${gadget.id}`}>
            <GadgetSection
              coursePosition={coursePosition}
              onClick={onGadgetClick}
              key={gadget.id}
              lessonId={lesson.id}
              gadget={gadget}
              i18nStrings={i18nStrings}
            />
          </li>)}
        </ul>
      </div>}
    </Box>
  );
});
LessonSection.displayName = 'LessonSection';

/**
 * Renders a gadget section in the Sidebar
 */
export const GadgetSection: FunctionComponent<GadgetSectionProps> = (
  {
    coursePosition,
    lessonId,
    gadget,
    onClick = noop,
    i18nStrings,
  }: GadgetSectionProps
) => {

  const {defaultTitle} = DEFAULT_GADGETS_REGISTRY[gadget.type];
  const {config} = gadget;

  const {
    setCoursePosition,
    emitLearnerActivity,
  } = useCourseContext();

  const isActive = gadget.id === coursePosition?.gadgetId;

  const onGadgetClick = useCallback(() => {
    setCoursePosition({
      displaySection: DisplaySection.LESSON,
      lessonId,
      gadgetId: gadget.id,
      updatedAt: new Date()
    });
    onClick(gadget.id);

    emitLearnerActivity(
      new LearnerGadgetActivity(
        LearnerVerb.OPENED,
        LearnerObject.LESSON,
        gadget.id,
        gadget.type,
        lessonId,
      )
    );
  }, [emitLearnerActivity, gadget.id, gadget.type, lessonId, onClick, setCoursePosition]);

  const defaultGadgetIcon = DEFAULT_GADGETS_REGISTRY[gadget.type].iconUrl;
  const isHeaderGadget = gadget.type === 'amzn/header';

  const gadgetContainerStyles = cx(
    'gadget-container',
    isActive ? 'gadget-container--active' : undefined
  );

  return <Theme tokens={isActive ? waveTokens : {}}>
    {isHeaderGadget ?
      <div onClick={onGadgetClick} className='header-gadget-sidebar-container'>
        <div className={gadgetContainerStyles}>
          <Link
            type='secondary'
            aria-current={isActive}
            onClick={onGadgetClick}
            aria-label={i18nStrings.goToSectionLabel(config.title || defaultTitle)}
          >
            <Text color={isActive ? 'primary' : 'secondary'}
              type='b100'><strong>{config.title || defaultTitle}</strong></Text>
          </Link>
        </div>
      </div> :
      <div onClick={onGadgetClick} className={gadgetContainerStyles}>
        <Row>
          <img src={config.icon || defaultGadgetIcon} className='gadget-icon' alt='' aria-hidden={true} />
          <Link
            type='secondary'
            aria-current={isActive}
            onClick={onGadgetClick}
            aria-label={i18nStrings.goToGadgetLabel(config.title || defaultTitle)}
          >
            <Text type='b200'>{config.title || defaultTitle}</Text>
          </Link>
        </Row>
      </div>
    }
  </Theme>;
};
