import {forEach, omit, reduce} from 'lodash';
import {Content, ContentType, Course, Gadget, Lesson, Module} from '../../context/course';
import {CourseUpdateRequest} from '../context/models/CourseUpdateRequest';

export const toLessonUpdateRequestModel = (lesson: Lesson) => {
  return {
    id: lesson.id,
    title: lesson.title,
    contentType: lesson.contentType,
    gadgetIds: lesson.gadgets.map(gadget => gadget.id) || []
  };
};

export const toCourseUpdateRequest = (course: Course) => {
  return {
    ...(omit(course, ['lessons'])),
    contents: course.contents.map(content => {
      switch (content.contentType) {
        case ContentType.LESSON:
          return toLessonUpdateRequestModel(content as Lesson);
        case ContentType.MODULE:
          return {
            id: content.id,
            title: content.title,
            contentType: content.contentType,
            lessons: (content as Module)?.lessons.map(lesson => toLessonUpdateRequestModel(lesson)) || []
          };
        default: {
          throw new Error('Invalid content type');
        }
      }
    })
  } as CourseUpdateRequest;
};

export const getGadgetsById = (course: Course) => {
  return reduce(course.contents, (gadgetsById:  Record<string, Gadget>, content: Content) => {
    switch (content.contentType) {
      case ContentType.LESSON:
        forEach((content as Lesson).gadgets, gadget => {
          gadgetsById[gadget.id] = gadget;
        });
        break;
      case ContentType.MODULE:
        forEach((content as Module)?.lessons, lesson => {
          forEach(lesson.gadgets, gadget => {
            gadgetsById[gadget.id] = gadget;
          });
        });
        break;
    }
    return gadgetsById;
  }, {} as Record<string, Gadget>);
};
