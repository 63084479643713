import colorPalette from './color-palette';

const brandedDark = {
  themeBorderPrimaryFocus: colorPalette.wave500,
  themeForegroundPrimaryDefault: colorPalette.gray0,
  themeForegroundSecondaryDefault: colorPalette.gray300,
  themeBackgroundPrimaryDefault: colorPalette.blue800,
  themeBackgroundSecondaryDefault: colorPalette.blue700,
  themeBackgroundAccentDefault: colorPalette.wave500,
  themeBorderSecondaryDefault: colorPalette.gray500,
  themeBorderAccentDefault: colorPalette.wave300,

  // box
  boxBackgroundColorFill: colorPalette.blue800,

  // Primary button
  buttonBackgroundColorPrimaryDefault: colorPalette.wave500,
  buttonBackgroundColorPrimaryDisabled: colorPalette.gray050,
  buttonBackgroundColorPrimaryHover: colorPalette.wave600,
  buttonBackgroundColorPrimaryPressed: colorPalette.wave700,
  buttonForegroundColorPrimaryDefault: colorPalette.gray0,
  buttonForegroundColorPrimaryDisabled: colorPalette.gray200,

  // buttons
  buttonBackgroundColorSecondaryDefault: colorPalette.gray600,
  buttonBackgroundColorSecondaryHover: colorPalette.gray500,
  buttonBackgroundColorSecondaryPressed: colorPalette.gray400,
  buttonForegroundColorSecondaryDefault: colorPalette.gray0,
  buttonForegroundColorSecondaryDisabled: colorPalette.gray0,

  // button link
  buttonForegroundColorLinkDarkBackdropDefault	: colorPalette.gray0,
  buttonForegroundColorLinkDarkBackdropHover	: colorPalette.gray0,

  // link
  linkForegroundColorDefault: colorPalette.gray0,
  linkForegroundColorHover: colorPalette.gray0,
  linkForegroundColorPressed: colorPalette.gray050,

  // checkboxes
  checkboxBackgroundColorSelectedDefault: colorPalette.wave300,
  checkboxBackgroundColorUnselectedHover: colorPalette.wave200,
  checkboxBackgroundColorSelectedHover: colorPalette.wave400,
  checkboxBackgroundColorSelectedPressed: colorPalette.wave100,
  checkboxBackgroundColorUnselectedPressed: colorPalette.wave100,
  checkboxBackgroundColorUnselectedDefault: colorPalette.blue700,
  checkboxIndicatorColorUnselectedHover: colorPalette.wave300,
  checkboxBorderColorUnselectedDefault: colorPalette.gray200,

  // select
  optionListOptionBackgroundColorPreselected: colorPalette.wave200,
  optionListOptionBackgroundColorPressed: colorPalette.wave100,
  optionListOptionBackgroundColorSelected: colorPalette.wave300,

  // Loader Component
  loaderIndicatorBackgroundColor: colorPalette.wave400,
  loaderTrackBackgroundColor: colorPalette.gray600
};

export default brandedDark;
