import { Stage } from "./stage";

export interface GandalfConfig {
  Auth: {
    userPoolWebClientId: string;
    region: string;
    userPoolId: string;
    oauth: {
      domain: string;
      scope: string[];
      responseType: string;
      redirectSignIn: string;
      redirectSignOut: string;
    };
  };
  Gandalf?: {
    customSignInDomain: string;
    customSignOutDomain: string;
  };
}

const configurations: { [key in Stage]: GandalfConfig } = {
  dev: {
    Auth: {
      userPoolWebClientId: "3j9p1hb0r6nja44lau0v239mn0",
      region: "us-east-1",
      userPoolId: "us-east-1_KcXGNtlRY",
      oauth: {
        domain: "gandalf-prod.auth.us-east-1.amazoncognito.com",
        scope: ["openid"],
        responseType: "code",
        redirectSignIn: "https://localhost:3000",
        redirectSignOut: "https://localhost:3000/logout",
      },
    },
    Gandalf: {
      customSignInDomain: "login.us-east-1.auth.skillbuilder.aws",
      customSignOutDomain: "us-east-1.auth.skillbuilder.aws",
    },
  },
  "dev-dub": {
    Auth: {
      userPoolWebClientId: "3j9p1hb0r6nja44lau0v239mn0",
      region: "us-east-1",
      userPoolId: "us-east-1_KcXGNtlRY",
      oauth: {
        domain: "gandalf-prod.auth.us-east-1.amazoncognito.com",
        scope: ["openid"],
        responseType: "code",
        redirectSignIn: "https://localhost:3000",
        redirectSignOut: "https://localhost:3000/logout",
      },
    },
    Gandalf: {
      customSignInDomain: "login.us-east-1.auth.skillbuilder.aws",
      customSignOutDomain: "us-east-1.auth.skillbuilder.aws",
    },
  },
  "alpha-iad": {
    Auth: {
      userPoolWebClientId: "7rpcui8nb4cn1m67krb9ojod6i",
      region: "us-east-1",
      userPoolId: "us-east-1_KcXGNtlRY",
      oauth: {
        domain: "gandalf-prod.auth.us-east-1.amazoncognito.com",
        scope: ["openid"],
        responseType: "code",
        redirectSignIn: "https://us-east-1.alpha.soju.training.aws.dev",
        redirectSignOut: "https://us-east-1.alpha.soju.training.aws.dev/logout",
      },
    },
    Gandalf: {
      customSignInDomain: "login.us-east-1.auth.skillbuilder.aws",
      customSignOutDomain: "us-east-1.auth.skillbuilder.aws",
    },
  },
  "alpha-dub": {
    Auth: {
      userPoolWebClientId: "7cqo5sm2ma6rvk28p1daupd8me",
      region: "eu-west-1",
      userPoolId: "eu-west-1_rjD3vqPWW",
      oauth: {
        domain: "gandalf-prod.auth.eu-west-1.amazoncognito.com",
        scope: ["openid"],
        responseType: "code",
        redirectSignIn: "https://eu-west-1.alpha.soju.training.aws.dev",
        redirectSignOut: "https://eu-west-1.alpha.soju.training.aws.dev/logout",
      },
    },
  },
  "beta-iad": {
    Auth: {
      userPoolWebClientId: "46si78n1hgcav0dfj0jqg23r6d",
      region: "us-east-1",
      userPoolId: "us-east-1_KcXGNtlRY",
      oauth: {
        domain: "gandalf-prod.auth.us-east-1.amazoncognito.com",
        scope: ["openid"],
        responseType: "code",
        redirectSignIn: "https://us-east-1.beta.soju.training.aws.dev",
        redirectSignOut: "https://us-east-1.beta.soju.training.aws.dev/logout",
      },
    },
    Gandalf: {
      customSignInDomain: "login.us-east-1.auth.skillbuilder.aws",
      customSignOutDomain: "us-east-1.auth.skillbuilder.aws",
    },
  },
  "beta-dub": {
    Auth: {
      userPoolWebClientId: "2labfjvkla2dc30oesk8ogps5q",
      region: "eu-west-1",
      userPoolId: "eu-west-1_rjD3vqPWW",
      oauth: {
        domain: "gandalf-prod.auth.eu-west-1.amazoncognito.com",
        scope: ["openid"],
        responseType: "code",
        redirectSignIn: "https://eu-west-1.beta.soju.training.aws.dev",
        redirectSignOut: "https://eu-west-1.beta.soju.training.aws.dev/logout",
      },
    },
  },
  "gamma-iad": {
    Auth: {
      userPoolWebClientId: "7n9c3oi2frnnspeoth1fnokt0f",
      region: "us-east-1",
      userPoolId: "us-east-1_KcXGNtlRY",
      oauth: {
        domain: "gandalf-prod.auth.us-east-1.amazoncognito.com",
        scope: ["openid"],
        responseType: "code",
        redirectSignIn: "https://us-east-1.gamma.soju.training.aws.dev",
        redirectSignOut: "https://us-east-1.gamma.soju.training.aws.dev/logout",
      },
    },
    Gandalf: {
      customSignInDomain: "login.us-east-1.auth.skillbuilder.aws",
      customSignOutDomain: "us-east-1.auth.skillbuilder.aws",
    },
  },
  "gamma-dub": {
    Auth: {
      userPoolWebClientId: "23inho085kh453a38lri9h3o8k",
      region: "eu-west-1",
      userPoolId: "eu-west-1_rjD3vqPWW",
      oauth: {
        domain: "gandalf-prod.auth.eu-west-1.amazoncognito.com",
        scope: ["openid"],
        responseType: "code",
        redirectSignIn: "https://eu-west-1.gamma.soju.training.aws.dev",
        redirectSignOut: "https://eu-west-1.gamma.soju.training.aws.dev/logout",
      },
    },
  },
  "prod-iad": {
    Auth: {
      userPoolWebClientId: "5p3j01rdnuadijc90tqiqgi8u4",
      region: "us-east-1",
      userPoolId: "us-east-1_KcXGNtlRY",
      oauth: {
        domain: "gandalf-prod.auth.us-east-1.amazoncognito.com",
        scope: ["openid"],
        responseType: "code",
        redirectSignIn: "https://us-east-1.soju.training.aws.dev",
        redirectSignOut: "https://us-east-1.soju.training.aws.dev/logout",
      },
    },
    Gandalf: {
      customSignInDomain: "login.us-east-1.auth.skillbuilder.aws",
      customSignOutDomain: "us-east-1.auth.skillbuilder.aws",
    },
  },
  "prod-dub": {
    Auth: {
      userPoolWebClientId: "63hdtq9u4bhimhgg24aoo62hvf",
      region: "eu-west-1",
      userPoolId: "eu-west-1_rjD3vqPWW",
      oauth: {
        domain: "gandalf-prod.auth.eu-west-1.amazoncognito.com",
        scope: ["openid"],
        responseType: "code",
        redirectSignIn: "https://eu-west-1.soju.training.aws.dev",
        redirectSignOut: "https://eu-west-1.soju.training.aws.dev/logout",
      },
    },
  },
};

export function config(stage: Stage = Stage.dev): GandalfConfig {
  return configurations[stage];
}
