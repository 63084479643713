import { Quill } from 'react-quill';
import reduce from 'lodash/reduce';
import {AssetModel} from '../../../context/course';
import '../../image/styles/image.scss';
import {isEqual} from 'lodash';

const Image = Quill.import('formats/image');
const IMAGE_ATTRIBUTES = new Set([
  'alt',
  'height',
  'width',
]);
const ATTRIBUTES = new Set([
  ...IMAGE_ATTRIBUTES,
  'class',
  'style'
]);

const IMAGE_LOADING_URL = 'https://m.media-amazon.com/images/G/01/courses/soju-player/icons/img_spinner._CB1198675309_.gif';

/**
 * By default, Quill Image will only allow alt, height and width properties
 * We had to create our custom definition that allow us to set style and class tags
 * BlotFormatter needs to modify those attributes to set the alignment
 *
 * This CustomImage also takes care of converting the assetId for the image into a URL
 */
class CustomImage extends Image {
  static getAssetModel: (assetId: string) => Promise<AssetModel | undefined>;
  static onClick?: (image: {src: string, alt?: string}) => void;
  static ariaDescription: string;
  static assetModel: AssetModel;

  hasAddedButton = false;

  constructor(domNode: Element) {
    super(domNode);
  }

  private get quill() {
    const quillNode = this.scroll?.domNode.parentNode;
    return quillNode ? Quill.find(quillNode) : null;
  }

  private get readOnly() {
    return this.quill?.options?.readOnly || false;
  }

  private get assetId() {
    return this.domNode.getAttribute('asset-id');
  }

  private get src() {
    return this.domNode.getAttribute('src');
  }

  private get alt() {
    return this.domNode.getAttribute('alt');
  }

  static isValidUrl(urlString: string) {
    try {
      return Boolean(new URL(urlString));
    }
    catch(e){
      return false;
    }
  }

  static createDescriptionNode(id: string) {
    const ariaDescriptionNode = document.createElement('span');
    ariaDescriptionNode.innerText = CustomImage.ariaDescription;
    ariaDescriptionNode.setAttribute('aria-hidden', 'true');
    ariaDescriptionNode.className = 'SojuImage__description';
    ariaDescriptionNode.id = id;
    return ariaDescriptionNode;
  }

  static create(value: any) {
    const node = super.create(value) as Element;
    if (typeof value === 'string') {
      const assetId = this.sanitize(value);
      if (!this.isValidUrl(assetId)) {
        node.setAttribute('asset-id', assetId);
        node.setAttribute('src', IMAGE_LOADING_URL);
        if (this.getAssetModel) {
          this.getAssetModel(assetId).then(assetModel => {
            if (assetModel?.location) {
              node.setAttribute('src', assetModel.location);
            }
          });
        }
      }
    }
    return node;
  }

  static value(domNode: Element) {
    return domNode.getAttribute('asset-id');
  }

  static formats (domNode: HTMLElement) {
    return reduce(Array.from(ATTRIBUTES), (formats: Record<string, string | null>, attribute: string) => {
      const copy = { ...formats };

      if (domNode.hasAttribute(attribute)) {
        copy[attribute] = domNode.getAttribute(attribute);
      }

      return copy;
    }, {});
  }

  static fixMargins(style: string) {
    return style.replace(/0px/g, '0.25rem');
  }

  attach() {
    super.attach();
    if (this.readOnly && !this.hasAddedButton) {
      const imageNode = this.domNode;
      const assetId = this.assetId;
      const descriptionId = `${assetId}-img-description`;
      const buttonNode = document.createElement('button');
      buttonNode.className = 'SojuImage__link';
      buttonNode.setAttribute('aria-describedby', descriptionId);
      imageNode.parentNode?.insertBefore(buttonNode, imageNode);

      // hacky "polyfill" for replaceChildren. Remove all existing children, then append the new children.
      if (buttonNode.hasChildNodes()) {
        buttonNode.childNodes.forEach(child => {
          buttonNode.removeChild(child);
        });
      }
      buttonNode.appendChild(imageNode);
      buttonNode.appendChild(CustomImage.createDescriptionNode(assetId));

      buttonNode.onclick = () => {
        CustomImage.onClick?.({src: this.src, alt: this.alt});
      };
      this.hasAddedButton = true;
    }
  }

  format (name: string, value?: string) {
    if (ATTRIBUTES.has(name)) {
      // add certain attributes to image button wrapper element if in readonly mode,
      // add all attributes to image element if button wrapper doesn't exist.
      const shouldAddAttributeToButton = this.readOnly && this.hasAddedButton && !IMAGE_ATTRIBUTES.has(name);
      const node = shouldAddAttributeToButton ? this.domNode.parentNode : this.domNode;
      if (value) {
        if (!isEqual(node.getAttribute(name), value)) {
          if (name === 'style' && shouldAddAttributeToButton) {
            // image buttons need a minimum of 0.25rem margin to account for focus ring.
            // replaces all 0px margins to 0.25rem
            node.setAttribute(name, CustomImage.fixMargins(value));
          } else {
            node.setAttribute(name, value);
          }
        }
      } else {
        node.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

export default CustomImage;
