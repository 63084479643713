import { GadgetConfigAdapterRegistry } from '../../../context/course/soju/adapters/GadgetConfigAdapterRegistry';
import { GadgetLearnerStateConverterRegistry } from '../../../context/course/soju/converters/GadgetLearnerStateConverterRegistry';
import {CourseAuthoringApi} from '../CourseAuthoringApi';
import {SojuCourseAuthoringApiConfig} from './SojuCourseAuthoringApiConfig';
import axios, {AxiosInstance, InternalAxiosRequestConfig, AxiosRequestHeaders} from 'axios';
import {
  AssetModel,
  CloudLabInfo,
  Gadget,
  GadgetConfig,
  Lesson,
  VideoAssetMetadata,
  CourseModel,
  Course
} from '../../../context/course';
import {SojuAuthoringSession} from './SojuLearnerSession';
import {DrmType} from '../../../components/gadgets/video/models/DrmType';
import {debounce} from 'lodash';
import {toCourseWithLessonsArray} from '../../../utils/CourseUtils';
import {CreateAssetRequest} from '../models/CreateAssetRequest';
import {CourseUpdateRequest} from '../models/CourseUpdateRequest';

const AUTHOR_SESSION_HEADER_NAME = 'sid';
const CSRF_TOKEN_HEADER_NAME = 'anti-csrftoken-a2z';
const DEBOUNCE_TIME_IN_MS = 1000;

// for expired learner tokens we return 401 status code
export const HTTP_STATUS_CODE_ON_EXPIRED_LEARNER_SESSION = 401;
// when the csrf expires, horizonte returns 403
export const HTTP_STATUS_CODE_ON_EXPIRED_CSRF = 403;

export class SojuCourseAuthoringApi implements CourseAuthoringApi {

  private readonly axiosInstance: AxiosInstance;
  private readonly apiConfig: SojuCourseAuthoringApiConfig;
  private authoringSession: SojuAuthoringSession;
  private readonly adapterRegistry: GadgetConfigAdapterRegistry;
  private readonly converterRegistry: GadgetLearnerStateConverterRegistry;

  /**
   * Creates a new instance of the SojuCourseApi
   * @param config api configuration
   * @param session initial LearnerSession
   */
  constructor(private config: SojuCourseAuthoringApiConfig, private session: SojuAuthoringSession) {
    this.apiConfig = config;
    this.authoringSession = session;

    // axios instance for making API calls
    this.axiosInstance = axios.create({
      baseURL: config.baseUrl,
    });

    // interceptor to add learner/csrf tokens
    this.axiosInstance.interceptors.request.use(requestConfig => this.addTokenHeaders(requestConfig));

    // global errors interceptor
    this.axiosInstance.interceptors.response.use(
      res => res,
      error => {
        return this.handleError(error);
      }
    );

    this.adapterRegistry = new GadgetConfigAdapterRegistry();
    this.converterRegistry = new GadgetLearnerStateConverterRegistry();
  }

  async createContent(courseDraftId: string, type: string, title: string, parentId: string | undefined): Promise<Lesson> {
    const createContents = await this.axiosInstance.post(
      `/courses/${courseDraftId}/contents`,
      {
        title,
        contentType: type,
        parentId
      }
    );
    return createContents.data;
  }

  async createLessonGadgets(courseDraftId: string, lessonId: string, gadgets: Pick<Gadget, 'type' | 'config'>[], index: number | undefined): Promise<Gadget[]> {
    const { data: gadgetIds } = await this.axiosInstance.post(
      `/courses/${courseDraftId}/lessons/${lessonId}/many_gadgets`,
      {
        gadgets,
        index
      }
    );
    return gadgets.map((gadget, index) => {
      return {
        ...gadget,
        id: gadgetIds[index]
      };
    });
  }

  debouncedUpdateLessonGadget = debounce((courseDraftId: string, gadgetId: string, config: GadgetConfig) => {
    return this.axiosInstance.put(
      `/courses/${courseDraftId}/gadgets/${gadgetId}/config`,
      {
        ...config
      }
    );
  }, DEBOUNCE_TIME_IN_MS);

  async updateLessonGadget(courseDraftId: string, gadgetId: string, config: GadgetConfig): Promise<void> {
    await this.debouncedUpdateLessonGadget(courseDraftId, gadgetId, config);
  }

  async deleteContent(courseDraftId: string, contentId: string): Promise<void> {
    await this.axiosInstance.delete(
      `/courses/${courseDraftId}/contents/${contentId}`
    );
  }

  async deleteLessonGadgets(courseDraftId: string, lessonId: string, gadgetIds: string[]): Promise<void> {
    await this.axiosInstance.post(
      `/courses/${courseDraftId}/lessons/${lessonId}/delete_gadgets`,
      {
        gadgets: gadgetIds
      }
    );
  }

  async loadCourse(courseDraftId: string): Promise<Course> {
    const {data: courseModel} = await this.axiosInstance.get<CourseModel>(`/courses/${courseDraftId}`);

    return toCourseWithLessonsArray(courseModel, this.adapterRegistry, this.converterRegistry);
  }

  updateContent(courseDraftId: string, content: Lesson): Promise<void> {
    return Promise.resolve(undefined);
  }

  debouncedUpdateCourse = debounce((courseDraftId: string, courseUpdateRequest: CourseUpdateRequest) => {
    return this.axiosInstance.put(
      `/courses/${courseDraftId}`,
      courseUpdateRequest
    );
  }, DEBOUNCE_TIME_IN_MS);

  async updateCourse(courseDraftId: string, courseUpdateRequest: CourseUpdateRequest): Promise<void> {
    await this.debouncedUpdateCourse(courseDraftId, courseUpdateRequest);
  }

  async getAssetModel(courseDraftId: string, assetId: string): Promise<AssetModel> {
    const {data: assetModel} = await this.axiosInstance.get<AssetModel>(`/assets/${assetId}/model`);
    if (assetModel.type === 'VIDEO') {
      assetModel.assetMetadata = assetModel.assetMetadata
        ? this.toVideoAssetMetadata(assetId, assetModel.assetMetadata)
        : {};
    }
    return assetModel;
  }

  async createAsset(courseDraftId: string, createAssetRequest: CreateAssetRequest): Promise<AssetModel> {
    const {data: assetModel} =  await this.axiosInstance.post<AssetModel>(
      '/assets/new',
      createAssetRequest
    );

    return assetModel;
  }

  async startAssetProcessing(courseDraftId: string, assetId: string): Promise<void> {
    await this.axiosInstance.post(
      `/assets/${assetId}/process`
    );
  }

  async getCloudLabInfo(courseDraftId: string, gadgetId: string): Promise<CloudLabInfo> {
    const {data: cloudLabInfo} = await this.axiosInstance.get<CloudLabInfo>(`/courses/${courseDraftId}/gadgets/${gadgetId}/cloud-lab-info`);

    return cloudLabInfo;
  }

  async createCourseRevision(courseDraftId: string): Promise<void> {
    await this.axiosInstance.post(
      `/courses/${courseDraftId}/revisions`
    );
  }

  /**
   * Convert Soju video asset metadata to the Gadget expected metadata
   * @param assetId asset id
   * @param metadata metadata as stored in Soju
   * @private
   */
  private toVideoAssetMetadata(assetId: string, metadata: Record<string, unknown>) {
    return {
      hlsEgressEndpoint: metadata.hlsEgressEndpoint ? metadata.hlsEgressEndpoint : metadata.hlsUrl,
      dashEgressEndpoint: metadata.dashEgressEndpoint ? metadata.dashEgressEndpoint : metadata.hlsUrl,
      drmLicenseUrl: `${this.apiConfig.baseUrl}/assets/drm_license`,
      videoProcessingDbGuid: metadata.videoProcessingDbGuid,
      videoProcessingWorkflowStatus: metadata.videoProcessingWorkflowStatus,
      transcriptFileGetUrl: metadata.transcriptFileGetUrl,
      transcriptFilePutUrl: metadata.transcriptFileGetUrl,
      videoTranscriptionStatus: metadata.videoTranscriptionStatus,
      thumbNailsUrls: metadata.thumbNailsUrls,
      videoProcessingWorkflowErrorMessage: metadata.videoProcessingWorkflowErrorMessage,
      videoTranscriptionErrorMessage: metadata.videoTranscriptionErrorMessage,
      getDrmRequest: (drmType: string, licenseChallenge: string, contentId: string) => {
        const wrapped: Record<string, any> = {};
        wrapped.drmType = drmType;
        wrapped.licenseChallenge = licenseChallenge;
        wrapped.assetId = assetId;
        wrapped.contentId = contentId;
        return {
          headers: {
            'Accept': drmType === DrmType.FAIRPLAY ? 'text/plain' : 'application/json',
            [AUTHOR_SESSION_HEADER_NAME]: this.authoringSession.learnerToken,
            [CSRF_TOKEN_HEADER_NAME]: this.authoringSession.csrfToken,
            'Content-Type': 'application/json',
            'cache-control': 'no-cache',
            'pragma': 'no-cache'
          },
          body: JSON.stringify(wrapped)
        };
      }
    } as VideoAssetMetadata;
  }

  /**
   * Adds the required headers from the learner session to the request
   * @param requestConfig axios request
   */
  private addTokenHeaders(requestConfig: InternalAxiosRequestConfig) {
    return {
      ...requestConfig,
      headers: {
        ...requestConfig.headers,
        [AUTHOR_SESSION_HEADER_NAME]: this.authoringSession.learnerToken,
        [CSRF_TOKEN_HEADER_NAME]: this.authoringSession.csrfToken
      } as unknown as AxiosRequestHeaders
    };
  }

  /**
   * API errors handler
   * @param error API response error
   */
  private async handleError(error: any) {
    const {response} = error;
    switch (response.status) {
      // handle expired tokens
      case HTTP_STATUS_CODE_ON_EXPIRED_CSRF:
      case HTTP_STATUS_CODE_ON_EXPIRED_LEARNER_SESSION: {
        if (!error.config._retrying) {
          try {
            // refresh the learner session
            this.authoringSession = await this.apiConfig.refreshSession();

            // repeat the request
            return this.axiosInstance.request({
              ...error.config,
              _retrying: true // avoids infinite loop
            });
          } catch (e) {
            return Promise.reject(e);
          }
        }
        return Promise.reject(error);
      }
      default: {
        return Promise.reject(error);
      }
    }
  }
}
