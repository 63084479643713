import { last } from "lodash";
import { CreateCourseDraftResponse } from "../models/CreateCourseDraftResponse";
import { CourseLearnerSession } from "./CourseLearnerSession";
import { BindleSoftwareApp } from "../models/bindles";
import { ListCoursesResponse } from "../models/ListCoursesResponse";
import { CourseDraft, CourseExport, CourseRevision } from "../models/courses";
import { config } from "./ApiConfig";
import { API, graphqlOperation } from "@aws-amplify/api";
import { getCourseDetails, getLatestCourseRevision } from "../graphql/queries";
import { createLearnerSession } from "../graphql/mutations";
import { CourseDetails, LearnerToken } from "../graphql/model";
import { parseStringToDate } from "./utils/parseStringToDate";

API.configure(config);

export class SojuApi {
  constructor() {}

  getHeaders(customerId: string) {
    return {
      "X-AMZN-SOJU-CUSTOMER": customerId,
      "Content-Type": "application/json",
    };
  }

  async getCourseDetails(
    customerId: string,
    courseDraftId: string,
  ): Promise<CourseDraft> {
    const response = (await API.graphql(
      graphqlOperation(getCourseDetails, {
        request: {
          courseId: courseDraftId,
          isDraft: false,
        },
      }),
      {
        ...this.getHeaders(customerId),
      },
    )) as {
      data: {
        getCourseDetails: CourseDetails;
      };
      error: object;
    };
    const courseDraft = response.data.getCourseDetails;
    return {
      id: courseDraft.id,
      title: courseDraft.title,
      createdAt: parseStringToDate(courseDraft.createdAt, new Date()),
      lastModified: parseStringToDate(courseDraft.lastModified, new Date()),
      tags: courseDraft.tags as string[],
    };
  }

  async getLatestCourseRevision(
    customerId: string,
    courseDraftId: string,
  ): Promise<CourseRevision[]> {
    const response = (await API.graphql(
      graphqlOperation(getLatestCourseRevision, {
        request: {
          courseDraftId: courseDraftId,
        },
      }),
      {
        ...this.getHeaders(customerId),
      },
    )) as { data: { courseRevisionId: string }; error: object };
    if (response.data) {
      const latestCourseRevision = await response.data;
      const latestRevisionNumber = Number(
        last(latestCourseRevision.courseRevisionId.split(".")),
      );
      const revisions: CourseRevision[] = [];
      for (
        let i = latestRevisionNumber;
        i > 0 && i > latestRevisionNumber - 5;
        i--
      ) {
        revisions.push({
          revisionId: "",
          number: i,
          createdAt: new Date(),
        });
      }
      return revisions;
    }
    return [];
  }

  async createCourseRevision(
    resourceId: string,
    courseDraftId: string,
  ): Promise<CourseRevision> {
    // const response = await sentryFetch(
    //   `${this.baseUrl}/api/courses/${courseDraftId}/revisions`,
    //   this.getOptions(resourceId, "POST"),
    // );
    // const courseRevision = await response.json();
    // return {
    //   createdAt: new Date(),
    //   revisionId: courseRevision.courseRevisionId,
    //   number: Number(last(courseRevision.courseRevisionId.split("."))),
    // };
    throw new Error("Not Implemented");
  }

  async getCourseRevisionExports(
    resourceId: string,
    courseRevisionId: string,
  ): Promise<CourseExport[]> {
    // const response = await sentryFetch(
    //   `${this.baseUrl}/api/catalogs/staged/courses/${courseRevisionId}/exports/all`,
    //   this.getOptions(resourceId),
    // );
    // if (response.ok) {
    //   const courseExports = (await response.json()) as CourseExport[];
    //   const courseExportsWithLinks: CourseExport[] = [];
    //   for (let i = 0; i < courseExports.length; i++) {
    //     const courseExport = courseExports[i];
    //     const exportWithLink =
    //       courseExport.status === "COMPLETED"
    //         ? (await this.getCourseRevisionExport(
    //             resourceId,
    //             courseRevisionId,
    //             courseExport.version,
    //           )) || courseExport
    //         : courseExport;
    //     courseExportsWithLinks.push(exportWithLink);
    //   }
    //   return courseExportsWithLinks;
    // }
    // if (!response.ok && response.status !== 404) {
    //   console.log("Failed response to get course revision exports: ", response);
    // }
    // return [];
    throw new Error("Not Implemented");
  }

  async getCourseRevisionExport(
    resourceId: string,
    courseRevisionId: string,
    version: number,
  ): Promise<CourseExport | null> {
    // const response = await sentryFetch(
    //   `${this.baseUrl}/api/catalogs/staged/courses/${courseRevisionId}/exports/${version}`,
    //   this.getOptions(resourceId),
    // );
    // if (response.ok) {
    //   return (await response.json()) as CourseExport;
    // }
    // if (!response.ok && response.status !== 404) {
    //   console.log("Failed response to get course revision export: ", response);
    // }
    // return null;
    throw new Error("Not Implemented");
  }

  async createCourseRevisionExport(
    resourceId: string,
    courseRevisionId: string,
  ): Promise<CourseExport> {
    // const response = await sentryFetch(
    //   `${this.baseUrl}/api/catalogs/staged/courses/${courseRevisionId}/exports`,
    //   this.getOptions(resourceId, "POST"),
    // );
    // return (await response.json()) as CourseExport;
    throw new Error("Not Implemented");
  }

  async createCourse(
    bindleId: string,
    courseDraft: Omit<CourseDraft, "id" | "createdAt" | "lastModified">,
  ): Promise<CreateCourseDraftResponse> {
    // const createResponse = await sentryFetch(
    //   `${this.baseUrl}/api/internal/courses`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //     },
    //     body: JSON.stringify({
    //       ...courseDraft,
    //       shortDescription: courseDraft.description,
    //       bindleId,
    //     }),
    //   },
    // );
    // return (await createResponse.json()) as CreateCourseDraftResponse;
    throw new Error("Not Implemented");
  }

  async listBindles(): Promise<BindleSoftwareApp[]> {
    // const response = await new Promise((resolve, reject) => {
    //   // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   // eslint-disable-next-line no-undef
    //   Bindles.findSoftwareApplicationsByUser(
    //     {},
    //     (data) => resolve(data),
    //     (error) => reject(error),
    //   );
    // });
    // if (typeof response === "string") {
    //   const bindles = JSON.parse(response) as any[];
    //   return bindles.map((bindle) => {
    //     return bindle.bindle_reference;
    //   });
    // }
    // return [];
    throw new Error("Not Implemented");
  }

  async listCourses(): Promise<ListCoursesResponse> {
    // const response = await new Promise((resolve, reject) => {
    //   // eslint-disable-next-line no-undef,@typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   // eslint-disable-next-line no-undef
    //   Bindles.findResourcesWithSoftwareApplicationsByPermission(
    //     {
    //       //parentId: 'amzn1.bindle.resource.35vyreedbfjvtbuzske3kplxa',
    //       resourceTypeId: SojuCourseResourceTypeId,
    //     },
    //     (data) => resolve(data),
    //     (error) => reject(error),
    //   );
    // });
    // if (typeof response === "string") {
    //   const softwareApps = JSON.parse(
    //     response,
    //   ) as CoursesInSoftwareAppResponse[];
    //   const draftCourses: CourseDraft[] = [];
    //   for (let i = 0; i < softwareApps.length; i++) {
    //     const softwareApp = softwareApps[i];
    //     for (let j = 0; j < softwareApp.resources.length; j++) {
    //       try {
    //         const courseDetails = await this.getCourseDetails(
    //           softwareApp.resources[j].id,
    //           softwareApp.resources[j].name,
    //         );
    //         draftCourses.push({
    //           ...courseDetails,
    //           ownerBindle: softwareApp.bindle_reference,
    //         });
    //       } catch (error) {
    //         console.log(
    //           `Failed to get course details for courseId: ${JSON.stringify(
    //             softwareApp.resources[j],
    //           )}`,
    //           error,
    //         );
    //       }
    //     }
    //   }
    //   return {
    //     bindles: softwareApps.map((app) => {
    //       return { ...app.bindle_reference };
    //     }),
    //     courses: draftCourses,
    //   };
    // }
    // return {
    //   bindles: [],
    //   courses: [],
    // };
    throw new Error("Not Implemented");
  }

  async createCourseSession(
    userPrincipal: string,
    courseId: string,
    isDraft: boolean,
  ): Promise<CourseLearnerSession> {
    const response = (await API.graphql(
      {
        query: createLearnerSession,
        variables: {
          request: {
            principal: userPrincipal,
            resource: courseId,
            principalType: "LEARNER",
          },
        },
      },
      {
        ...this.getHeaders(userPrincipal),
      },
    )) as {
      data: {
        createLearnerSession: LearnerToken;
      };
      error: object;
    };
    return {
      learnerToken: response.data.createLearnerSession.token,
      resourceId: userPrincipal,
      csrfToken: "",
      isDraft: isDraft,
    };
  }
}
