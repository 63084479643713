
/**
 * @description list of configuration constants required by the Shaka player
 */
export const SHAKA_PLAYER_CONFIG = {
  MANIFEST: {
    MANIFEST_RETRY_PARAMETERS: {
      MANIFEST_TIMEOUT: 30000, // timeout in ms, after which we abort
      MANIFEST_STALL_TIMEOUT: 5000, // stall timeout in ms, after which we abort
      MANIFEST_CONNECTIONS_TIMEOUT: 10000, // connection timeout in ms, after which we abort
      MANIFEST_MAX_ATTEMPTS: 2, // the maximum number of requests before we fail
      MANIFEST_BASE_DELAY: 1000, // the base delay in ms between retries
      MANIFEST_BACKOFF_FACTOR: 2, // the multiplicative backoff factor between retries
      MANIFEST_FUZZ_FACTOR: 0.5, // the fuzz factor to apply to each retry delay
    },
    HLS: {
      USE_FULL_SEGMENTS_FOR_START_TIME: true,
    },
    DASH: {
      IGNORE_MIN_BUFFER_TIME: true,
    },
  },
  STREAMING: {
    STREAMING_RETRY_PARAMETERS: {
      STREAMING_TIMEOUT: 30000, // timeout in ms, after which we abort
      STREAMING_STALL_TIMEOUT: 5000, // stall timeout in ms, after which we abort
      STREAMING_CONNECTIONS_TIMEOUT: 10000, // connection timeout in ms, after which we abort
      STREAMING_MAX_ATTEMPTS: 2, // the maximum number of requests before we fail
      STREAMING_BASE_DELAY: 1000, // the base delay in ms between retries
      STREAMING_BACKOFF_FACTOR: 2, // the multiplicative backoff factor between retries
      STREAMING_FUZZ_FACTOR: 0.5, // the fuzz factor to apply to each retry delay
    },
  }
};
