import React, {FunctionComponent, useRef, useState} from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Checkbox from '@amzn/meridian/checkbox';
import Popover, {PopoverHeader} from '@amzn/meridian/popover';
import checkCircleTokens from '@amzn/meridian-tokens/base/icon/check-circle';
import Theme from '@amzn/meridian/theme';
import {colorPalette} from '../../../../theme';

import {
  DisplaySection,
  ProgressStatus,
  useCourseContext
} from '../../../../context/course';

import '../styles/CourseStatusCheckbox.scss';
import {CourseStatusCheckboxI18nStrings, WithI18nStringsProps} from '../../../../context/course/models/I18n';

/**
 * Progress tracker course status checkbox props
 */
interface CourseStatusCheckboxProps extends WithI18nStringsProps<CourseStatusCheckboxI18nStrings> {

  /**
   * Flag to know if learner marked course as completed
   */
   isCourseCompleted: boolean;
}

/**
 * Course status checkbox for use to mark course status
 */
const CourseStatusCheckbox: FunctionComponent<CourseStatusCheckboxProps> = (
  {
    isCourseCompleted,
    i18nStrings,
  }: CourseStatusCheckboxProps
) => {
  const {
    setCoursePosition,
    setCourseProgress
  } = useCourseContext();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState<boolean>(false);
  const popoverRef = useRef<any>(null);
  const buttonToken = {'buttonBackgroundColorIconDarkBackdropDefault': isCourseCompleted ? colorPalette.wave400 : colorPalette.gray700};

  const toggleCourseStatus = async (completed: boolean) => {
    setIsCheckBoxDisabled(true);
    const response = await setCourseProgress(
      completed ? ProgressStatus.COMPLETED : ProgressStatus.NOT_STARTED
    );
    setIsCheckBoxDisabled(false);
    if(response.statusTransitionSuccessful) {
      if(completed) {
        setCoursePosition({
          lessonId: '',
          gadgetId: '',
          displaySection: DisplaySection.COMPLETION_PAGE,
          updatedAt: new Date(),
        });
      }
    } else {
      setIsPopoverOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Theme tokens={buttonToken}>
        <Button label={i18nStrings.courseStatusCheckboxButtonLabel} type='icon' size='small' onClick={() => setIsPopoverOpen(true)}
          ref={popoverRef}>
          <Icon tokens={checkCircleTokens}/>
        </Button>
      </Theme>
      <Popover
        anchorNode={popoverRef.current}
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        position='right'
        maxWidth={300}
      >
        <PopoverHeader closeLabel={i18nStrings.closeLabel}>{i18nStrings.markThisCourseAsComplete}</PopoverHeader>
        <Column>
          <Text type='b200'>{i18nStrings.markUnfinishedCourseAsCompleteBlurb}</Text>
          <Checkbox
            // className='learnerSidebarHeader__popover__checkbox'
            checked={isCourseCompleted}
            onChange={toggleCourseStatus}
            disabled={isCheckBoxDisabled}
          >{i18nStrings.markCourseComplete}</Checkbox>
        </Column>
      </Popover>
    </React.Fragment>
  );
};

export default CourseStatusCheckbox;
