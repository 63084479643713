import React, {FunctionComponent, useRef, useState} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import {Delta} from 'quill';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import BlotFormatter from 'quill-blot-formatter';
import findLast from 'lodash/findLast';
import katex from 'katex';
import {getContentType} from './utils/form';
import {useCourseAuthoringContext} from '../../context';
import {imageFileTypes} from '../asset-uploader/Constants';
import CustomImageSpec from './blot-formatter/specs/CustomImageSpec';

import 'react-quill/dist/quill.snow.css';
import './styles/quill-overrides.scss';
import 'katex/dist/katex.min.css';

import codeblockIcon from './code-block';
import {CreateAssetRequest} from '../../context/models/CreateAssetRequest';
import CustomImage from '../../../components/gadgets/reading/CustomImage';
import {useCourseContext} from '../../../context/course';
import {InvalidFile} from '../asset-uploader/AssetUploader';
import {defaultAssetUploadI18nStrings} from '../../../context/course/models/I18n';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.katex = katex;

const icons = Quill.import('ui/icons');
const quillDelta = Quill.import('delta');
icons['code-block'] = codeblockIcon;

Quill.register({
  'modules/blotFormatter': BlotFormatter,
  'modules/imageDropAndPaste': QuillImageDropAndPaste,
  'formats/image': CustomImage
}, true);

export interface CustomQuillProps {
  defaultValue: Delta;
  onChange: (delta: Delta) => void;
  onFileRejection: (invalidFiles: InvalidFile[]) => void;
}

// Quill configuration
const getModulesConfig = (imageHandler: unknown, imageDropAndPasteHandler: unknown) => {
  return  {
    toolbar: {
      container: [
        [{'header': [4, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code', 'code-block', {'script': 'sub'}, {'script': 'super'}],
        [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
        [{color: []}, {background: []}],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'formula'],
        ['clean']
      ],
      handlers: {
        image: imageHandler
      }
    },
    clipboard: {
    // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    blotFormatter: {
      specs: [
        CustomImageSpec
      ]
    },
    imageDropAndPaste: {
      handler: imageDropAndPasteHandler
    }
  };
};

export const CustomQuill: FunctionComponent<CustomQuillProps> = (
  {
    defaultValue,
    onChange,
    onFileRejection
  }: CustomQuillProps
) => {
  const {createAsset, uploadFileAssetToS3} = useCourseAuthoringContext();
  const {getAssetModel} = useCourseContext();
  const [quillValue, setQuillValue] = useState<Delta>(defaultValue);
  const quillObj = useRef<ReactQuill>(null);

  CustomImage.getAssetModel = getAssetModel;

  // Uploads image to API and returns the location of the asset to insert into the RTE editor
  const uploadFile = async (file: File) => {
    const createAssetRequest = {
      title: 'New File',
      type: 'IMAGE',
      tags: ['image'],
      contentType: getContentType(file)
    } as CreateAssetRequest;

    try {
      const newAssetModel = await createAsset(createAssetRequest);
      const assetId = newAssetModel.id;
      const assetLocation = newAssetModel.location as string;

      await uploadFileAssetToS3(assetLocation, file);
      if (quillObj.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const range = quillObj.current.getEditorSelection() || {index: 0, range: 0};
        quillObj.current.getEditor().insertEmbed(range.index, 'image', assetId);
      }
    } catch {
      // TODO: handle image upload error
    }
  };

  // Filter invalid file types
  const filterInvalidFiles = async (fileType: string, file: File) => {
    if(imageFileTypes.includes(`.${fileType.toLowerCase()}`)) {
      await uploadFile(file);
    } else {
      onFileRejection([{
        file,
        error: {
          title: defaultAssetUploadI18nStrings.fileUploadErrorGenericTitle,
          message: defaultAssetUploadI18nStrings.fileUploadErrorDueToUnsupportedFileType(file.name, imageFileTypes.join(', '))
        }}
      ]);
    }
  };

  
  // Event handler for Quill when insert image icon gets clicked
  // It will create a file input and programmatically click it
  // Once  the image gets selected, it will be uploaded to the API
  const imageHandler = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = async () => {
      if (input?.files) {
        const file = input?.files[0];
        const fileType = findLast(file.name.split('.')) || '';
        await filterInvalidFiles(fileType, file);
      }
    };

    input.click();
  };


  // Event handler for image drop and paste module
  // When the user pastes an image in Quill
  // We will extract the image and call uploadFile with it
  const dropAndPasteHandler = async (imageDataUrl: string, type: string, imageData: unknown) => {
    const filename = 'temp_name';
    // DropAndPaste library has no types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const file = imageData.toFile(filename);
    const fileType = findLast(type.split('/')) || '';
    await filterInvalidFiles(fileType, file);
  };

  const [modules] = useState(getModulesConfig(imageHandler, dropAndPasteHandler));

  const handleQuillChange = (content: any, delta: any, source: any, editor: any) => {
    setQuillValue(editor.getContents());
    onChange(editor.getContents());
  };

  return (
    <ReactQuill
      value={quillValue || new quillDelta()}
      modules={modules}
      bounds={'.quill'}
      onChange={handleQuillChange}
      className={'ql-edit-mode'}
      ref={quillObj}
    />
  );
};
