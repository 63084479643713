import React, {FunctionComponent} from 'react';

// import './BaseIcon.scss';

export interface BaseIconProps {
  color?: string,
  alt?: string,
  width?: number,
  height?: number,
}

export const DefaultIconSize = 18;

export const BaseIcon: FunctionComponent<BaseIconProps> = ({
  color = 'currentColor',
  alt,
  width = DefaultIconSize,
  height = DefaultIconSize,
  children
}) => {
  return <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={color} xmlns="http://www.w3.org/2000/svg">
    {alt ? <title>{alt}</title> : null}
    {children}
  </svg>;
};
