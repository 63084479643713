import React, {forwardRef, FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useEmblaCarousel} from 'embla-carousel/react';
import {Delta} from 'quill';
import {Quill} from 'react-quill';
import {RequireAtLeastOne} from 'type-fest';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Pagination from '@amzn/meridian/pagination';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Divider from '@amzn/meridian/divider';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import {GadgetEditorProps} from '../models';
import {AssetModel, GadgetConfig} from '../../../../context/course';
import GadgetContainer from '../../../../components/containers/gadget/GadgetContainer';
import {useCourseAuthoringContext} from '../../../context';
import {shortId} from '../../../../gadgets/utils/shortId';
import CarouselSlide from './CarouselSlide';
import {InvalidFileUploadAlert} from '../../alerts/invalid-file-upload-alert/InvalidFileUploadAlert';
import {SlideshowGadgetI18nStrings} from '../../../../context/course/models/I18n';
import {InvalidFile} from '../../asset-uploader/AssetUploader';

import './styles/slideshow.scss';

const DeltaCtor = Quill.import('delta');

type SlideContentTypes = {
  id: string,
  image?: AssetModel,
  content?: Delta
};

export type SlideType = RequireAtLeastOne<SlideContentTypes, 'image' | 'content'>;

export interface SlideshowGadgetConfig extends GadgetConfig {
  slides: SlideType[]
}
export type SlideshowGadgetProps = GadgetEditorProps<SlideshowGadgetConfig, SlideshowGadgetI18nStrings>;

/**
 * You can use this gadget to add reading sections to your course.
 * The content will be generated by a WYSIWYG text editor, which allows the author to add basic formatting to the text, as well as media assets (only images supported).
 * The body of the gadget is a Quill Delta object: https://quilljs.com/docs/delta/
 */
export const SlideshowGadget: FunctionComponent<SlideshowGadgetProps> = forwardRef<HTMLElement | undefined, SlideshowGadgetProps>((
  {
    id: gadgetId,
    config,
    i18nStrings
  }: SlideshowGadgetProps,
  ref
) => {
  const {
    title,
    slides
  } = config;
  const {updateGadget} = useCourseAuthoringContext();
  const [currentSlide, setCurrentSlide] = useState(1);
  const [viewportRef, carouselApi] = useEmblaCarousel({skipSnaps: false, draggable: false});
  const [invalidFiles, setInvalidFiles] = useState<InvalidFile[]>([]);

  const handleTitleChange = useCallback((newTitle: string) => {
    updateGadget(gadgetId, {
      title: newTitle,
      slides: slides
    } as SlideshowGadgetConfig);
  }, [updateGadget, gadgetId, slides]);

  // Callback to handle pagination onClick event
  const scrollTo = useCallback(page => {
    if (carouselApi) {
      carouselApi.scrollTo(page - 1);
      setCurrentSlide(page);
    }
  }, [carouselApi, setCurrentSlide]);

  /**
   * Callback to add new slide
   * The slide will be added next to the current slide
   */
  const addSlide = useCallback(() => {
    const newSlides = size(slides) > 0 ? [...slides] : [];
    const newSlide = {id: shortId(), image: undefined, content: new DeltaCtor()};
    newSlides.splice(currentSlide, 0, newSlide);

    updateGadget(gadgetId, {
      title,
      slides: newSlides,
    } as SlideshowGadgetConfig);
  }, [slides, currentSlide, updateGadget, gadgetId, title]);

  // Callback to delete current slide
  const removeSlide = useCallback(() => {
    let newSlides = [...slides];
    if(slides.length === 1) {
      const newSlide = {id: shortId(), image: undefined, content: new DeltaCtor()};
      newSlides = [newSlide];
    } else {
      newSlides.splice(currentSlide - 1, 1);
    }
    updateGadget(gadgetId, {
      title,
      slides: newSlides,
    } as SlideshowGadgetConfig);
    setCurrentSlide(currentSlide <= 1 ? 1 : currentSlide === slides.length ? currentSlide - 1 : currentSlide);
  }, [slides, currentSlide, updateGadget, gadgetId, title]);

  // Callback to update slide content
  const updateSlide = useCallback((slideIndex: number, updatedSlide: SlideType) => {
    const newSlides = [...slides];
    newSlides[slideIndex] = updatedSlide;
    updateGadget(gadgetId, {
      title,
      slides: newSlides,
    } as SlideshowGadgetConfig);
  }, [gadgetId, slides, title, updateGadget]);

  /**
   * Triggers when the slides array changes
   * It will update embla with the latest slides
   */
  useEffect(() => {
    if (!carouselApi) {
      return;
    }
    const totalCurrentSlides = carouselApi.slideNodes().length;
    const totalNewSlides = size(slides);
    carouselApi.reInit();
    if (totalNewSlides === totalCurrentSlides + 1) { // slide was added
      carouselApi.scrollNext();
    }
  }, [carouselApi, slides]);

  /**
   * Function triggered by embla when scroll by the carousel happens
   * The function will set the new current slide index
   */
  const onSelect = useCallback(() => {
    if (!carouselApi) {
      return;
    }
    setCurrentSlide(carouselApi.selectedScrollSnap() + 1);
  }, [carouselApi]);

  /**
   * Appen onSelect event to carousel
   */
  useEffect(() => {
    if (!carouselApi) {
      return;
    }
    carouselApi
      .on('select', onSelect);
    onSelect();
  }, [carouselApi, onSelect]);

  const onAlertClose = useCallback((index: number) => {
    const fileNames = Array.from(invalidFiles) as InvalidFile[];
    fileNames.splice(index, 1);
    setInvalidFiles(fileNames);
  }, [invalidFiles]);

  return (
    <GadgetContainer id={gadgetId} ref={ref}>
      <Column alignmentHorizontal='center' spacing={'small'} className={'slideshow-gadget__container'}>
        <InvalidFileUploadAlert
          invalidFiles={invalidFiles}
          onAlertClose={onAlertClose}
        />
        <Box width={'100%'}>
          <Input
            onChange={handleTitleChange}
            placeholder={'Slideshow title'}
            value={title}
            constraintText={'Title is optional but recommended'}
            size={'xlarge'}
          />
        </Box>

        <>
          <Row alignmentHorizontal={'justify'} spacing={'none'} spacingInset={'none'} width='100%'>
            <Box>
              <Text type={'b100'}>{size(slides)} slide{size(slides) !== 1 && 's'}</Text>
            </Box>
            <Box spacingInset={'small'} minWidth='0'>
              <Pagination
                numberOfPages={size(slides)}
                onChange={page => scrollTo(page)}
                currentPage={currentSlide}
                showSkipArrows
              />
            </Box>
            <Box>
              <Button type={'primary'} onClick={addSlide}>
                <Icon tokens={plusTokens} />
              </Button>
            </Box>
          </Row>
          <Box width={'100%'}>
            <Divider />
          </Box>
          <Row width={'100%'} alignmentHorizontal={'justify'} spacing={'none'} spacingInset={'none'} minHeight={40}>
            <Box>
              <Text type={'h100'}>Slide {currentSlide}</Text>
            </Box>
            {!isEmpty(slides) &&
              <Box>
                <Button type={'icon'} onClick={removeSlide}>
                  <Icon tokens={trashTokens} />
                </Button>
              </Box>
            }
          </Row>
        </>

        <div className='carousel'>
          <div className='carousel__viewport' ref={viewportRef}>
            <div className='carousel__container'>
              {slides?.map((slide, i) =>
                <CarouselSlide
                  key={slide.id}
                  slide={slide}
                  onUpdate={(content: SlideType) => updateSlide(i, content)}
                  onRejection={setInvalidFiles}
                  i18nStrings={i18nStrings}
                />
              )}
            </div>
          </div>
        </div>
      </Column>
    </GadgetContainer>
  );
});
SlideshowGadget.displayName = 'SlideshowGadget';
