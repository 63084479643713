export enum GadgetType {
  QUIZ = 'amzn/quiz',
  VIDEO = 'amzn/video',
  HEADER = 'amzn/header',
  SLIDESHOW =  'amzn/slideshow',
  RESPONSE = 'amzn/response',
  READING = 'amzn/rte',
  MARKDOWN = 'amzn/markdown',
  LAB = 'amzn/hocl'
}
