import React, {forwardRef} from 'react';
import {MarkdownRenderer} from './MarkdownRenderer';
import Box from '@amzn/meridian/box';
import Heading from '@amzn/meridian/heading';
import Theme from '@amzn/meridian/theme';
import brandedLightTokens from '../../../theme/branded-light';
import {GadgetProps} from '../models';
import {GadgetConfig, GadgetLearnerState, GadgetType} from '../../../context/course';
import GadgetContainer from '../../containers/gadget/GadgetContainer';
import {defaultI18nStrings, MarkdownGadgetI18nStrings} from '../../../context/course/models/I18n';

import './styles/markdown-gadget.scss';

export interface MarkdownGadgetConfig extends GadgetConfig {
  body : {
    source?: string
  }
}

export type MarkdownGadgetProps = GadgetProps<MarkdownGadgetConfig, GadgetLearnerState, MarkdownGadgetI18nStrings>

export const MarkdownGadget = forwardRef<HTMLElement | undefined, MarkdownGadgetProps>((props: MarkdownGadgetProps, ref) => {
  const {
    id,
    config: {title, body},
    i18nStrings = defaultI18nStrings.gadgets[GadgetType.MARKDOWN]!,
  } = props;

  return (
    <GadgetContainer ref={ref} id={id}>
      <Theme tokens={brandedLightTokens}>
        <Box className='markdown-gadget'>
          <div className='markdown-gadget__title'>
            <Heading level={3}>{title}</Heading>
          </div>
          <MarkdownRenderer
            markdownText={body.source || ''}
            i18nStrings={i18nStrings}
          />
        </Box>
      </Theme>
    </GadgetContainer>
  );
});
MarkdownGadget.displayName = 'MarkdownGadget';
