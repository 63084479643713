import React, {FunctionComponent, useRef, useState} from 'react';
import {MarkdownRenderer} from '../../../../components/gadgets/markdown/MarkdownRenderer';
import Text from '@amzn/meridian/text';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Tab, {TabGroup} from '@amzn/meridian/tab';
import Link from '@amzn/meridian/link';
import AceEditor from 'react-ace';
import noop from 'lodash/noop';
import {AssetDataModel, AssetUploader, InvalidFile} from '../../asset-uploader/AssetUploader';
import {downloadableFileTypes, imageFileTypes} from '../../asset-uploader/Constants';
import {InvalidFileUploadAlert} from '../../alerts/invalid-file-upload-alert/InvalidFileUploadAlert';
import {
  defaultI18nStrings,
  MarkdownGadgetI18nStrings,
  WithI18nStringsProps
} from '../../../../context/course/models/I18n';
import { GadgetType } from '../../../../context/course';
import {getItemFromSessionStorage} from '../../../../hooks/useSessionStorage';
import {FileProcessingInfoModal, HideFileProcessingInfoStorageKey} from './FileProcessingInfoModal';

import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

import './styles/MarkdownEditor.scss';

const MAX_FILE_SIZE = 1024 * 1024 * 1024 * 5; // 5GB

export interface MarkdownEditorProps extends WithI18nStringsProps<MarkdownGadgetI18nStrings> {
  /**
   * Markdown text to render.
   */
  markdownText: string,

  /**
   * Callback to handle markdown text change.
   */
  handleMarkdownTextChange: (markdownText: string) => void,

  /**
   * Flag to enable preview tab in markdown editor.
   */
  enablePreviewTab?: boolean,

  /**
   * Markdown editor min lines.
   */
  minLines?: number
}

const enum TAB_OPTION {
  EDITOR,
  PREVIEW
}

const SUPPORTED_FILE_TYPES = {
  ...downloadableFileTypes,
  'image/*': imageFileTypes,
};

export const MarkdownEditor: FunctionComponent<MarkdownEditorProps> = ({
  markdownText,
  handleMarkdownTextChange,
  enablePreviewTab,
  minLines,
  i18nStrings = defaultI18nStrings.gadgets[GadgetType.MARKDOWN]!,
}: MarkdownEditorProps)=> {
  const [currentTab, setCurrentTab] = useState<TAB_OPTION>(TAB_OPTION.EDITOR);
  const [invalidFiles, setInvalidFiles] = useState<InvalidFile[]>([]);
  const [fileProcessingInfoModalOpen, setFileProcessingInfoModalOpen] = useState(false);
  const editorRef = useRef<AceEditor>(null);

  const onClose = () => {
    setFileProcessingInfoModalOpen(false);
  };

  const updateMarkdownText = (assetData: AssetDataModel) => {
    if ((assetData.image || assetData.downloadable) && editorRef.current) {
      const editor = editorRef.current.editor;
      let text;
      if (assetData.image) {
        text = `![${assetData.image.title}](asset/${assetData.image.assetId})`;
      } else {
        text = `[${assetData.downloadable?.title}](asset/${assetData.downloadable?.assetId})`;
      }
      editor.session.insert(editor.getCursorPosition(), text);

      if (assetData.downloadable) {
        const isFileProcessingInfoModalHidden = getItemFromSessionStorage(HideFileProcessingInfoStorageKey, false);
        if (!isFileProcessingInfoModalHidden) {
          setFileProcessingInfoModalOpen(true);
        }
      }
    }
  };

  const switchTab = (tabOption: TAB_OPTION) => {
    setCurrentTab(tabOption);
    setInvalidFiles([]);
  };

  const onAlertClose = (index: number) => {
    setInvalidFiles(invalidFiles => {
      const files = Array.from(invalidFiles) as InvalidFile[];
      files.splice(index, 1);
      return files;
    });
  };

  return (
    <Column>
      <Box>
        <Row alignmentHorizontal='justify'>
          <Column>
            {enablePreviewTab ?
              <TabGroup value={currentTab} onChange={switchTab} fill='tabs'>
                <Tab value={TAB_OPTION.EDITOR}>Editor</Tab>
                <Tab value={TAB_OPTION.PREVIEW}>Preview</Tab>
              </TabGroup>
              : (
                <Text>
                  {i18nStrings.editorLabel}
                </Text>
              )
            }
          </Column>
          <Column>
            <Link
              aria-label='Markdown help'
              type='secondary'
              target={'_blank'}
              rel={'noopener noreferrer'}
              href='https://commonmark.org/help/'
            >
              Markdown help
            </Link>
          </Column>
        </Row>
      </Box>
      <Box className={'markdown-editor__container'}>
        <InvalidFileUploadAlert
          invalidFiles={invalidFiles}
          onAlertClose={onAlertClose}
        />
        {currentTab === TAB_OPTION.EDITOR && (
          <>
            <AssetUploader
              maxSize={MAX_FILE_SIZE}
              supportedFileTypes={SUPPORTED_FILE_TYPES}
              updateAssetData={updateMarkdownText}
              onUploadFailure={setInvalidFiles}
              onUploadSuccess={noop}
              onRejection={setInvalidFiles}
              noClick={{
                label: i18nStrings.noClickLabel,
                uploadLabel: i18nStrings.noClickUploadLabel,
                uploadOptionLabel: i18nStrings.noClickUploadOptionLabel,
              }}
              i18nStrings={i18nStrings.assetUpload}
            >
              <div className={'markdown-editor__container__text-input'}>
                <AceEditor
                  placeholder={i18nStrings.editorPlaceholder}
                  width={'100%'}
                  mode="markdown"
                  theme="github"
                  ref={editorRef}
                  minLines={minLines || 10}
                  maxLines={Infinity}
                  name="MarkdownEditor"
                  onChange={handleMarkdownTextChange}
                  fontSize={16}
                  showPrintMargin={false}
                  showGutter={false}
                  highlightActiveLine={false}
                  wrapEnabled={true}
                  value={markdownText}
                  setOptions={{
                    minLines: minLines || 10,
                    maxLines: Infinity,
                  }}/>
              </div>
            </AssetUploader>
            <FileProcessingInfoModal
              open={fileProcessingInfoModalOpen}
              onClose={onClose}
              i18nStrings={i18nStrings.fileProcessingInfo}
            />
          </>
        )}
        {currentTab === TAB_OPTION.PREVIEW && (
          <div data-testid={'markdown-renderer'} onDoubleClick={() => switchTab(TAB_OPTION.EDITOR)}>
            <MarkdownRenderer
              markdownText={markdownText}
              i18nStrings={i18nStrings}
            />
          </div>
        )}
      </Box>
    </Column>
  );
};
