export const POLL_ASSET_INTERVAL_MILLISECONDS = 20000;
export const POLL_ASSET_MAXIMUM_ATTEMPTS = 45; // 20 secs * 45 -- roughly 15 minutes

export const LABELS = {
  noVideoSelected: 'No video chosen',
  replaceFile: 'replace file',
  titlePlaceholder: 'Video title (recommended)',
  titleConstraintPlaceholder: 'Title is optional but recommended',
  captionUploadPlaceholder: 'Drag file here or click to upload/replace captions. Supported format(s): *.vtt',
  textTranscriptUploadPlaceholder: 'Drag file here or click to upload untimed transcript text file (*.txt). \n (Use only if VTT captions aren\'t available.)',
  errorMessageTitle: 'Invalid or damaged file.',
  captionsFetchErrorMessage: 'An error occurred while processing your captions. Please check the file.',
  assetUploadSuccessMessagePrefix: 'Successfully uploaded file '
};
