import React, {FunctionComponent} from 'react';
import {DropZoneRenderProps, DropZoneType} from '../drag-and-drop/DnDList';
import Box from '@amzn/meridian/box';
import Text from '@amzn/meridian/text';
import cx from 'classnames';

import './styles/GadgetDropZone.scss';

export const GadgetDropZone: FunctionComponent<DropZoneRenderProps> = ({
  type,
  hovering
}) => {
  if (type === DropZoneType.Only) {
    return <Box height='100%'>
      <Text type='h400' color='secondary'>
        To begin, grab a gadget and drag it onto the canvas
      </Text>
    </Box>;
  } else if (type === DropZoneType.Last) {
    const dropZoneClassName = cx('GadgetDropZone', hovering && 'GadgetDropZone--hovering');
    return <div className={dropZoneClassName}>
      <Box backgroundColor='secondary' type='fill' height='170px' />
    </div>;
  } else {
    return null;
  }
};
