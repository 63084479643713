import React, {FunctionComponent, PropsWithChildren, ReactElement, ReactNode} from 'react';
import {useDragLayer, useDrop} from 'react-dnd';
import Box from '@amzn/meridian/box';
import Icon from '@amzn/meridian/icon';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import cx from 'classnames';

import {DnDListItemProps, DnDListItemType} from './DnDListItem';
import './styles/DnDList.scss';

export enum DropZoneType {
  First = 'first',
  Last = 'last',
  Only = 'only'
}

export interface DropZoneRenderProps {
  hovering: boolean;
  type: DropZoneType;
}
export const DefaultDropZoneExtender: FunctionComponent = () => {
  return <Row spacingInset='400' width='100%' widths={['fill']}>
    <Box type='outline' backgroundColor='secondary' spacingInset='600 400'>
      <Row alignmentHorizontal='center' alignmentVertical='center'>
        <Text><Icon tokens={plusTokens} /> Add</Text>
      </Row>
    </Box>
  </Row>;
};


export interface AddableDnDListProps<T extends DnDListItemType> {
  addableDropTypes: string[];
  onDrop: (draggedItem: T, dropIndex: number, dropType: string) => void;
  children: Array<ReactElement<DnDListItemProps<T>>>;
  dropZoneRender?: (props: DropZoneRenderProps) => ReactNode
  disableAddToBeginning?: boolean;
  disableAddToEnd?: boolean;
  onlyRenderOnDrag?: boolean;
}

type AddableDnDListPropsWithChildren<T extends DnDListItemType> = PropsWithChildren<AddableDnDListProps<T>>

/**
 * A wrapper component around a number of DndListItems children. Renders extra drop targets at start and end of the
 * list, to make it easier to add new items to the beginning or end of the list
 * @param addableDropTypes an array of drop types that are allowed to be added to this list.
 * @param onEnd optional callback to be called when all dnd events are over
 * @param onDrop callback for when an acceptable item is dropped
 * @param children an array of DnDListItem components and nothing else.
 * @constructor
 */
export const AddableDnDList = <T extends DnDListItemType>({
  addableDropTypes,
  onDrop,
  dropZoneRender = () => <DefaultDropZoneExtender />,
  disableAddToBeginning,
  disableAddToEnd,
  onlyRenderOnDrag,
  children = []
}: AddableDnDListPropsWithChildren<T>) => {
  const numberOfChildren = React.Children.count(children);
  const hasChildren = numberOfChildren > 0;
  const {draggingAcceptableType} = useDragLayer(monitor => {
    return {
      draggingAcceptableType: monitor.isDragging() &&
        addableDropTypes?.some(type => monitor.getItemType() as string === type)
    };
  });
  const [collectFirst, dropFirst] = useDrop(
    () => ({
      accept: addableDropTypes,
      drop(draggedItem: T, monitor) {
        onDrop(draggedItem, 0, monitor.getItemType() as string);
      },
      collect(monitor) {
        return {
          hoveringOverFirstDropZone: monitor.isOver()
        };
      }
    }),
    [numberOfChildren, onDrop],
  );

  const [collectLast, dropLast] = useDrop(
    () => ({
      accept: addableDropTypes,
      drop(draggedItem: T, monitor) {
        onDrop(draggedItem, numberOfChildren, monitor.getItemType() as string);
      },
      collect(monitor) {
        return {
          hoveringOverLastDropZone: monitor.isOver()
        };
      }
    }),
    [numberOfChildren, onDrop],
  );

  const className = cx(
    'AddableDnDList__drop-zone',
    onlyRenderOnDrag && !draggingAcceptableType && 'AddableDnDList__drop-zone--invisible'
  );

  if (hasChildren) {
    return <>
      {!disableAddToBeginning && <div className={className} ref={node => dropFirst(node)}>
        {dropZoneRender({
          hovering: collectFirst.hoveringOverFirstDropZone,
          type: DropZoneType.First
        })}
      </div>}
      {children}
      {!disableAddToEnd && <div className={className} ref={node => dropLast(node)}>
        {dropZoneRender({
          hovering: collectLast.hoveringOverLastDropZone,
          type: DropZoneType.Last
        })}
      </div>}
    </>;
  } else {
    return <div
      className='AddableDnDList__drop-zone'
      ref={node => dropFirst(node)}
    >
      {dropZoneRender({
        hovering: collectFirst.hoveringOverFirstDropZone,
        type: DropZoneType.Only
      })}
    </div>;
  }
};
