import React, {FunctionComponent, RefObject, useCallback, useEffect, useState} from 'react';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Theme from '@amzn/meridian/theme';
import blueLightTokens from '@amzn/meridian-tokens/theme/blue-light';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';
import Modal, {ModalFooter} from '@amzn/meridian/modal';
import {chain, find} from 'lodash';
import entries from 'lodash/entries';
import indexOf from 'lodash/indexOf';
import findIndex from 'lodash/findIndex';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';

import brandedLight from '../../../theme/branded-light';
import {Gadget, Lesson} from '../../../context/course';
import {AuthoringGadgetRenderer} from '../gadgets/registry';
import {useCourseAuthoringContext} from '../../context';
import {
  GADGET_DRAG_ITEM_TYPE,
  GadgetEditorContainer
} from './GadgetEditorContainer';
import {
  GadgetTray,
  GadgetTrayItem,
  GADGET_TRAY_WIDTH,
  GADGET_TRAY_DRAG_ITEM_TYPE
} from '../gadget-tray/GadgetTray';
import {LessonCanvasTitleId} from '../../../components/containers/lesson-canvas/LessonCanvas';
import {DnDListItem} from '../drag-and-drop/DnDListItem';
import {AddableDnDList} from '../drag-and-drop/DnDList';
import {GadgetDropZone} from './GadgetDropZone';

import './styles/lesson-editor.scss';
import {GadgetType} from '../../../context/course';
import {FullScreenGadgetEditorContainer} from './FullScreenGadgetEditorContainer';

/**
 * Lesson Editor props
 */
export interface LessonEditorProps {
  /**
   * Lesson to render in the editor
   */
  lesson: Lesson;

  /**
   * Gadget selected to be shown in full screen
   */
  fullScreenGadgetId?: string;

  /**
   * Registry of gadgets.
   */
  gadgetsRegistry: AuthoringGadgetRenderer;

  /**
   * map of gadget ids to ref object of the gadget's container
   */
  gadgetRefsByGadgetId: Record<string, RefObject<HTMLElement>>;
}

/**
 * Renders the lesson editor
 */
export const LessonEditor: FunctionComponent<LessonEditorProps> = ({
  lesson: {id: lessonId, title, gadgets},
  fullScreenGadgetId,
  gadgetsRegistry,
  gadgetRefsByGadgetId,
}: LessonEditorProps) => {
  const {
    removeGadget,
    updateContentTitle,
    updateGadgetIndex,
    addLessonGadgets
  } = useCourseAuthoringContext();

  const [selectedGadgets, setSelectedGadgets] = useState<string[]>([]);
  const [selectedGadgetsForDeletion, setSelectedGadgetsForDeletion] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [lessonTitle, setLessonTitle] = useState<string>(title);

  useEffect(() => {
    // If new title comes from props means it was update from a different source
    // Update local state to make it match with what we have in Recoil
    if(title !== lessonTitle) {
      setLessonTitle(title);
    }
  }, [title]);

  const onRemoveGadget = useCallback(async gadgetId => {
    setSelectedGadgetsForDeletion([gadgetId]);
    await removeGadget(lessonId, [gadgetId]);
    setSelectedGadgetsForDeletion([]);
  }, [lessonId, removeGadget]);

  const onUpdateLessonTitle = useCallback((newTitle: string) => {
    // update local state
    setLessonTitle(newTitle);

    // save title
    updateContentTitle(lessonId, newTitle);
  }, [lessonId, updateContentTitle]);

  const findGadgetIndex = useCallback(
    (gadgetId: string) => {
      return findIndex(gadgets, {'id': gadgetId});
    },
    [gadgets],
  );

  const moveGadget = useCallback((gadgetId: string, index: number) => {
    updateGadgetIndex(lessonId, gadgetId, index);
  }, [lessonId, updateGadgetIndex]);

  const addNewGadget = useCallback(async (gadgetType: GadgetType, index: number) => {
    const config = gadgetsRegistry[gadgetType].defaultConfig;
    await addLessonGadgets(lessonId, [{type: gadgetType, config}], index);
  }, [gadgetsRegistry, addLessonGadgets, lessonId]);

  const toggleSelectedGadget = useCallback((gadgetId: string) => {
    const gadgetIdIndex = indexOf(selectedGadgets, gadgetId);
    if (gadgetIdIndex !== -1) {
      const newSelection = [...selectedGadgets];
      newSelection.splice(gadgetIdIndex, 1);
      setSelectedGadgets([...newSelection]);
    } else {
      setSelectedGadgets([
        ...selectedGadgets,
        gadgetId
      ]);
    }
  }, [selectedGadgets]);

  const handleDeselectGadgets = useCallback(() => {
    setSelectedGadgets([]);
  }, []);

  const handleDeleteSelectedGadgetsClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const removeGadgets = useCallback(async () => {
    setSelectedGadgetsForDeletion(selectedGadgets);
    await removeGadget(lessonId, selectedGadgets);
    setSelectedGadgets([]);
    setSelectedGadgetsForDeletion([]);
  }, [removeGadget, lessonId, selectedGadgets]);

  const onGadgetDeleteConfirm = useCallback(async () => {
    await removeGadgets();
    setIsModalOpen(false);
  }, [removeGadgets]);
  const onDropDndList = useCallback(async (draggedItem, dropIndex, dropType) => {
    if (dropType === GADGET_DRAG_ITEM_TYPE) {
      moveGadget(draggedItem.id, dropIndex);
    }
    if (dropType === GADGET_TRAY_DRAG_ITEM_TYPE) {
      await addNewGadget(draggedItem.id, dropIndex);
    }
  }, [addNewGadget, moveGadget]);

  const onDropEmptyDndList = useCallback(async (draggedItem, dropIndex) => {
    await addNewGadget(draggedItem.id, dropIndex);
  }, [addNewGadget]);

  const fullScreenGadget = find(gadgets, {'id': fullScreenGadgetId});
  if (fullScreenGadget) {
    return (
      <FullScreenGadgetEditorContainer
        gadget={fullScreenGadget}
        gadgetsRegistry={gadgetsRegistry}
        gadgetRefsByGadgetId={gadgetRefsByGadgetId}
      />
    );
  }

  return (<Theme tokens={{
    ...blueLightTokens,
    ...brandedLight
  }}>
    <Row
      className='lessonEditor'
      height='100%'
      width='100%'
      widths={['fill', 'fit']}
      alignmentVertical='stretch'
      spacing='none'
      spacingInset='none'
    >
      <Column
        alignmentVertical='top'
        alignmentHorizontal='center'
        overflowY='scroll'
        height='100%'
        width='100%'
        heights={['fill']}
      >
        <Column
          className='lessonEditor__lessonCanvas'
          alignmentVertical='top'
          alignmentHorizontal='center'
          maxWidth='673px'
          width='100%'
          heights={!isEmpty(selectedGadgets) ? ['fit', 'fill'] : ['fill']}
        >
          {!isEmpty(selectedGadgets) && <div className='lessonEditor__lessonCanvas__topNotification'>
            <Row alignmentHorizontal='center' spacingInset={'small'}>
              <Button type='primary' onClick={handleDeleteSelectedGadgetsClick}>{`Delete all (${size(selectedGadgets)})`}</Button>
              <Button type='secondary' onClick={handleDeselectGadgets}>{`Deselect all (${size(selectedGadgets)})`}</Button>
            </Row>
          </div>}
          <Column heights={['fit', 'fill']} width='100%' spacingInset='600 none none none'>
            <Row widths={['fill']} spacingInset={'none none 500 none'}>
              <Input
                id={LessonCanvasTitleId}
                size='xlarge'
                value={lessonTitle}
                onChange={onUpdateLessonTitle}
              />
            </Row>
            <Box>
              <AddableDnDList
                addableDropTypes={[GADGET_TRAY_DRAG_ITEM_TYPE]}
                onDrop={onDropEmptyDndList}
                dropZoneRender={GadgetDropZone}
                disableAddToBeginning
              >
                {chain(gadgets)
                  .map((gadget: Gadget, index) => {
                    return (
                      <DnDListItem
                        key={gadget.id}
                        dragType={GADGET_DRAG_ITEM_TYPE}
                        dropTypes={[GADGET_DRAG_ITEM_TYPE, GADGET_TRAY_DRAG_ITEM_TYPE]}
                        item={{
                          id: gadget.id,
                          index
                        }}
                        onDrop={onDropDndList}
                        overrideDnDZones={{drag: true}}
                        onDroppingElement={<DroppingSpinner />}
                      >
                        <GadgetEditorContainer
                          gadget={gadget}
                          isGadgetSelected={indexOf(selectedGadgets, gadget.id) !== -1}
                          findGadgetIndex={findGadgetIndex}
                          moveGadget={moveGadget}
                          gadgetsRegistry={gadgetsRegistry}
                          onRemoveLessonGadget={onRemoveGadget}
                          toggleSelectedGadget={() => toggleSelectedGadget(gadget.id)}
                          gadgetRefsByGadgetId={gadgetRefsByGadgetId}
                          isDeleteInProgress={selectedGadgetsForDeletion.includes(gadget.id)}
                        />
                      </DnDListItem>
                    );
                  })
                  .value()}
              </AddableDnDList>
            </Box>
          </Column>
        </Column>
      </Column>
      <Box type='outline' width={GADGET_TRAY_WIDTH} overflowY='scroll'>
        <GadgetTray
          gadgets={entries(gadgetsRegistry).map(([gadgetType, gadget]) => {
            return {
              name: gadget.defaultTitle,
              type: gadgetType,
              description: '',
              icon: gadget.iconUrl
            } as GadgetTrayItem;
          })}
        />
      </Box>
      <Modal open={isModalOpen} title='Delete Gadgets'>
        <Text>
          Are you sure you want to delete {selectedGadgets.length > 1 ? 'multiple gadgets' : 'the gadget'}
        ? This action cannot be undone.
        </Text>
        <ModalFooter>
          <Row spacing='medium' alignmentHorizontal='right'>
            <Button
              type='secondary'
              label='Cancel delete gadgets'
              onClick={() => {
                setIsModalOpen(false);
              }}
            >Cancel</Button>
            <Button
              label='Confirm delete gadgets'
              onClick={onGadgetDeleteConfirm}
            >Delete</Button>
          </Row>
        </ModalFooter>
      </Modal>
    </Row>
  </Theme>
  );
};

const DroppingSpinner: FunctionComponent = () => {
  return (
    <Row
      className='lessonEditor__lessonCanvas__gadget__spinnerContainer'
      alignmentHorizontal='center'
      width={'100%'}
      backgroundColor='secondary'
      spacingInset='xlarge'
    >
      <Loader />
    </Row>
  );
};
