const blueScale = {
  // Blue scale
  blue025: '#F4F9FB',
  blue050: '#DDEFF3',
  blue100: '#A8DFEE',
  blue200: '#68C1DE',
  blue300: '#1099CB',
  blue400: '#0075A3',
  blue500: '#005276',
  blue600: '#1C374A',
  blue700: '#232F3E',
  blue800: '#141C2A'
};

const waveScale = {
  // Green wave scale (Brand)
  wave025: '#F4FBF8',
  wave050: '#DDF3EB',
  wave100: '#A6E7CE',
  wave200: '#68DEBF',
  wave300: '#10CBA9',
  wave400: '#00A38D',
  wave500: '#008577',
  wave600: '#005F61',
  wave700: '#00464F',
  wave800: '#05282E'
};

const grayScale = {
  // Gray scale
  gray0: '#FFFFFF',
  gray025: '#F7F7F8',
  gray050: '#EFF0F1',
  gray100: '#D0D3D7',
  gray200: '#B5BAC0',
  gray300: '#99A0A8',
  gray400: '#7C8692',
  gray500: '#626C7A',
  gray600: '#424E5C',
  gray700: '#232F3E',
  gray800: '#181D26'
};

const redScale = {
  red100: '#FFF3F0',
  red200: '#FFBDAD',
  red250: '#F3B9BA',
  red300: '#FF5630',
  red400: '#DE350B',
  red500: '#BF2600'
};

const tangerineScale = {
  tangerine100: '#FFE5CB',
  tangerine200: '#FFCB96',
  tangerine300: '#FFAE5D',
  tangerine400: '#FF8F00',
  tangerine500: '#D4780C',
  tangerine600: '#A45C0A'
};

const colorPalette = {
  ...blueScale,
  ...waveScale,
  ...grayScale,
  ...redScale,
  ...tangerineScale
};

export default colorPalette;

// import colorPalette from './color-palette.scss';
// export default colorPalette
