import React, {FunctionComponent, useCallback, useState, PropsWithChildren} from 'react';
import Card from '@amzn/meridian/card';
import Text from '@amzn/meridian/text';
import {AssetDataModel, AssetUploader, InvalidFile} from '../../asset-uploader/AssetUploader';
import {LABELS} from './Constants';
import {
  AssetUploadI18nStrings,
  WithI18nStringsProps
} from '../../../../context/course/models/I18n';


export interface CaptionsAndTextTranscriptUploadProps extends WithI18nStringsProps<AssetUploadI18nStrings> {
  supportedFileTypes: Record<string, string[]>;
  assetType: string;
  updateAssetData: (asset: AssetDataModel) => void;
  uploadPlaceholder: string;
  onRejection: (invalidFiles: InvalidFile[]) => void;
}

export const CaptionsAndTextTranscriptUpload: FunctionComponent<PropsWithChildren<CaptionsAndTextTranscriptUploadProps>> = (
  {
    supportedFileTypes,
    assetType,
    updateAssetData,
    uploadPlaceholder,
    onRejection,
    i18nStrings,
    children
  }: PropsWithChildren<CaptionsAndTextTranscriptUploadProps>) => {

  const [fileUploadPlaceholder, setFileUploadPlaceholder] = useState<string>(uploadPlaceholder);

  const onUploadSuccess = (assetData: {assetId: string, fileName: string}) => {
    setFileUploadPlaceholder(LABELS.assetUploadSuccessMessagePrefix + assetData.fileName);
  };

  const onUploadFailure = useCallback((invalidFiles: InvalidFile[]) => {
    setFileUploadPlaceholder(i18nStrings.fileUploadErrorWithFileName(invalidFiles[0].file.name));
  }, [i18nStrings]);

  return (
    <AssetUploader
      supportedFileTypes={supportedFileTypes}
      assetType={assetType}
      updateAssetData={updateAssetData}
      onUploadFailure={onUploadFailure}
      onUploadSuccess={onUploadSuccess}
      onRejection={onRejection}
      i18nStrings={i18nStrings}
    >
      <Card spacingInset={'400'}>
        <Text type='b300' alignment='center'>{fileUploadPlaceholder}</Text>
        {children}
      </Card>
    </AssetUploader>
  );
};
