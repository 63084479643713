import React, {forwardRef, FunctionComponent, useCallback, useState} from 'react';
import {Delta} from 'quill';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import {GadgetEditorProps} from '../models';
import {GadgetConfig} from '../../../../context/course';
import GadgetContainer from '../../../../components/containers/gadget/GadgetContainer';
import {useCourseAuthoringContext} from '../../../context';
import {CustomQuill} from '../../quill/CustomQuill';
import {InvalidFileUploadAlert} from '../../alerts/invalid-file-upload-alert/InvalidFileUploadAlert';
import {ReadingGadgetI18nStrings} from '../../../../context/course/models/I18n';
import {InvalidFile} from '../../asset-uploader/AssetUploader';

import './styles/reading-gadget.scss';

export interface ReadingGadgetConfig extends GadgetConfig {
  body: Delta
}

export type ReadingGadgetProps = GadgetEditorProps<ReadingGadgetConfig, ReadingGadgetI18nStrings>;

/**
 * You can use this gadget to add reading sections to your course.
 * The content will be generated by a WYSIWYG text editor, which allows the author to add basic formatting to the text, as well as media assets (only images supported).
 * The body of the gadget is a Quill Delta object: https://quilljs.com/docs/delta/
 */
export const ReadingGadget: FunctionComponent<ReadingGadgetProps> = forwardRef<HTMLElement | undefined, ReadingGadgetProps>((
  {
    id: gadgetId,
    config: {title: initialTitle, body}
  }: ReadingGadgetProps,
  ref
) => {
  const {updateGadget} = useCourseAuthoringContext();
  const [title, setTitle] = useState<string | undefined>(initialTitle);
  const [invalidFiles, setInvalidFiles] = useState<InvalidFile[]>([]);

  const handleTitleChange = useCallback((newTitle: string) => {
    updateGadget(gadgetId, {
      title: newTitle,
      body
    } as ReadingGadgetConfig);
    setTitle(newTitle);
  }, [gadgetId, body, setTitle]);

  const handleRTEChange = useCallback((delta: Delta) => {
    updateGadget(gadgetId, {
      title,
      body: delta
    } as ReadingGadgetConfig);
  }, [gadgetId, title, updateGadget]);

  const onFileRejection = useCallback((invalidFiles: InvalidFile[]) => {
    setInvalidFiles(invalidFiles);
  }, []);

  const onAlertClose = useCallback(() => {
    setInvalidFiles([]);
  }, []);

  return (
    <GadgetContainer id={gadgetId} ref={ref}>
      <Column alignmentHorizontal='center' spacing={'450'} className={'reading-gadget__container'}>
        <InvalidFileUploadAlert
          invalidFiles={invalidFiles}
          onAlertClose={onAlertClose}
        />
        <Box width={'100%'}>
          <Input
            onChange={handleTitleChange}
            placeholder={'Reading title'}
            value={title}
            constraintText={'Title is optional but recommended'}
            size={'xlarge'}
          />
        </Box>
        <Box width={'100%'}>
          <CustomQuill
            defaultValue={body}
            onChange={handleRTEChange}
            onFileRejection={onFileRejection}
          />
        </Box>
      </Column>
    </GadgetContainer>
  );
});
ReadingGadget.displayName = 'ReadingGadget';
