/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import shaka from 'shaka-player/dist/shaka-player.ui';

/**
 * Extending Shaka UI OverflowMenu for adding new functionality
 */
export class SettingsButton extends shaka.ui.OverflowMenu {

  static BUTTON_NAME = 'soju_settings';
  static settingsTooltipMessage = 'Settings';

  static setSettingsButtonTooltipMessage(message: string) { this.settingsTooltipMessage = message; }

  button: HTMLButtonElement;

  constructor(parent: HTMLElement, controls: shaka.ui.Controls) {
    super(parent, controls);

    this.button = <HTMLButtonElement>parent.lastChild;
    this.button.ariaLabel = SettingsButton.settingsTooltipMessage;
  }
}


export class SettingsButtonFactory {
  create(rootElement: HTMLElement, controls: shaka.ui.Controls) {
    return new SettingsButton(rootElement, controls);
  }
}

shaka.ui.Controls.registerElement(SettingsButton.BUTTON_NAME, new SettingsButtonFactory());
