/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import shaka from 'shaka-player/dist/shaka-player.ui';
import {detect} from 'detect-browser';
import {isSafari} from '../VideoGadget';

/**
 * Custom Shaka UI button for captions
 */
export class CaptionsButton extends shaka.ui.Element {

  static BUTTON_NAME = 'soju_captions';
  static captionsAvailableTooltipMessage = 'Subtitles/closed captions (c)';
  static captionsUnavailableTooltipMessage = 'Subtitles/closed captions unavailable';

  static setCaptionsTooltipMessages(captionsAvailable: string, captionsUnavailable: string) {
    this.captionsAvailableTooltipMessage = captionsAvailable;
    this.captionsUnavailableTooltipMessage = captionsUnavailable;
  }

  button: HTMLButtonElement;
  browser: { name: string } | null;
  hasUserEnabledSubtitle = false;

  constructor(parent: HTMLElement, controls: shaka.ui.Controls) {
    super(parent, controls);

    this.browser = detect();
    this.button = document.createElement('button');
    this.button.classList.add('shaka-controls-soju-captions', 'shaka-tooltip');
    this.button.ariaLabel = CaptionsButton.captionsUnavailableTooltipMessage;
    parent.appendChild(this.button);

    this.updateButtonState();

    // @ts-ignore
    this.eventManager.listen(controls.getVideoContainer(), 'keydown', e => {
      if (e.key == 'c' || e.key == 'C') {
        if (!this.hasUserEnabledSubtitle) {
          this.hasUserEnabledSubtitle = true;
        }
        this.toggleCaptions();
      }
    });

    // @ts-ignore
    this.eventManager.listen(this.button, 'click', async () => {

      if (!this.hasUserEnabledSubtitle) {
        this.hasUserEnabledSubtitle = true;
      }

      this.toggleCaptions();
    });

    // @ts-ignore
    this.eventManager.listen(this.player, 'trackschanged', () => {
      this.updateButtonState();
    });

    // @ts-ignore
    this.eventManager.listen(this.player, 'texttrackvisibility', () => {
      this.updateButtonState();
    });
  }

  async toggleCaptions() {
    if (isSafari()) {
      // @ts-ignore
      const tracks = this.controls?.getLocalVideo()?.textTracks;
      if (tracks && tracks[0]?.mode) {
        tracks[0].mode = tracks[0]?.mode === 'hidden' ? 'showing' : 'hidden';
      }
    } else {
      // @ts-ignore
      await this.player?.setTextTrackVisibility(!this.player?.isTextTrackVisible());
      this.updateButtonState();
    }
  }

  isSubtitleAvailable(tracks: TextTrackList | undefined) {
    // @ts-ignore
    if(isSafari() && tracks && tracks[0] && tracks[0]?.cues.length > 0 ||
      // @ts-ignore
      !isSafari() && this.player?.getTextTracks().length > 0) {
      return true;
    }
    return false;
  }

  isSubtitleEnabled(tracks: TextTrackList | undefined) {
    // @ts-ignore
    if(isSafari() && tracks && tracks[0]?.mode === 'showing' ||
      // @ts-ignore
      !isSafari() && this.player?.isTextTrackVisible()){
      return true;
    }
    return false;
  }

  disableSubtitle(tracks: TextTrackList | undefined) {
    if (!this.hasUserEnabledSubtitle && tracks && tracks[0]?.mode === 'showing') {
      tracks[0].mode = 'hidden';
    }
  }

  updateButtonState() {
    // @ts-ignore
    const tracks = this.controls?.getLocalVideo()?.textTracks;

    // Disable the subtitle if user not enabled the subtitle before.
    if(isSafari()) {
      this.disableSubtitle(tracks);
    }

    // check whether subtitle is available or not.
    if (this.isSubtitleAvailable(tracks)) {
      this.button.removeAttribute('disabled');
      this.button.ariaLabel = CaptionsButton.captionsAvailableTooltipMessage;

      // check whether subtitle is enabled or not.
      if (this.isSubtitleEnabled(tracks) && this.hasUserEnabledSubtitle) {
        this.button.classList.remove('captions-not-available', 'captions-disabled');
        this.button.classList.add('captions-enabled');
      } else {
        this.button.classList.remove('captions-enabled', 'captions-not-available');
        this.button.classList.add('captions-disabled');
      }
    } else {
      this.button.setAttribute('disabled', 'true');
      this.button.classList.remove('captions-enabled', 'captions-disabled',);
      this.button.classList.add('captions-not-available');
      this.button.ariaLabel = CaptionsButton.captionsUnavailableTooltipMessage;
    }
  }
}


export class CaptionsButtonFactory {
  create(rootElement: HTMLElement, controls: shaka.ui.Controls) {
    return new CaptionsButton(rootElement, controls);
  }
}

shaka.ui.Controls.registerElement(CaptionsButton.BUTTON_NAME, new CaptionsButtonFactory());
