import React, {FunctionComponent, useState} from 'react';
import {defaultImageI18nStrings, ImageI18nStrings, WithI18nStringsProps} from '../../context/course/models/I18n';
import {ImageAttributes, ImageLightbox} from '../image/ImageLightbox';
import CustomImage from '../gadgets/reading/CustomImage';

export type QuillImageLightboxProps = WithI18nStringsProps<ImageI18nStrings>;

export const QuillImageLightbox: FunctionComponent<QuillImageLightboxProps> = ({
  i18nStrings = defaultImageI18nStrings
}) => {
  const [image, setImage] = useState<ImageAttributes>();
  const [open, setOpen] = useState(false);
  CustomImage.onClick = image => {
    setImage(image);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setImage(undefined);
  };

  return <ImageLightbox
    open={open}
    image={image}
    onClose={onClose}
    i18nStrings={i18nStrings}
  />;
};
