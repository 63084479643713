import React, {FunctionComponent, RefObject} from 'react';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Responsive from '@amzn/meridian/responsive';
import Theme from '@amzn/meridian/theme';
import blueLightTokens from '@amzn/meridian-tokens/theme/blue-light';
import brandedLight from '../../../theme/branded-light';
import cx from 'classnames';
import {chain} from 'lodash';
import {GadgetRenderer} from '../../../gadgets/registry';
import {Gadget} from '../../../context/course';
import {isHeaderGadget} from '../../../utils/CourseUtils';
import './styles/lesson-canvas.scss';
import Column from '@amzn/meridian/column';

import responsiveStyles from '../../../styles/responsive.module.scss';

export const LessonCanvasTitleId = 'lesson-canvas-title-id';

/**
 * Lesson Canvas props
 */
export interface LessonCanvasProps {
  /**
   * a list of gadgets to render in the canvas
   */
  gadgets: Gadget[];

  /**
   * Registry of gadgets.
   */
  gadgetsRegistry: GadgetRenderer;

  /**
   * map of gadget ids to ref object of the gadget's container
   */
  gadgetRefsByGadgetId: Record<string, RefObject<HTMLElement>>;

  /**
   * title to display at the top of the lesson canvas.
   */
  title?: string;
}

/**
 * Renders a lesson
 */
export const LessonCanvas: FunctionComponent<LessonCanvasProps> = ({
  gadgets,
  gadgetsRegistry,
  gadgetRefsByGadgetId,
  title
}: LessonCanvasProps) => {

  return (
    <Theme tokens={{
      ...blueLightTokens,
      ...brandedLight
    }}>
      <Responsive
        query='max-width'
        props={{
          containerSpacingInsert: {
            default: '0 0 600 0',
            [responsiveStyles.tablet_breakpoint]: '600 500 600 500'
          }
        }}
      >
        {props =>
          <Column className='lessonCanvas-container' width='100%' spacingInset={props.containerSpacingInsert}
            alignmentHorizontal='center'>
            {title && <Row className="lessonTitle" width='100%' maxWidth='673px'>
              <Heading
                id={LessonCanvasTitleId}
                tabIndex='-1'
                level={1}
                type='h600'
                className='lessonTitle__header'
              >
                {title}
              </Heading>
            </Row>}
            {chain(gadgets)
              .map((gadget: Gadget) => {
                const {
                  component: GadgetComponent,
                  i18nStrings,
                } = gadgetsRegistry[gadget.type];

                const {
                  config,
                  learnerState
                } = gadget;

                const gadgetIsHeader = isHeaderGadget(gadget.type);
                const containerClass = cx('gadget', gadgetIsHeader && 'header-gadget-container');

                return <div key={gadget.id} className={containerClass}>
                  <GadgetComponent
                    id={gadget.id}
                    type={gadget.type}
                    learnerState={learnerState}
                    config={config}
                    ref={gadgetRefsByGadgetId[gadget.id]}
                    i18nStrings={i18nStrings}
                  />
                </div>;
              })
              .value()}
          </Column>
        }
      </Responsive>
    </Theme>
  );
};
