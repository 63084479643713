import {Quill} from 'react-quill';
import {AuthoringGadgetRenderingConfig} from './AuthoringGadgetRenderingConfig';
import {DEFAULT_GADGETS_REGISTRY} from '../../../../gadgets/registry';
import {AuthoringGadgetRenderer} from './AuthoringGadgetRenderer';
import SectionGadget from '../section';
import ResponseGadget from '../response';
import {ResponseGadgetConfig} from '../../../../components/gadgets/response';
import ReadingGadget, {ReadingGadgetConfig} from '../reading';
import QuizGadget, {QuizGadgetAuthorConfig} from '../quiz';
import VideoGadget from '../video';
import SlideshowGadget, {SlideshowGadgetConfig} from '../slideshow';
import {shortId} from '../../../../gadgets/utils/shortId';
import MarkdownGadget, {MarkdownGadgetConfig} from '../markdown';
import LabsGadget, {LabsGadgetConfig} from '../labs';
import {mergeI18nStringsWithConfig} from '../../../../utils/CourseUtils';
import {defaultI18nStrings} from '../../../../context/course/models/I18n';
const Delta = Quill.import('delta');

/**
 * Default registry of supported Gadgets
 */
export const DEFAULT_AUTHORING_GADGETS_REGISTRY = {
  'amzn/quiz': {
    ...DEFAULT_GADGETS_REGISTRY['amzn/quiz'],
    editorComponent: QuizGadget,
    defaultConfig: {
      title:'New Quiz Gadget',
      instructions:'',
      questions:[{
        id: shortId(),
        title: 'Half past eight is',
        type: 'Single',
        choices:[{
          id: shortId(),
          value: '8:15',
          isCorrect: false
        },{
          id: shortId(),
          value: '19:30',
          isCorrect:false
        },{
          id: shortId(),
          value: '8:30',
          isCorrect:true
        },{
          id: shortId(),
          value: '22:45',
          isCorrect: false
        }]
      }]
    } as QuizGadgetAuthorConfig
  } as AuthoringGadgetRenderingConfig,
  'amzn/rte': {
    ...DEFAULT_GADGETS_REGISTRY['amzn/rte'],
    editorComponent: ReadingGadget,
    defaultConfig: {
      title: 'New Reading Gadget',
      body: new Delta()
    } as ReadingGadgetConfig
  } as AuthoringGadgetRenderingConfig,
  'amzn/video': {
    ...DEFAULT_GADGETS_REGISTRY['amzn/video'],
    editorComponent: VideoGadget,
    defaultConfig: {
      title: 'New Video Gadget'
    }
  } as AuthoringGadgetRenderingConfig,
  'amzn/header': {
    ...DEFAULT_GADGETS_REGISTRY['amzn/header'],
    editorComponent: SectionGadget,
    defaultConfig: {
      title: 'New Header Gadget'
    }
  } as AuthoringGadgetRenderingConfig,
  'amzn/slideshow': {
    ...DEFAULT_GADGETS_REGISTRY['amzn/slideshow'],
    editorComponent: SlideshowGadget,
    defaultConfig: {
      title: 'New Slideshow Gadget',
      slides: [{
        id: shortId(),
        content: new Delta()
      }]
    }as SlideshowGadgetConfig
  } as AuthoringGadgetRenderingConfig,
  'amzn/response': {
    ...DEFAULT_GADGETS_REGISTRY['amzn/response'],
    editorComponent: ResponseGadget,
    defaultConfig: {
      title: 'New Response Gadget',
      challenges: [{
        id: shortId(),
        prompt: '',
        answers: [{
          id: shortId(),
          answer: ''
        }]
      }]
    } as ResponseGadgetConfig
  } as AuthoringGadgetRenderingConfig,
  'amzn/markdown': {
    ...DEFAULT_GADGETS_REGISTRY['amzn/markdown'],
    iconUrl: 'https://m.media-amazon.com/images/G/01/courses/soju-player/gadget-icons/Markdown-icon.png',
    editorComponent: MarkdownGadget,
    defaultConfig: {
      title: '',
      body: {
        source: ''
      }
    } as MarkdownGadgetConfig
  } as AuthoringGadgetRenderingConfig
} as unknown as AuthoringGadgetRenderer;

export const DEFAULT_AUTHORING_GADGETS_REGISTRY_WITH_I18N = mergeI18nStringsWithConfig(DEFAULT_AUTHORING_GADGETS_REGISTRY, defaultI18nStrings.gadgets);
