import {GadgetLearnerStateConverter} from './GadgetLearnerStateConverter';
import {
  AssessmentEvaluationStatus,
  QuizEvaluationResults,
  QuizGadgetLearnerState
} from '../../../../components/gadgets/quiz';
import {Evaluation} from '../../models/Evaluation';
import {GadgetType} from '../models/GadgetType';

/**
 * Quiz State object used in SCARS
 */
interface QuizState {
  updatedAt?: Date | null;

  questions?: { id: string, state: string }[] | null;

  answerKey?: Record<string, string[] | number[]> | null;

  answers?: Record<string, string[] | number[]> | null;

  results?: { dateEvaluated: string, correct: number, incorrect: number, pass: boolean } | null;

  backendState?: string | null;
}

export class QuizGadgetLearnerStateConverter implements GadgetLearnerStateConverter<QuizGadgetLearnerState> {
  getGadgetType(): string {
    return GadgetType.QUIZ;
  }

  fromGadgetLearnerState(learnerState: QuizGadgetLearnerState): QuizState {
    return {
      updatedAt: learnerState.updatedAt,
      answers: learnerState.choicesByQuestionId,
    } as QuizState;
  }

  toGadgetLearnerState(state: QuizState): QuizGadgetLearnerState {
    const evaluationResults = state.results
      ? {
        status: state.results.pass ? AssessmentEvaluationStatus.Pass : AssessmentEvaluationStatus.Fail,
        correctQuestionsCount: state.results.correct,
        incorrectQuestionsCount: state.results.incorrect,
        correctChoicesByQuestionId: state.answerKey,
        evaluationsByQuestionId: state.questions!
          // eslint-disable-next-state
          .reduce((evaluations: Record<string, Evaluation>, question: any) => {
            return {
              ...evaluations,
              [question.id]: question.state === 'correct'
                ? Evaluation.Correct
                : Evaluation.Incorrect
            };
          }, {} as Record<string, Evaluation>),
      } as QuizEvaluationResults : undefined;

    return {
      updatedAt: state.updatedAt,
      choicesByQuestionId: state.answers,
      evaluationResults
    } as QuizGadgetLearnerState;
  }
}
