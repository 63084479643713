import {Gadget, Lesson} from '../context/course';
import {last, reduce} from 'lodash';
import {LessonPartition, PartitionedLesson} from '../context/course/models/Lesson';
import {LessonViewMode} from '../context/course/models/LessonViewMode';
import {isHeaderGadget} from './CourseUtils';

export const lessonHasSections = (gadgets: Gadget[]) => {
  return gadgets.some(gadget => isHeaderGadget(gadget.type));
};
/**
 * separates a list of Gadgets into sections.
 *
 * A section is defined as "every gadget until the next Section gadget (exclusive)"
 * @param gadgets
 */
export const partitionGadgetsBySection = (gadgets: Gadget[]) => {
  return reduce(
    gadgets,
    (prevSections, currentGadget) => {
      const prevSection = last(prevSections) as LessonPartition;
      // encountered a header gadget, start a new section
      if (isHeaderGadget(currentGadget.type)) {
        const newSection = {
          title: currentGadget.config.title,
          gadgets: [currentGadget]
        };
        prevSections = [...prevSections, newSection];
      } else {
        if (!prevSection) {
          // first gadget in the list is not a header, start a new section
          prevSections = [{
            gadgets: [currentGadget]
          }];
        } else {
          // add current gadget to the last section in the list
          prevSections[prevSections.length - 1].gadgets = [...prevSection.gadgets, currentGadget];
        }
      }
      return prevSections;
    },
    [] as LessonPartition[]
  );
};
/**
 * separates a list of Gadgets into Gadget pages.
 *
 * A Gadget page is defined as "a single gadget, or a Section gadget plus the next immediate gadget"
 * @param gadgets
 */
export const partitionGadgetsByGadget = (gadgets: Gadget[]) => {
  let currentSectionTitle: string | undefined;
  return reduce(
    gadgets,
    (prevSections, currentGadget) => {
      const prevSection = last(prevSections) as LessonPartition;
      const lastGadget = last(prevSection?.gadgets) as Gadget;
      if (lastGadget && isHeaderGadget(lastGadget.type)) {
        currentSectionTitle = lastGadget.config.title;
        prevSection.title = currentSectionTitle;
        prevSection.gadgets.push(currentGadget);
      } else {
        const newSection = {
          title: currentSectionTitle,
          gadgets: [currentGadget]
        };
        prevSections = [...prevSections, newSection];
      }
      return prevSections;
    },
    [] as LessonPartition[]);
};
/**
 * separates a list of Gadgets based on a given LessonViewMode.
 *
 * @param gadgets
 * @param viewMode
 */
export const partitionGadgetsByViewMode = (gadgets: Gadget[], viewMode: LessonViewMode): LessonPartition[] => {
  return _partitionGadgetsByViewMode(
    gadgets,
    viewMode,
    partitionGadgetsBySection,
    partitionGadgetsByGadget
  );
};
/**
 * constructs a mapping of lessonId to partitioned lesson, based on a given list of lessons and the desired view mode
 * @param lessons
 * @param viewMode
 */
export const partitionCourseLessonsByViewMode = (lessons: Lesson[], viewMode: LessonViewMode): Record<string, PartitionedLesson> => {
  return reduce(
    lessons,
    (acc, lesson) => {
      acc[lesson.id] = {
        ...lesson,
        partitions: partitionGadgetsByViewMode(lesson.gadgets, viewMode)
      };
      return acc;
    },
    {} as Record<string, PartitionedLesson>
  );
};
/**
 * given a list of partitions and a gadget id, returns the index of the partition that contains the gadget
 * with the given id.
 *
 * @param gadgetId
 * @param partitions
 */
export const getPartitionIndexByGadgetId = (
  gadgetId: string,
  partitions: LessonPartition[]
): number =>
  partitions.findIndex(partition => {
    return partition.gadgets.some(gadget => gadget.id === gadgetId);
  });

/**
 * testable version of partitionGadgetsByViewMode, to get around ES module weirdness. DO NOT USE.
 */
export const _partitionGadgetsByViewMode = (
  gadgets: Gadget[],
  viewMode: LessonViewMode,
  partitionBySection: (gadgets: Gadget[]) => LessonPartition[],
  partitionByGadget: (gadgets: Gadget[]) => LessonPartition[]
): LessonPartition[] => {
  if (viewMode === LessonViewMode.Section) {
    return partitionBySection(gadgets);
  }
  if (viewMode === LessonViewMode.Gadget) {
    return partitionByGadget(gadgets);
  }
  return [{
    gadgets
  }];
};
