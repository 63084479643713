import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {ReactQuillProps} from 'react-quill/lib';
import ReactQuill, {Quill} from 'react-quill';
import katex from 'katex';
import {QuillExternalLinkWarning} from './QuillExternalLinkWarning';
import CustomImage from '../gadgets/reading/CustomImage';
import LinkWithWarning from '../link-warning/LinkWithWarning';
import {readonlyQuillProps} from '../../gadgets/utils/quill';
import {useCourseContext} from '../../context/course';
import {QuillImageLightbox} from './QuillImageLightbox';
import {
  defaultQuillI18nStrings,
  QuillI18nStrings,
  WithI18nStringsProps
} from '../../context/course/models/I18n';
import {getAssetUrl} from './util';

import 'katex/dist/katex.min.css';

Quill.register({
  'formats/image': CustomImage,
  'formats/link': LinkWithWarning
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.katex = katex;

export interface CustomReactQuillProps extends ReactQuillProps, WithI18nStringsProps<QuillI18nStrings | undefined> {}

export const CustomReactQuill: FunctionComponent<CustomReactQuillProps> = ({
  i18nStrings = defaultQuillI18nStrings,
  ...otherProps
}) => {
  const {getAssetModel} = useCourseContext();
  CustomImage.getAssetModel = getAssetModel;
  CustomImage.ariaDescription = i18nStrings.imageLightbox.clickableImageLinkLabel;
  const [modifiedValue, setModifiedValue] = useState(otherProps.value);

  useEffect(() => {
    (async () => {
      setModifiedValue(await getAssetUrl(otherProps.value, getAssetModel));
    })();
  }, [getAssetModel, otherProps.value]);

  const modifiedProps = {
    ...otherProps,
    value: modifiedValue
  };

  return <>
    <ReactQuill {...readonlyQuillProps} {...modifiedProps} />
    <QuillExternalLinkWarning i18nStrings={i18nStrings.linkWarning} />
    <QuillImageLightbox i18nStrings={i18nStrings.imageLightbox} />
  </>;
};
