/**
 * Possible assessment evaluation statuses
 */
export enum AssessmentEvaluationStatus {
  /**
   * Something went wrong
   */
  Error = 'Error',

  /**
   * Passed assessment
   */
  Pass = 'Pass',

  /**
   * Failed assessment
   */
  Fail = 'Fail'
}
