import {LearnerActivityType} from './LearnerActivityType';

export abstract class LearnerActivity {
  public readonly type: string;

  // Local time in milliseconds
  public readonly timestamp: number;

  public readonly verb: string;
  public readonly object: string;

  protected constructor(type: LearnerActivityType,
    verb: string,
    object: string,
    timestamp: number = Date.now()) {
    this.type = type;
    this.verb = verb;
    this.object = object;
    this.timestamp = timestamp;
  }
}
