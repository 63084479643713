import React, {FunctionComponent, ReactNode, useState} from 'react';

import {ASSET_ID_REGEX} from './Constants';
import {MarkdownGadgetI18nStrings, WithI18nStringsProps} from '../../../context/course/models/I18n';
import DownloadLink from './DownloadLink';
import ExternalLink from './ExternalLink';

export interface CustomLinkComponentProps extends WithI18nStringsProps<MarkdownGadgetI18nStrings> {
  href?: string,
  children: ReactNode | string
}

const CustomLinkComponent: FunctionComponent<CustomLinkComponentProps> = (
  {
    href,
    children,
    i18nStrings
  }
) => {
  const [isDownloadLink] = useState(href && ASSET_ID_REGEX.test(href));

  if (isDownloadLink) {
    return <DownloadLink href={href!} i18nStrings={i18nStrings}>
      {children}
    </DownloadLink>;
  } else {
    return <ExternalLink href={href} i18nStrings={i18nStrings}>
      {children}
    </ExternalLink>;
  }
};

export default CustomLinkComponent;
