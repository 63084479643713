import React, {FunctionComponent, PropsWithChildren, useContext} from 'react';
import {AssetModel, GadgetConfig, CloudLabInfo} from '../../context/course';
import {Gadget} from '../../context/course';
import {CreateAssetRequest} from './models/CreateAssetRequest';

/**
 * Course authoring context
 */
export interface CourseAuthoringContextProps {

  /**
   * Adds a new default lesson to the course
   */
  addLesson: () => void;

  /**
   * Updates the title of a content element in the course
   * @param lessonId Id of the content to update
   * @param title content title
   */
  updateContentTitle: (contentId: string, title: string) => void;

  /**
   * Removes a lesson from the course
   * @param lessonId Id of the lesson to remove
   */
  removeLesson: (lessonId: string, lessonIndex: number) => void;

  /**
   * Adds a gadget to a lesson
   * @param lessonId Id of the lesson where the gadget should be added to
   * @param gadgets list of gadgets to add
   * @param index zero based index in the lessons gadgets list where the gadgets should be inserted
   */
  addLessonGadgets: (lessonId: string, gadgets: Pick<Gadget, 'type' | 'config'>[], index?: number) => Promise<void>;

  /**
   * Updates the gadget's config
   * @param gadgetId gadget id
   * @param gadgetConfig gadget config
   */
  updateGadget: <T extends GadgetConfig>(gadgetId: string, gadgetConfig: T) => void;

  /**
   * Removes a gadget from a course lesson
   * @param lessonId Id of the lesson that contains the gadget
   * @param gadgetId Id of the gadget to remove
   */
  removeGadget: (lessonId: string, gadgetIds: string[]) => void;

  /**
   * Updates the gadget position within a lesson
   * @param lessonId Id of the lesson that contains the gadget
   * @param gadgetId Id of the gadget to move
   * @param newIndex new gadget index within the lesson
   */
  updateGadgetIndex: (lessonId: string, gadgetId: string, newIndex: number) => void;

  /**
   * Enters the full screen edit mode for the given gadget
   * @param gadgetId Id of the gadget to enter edit mode
   */
  enterGadgetFullEditMode: (gadgetId: string) => void;

  /**
   * Exits the full screen edit mode for gadgets
   */
  exitGadgetFullEditMode: () => void;

  /**
   * Updates the lesson position within a course
   * @param lessonId Id of the lesson to move
   * @param newIndex new lesson index within the course
   */
  updateLessonIndex: (lessonId: string, newIndex: number) => void;

  /**
   * Function that redirects user to preview draft
   * If lessonIndex is provided, the function will send to that lesson within the course
   */
  previewDraftCourse: (lessonIndex?: number) => void;

  /**
   * Create a new asset.
   * @param createAssetRequest create asset request
   */
  createAsset: (createAssetRequest: CreateAssetRequest) => Promise<AssetModel>;

  /**
   * Upload asset to s3.
   * @param s3Location asset location in S3
   * @param file asset content
   */
  uploadFileAssetToS3: (s3Location: string, file: File) => Promise<void>;

  /**
   * Trigger asset processing
   * @param assetId asset ID
   */
  startAssetProcessing: (assetId: string) => Promise<void>;

  /**
   * Check build status for cloud labs
   * @param gadgetId gadget ID
   */
   getCloudLabInfo: (gadgetId: string) => Promise<CloudLabInfo>;

  /**
   * Create new course revision
   * @param courseDraftId
   */
  createCourseRevision: (courseDraftId: string) => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// we don't provide a value, because we want the context to be created using the custom function
const CourseAuthoringContext = React.createContext<CourseAuthoringContextProps>({});

/**
 * Course Authoring Context Provider to be consumed by the components
 */
export const CourseAuthoringContextProvider: FunctionComponent<CourseAuthoringContextProps> = ({
  children,
  ...contextValue
}: PropsWithChildren<CourseAuthoringContextProps>) => {

  return (
    <CourseAuthoringContext.Provider value={contextValue}>
      {children}
    </CourseAuthoringContext.Provider>
  );
};

/**
 * React hook to use the course authoring context
 */
export const useCourseAuthoringContext = (): CourseAuthoringContextProps => {
  return useContext(CourseAuthoringContext);
};
