import React, {forwardRef, useState} from 'react';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Heading from '@amzn/meridian/heading';
import Icon from '@amzn/meridian/icon';
import arrowLeftLarge from '@amzn/meridian-tokens/base/icon/arrow-left-large';
import {GadgetProps} from '../models';
import {GadgetConfig, GadgetLearnerState, GadgetType, useCourseContext} from '../../../context/course';
import {LabChallenge} from './models/LabChallenge';
import {CloudConfig} from './models/CloudConfig';
import GadgetContainer from '../../containers/gadget/GadgetContainer';
import {LabPreview} from './LabPreview';
import {defaultI18nStrings, LabGadgetI18nStrings} from '../../../context/course/models/I18n';
import {LabPlayer} from './LabPlayer';

import './styles/LabGadget.scss';

const Origin = 'https://play.instruqt.com';
const getEmbedUrl = (teamName: string, trackSlug: string, token: string) =>
  `${Origin}/embed/${teamName}/tracks/${trackSlug}?token=${token}`;

export interface LabGadgetConfig extends GadgetConfig {
  teaser: string;
  isSubmit: boolean;
  challenges: LabChallenge[];
  cloudConfig: CloudConfig,
  settings: {
    isRequiredForCertificate: boolean;
  }
}

export interface LabGadgetLearnerState extends GadgetLearnerState {
  attempts?: number,
  completed?: boolean
}

/**
 * Lab gadget props
 */
export type LabGadgetProps = GadgetProps<LabGadgetConfig, LabGadgetLearnerState, LabGadgetI18nStrings>

export const LabGadget = forwardRef<HTMLElement | undefined, LabGadgetProps>((
  {
    id,
    config,
    learnerState,
    i18nStrings = defaultI18nStrings.gadgets[GadgetType.LAB]!
  }: LabGadgetProps,
  ref
) => {
  const {
    attempts = 0,
    completed = false
  } = learnerState || {} as LabGadgetLearnerState;

  const {getCloudLabEmbedInfo, setGadgetLearnerState} = useCourseContext();

  const [isTokenLoading, setIsTokenLoading] = useState(false);
  const [embedUrl, setEmbedUrl] = useState<string | null>();
  const onOpenActivity = async () => {
    if (!isTokenLoading) {
      setIsTokenLoading(true);
      const runToken = await getCloudLabEmbedInfo(id);
      if (runToken) {
        const {teamName, trackSlug, token} = runToken;
        setEmbedUrl(getEmbedUrl(teamName, trackSlug, token));
      }
      setIsTokenLoading(false);
    }
  };

  const onExit = () => {
    setEmbedUrl(null);
  };

  return !embedUrl ?
    <GadgetContainer id={id} ref={ref}>
      <LabPreview
        id={id}
        config={config}
        onOpenActivity={onOpenActivity}
        completed={completed}
        i18nStrings={i18nStrings}
      />
    </GadgetContainer> :
    <Box ref={ref} className="LabGadget__player" type='outline' backgroundColor='primary'>
      <Row alignmentHorizontal='end' spacingInset='450' widths={['fill', 'fit']}>
        <Heading level={3}>{config.title}</Heading>
        <Button type='link' onClick={onExit}><Icon tokens={arrowLeftLarge} />{i18nStrings.exit}</Button>
      </Row>
      <LabPlayer
        embedUrl={embedUrl}
        origin={Origin}
        onTrackStarted={() => {
          console.info('track started');
          setGadgetLearnerState<LabGadgetLearnerState>(id, GadgetType.LAB, {
            updatedAt: new Date(),
            completed,
            attempts: attempts + 1
          }, true);
        }}
        onTrackCompleted={() => {
          console.info('track completed');
          setGadgetLearnerState<LabGadgetLearnerState>(id, GadgetType.LAB, {
            updatedAt: new Date(),
            completed: true,
            attempts
          });
        }}
        onChallengeStarted={() => {
          console.info('challenge started');
        }}
        onChallengeSkipped={() => {
          console.info('challenge skipped');
        }}
        onChallengeCompleted={() => {
          console.info('challenge completed');
        }}
      />
    </Box>
  ;
});

LabGadget.displayName = 'LabGadget';
