import React, {FunctionComponent, useCallback} from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkUnwrapImages from 'remark-unwrap-images';
import rehypeSanitize, {defaultSchema} from 'rehype-sanitize';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {a11yLight} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {ImageComponentProps} from '../models/ImageComponentProps';
import CustomImageComponent from './CustomImageComponent';
import CustomLinkComponent from './CustomLinkComponent';
import {
  MarkdownGadgetI18nStrings,
  WithI18nStringsProps
} from '../../../context/course/models/I18n';

import 'github-markdown-css/github-markdown-light.css';
import './styles/markdown-renderer.scss';

const SYNTAX_HIGHLIGHTING_ALLOWED_LANGUAGES = [
  'className',
  'language-js',
  'language-html',
  'language-css',
  'language-md',
  'language-json',
  'language-scala',
  'language-rust',
  'language-go',
  'language-cpp',
  'language-java',
  'language-python'
];

export interface MarkdownRendererProps extends WithI18nStringsProps<MarkdownGadgetI18nStrings> {
  /**
   * Markdown text to render.
   */
  markdownText: string
}

type anchorType = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

export const MarkdownRenderer: FunctionComponent<MarkdownRendererProps> = ({
  markdownText,
  i18nStrings
}: MarkdownRendererProps)=> {

  const renderCustomImageComponent = useCallback((image: ImageComponentProps) => {
    return <CustomImageComponent
      image={image}
      i18nStrings={i18nStrings.imageLightbox}
    />;
  }, [i18nStrings]);

  const renderCustomLinkComponent = useCallback((node: anchorType) => {
    return <CustomLinkComponent
      href={node.href}
      i18nStrings={i18nStrings}
    >
      {node.children}
    </CustomLinkComponent>;
  }, [i18nStrings]);

  return (
    <>
      <div className={'markdown-body'}>
        <ReactMarkdown
          components={{
            h1: 'h4',
            h2: 'h4',
            h3: 'h4',
            img: renderCustomImageComponent,
            code({node, inline, className, children, ...props}) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  showLineNumbers={true}
                  style={a11yLight}
                  wrapLines={true}
                  language={match[1]}
                  PreTag="div"
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            a: renderCustomLinkComponent
          }}
          remarkPlugins={[
            remarkGfm,
            remarkUnwrapImages
          ]}
          rehypePlugins={[
            [rehypeSanitize, {
              ...defaultSchema,
              attributes: {
                ...defaultSchema.attributes,
                code: [
                  ...(defaultSchema.attributes?.code || []),
                  // List of all allowed languages:
                  SYNTAX_HIGHLIGHTING_ALLOWED_LANGUAGES
                ]
              }
            }]
          ]}
        >
          {markdownText}
        </ReactMarkdown>
      </div>
    </>
  );
};

MarkdownRenderer.displayName = 'MarkdownRenderer';
