import React, {FunctionComponent} from 'react';
import {HideDownloadWarningStorageKey} from './Constants';
import Text from '@amzn/meridian/text';
import useSessionStorage from '../../hooks/useSessionStorage';
import {DownloadLinkMetadata, LinkWarningModal} from './LinkWarningModal';
import {LinkWarningI18nStrings, WithI18nStringsProps} from '../../context/course/models/I18n';

export interface DownloadLinkWarningModalProps extends WithI18nStringsProps<LinkWarningI18nStrings> {
  open: boolean;
  onClose: () => void;
  href?: string;
  warningMetadata: DownloadLinkMetadata
}

export const DownloadLinkWarningModal: FunctionComponent<DownloadLinkWarningModalProps> = ({
  open,
  href,
  onClose,
  warningMetadata,
  i18nStrings
}) => {
  const [/* hideFutureDownloadLinkWarningsSession */, setHideFutureDownloadLinkWarningsSession] = useSessionStorage<boolean>(HideDownloadWarningStorageKey, false);

  const showFileNameAndSize = (warningMetadata.fileName && warningMetadata.fileSize);
  const aboutToTitle = (showFileNameAndSize ?
    i18nStrings.aboutToDownload :
    i18nStrings.aboutToDownloadGeneric);

  return (<LinkWarningModal
    open={open}
    onClose={onClose}
    href={href}
    title={i18nStrings.downLoadLinkTitle}
    i18nStrings={i18nStrings}
    proceedButtonTitle={i18nStrings.proceedToDownloadLinkButton}
    setHideFutureWarningsSession={setHideFutureDownloadLinkWarningsSession}
  >
    <Text>
      {aboutToTitle}
    </Text>
    {showFileNameAndSize && (
      <Text>
        <strong>{warningMetadata.fileName} ({warningMetadata.fileSize})</strong>
      </Text>
    )}
  </LinkWarningModal>);
};
