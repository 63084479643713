import React, {FunctionComponent} from 'react';
import {AssessmentEvaluationStatus} from '../../context/course';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Link from '@amzn/meridian/link';
import { ScoreBreakdownI18nStrings, WithI18nStringsProps } from '../../context/course/models/I18n';

export interface ScoreBreakdownProps extends WithI18nStringsProps<ScoreBreakdownI18nStrings> {
  status: AssessmentEvaluationStatus,
  numberCorrect: number,
  totalQuestions: number,
  learnerScore: number,
  passPercentage: number,
  isRequiredForCertificate: boolean,
  onJumpToFirstQuestion: () => void
}

export const ScoreBreakdown: FunctionComponent<ScoreBreakdownProps> = (
  {
    status,
    numberCorrect,
    totalQuestions,
    learnerScore,
    passPercentage,
    isRequiredForCertificate,
    onJumpToFirstQuestion,
    i18nStrings,
  }
) => {
  const passed = status === AssessmentEvaluationStatus.Pass;
  const allCorrect = learnerScore === 100;
  let scoreText;
  if (allCorrect) {
    scoreText = i18nStrings.scored100Percent;
  } else if (isRequiredForCertificate && passed) {
    scoreText = i18nStrings.passedWithPercentage(learnerScore);
  } else {
    scoreText = i18nStrings.scoreReport(learnerScore, numberCorrect, totalQuestions);
  }
  return <Column spacing='none'>
    <div aria-live='assertive'>
      <Text><strong>{scoreText}</strong></Text>
    </div>
    <Row spacing='400'>
      {!allCorrect && isRequiredForCertificate && passPercentage &&
      <Text color='secondary'>{i18nStrings.percentRequiredToPass(passPercentage)}</Text>
      }
      <Link
        type='secondary'
        onClick={onJumpToFirstQuestion}
      >
        {i18nStrings.jumpToFirstQuestion}
      </Link>
    </Row>
  </Column>;
};
