import {GadgetType} from '../soju/models/GadgetType';

export type SupplementalI18nStrings = Record<string, string | ((...params: (string & number)[]) => string)>;

export type I18nStrings = Record<string, string | ((...params: (string & number)[]) => string) | SupplementalI18nStrings>;

export type CompositeI18nStrings = Record<string, I18nStrings>;

export interface ScoreBreakdownI18nStrings extends SupplementalI18nStrings {
  /**
   * default: 'Congrats! You scored 100%.'
   */
  scored100Percent: string;

  /**
   * default: `Congrats! You passed with a score of ${score}%.`
   */
  passedWithPercentage: (scorePercentage: number) => string;

  /**
   * default: `You scored ${learnerScore}%, with ${numberCorrect} of ${totalQuestions} questions answered correctly.`
   */
  scoreReport: (scorePercentage: number, numberCorrect: number, totalQuestions: number) => string;

  /**
   * default: `${passPercentage}% required to pass.`
   */
  percentRequiredToPass: (passPercentage: number) => string;

  /**
   * default: 'Jump to first question'
   */
  jumpToFirstQuestion: string;
}

export interface QuizGadgetI18nStrings extends I18nStrings {
  /**
   * default: 'Check Your Answers'
   */
  checkYourAnswers: string;

  /**
   * default: 'Why did I get this wrong?'
   */
  whyWrongAnswer: string;

  /**
   * default: 'None of the answers are correct.'
   */
  noCorrectAnswers: string;

  /**
   * default: 'The correct answer for this question was ${answer}.'
   * @param answer The correct answer to the question
   */
  oneCorrectAnswer: (answer: string) => string;

  /**
   * default: 'The correct answers for this question were:'
   */
  multipleCorrectAnswers: string;

  /**
   * default: 'You did not select any answers.'
   */
  noLearnerAnswers: string;

  /**
   * default: 'You selected ${answer}.'
   * @param answer The learner's answer to the question
   */
  oneLearnerAnswer: (answer: string) => string;

  /**
   * default: 'You selected:'
   */
  multipleLearnerAnswers: string;

  /**
   * default: 'correct.'
   */
  correct: string;

  /**
   * default: 'incorrect.'
   */
  incorrect: string;
  /**
   * default: 'Select every correct answer.'
   */
  selectCorrectAnswers: string;

  /**
   * default: 'Retake Quiz'
   */
  retakeQuiz: string;

  scoreBreakdown: ScoreBreakdownI18nStrings;

  imageLightbox: ImageI18nStrings;

  /**
   * @see AssetUploadI18nStrings
   */
  assetUpload: AssetUploadI18nStrings;
}

export interface ResponseGadgetI18nStrings extends I18nStrings {
  /**
   * default: 'Retake Quiz'
   */
  retakeQuiz: string;

  /**
   * default: 'Check Your Answers'
   */
  checkYourAnswers: string;

  /**
   * default: 'correct response'
   */
  correctResponse: string;

  /**
   * default: 'incorrect response'
   */
  incorrectResponse: string;

  /**
   * default: 'Type your answer'
   */
  answerInputPlaceholder: string;

  /**
   * default: 'Correct answer:'
   */
  correctAnswer: string;

  /**
   * default: 'Correct answers:'
   */
  correctAnswers: string;

  scoreBreakdown: ScoreBreakdownI18nStrings;
}

export interface VideoGadgetI18nStrings extends I18nStrings {
  /**
   * default: 'Transcript'
   */
  transcript: string;

  /**
   * default: 'English'
   * BEWARE: This word should not be directly translated. Should change per locale, for example es_ES should provide 'Spanish'.
   */
  language: string;

  /**
   * default: 'en'
   * BEWARE: This prefix should not be a directly translated. Should change pre locale, for example es_ES should provide 'es'.
   */
  twoLetterLocalePrefix: string;

  /**
   * default: 'Mute'
   */
  mute: string;

  /**
   * default: 'Unmute'
   */
  unmute: string;

  /**
   * default: 'Subtitles/closed captions (c)'
   */
  closedCaptions: string;

  /**
   * default: 'Subtitles/closed captions unavailable'
   */
  closedCaptionsUnavailable: string;

  /**
   * default: `Move backward ${timeInSeconds} seconds`
   */
  moveBackwardXSeconds: (timeInSeconds: number) => string;

  /**
   * default: `Move forward ${timeInSeconds} seconds`
   */
  moveForwardXSeconds: (timeInSeconds: number) => string;

  /**
   * default: 'Full screen (f)'
   */
  fullScreen: string;

  /**
   * default: 'Picture in picture (i)'
   */
  pictureInPicture: string;

  /**
   * default: 'Play'
   */
  play: string;

  /**
   * default: 'Pause'
   */
  pause: string;

  /**
   * default: 'Settings'
   */
  settings: string;

  /**
   * default: 'Description (markdown syntax required)'
   */
  editorLabel: string;

  /**
   * default: 'Add your description using Markdown syntax here'
   */
  editorPlaceholder: string;

  /**
   * @see AssetUploadI18nStrings
   */
  assetUpload: AssetUploadI18nStrings;

  /**
   * default: 'Download'
   */
  download: string;

  /**
   * default: 'Caption file attached.'
   */
  captionFileAttached: string;

  /**
   * default: 'Caption file required.'
   */
  captionFileRequired: string;

  /**
   * default: 'Caption file could not be generated due to the video file format or length. Provide them manually below. Video captions are required to publish the course.'
   */
  autoGeneratedTranscriptErrorMessage: string;

  /**
   * default: 'Upload video to auto-generate captions.'
   */
  uploadVideoMessage: string;

  /**
   * default: 'Auto-generating captions...'
   */
  autoGeneratingCaptions: string;
}

export interface ReadingGadgetI18nStrings extends I18nStrings, QuillI18nStrings {
  /**
   * @see AssetUploadI18nStrings
   */
  assetUpload: AssetUploadI18nStrings;
}

export type SectionGadgetI18nStrings = I18nStrings

export interface SlideshowGadgetI18nStrings extends I18nStrings, QuillI18nStrings {
  /**
   * @see AssetUploadI18nStrings
   */
  assetUpload: AssetUploadI18nStrings;
}

export interface MarkdownGadgetI18nStrings extends I18nStrings {
  /**
   * default: 'Attach files by drag and drop, or click here to upload.'
   */
  noClickLabel: string,

  /**
   * default: 'Uploading file...'
   */
  noClickUploadLabel: string,

  /**
   * default: 'Upload new file'
   */
  noClickUploadOptionLabel: string,

  /**
   * default: 'Content (markdown syntax required)'
   */
  editorLabel: string,

  /**
   * default: 'Add your content using Markdown syntax here'
   */
  editorPlaceholder: string,

  /**
   * default: 'Threat detected'
   */
  assetWithThreatDetectedLabel: string

  /**
   * default: 'Asset still processing'
   */
  assetWithProcessingLabel: string

  /**
   * default: 'Some error occurred'
   */
  assetWithErrorLabel: string

  /**
   * @see LinkWarningI18nStrings
   */
  linkWarning: LinkWarningI18nStrings,

  /**
   * @see AssetUploadI18nStrings
   */
  assetUpload: AssetUploadI18nStrings,

  /**
   * @see FileProcessingInfoI18nStrings
   */
  fileProcessingInfo: FileProcessingInfoI18nStrings,

  /**
   * @see ImageI18nStrings
   */
  imageLightbox: ImageI18nStrings,
}

export interface LabGadgetI18nStrings extends I18nStrings {
  /**
   * default: 'Hands-on Activity'
   */
  header: string;
  /**
   * default: 'Completed'
   */
  completed: string;
  /**
   * default: 'Not completed'
   */
  incomplete: string;
  /**
   * default: 'Required for certificate'
   */
  requiredForCert: string;
  /**
   * default: '${count} exercises'
   * @param count
   */
  exerciseCount: (count: number) => string;
  /**
   * default: 'Total time: ${minutes} minutes'
   * @param minutes
   */
  totalTime: (minutes: number) => string;
  /**
   * default: '${minutes} minutes'
   * @param minutes
   */
  exerciseTimeLimit: (minutes: number) => string;
  /**
   * default: 'Open Activity'
   */
  startActivityCta: string;
  /**
   * default: 'Redo Activity'
   */
  restartActivityCta: string;

  /**
   * default: 'Exit'
   */
  exit: string;
}

export interface PageFooterI18nStrings extends I18nStrings {
  /**
   * default: `Go to next section, ${nextSectionNumber} of ${totalSections}, in lesson: ${lessonTitle}`
   */
  goToNextSection: (nextSectionNumber: number, totalSections: number, lessonTitle: string) => string;

  /**
   * default: `Go to previous section, ${prevSectionNumber} of ${totalPages}, in lesson: ${lessonTitle}`
   */
  goToPreviousSection: (prevSectionNumber: number, totalSections: number, lessonTitle: string) => string;

  /**
   * default: `Go to next page, ${nexPageNumber} of ${totalPages}, in lesson: ${lessonTitle}`
   */
  goToNextPage: (nextPageNumber: number, totalPages: number, lessonTitle: string) => string;

  /**
   * default: `Go to previous page, ${prevPageNumber} of ${totalPages}, in lesson: ${lessonTitle}`
   */
  goToPreviousPage: (prevPageNumber: number, totalPages: number, lessonTitle: string) => string;

  /**
   * default: `Navigate sections in lesson: ${lessonTitle}`
   */
  navigateSections: (lessonTitle: string) => string;

  /**
   * default: `Navigate pages in lesson: ${lessonTitle}`
   */
  navigatePages: (lessonTitle: string) => string;

  /**
   * default: 'Section'
   */
  section: string;

  /**
   * default: 'Page'
   */
  page: string;

  /**
   * default: 'Next section'
   */
  nextSection: string;

  /**
   * default: 'Previous section'
   */
  previousSection: string;

  /**
   * default: 'Next page'
   */
  nextPage: string;

  /**
   * default: 'Previous page'
   */
  previousPage: string;
}

export interface FooterI18nStrings extends I18nStrings {
  /**
   * default: 'Good work! Ready for the next lesson?'
   */
  readyForNextLesson: string;

  /**
   * default: `Great work! You've completed the last lesson in ${courseTitle}.`
   */
  completedLastLesson: (courseTitle: string) => string;

  /**
   * default: 'Mark course complete'
   */
  markCourseComplete: string;

  /**
   * default: 'Mark complete & continue'
   */
  markCompleteAndContinue: string;
}

export interface CompleteCourseModalI18nStrings extends SupplementalI18nStrings {
  /**
   * default: 'Mark complete'
   */
  markComplete: string;

  /**
   * default: 'Return to the course'
   */
  returnToCourse: string;
}

export interface CourseStatusCheckboxI18nStrings extends SupplementalI18nStrings {
  /**
   * default: 'Mark this course as complete'
   */
  markThisCourseAsComplete: string;

  /**
   * default: 'Haven\'t finished all of this course\'s lessons but ready to move on? Mark the course as complete and its status will show as Completed on the Your Courses page. (Your progress will stay as you left it.)'
   */
  markUnfinishedCourseAsCompleteBlurb: string;

  /**
   * default: 'Mark course complete'
   */
  markCourseComplete: string;

  /**
   * default: 'Course completed'
   */
  courseCompleted: string;

  /**
   * default: 'course status menu'
   */
  courseStatusCheckboxButtonLabel: string;

  /**
   * default: 'Close'
   */
  closeLabel: string;
}

export interface ProgressTrackerIndicatorI18nStrings extends SupplementalI18nStrings {
  /**
   * default: `You've finished all the lessons in ${courseTitle}.`
   */
  youFinishedAllLessonsInCourse: (courseTitle: string) => string;

  /**
   * default: 'Would you like to mark this course as complete?'
   */
  wouldYouLikeMarkCourseComplete: string;

  /**
   * default: 'course completion: ${percentComplete}%'
   */
  courseCompletionPercentage: (percentComplete: number) => string;

  /**
   * default: '${percentComplete}%'
   */
  percentComplete: (percentComplete: number) => string;

  /**
   * default: 'Great job!'
   */
  greatJob: string;
}

export interface CourseCompletedI18nStrings extends I18nStrings {
  /**
   * default: `Congratulations, you completed ${courseTitle}!`
   */
  congratulationsTitle: (courseTitle: string) => string;

  /**
   * default: 'What did you think of this course?'
   */
  whatDidYouThink: string;

  /**
   * default: 'Share your experience with other customers.'
   */
  shareYourExperience: string;

  /**
   * default: 'Write a customer review'
   */
  writeReview: string;

  /**
   * default: 'Keep up your streak'
   */
  keepUpYourStreak: string;

  /**
   * default: 'Find a new course, or make progress on other courses you own.'
   */
  findNewCourse: string;

  /**
   * default: 'Return to your courses'
   */
  returnToYourCourses: string;

  /**
   * default: 'Discover new courses'
   */
  discoverNewCourses: string;
}

export interface SidebarI18nStrings extends I18nStrings {
  /**
   * default: 'Mark lesson incomplete'
   */
  markLessonIncomplete: string;

  /**
   * default: 'Mark lesson complete'
   */
  markLessonComplete: string;

  /**
   * default: '&quot;{lesson.title}&quot; completed'
   */
  lessonCompleted: (lessonTitle: string) => string;

  /**
   * default: 'Table of contents'
   */
  tableOfContentsLabel: string;

  /**
   * default: `lessons in "${title}"`
   */
  lessonsInTitleLabel: (title: string) => string;

  /**
   * default: `go to lesson: ${title}`
   */
  goToLessonLabel: (title: string) => string;

  /**
   * default: `collapse lesson contents for "${title}"`
   */
  collapseLessonsContentsLabel: (title: string) => string;

  /**
   * default: `expand lesson contents for "${title}"`
   */
  expandLessonContentsLabel: (title: string) => string;

  /**
   * default: `lesson contents for "${title}"`
   */
  lessonContentsLabel: (title: string) => string;

  /**
   * default: `go to section: ${title}`
   */
  goToSectionLabel: (title: string) => string;

  /**
   * default: `go to gadget: ${title}`
   */
  goToGadgetLabel: (title: string) => string;

  /**
   * @see CompleteCourseModalI18nStrings
   */
  completeCourseModal: CompleteCourseModalI18nStrings;

  /**
   * @see CourseStatusCheckboxI18nStrings
   */
  courseStatusCheckbox: CourseStatusCheckboxI18nStrings;

  /**
   * @see ProgressTrackerIndicatorI18nStrings
   */
  progressTrackerIndicator: ProgressTrackerIndicatorI18nStrings;
}

export interface TopNavI18nStrings extends I18nStrings {
  /**
   * default: 'Congratulations!'
   */
  congratulations: string;

  /**
   * default: `Lesson ${lessonNumber}: ${lessonTitle}`
   */
  lessonNumberAndTitle: (lessonNumber: number, lessonTitle: string) => string;

  /**
   * default: 'Course Menu'
   */
  courseMenu: string;

  /**
   * default: `Lesson ${lessonNumber}/${totalNumLessons}`
   */
  lessonNumberOfTotal: (lessonNumber: number, totalNumLessons: number) => string;

  /**
   * default: 'collapse course menu'
   */
  collapseMenuLabel: string;

  /**
   * default: 'expand course menu'
   */
  expandMenuLabel: string;

  /**
   * default: `go back to Lesson ${lessonIndex}`
   */
  goBackToLessonLabel: (lessonIndex: number) => string;

  /**
   * default: 'no previous lesson'
   */
  noPreviousLessonLabel: string;

  /**
   * default: `go forward to Lesson ${lessonIndex}`
   */
  goForwardToLessonLabel: (lessonIndex: number) => string;

  /**
   * default: 'no next lesson'
   */
  noNextLessonLabel: string;
}

export interface PaywallI18nStrings extends I18nStrings {
  /**
   * default: 'Want access to this entire course?'
   */
  wantAccessToCourse: string;

  /**
   * default: 'Purchase this course to access the entire content and track your progress.'
   */
  purchaseCourseForAccess: string;

  /**
   * default: 'Return to course details'
   */
  returnToCourseDetails: string;
}

export interface GracefulErrorI18nStrings extends I18nStrings {
  /**
   * default: 'We ran into an issue loading this content'
   */
  issueLoadingContent: string;

  /**
   * default: 'Please check your internet connection and try refreshing the page, or return to your course library.'
   */
  mainText: string;
}

export interface FeedbackI18nStrings extends I18nStrings {
  /**
   * default: `Your text should be less than ${maxCharacters} characters.`
   */
  textShouldBeLessThan: (maxCharacters: number) => string;

  /**
   * default: 'How are we doing?'
   */
  howAreWeDoing: string;

  /**
   * default: 'I like it!'
   */
  iLikeIt: string;

  /**
   * default: 'I think you can do a little better...'
   */
  youCanDoBetter: string;

  /**
   * default: 'Topic (optional):'
   */
  topic: string;

  /**
   * default: 'more info about feedback'
   */
  feedbackIconLabel: string;

  /**
   * default: 'Your feedback is important, please share your thoughts so we can improve'
   */
  shareYourThoughts: string;

  /**
   * default: 'Send'
   */
  send: string;

  /**
   * default: 'Cancel'
   */
  cancel: string;

  /**
   * default: 'Your feedback will only be available privately to the Amazon employees behind this online courses experience'
   */
  yourFeedbackIsPrivate: string;

  /**
   * default: 'Thanks for your feedback!'
   */
  thanksForYourFeedback: string;

  /**
   * default: 'Submit more feedback'
   */
  submitMoreFeedback: string;

  /**
   * default: 'Close'
   */
  close: string;

  /**
   * default: 'Something went wrong on our end, please try again later.'
   */
  somethingWentWrong: string;

  /**
   * default: 'Try again'
   */
  tryAgain: string;

  /**
   * default: 'Details (optional):'
   */
  details: string;

  /**
   * default: 'Course Content'
   */
  courseContentFeedbackTopicOption: string;

  /**
   * default: 'Course Player Experience'
   */
  playerExperienceFeedbackTopicOption: string;

  /**
   * default: 'Other'
   */
  otherFeedbackTopicOption: string;
}

export interface CourseRequirementsIncompleteI18nStrings extends I18nStrings {
  /**
   * default: 'Return to course'
   */
  returnToCourse: string;

  /**
   * default: 'Course requirements incomplete'
   */
  courseRequirementsIncomplete: string;

  /**
   * default: 'The following requirements need to be completed in order to complete this course:'
   */
  requirementsToCompleteThisCourse: string;

  /**
   * default: 'Close'
   */
  closeModalLabel: string;
}

export interface LoaderI18nStrings extends I18nStrings {
  /**
   * default: 'Getting your course ready…'
   */
  gettingYourCourseReady: string;
}

export interface FileProcessingInfoI18nStrings extends SupplementalI18nStrings {

  /**
   * default: 'Processing file'
   */
  title: string;

  /**
   * default: 'Files with viruses or any errors will not be accessible to learners. To check the status of your file upload and to preview your gadget, please click on:'
   */
  checkStatusLabel: string

  /**
   * default: 'Don’t show this message again for uploads'
   */
  dontShowAgainLabel: string;

  /**
   * default: 'Dismiss'
   */
  dismissLabel: string;
}

export interface LinkWarningI18nStrings extends SupplementalI18nStrings {
  /**
   * default: 'Link outside Amazon'
   */
  externalLinkTitle: string;

  /**
   * default: 'Download file'
   */
  downLoadLinkTitle: string;

  /**
   * default: `You are about to visit: ${href}`
   */
  aboutToVisit: (href?: string) => string;

  /**
   * default: `You are about to download a file`
   */
  aboutToDownloadGeneric: string;

  /**
   * default: `You are about to download: `
   */
  aboutToDownload: string;

  /**
   * default: 'This link is outside of Amazon. External links can contain unsafe or offensive material, and should be used with caution.'
   */
  externalContentWarning: string;

  /**
   * default: 'If you proceed, this link will open in a new tab or start a download.'
   */
  proceedWarning: string;

  /**
   * default: `Don't show this message again`
   */
  dontShowAgainLabel: string;

  /**
   * default: 'Cancel'
   */
  cancelButton: string;

  /**
   * default: 'Proceed to external link'
   */
  proceedToExternalLinkButton: string;

  /**
   * default: 'Proceed to download file'
   */
  proceedToDownloadLinkButton: string;

  /**
   * default: 'Cancel'
   */
  closeButtonLabel: string;
}

export interface ImageI18nStrings extends SupplementalI18nStrings {
  clickableImageLinkLabel: string;
  lightboxCloseButtonLabel: string;
}

export interface QuillI18nStrings extends I18nStrings {
  imageLightbox: ImageI18nStrings,
  linkWarning: LinkWarningI18nStrings
}

export interface AssetUploadI18nStrings extends SupplementalI18nStrings {
  /**
   * default: 'Invalid or damaged file.'
   */
  fileUploadErrorGenericTitle: string,

  /**
   * default: 'The file ${fileName} could not be uploaded. Please check the file or try a different one. Supported formats: ${supportedFileTypes}.'
   */
  fileUploadErrorDueToUnsupportedFileType: (fileName: string, supportedFileTypes: string) => string

  /**
   * default: 'The file ${fileName} cannot be uploaded as its file size exceeds max file size of ${maxFileSize}'
   */
  fileSizeTooLargeError: (fileName: string, maxFileSize: string) => string,

  /**
   * default: 'Failed uploading file ${fileName}'
   */
  fileUploadErrorWithFileName: (fileName: string) => string,

  /**
   * default: 'File upload failed. Please refresh and try again.'
   */
  fileUploadErrorWithRefresh: string,

  /**
   * default: 'File upload failed. ${fileName} is damaged or corrupted.'
   */
  fileUploadErrorDueToCorruptedFile: (fileName: string) => string,

  /**
   * default: 'Uploading ${fileName}...'
   */
  videoUploading: (fileName: string) => string,

  /**
   * default: 'Your video file upload failed'
   */
  videoUploadError: string,

  /**
   * default: 'Your video file ${fileName} is failed in processing with error: ${errorMessage} '
   */
  videoProcessingError: (fileName: string, errorMessage: string) => string

  /**
   * default: 'Your video file ${fileName} is uploaded successfully. It is being processed.'
   */
  videoProcessing: (fileName: string) => string
}

export interface GadgetI18nStringSConfig {
  [GadgetType.QUIZ]?: QuizGadgetI18nStrings;
  [GadgetType.READING]?: ReadingGadgetI18nStrings;
  [GadgetType.VIDEO]?: VideoGadgetI18nStrings;
  [GadgetType.HEADER]?: SectionGadgetI18nStrings;
  [GadgetType.SLIDESHOW]?: SlideshowGadgetI18nStrings;
  [GadgetType.RESPONSE]?: ResponseGadgetI18nStrings;
  [GadgetType.MARKDOWN]?: MarkdownGadgetI18nStrings;
  [GadgetType.LAB]?: LabGadgetI18nStrings;
}

export interface I18nStringsConfig {
  gadgets: GadgetI18nStringSConfig;
  footer: FooterI18nStrings;
  pageFooter: PageFooterI18nStrings;
  sidebar: SidebarI18nStrings;
  topNav: TopNavI18nStrings;
  paywall: PaywallI18nStrings;
  errors: GracefulErrorI18nStrings;
  feedback: FeedbackI18nStrings;
  loader: LoaderI18nStrings;
  reqsIncomplete: CourseRequirementsIncompleteI18nStrings;
  courseCompleted: CourseCompletedI18nStrings;
}

const scoreBreakdownI18nStrings: ScoreBreakdownI18nStrings = {
  jumpToFirstQuestion: 'Jump to first question',
  passedWithPercentage: score => `Congrats! You passed with a score of ${score}%.`,
  percentRequiredToPass: passPercentage => `${passPercentage}% required to pass.`,
  scored100Percent: 'Congrats! You scored 100%.',
  scoreReport: (score, numberCorrect, totalQuestions) =>
    `You scored ${score}%, with ${numberCorrect} of ${totalQuestions} questions answered correctly.`,
};

export const defaultLinkWarningI18nStrings: LinkWarningI18nStrings = {
  aboutToVisit: href => `You are about to visit: ${href}`,
  aboutToDownload: 'You are about to download: ',
  aboutToDownloadGeneric: 'You are about to download a file',
  externalContentWarning: 'This link is outside of Amazon. External links can contain unsafe or offensive material, and should be used with caution.',
  proceedWarning: 'If you proceed, this link will open in a new tab or start a download.',
  externalLinkTitle: 'Link outside Amazon',
  downLoadLinkTitle: 'Download file',
  dontShowAgainLabel: 'Don\'t show this message again',
  cancelButton: 'Cancel',
  proceedToExternalLinkButton: 'Proceed to external link',
  proceedToDownloadLinkButton: 'Proceed to download file',
  closeButtonLabel: 'Cancel'
};

export const defaultFileProcessingInfoI18nStrings: FileProcessingInfoI18nStrings = {
  title: 'Processing file',
  checkStatusLabel: 'Files with viruses or any errors will not be accessible to learners. To check the status of your file upload and to preview your gadget, please click on:',
  dontShowAgainLabel: 'Don’t show this message again for uploads',
  dismissLabel: 'Dismiss'
};

export const defaultImageI18nStrings = {
  clickableImageLinkLabel: 'Expand image',
  lightboxCloseButtonLabel: 'Close image'
};

export const defaultQuillI18nStrings = {
  linkWarning: defaultLinkWarningI18nStrings,
  imageLightbox: defaultImageI18nStrings
};

export const defaultAssetUploadI18nStrings: AssetUploadI18nStrings = {
  fileUploadErrorGenericTitle: 'Invalid or damaged file.',
  fileUploadErrorDueToUnsupportedFileType: (fileName: string, supportedFileTypes: string) => `The file ${fileName} could not be uploaded. Please check the file or try a different one. Supported formats: ${supportedFileTypes}.`,
  fileSizeTooLargeError: (fileName: string, maxFileSize: string) => `The file ${fileName} cannot be uploaded as its file size exceeds max file size of ${maxFileSize}`,
  fileUploadErrorWithFileName:  (fileName: string) => `Failed uploading file ${fileName}`,
  fileUploadErrorWithRefresh: 'Failed uploading file. Please refresh and try again.',
  fileUploadErrorDueToCorruptedFile: (fileName: string) => `Failed uploading file. ${fileName} is damaged or corrupted.`,
  videoUploading: (fileName: string) => `Uploading ${fileName}...`,
  videoUploadError: 'Your video file upload failed',
  videoProcessingError: (fileName: string, errorMessage: string) => `Your video file ${fileName} is failed in processing with error: ${errorMessage} `,
  videoProcessing: (fileName: string) => `Your video file ${fileName} is uploaded successfully. It is being processed.`
};

export const defaultCompleteCourseModalI18nStrings: CompleteCourseModalI18nStrings = {
  markComplete: 'Mark Complete',
  returnToCourse: 'Return to the course',
};

export const defaultCourseStatusCheckboxI18nStrings: CourseStatusCheckboxI18nStrings = {
  markThisCourseAsComplete: 'Mark this course as complete',
  markUnfinishedCourseAsCompleteBlurb: 'Haven\'t finished all of this course\'s lessons but ready to move on? Mark the course as complete and its status will show as Completed on the Your Courses page. (Your progress will stay as you left it.)',
  markCourseComplete: 'Mark course complete',
  courseCompleted: 'Course completed',
  courseStatusCheckboxButtonLabel: 'course status menu',
  closeLabel: 'Close',
};

export const defaultProgressTrackerIndicatorI18nStrings: ProgressTrackerIndicatorI18nStrings = {
  youFinishedAllLessonsInCourse: courseTitle => `You've finished all the lessons in ${courseTitle}.`,
  wouldYouLikeMarkCourseComplete: 'Would you like to mark this course as complete?',
  courseCompletionPercentage: percentComplete => `course completion: ${percentComplete}%`,
  percentComplete: percentComplete => `${percentComplete}%`,
  greatJob: 'Great job!',
};

export const defaultI18nStrings: I18nStringsConfig = {
  gadgets: {
    [GadgetType.QUIZ]: {
      checkYourAnswers: 'Check your answers',
      correct: 'correct.',
      incorrect: 'incorrect.',
      multipleCorrectAnswers: 'The correct answers for this question were:',
      multipleLearnerAnswers: 'You selected:',
      noCorrectAnswers: 'None of the answers are correct.',
      noLearnerAnswers: 'You did not select any answers.',
      oneCorrectAnswer: answer => `The correct answer for this question was ${answer}.`,
      oneLearnerAnswer: answer => `You selected ${answer}.`,
      retakeQuiz: 'Retake Quiz',
      selectCorrectAnswers: 'Select every correct answer.',
      whyWrongAnswer: 'Why did I get this wrong?',
      scoreBreakdown: scoreBreakdownI18nStrings,
      imageLightbox: defaultImageI18nStrings,
      assetUpload: defaultAssetUploadI18nStrings
    },
    [GadgetType.RESPONSE]: {
      checkYourAnswers: 'Check your answers',
      correctResponse: 'correct response',
      incorrectResponse: 'incorrect response',
      retakeQuiz: 'Retake Quiz',
      answerInputPlaceholder: 'Type your answer',
      correctAnswer: 'Correct answer:',
      correctAnswers: 'Correct answers:',
      scoreBreakdown: scoreBreakdownI18nStrings
    },
    [GadgetType.VIDEO]: {
      transcript: 'Transcript',
      language: 'English',
      twoLetterLocalePrefix: 'en',
      mute: 'Mute',
      unmute: 'Unmute',
      closedCaptions: 'Subtitles/closed captions (c)',
      closedCaptionsUnavailable: 'Subtitles/closed captions unavailable',
      moveBackwardXSeconds: (timeInSeconds: number) => `Move backward ${timeInSeconds} seconds`,
      moveForwardXSeconds: (timeInSeconds: number) => `Move forward ${timeInSeconds} seconds`,
      fullScreen: 'Full screen (f)',
      pictureInPicture: 'Picture in picture (i)',
      play: 'Play',
      pause: 'Pause',
      settings: 'Settings',
      editorLabel: 'Description (markdown syntax required)',
      editorPlaceholder: 'Add your description using Markdown syntax here',
      assetUpload: defaultAssetUploadI18nStrings,
      download: 'Download',
      captionFileRequired: 'Caption file required.',
      captionFileAttached: 'Caption file attached.',
      autoGeneratedTranscriptErrorMessage: 'Caption file could not be generated due to the video file format or length. Provide them manually below. Video captions are required to publish the course.',
      uploadVideoMessage: 'Upload video to auto-generate captions.',
      autoGeneratingCaptions: 'Auto-generating captions...',
    },
    [GadgetType.READING]: {
      linkWarning: defaultLinkWarningI18nStrings,
      imageLightbox: defaultImageI18nStrings,
      assetUpload: defaultAssetUploadI18nStrings
    },
    [GadgetType.HEADER]: {},
    [GadgetType.SLIDESHOW]: {
      linkWarning: defaultLinkWarningI18nStrings,
      imageLightbox: defaultImageI18nStrings,
      assetUpload: defaultAssetUploadI18nStrings
    },
    [GadgetType.MARKDOWN]: {
      noClickLabel: 'Attach files by drag and drop, or click here to upload.',
      noClickUploadLabel: 'Uploading file...',
      noClickUploadOptionLabel: 'Upload new file',
      editorLabel: 'Content (markdown syntax required)',
      editorPlaceholder: 'Add your content using Markdown syntax here',
      assetWithThreatDetectedLabel: 'Threat detected',
      assetWithProcessingLabel: 'Asset still processing',
      assetWithErrorLabel: 'Some error occurred',
      linkWarning: defaultLinkWarningI18nStrings,
      assetUpload: defaultAssetUploadI18nStrings,
      fileProcessingInfo: defaultFileProcessingInfoI18nStrings,
      imageLightbox: defaultImageI18nStrings,
    },
    [GadgetType.LAB]: {
      header: 'Hands-on Activity',
      completed: 'Completed',
      incomplete: 'Not completed',
      requiredForCert: 'Required for certificate',
      exerciseCount: count => `${count} exercises`,
      totalTime: minutes => `Total time: ${minutes} minutes`,
      exerciseTimeLimit: minutes => `${minutes} minutes`,
      startActivityCta: 'Open Activity',
      restartActivityCta: 'Redo Activity',
      exit: 'Exit Lab'
    }
  },
  footer: {
    readyForNextLesson: 'Good work! Ready for the next lesson?',
    completedLastLesson: courseTitle => `Great work! You've completed the last lesson in ${courseTitle}.`,
    markCourseComplete: 'Mark course complete',
    markCompleteAndContinue: 'Mark complete & continue',
  },
  pageFooter: {
    goToNextSection: (nextSectionNumber, totalSections, lessonTitle) =>
      `Go to next section, ${nextSectionNumber} of ${totalSections}, in lesson: ${lessonTitle}`,
    goToPreviousSection: (prevSectionNumber, totalSections, lessonTitle) =>
      `Go to previous section, ${prevSectionNumber} of ${totalSections}, in lesson: ${lessonTitle}`,
    goToNextPage: (nextPageNumber, totalPages, lessonTitle) =>
      `Go to next page, ${nextPageNumber} of ${totalPages}, in lesson: ${lessonTitle}`,
    goToPreviousPage: (prevPageNumber, totalPages, lessonTitle) =>
      `Go to previous page, ${prevPageNumber} of ${totalPages}, in lesson: ${lessonTitle}`,
    navigateSections: lessonTitle => `Navigate sections in lesson: ${lessonTitle}`,
    navigatePages: lessonTitle => `Navigate pages in lesson: ${lessonTitle}`,
    section: 'Section',
    page: 'Page',
    nextSection: 'Next section',
    previousSection: 'Previous section',
    nextPage: 'Next page',
    previousPage: 'Previous page',
  },
  sidebar: {
    markLessonIncomplete: 'Mark lesson incomplete',
    markLessonComplete: 'Mark lesson complete',
    lessonCompleted: lessonTitle => `&quot;${lessonTitle}&quot; completed`,
    tableOfContentsLabel: 'Table of contents',
    lessonsInTitleLabel: title => `lessons in "${title}"`,
    goToLessonLabel: title => `go to lesson: ${title}`,
    collapseLessonsContentsLabel: title => `collapse lesson contents for "${title}"`,
    expandLessonContentsLabel: title => `expand lesson contents for "${title}"`,
    lessonContentsLabel: title => `lesson contents for "${title}"`,
    goToSectionLabel: title => `go to section: ${title}`,
    goToGadgetLabel: title => `go to gadget: ${title}`,
    completeCourseModal: defaultCompleteCourseModalI18nStrings,
    courseStatusCheckbox: defaultCourseStatusCheckboxI18nStrings,
    progressTrackerIndicator: defaultProgressTrackerIndicatorI18nStrings,
  },
  topNav: {
    congratulations: 'Congratulations!',
    lessonNumberAndTitle: (lessonNumber, lessonTitle) => `Lesson ${lessonNumber}: ${lessonTitle}`,
    courseMenu: 'Course Menu',
    lessonNumberOfTotal: (lessonNumber, totalNumLessons) => `Lesson ${lessonNumber}/${totalNumLessons}`,
    collapseMenuLabel: 'collapse course menu',
    expandMenuLabel: 'expand course menu',
    goBackToLessonLabel: lessonIndex => `go back to Lesson ${lessonIndex}`,
    noPreviousLessonLabel: 'no previous lesson',
    goForwardToLessonLabel: lessonIndex => `go forward to Lesson ${lessonIndex}`,
    noNextLessonLabel: 'no next lesson',
  },
  paywall: {
    wantAccessToCourse: 'Want access to this entire course?',
    purchaseCourseForAccess: 'Purchase this course to access the entire content and track your progress.',
    returnToCourseDetails: 'Return to course details',
  },
  errors: {
    issueLoadingContent: 'We ran into an issue loading this content',
    mainText: 'Please check your internet connection and try refreshing the page, or return to your course library.',
  },
  feedback: {
    textShouldBeLessThan: maxCharacters => `Your text should be less than ${maxCharacters} characters.`,
    howAreWeDoing: 'How are we doing?',
    iLikeIt: 'I like it!',
    youCanDoBetter: 'I think you can do a little better...',
    topic: 'Topic (optional):',
    shareYourThoughts: 'Your feedback is important, please share your thoughts so we can improve',
    send: 'Send',
    cancel: 'Cancel',
    yourFeedbackIsPrivate: 'Your feedback will only be available privately to the Amazon employees behind this online courses experience',
    thanksForYourFeedback: 'Thanks for your feedback!',
    submitMoreFeedback: 'Submit more feedback',
    close: 'Close',
    somethingWentWrong: 'Something went wrong on our end, please try again later.',
    tryAgain: 'Try again',
    details: 'Details (optional):',
    feedbackIconLabel: 'more info about feedback',
    courseContentFeedbackTopicOption: 'Course Content',
    playerExperienceFeedbackTopicOption: 'Course Player Experience',
    otherFeedbackTopicOption: 'Other',
  },
  loader: {
    gettingYourCourseReady: 'Getting your course ready…',
  },
  reqsIncomplete: {
    returnToCourse: 'Return to course',
    courseRequirementsIncomplete: 'Course requirements incomplete',
    requirementsToCompleteThisCourse: 'The following requirements need to be completed in order to complete this course:',
    closeModalLabel: 'Close',
  },
  courseCompleted: {
    congratulationsTitle: courseTitle => `Congratulations, you completed ${courseTitle}!`,
    whatDidYouThink: 'What did you think of this course?',
    shareYourExperience: 'Share your experience with other customers.',
    writeReview: 'Write a customer review',
    keepUpYourStreak: 'Keep up your streak',
    findNewCourse: 'Find a new course, or make progress on other courses you own.',
    returnToYourCourses: 'Return to your courses',
    discoverNewCourses: 'Discover new courses',
  }
};

export type I18nStringPropType = (Partial<I18nStringsConfig> | I18nStrings | CompositeI18nStrings | undefined);

export interface WithI18nStringsProps<I18N extends I18nStringPropType> {

  /**
   * static text for the gadget
   */
  i18nStrings: I18N;
}
