import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import Theme from '@amzn/meridian/theme';
import brandedDark from '../../theme/branded-dark';
import brandedLight from '../../theme/branded-light';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import { defaultI18nStrings, GracefulErrorI18nStrings, WithI18nStringsProps } from '../../context/course/models/I18n';

import './styles/GracefulError.scss';

export enum GracefulErrorTheme {
  light = 'light',
  dark = 'dark'
}

export interface GracefulErrorAdditionalAction {
  buttonText: string,
  href?: string,
  action?: () => void
}

export interface GracefulErrorProps extends WithI18nStringsProps<GracefulErrorI18nStrings | undefined> {
  additionalAction?: GracefulErrorAdditionalAction
  theme?: GracefulErrorTheme
}

export const GracefulError: FunctionComponent<GracefulErrorProps> = ({
  additionalAction,
  theme = GracefulErrorTheme.light,
  i18nStrings = defaultI18nStrings.errors,
}: GracefulErrorProps) => {
  const iconClasses = cx('GracefulError__icon', `GracefulError__icon--${theme}`);
  const containerClasses = cx('GracefulError', `GracefulError--${theme}`);
  return <Theme tokens={theme === GracefulErrorTheme.dark ? brandedDark : brandedLight}>
    <div className={containerClasses}>
      <Column alignmentHorizontal='center'>
        <Row aria-live='polite' aria-atomic='true' spacingInset='450'>
          <Column alignmentHorizontal='center'>
            <div className={iconClasses} />
            <div className='GracefulError__heading'>
              <Heading type='h400' level={1}>{i18nStrings?.issueLoadingContent}</Heading>
            </div>
            <div className='GracefulError__main-text'>
              <Text type='b300'>{i18nStrings?.mainText}</Text>
            </div>
          </Column>
        </Row>
        {additionalAction && (additionalAction.href || additionalAction.action) && <Row>
          <Button href={additionalAction.href} onClick={additionalAction.action}>{additionalAction.buttonText}</Button>
        </Row>}
      </Column>
    </div>
  </Theme>;
};
