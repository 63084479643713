import React, {FunctionComponent, useEffect, useState} from 'react';
import Theme from '@amzn/meridian/theme';
import brandedLightTokens from '../../theme/branded-light';
import Loader from '@amzn/meridian/loader';
import cx from 'classnames';
import './styles/image.scss';

export interface ImageProps {
  src: string,
  alt?: string,
  fill?: boolean,
  onLoad?: (event?: HTMLImageElement) => void;
}

export const Image: FunctionComponent<ImageProps> = ({
  src,
  alt,
  fill,
  onLoad,
}) => {
  const [imageElement, setImageElement] = useState<HTMLImageElement>();

  useEffect(() => {
    onLoad?.(imageElement);
  }, [onLoad, imageElement]);

  return (
    <React.Fragment>
      {!imageElement &&
        <div className='SojuImage__loader'>
          <Theme tokens={brandedLightTokens}>
            <Loader/>
          </Theme>
        </div>
      }
      <img
        className={cx('SojuImage__image', !imageElement && 'is-loading', fill && 'fill')}
        src={src}
        alt={alt || ''}
        onLoad={event => setImageElement(event.target as HTMLImageElement)}
      />
    </React.Fragment>
  );
};
