export const videoFileTypes = [
  '.mp4', // .MP4
  '.avi', // .AVI (Audio Video Interleave)
  '.mpeg', // .M2V
  '.m2v', // .MPG
  '.mpg', // .MPEG
  '.m4v', // .M4V (File format developed by Apple - similar to Apple)
  '.flv', // .FLV (Adobe Flash Videos)
  '.mov', // .MOV
  '.webm', // .WEBM
  '.wmv' // .WMV (Windows Media)
];
export const captionsFileTypes = ['.vtt'];
export const textTranscriptFileTypes = ['.txt'];
export const imageFileTypes = ['.jpeg','.jpg', '.png', '.gif'];
export const downloadableFileTypes = {
  'application/vnd.ms-excel': ['.xls'] ,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/zip': ['.zip']
};
