/**
 * Content interface
 */
export interface Content {

  /**
   * Content ID
   */
  id: string;

  /**
   * Content title
   */
  title: string;

  /**
   * Content type
   */
  contentType: ContentType;
}

export enum ContentType {
  LESSON = 'Lesson',
  MODULE = 'Module'
}
