import React, {FunctionComponent, useEffect, useState, useRef} from 'react';

import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';
import Popover from '@amzn/meridian/popover';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import withAnimateMount, { AnimateSlideIn } from '@amzn/meridian/animate-mount';
import Box from '@amzn/meridian/box';
import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';
import Textarea from '@amzn/meridian/textarea';
import Tooltip from '@amzn/meridian/tooltip';
import Select, { SelectOption } from '@amzn/meridian/select';
import thumbsUpTokens from '@amzn/meridian-tokens/base/icon/thumbs-up';
import thumbsDownTokens from '@amzn/meridian-tokens/base/icon/thumbs-down';
import isNil from 'lodash/isNil';

import {LearnerFeedbackType, useCourseContext} from '../../../context/course';
import { defaultI18nStrings, FeedbackI18nStrings, WithI18nStringsProps } from '../../../context/course/models/I18n';

const FeedbackFormId = 'feedback-form-id';
const ThumbButtonGroupName = 'how-are-we-doing';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const AnimateSlideInWithMount = withAnimateMount(AnimateSlideIn);

export interface FeedbackWidgetProps extends WithI18nStringsProps<FeedbackI18nStrings | undefined> {
  /**
   * Limit of characters can enter on their feedback description
   */
  descriptionMaxLength?: number;

  /**
   * This topics will show up in the dropdown
   */
  topics: string[];
}

interface FeedbackSubmittedViewProps extends WithI18nStringsProps<FeedbackI18nStrings> {
  /**
   * Callback to go back to feedback form
   */
  submitMore: () => void;

  /**
   * Callback to close feedback form
   */
  closeFeedback: () => void;
}

export enum FeedbackUIState {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  SUBMITTED = 'SUBMITTED',
  FAILED = 'FAILED'
}

export const FeedbackWidget: FunctionComponent<FeedbackWidgetProps> = ({
  descriptionMaxLength,
  topics,
  i18nStrings = defaultI18nStrings.feedback,
}: FeedbackWidgetProps) => {
  const [uiState, setUIState] = useState(FeedbackUIState.CLOSED);
  const [feedbackType, setFeedbackType] = useState<LearnerFeedbackType | undefined>(undefined);
  const [feedbackTopic, setFeedbackTopic] = useState(undefined);
  const [feedbackDescription, setFeedbackDescription] = useState('');
  const [feedbackDescriptionErrorMessage, setFeedbackDescriptionErrorMessage] = useState('');

  const {submitLearnerFeedback} = useCourseContext();

  // Toggle between form open and closed based on UI state
  useEffect(() => {
    if(!isNil(feedbackType)) {
      setUIState(FeedbackUIState.OPEN);
    } else {
      setUIState(FeedbackUIState.CLOSED);
    }
  }, [feedbackType]);

  // Check for description maxLength
  useEffect(() => {
    if(descriptionMaxLength && feedbackDescription.length > descriptionMaxLength) {
      setFeedbackDescriptionErrorMessage(i18nStrings.textShouldBeLessThan(descriptionMaxLength));
    } else {
      setFeedbackDescriptionErrorMessage('');
    }
  }, [feedbackDescription, descriptionMaxLength]);

  const onOptionClick = (type: LearnerFeedbackType) => {
    if(type === feedbackType) {
      setFeedbackType(undefined);
    } else {
      setFeedbackType(type);
    }
  };

  /**
   * onSubmit form callback
   * Based on response status, will show success or fail message
   */
  const onSubmitFeedback = async () => {
    if(feedbackType && (!descriptionMaxLength || feedbackDescription.length <= descriptionMaxLength)) {
      try {
        await submitLearnerFeedback({
          feedbackGeneralExperience: feedbackType,
          feedbackArea: feedbackTopic,
          feedbackText: feedbackDescription
        });
        setUIState(FeedbackUIState.SUBMITTED);

      } catch (error) {
        setUIState(FeedbackUIState.FAILED);
      }
    }
  };

  const onCancelFeedback = () => {
    setFeedbackType(undefined);
    setFeedbackTopic(undefined);
    setFeedbackDescription('');
  };

  const onSubmitMore = () => {
    setFeedbackTopic(undefined);
    setFeedbackDescription('');
    setUIState(FeedbackUIState.OPEN);
  };

  if(uiState === FeedbackUIState.SUBMITTED) {
    return <FeedbackSubmitted submitMore={onSubmitMore} closeFeedback={onCancelFeedback} i18nStrings={i18nStrings} />;
  }
  if(uiState === FeedbackUIState.FAILED) {
    return <FeedbackFailedSubmit submitMore={onSubmitMore} closeFeedback={onCancelFeedback} i18nStrings={i18nStrings} />;
  }

  const expanded = uiState === FeedbackUIState.OPEN;

  return (
    <Box spacingInset='200 none'>
      <Row widths={['fit', 'fill', 'fit']} spacingInset='none 200'>
        <span aria-hidden><Text type='b200'>{i18nStrings.howAreWeDoing}</Text></span>
        <ButtonGroup value={feedbackType} onChange={onOptionClick} label={i18nStrings.howAreWeDoing} name={ThumbButtonGroupName}>
          <Tooltip position="top" title={i18nStrings.iLikeIt}>
            <ButtonOption
              name={ThumbButtonGroupName}
              aria-label={i18nStrings.iLikeIt}
              aria-expanded={expanded}
              aria-controls={FeedbackFormId}
              value={LearnerFeedbackType.GOOD} data-testid='btn-thumbs-up'
            >
              <Icon tokens={thumbsUpTokens} />
            </ButtonOption>
          </Tooltip>
	        <Tooltip position="top" title={i18nStrings.youCanDoBetter}>
            <ButtonOption
              name={ThumbButtonGroupName}
              aria-label={i18nStrings.youCanDoBetter}
              aria-expanded={expanded}
              value={LearnerFeedbackType.BAD}
            >
              <Icon tokens={thumbsDownTokens} />
            </ButtonOption>
          </Tooltip>
        </ButtonGroup>
        <InfoPopover i18nStrings={i18nStrings}/>
      </Row>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <AnimateSlideInWithMount
        className='feedbackWidget_animate_container'
        open={uiState === FeedbackUIState.OPEN}
        duration="150ms"
        easing="ease"
      >
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ({ className, style, onTransitionEnd, animatedRootRef }) => (
            <div
              style={style}
              className={className}
              ref={animatedRootRef}
              onTransitionEnd={onTransitionEnd}
              id={FeedbackFormId}
            >
              <Column data-testid='feedback-form' spacingInset="none 200 200">
                <Box>
                  <Text tag='label' type='b200' htmlFor='feedback-widget-topic-dropdown'>{i18nStrings.topic}</Text>
                  <Select id='feedback-widget-topic-dropdown' value={feedbackTopic} onChange={setFeedbackTopic} aria-label={i18nStrings.topic}>
                    {topics?.map((topic, i) =>
                      <SelectOption key={`feedback-topic-${i}`} value={topic} label={topic} />)}
                  </Select>
                </Box>
                <Box>
                  <Text tag='label' type='b200' htmlFor='feedback-widget-topic-description'>{i18nStrings.details}</Text>
                  <Textarea
                    id='feedback-widget-topic-description'
                    rows={4}
                    resize='none'
                    errorMessage={feedbackDescriptionErrorMessage}
                    value={feedbackDescription}
                    placeholder={i18nStrings.shareYourThoughts}
                    onChange={setFeedbackDescription} />
                </Box>
                <Row>
                  <Button className='feedbackWidget_submit_cta' type='primary' size='small' onClick={onSubmitFeedback} data-testid='submit-feedback-btn'>{i18nStrings.send}</Button>
                  <Button type='secondary' size='small' onClick={onCancelFeedback}>{i18nStrings.cancel}</Button>
                </Row>
              </Column>
            </div>
          )
        }
      </AnimateSlideInWithMount>
    </Box>
  );
};

const InfoPopover: FunctionComponent<{i18nStrings: FeedbackI18nStrings}> = ({i18nStrings}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<any>(null);

  return (
    <React.Fragment>
      <Button type='icon' size='small' onClick={() => setIsPopoverOpen(true)} ref={popoverRef} label={i18nStrings.feedbackIconLabel}>
        <Text type='b100' color='secondary'><Icon tokens={infoKnockoutTokens} /></Text>
      </Button>
      <Popover
        anchorNode={popoverRef.current}
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        position='right'
        maxWidth={300}
      >
        <Text type='b200'>{i18nStrings.yourFeedbackIsPrivate}</Text>
      </Popover>
    </React.Fragment>
  );
};

export const FeedbackSubmitted: FunctionComponent<FeedbackSubmittedViewProps> = ({
  submitMore,
  closeFeedback,
  i18nStrings,
}: FeedbackSubmittedViewProps) => {
  return (
    <Column alignmentHorizontal='center' spacingInset='400 none' data-testid='successful-submit-container'>
      <img
        src='https://m.media-amazon.com/images/G/01/courses/soju-player/icons/checkmark-circle-icon-large.png'
        alt=''
        style={{
          width: '75px',
          height: '75px'
        }}
      />
      <Text type='b200' alignment='center'>{i18nStrings.thanksForYourFeedback}</Text>
      <Row>
        <Button type='primary' size='small' onClick={submitMore}>{i18nStrings.submitMoreFeedback}</Button>
        <Button type='secondary' size='small' onClick={closeFeedback}>{i18nStrings.close}</Button>
      </Row>
    </Column>
  );
};
export const FeedbackFailedSubmit: FunctionComponent<FeedbackSubmittedViewProps> = ({
  submitMore,
  closeFeedback,
  i18nStrings,
}: FeedbackSubmittedViewProps) => {
  return (
    <Column alignmentHorizontal='center' spacingInset='400 none' data-testid='failed-submit-container'>
      <img
        src='https://m.media-amazon.com/images/G/01/courses/soju-player/icons/error-icon-light2x.png'
        alt=''
        style={{
          width: '75px',
          height: '75px'
        }}
      />
      <Text type='b200' alignment='center'>{i18nStrings.somethingWentWrong}</Text>
      <Row>
        <Button type='primary' size='small' onClick={submitMore}>{i18nStrings.tryAgain}</Button>
        <Button type='secondary' size='small' onClick={closeFeedback}>{i18nStrings.close}</Button>
      </Row>
    </Column>
  );
};
