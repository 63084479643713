/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import shaka from 'shaka-player/dist/shaka-player.ui';

/**
 * Extending Shaka UI PlayButton for adding new functionality
 */
export class MuteButton extends shaka.ui.MuteButton {

  static BUTTON_NAME = 'soju_mute_button';
  static muteTooltipMessage = 'Mute';
  static unmuteTooltipMessage = 'Unmute';

  static setMuteButtonTooltipMessages(mute: string, unmute: string) {
    this.muteTooltipMessage = mute;
    this.unmuteTooltipMessage = unmute;
  }

  button: HTMLButtonElement;

  constructor(parent: HTMLElement, controls: shaka.ui.Controls) {
    super(parent, controls);

    this.button = parent.lastChild as HTMLButtonElement;

    this.button.classList.add('shaka-tooltip');
    this.updateIcon();

    // @ts-ignore
    this.eventManager.listen(this.video, 'volumechange', () => {
      this.updateIcon();
    });
  }

  updateIcon() {
    // @ts-ignore
    if(this.video.muted) {
      this.button.classList.remove('unmute-button');
      this.button.classList.add('mute-button');
      this.button.ariaLabel = MuteButton.unmuteTooltipMessage;
    } else {
      this.button.classList.remove('mute-button');
      this.button.classList.add('unmute-button');
      this.button.ariaLabel = MuteButton.muteTooltipMessage;
    }
  }
}

export class MuteButtonFactory {
  create(rootElement: HTMLElement, controls: shaka.ui.Controls) {
    return new MuteButton(rootElement, controls);
  }
}

shaka.ui.Controls.registerElement(MuteButton.BUTTON_NAME, new MuteButtonFactory());
