import React, {FunctionComponent, useEffect, useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import {AssetUploader, AssetType, AssetDataModel, InvalidFile} from '../../asset-uploader/AssetUploader';
import {AssetMetaData} from './VideoGadget';
import {videoProcessingWorkflowStatusType} from './VideoComponent';
import {videoFileTypes} from '../../asset-uploader/Constants';
import {useCourseAuthoringContext} from '../../../context';
import Loader from '@amzn/meridian/loader';
import {AssetUploadI18nStrings, WithI18nStringsProps} from '../../../../context/course/models/I18n';

export interface VideoUploadPlaceholderProps extends WithI18nStringsProps<AssetUploadI18nStrings>{
  fileSelected: string;
  isVideoAssetCreated: boolean;
  onUploadStart: (filename: string) => void;
  onUploadFailure: (failureMessage: string) => void;
  updateAssetData: (asset: AssetDataModel) => void;
  videoMetadata: AssetMetaData | undefined;
  onRejection: (invalidFiles: InvalidFile[], supportedFileTypes: string[]) => void;
}

const isTouchSupported = 'ontouchstart' in document.documentElement;

const interactionType = isTouchSupported ? 'tap' : 'click';
const uploadMessage = `Drag file here or ${interactionType} to upload`;


export const VideoUploadPlaceholder: FunctionComponent<VideoUploadPlaceholderProps> = (
  {
    fileSelected,
    isVideoAssetCreated,
    onUploadFailure,
    onUploadStart,
    updateAssetData,
    videoMetadata,
    onRejection,
    i18nStrings
  }: VideoUploadPlaceholderProps
) => {

  const {startAssetProcessing} = useCourseAuthoringContext();

  const [isVideoFileGettingUploaded, setIsVideoFileGettingUploaded] = useState<boolean>(false);
  const [isVideoGettingProcessed, setIsVideoGettingProcessed] = useState<boolean>(false);
  const [isVideoProcessingFailed, setIsVideoProcessingFailed] = useState<boolean>(false);
  const [isVideoUploadingFailed, setIsVideoUploadingFailed] = useState<boolean>(false);

  const supportedVideoFileTypes = {'video/*': videoFileTypes};

  useEffect(() => {
    const videoProcessingWorkflowStatus = videoMetadata?.assetMetadata?.videoProcessingWorkflowStatus as string;
    setIsVideoProcessingFailed(!!(isVideoAssetCreated && videoMetadata?.assetMetadata &&
      videoProcessingWorkflowStatus?.toUpperCase() === videoProcessingWorkflowStatusType.ERROR));
  }, [isVideoAssetCreated, videoMetadata?.assetMetadata]);


  useEffect(() => {
    setIsVideoFileGettingUploaded(isEmpty(videoMetadata?.assetMetadata) && !isVideoGettingProcessed && !isEmpty(fileSelected));
  }, [videoMetadata?.assetMetadata, isVideoGettingProcessed, fileSelected]);


  useEffect(() => {
    setIsVideoGettingProcessed(isVideoAssetCreated && isEmpty(videoMetadata?.assetMetadata?.videoProcessingWorkflowStatus));
  }, [isVideoAssetCreated, videoMetadata?.assetMetadata]);

  const onUploadSuccess = async (assetData: { assetId: string, fileName: string }) => {
    await startAssetProcessing(assetData.assetId);
  };

  const onVideoUploadFailure = (invalidFiles: InvalidFile[]) => {
    setIsVideoFileGettingUploaded(false);
    setIsVideoUploadingFailed(true);
    onUploadFailure(i18nStrings.fileUploadErrorWithFileName(invalidFiles[0].file.name));
  };

  let placeHolderMessage = '';

  if (isVideoFileGettingUploaded) {
    placeHolderMessage = i18nStrings.videoUploading(fileSelected);
  } else if (isVideoUploadingFailed) {
    placeHolderMessage = i18nStrings.videoUploadError;
  } else if (isVideoProcessingFailed) {
    const errorMessage = videoMetadata?.assetMetadata?.videoProcessingWorkflowErrorMessage as string;
    placeHolderMessage = i18nStrings.videoProcessingError(fileSelected, errorMessage);
  } else if (isVideoGettingProcessed) {
    placeHolderMessage = i18nStrings.videoProcessing(fileSelected);
  }
  return (
    <>
      {!videoMetadata?.assetMetadata && !isVideoAssetCreated && !fileSelected ?
        (<AssetUploader
          supportedFileTypes={supportedVideoFileTypes}
          assetType={AssetType.VIDEO}
          onUploadStart={onUploadStart}
          onUploadFailure={onVideoUploadFailure}
          onUploadSuccess={onUploadSuccess}
          updateAssetData={updateAssetData}
          onRejection={invalidFiles => onRejection(invalidFiles, videoFileTypes)}
          i18nStrings={i18nStrings}
        >
          <Column
            alignmentHorizontal={'center'}
            alignmentVertical={'center'}
            className={'video-gadget__upload__placeholder'}>
            <Box>
              <Text>
                {uploadMessage}
              </Text>
            </Box>
          </Column>
        </AssetUploader>) :
        (<Column
          alignmentHorizontal={'center'}
          alignmentVertical={'center'}
          className={'video-gadget__upload__placeholder'}>
          <Box>
            { !isVideoProcessingFailed && !isVideoUploadingFailed && <Loader size='large' /> }
            <Text>
              {placeHolderMessage}
            </Text>
          </Box>
        </Column>)
      }
    </>
  );
};
