import {
  EMPTY_IDENTITY,
  Identity,
  IdentityFactory,
} from "../api/utils/IdentityFactory";
import { useCallback, useRef, useState } from "react";
export function useIdentity(): [Identity, (props: Identity) => void] {
  const [identity, setIdentity] = useState<Identity>(EMPTY_IDENTITY);
  const idToken = useRef<string>("");

  const setIdToken = useCallback(
    (props: Identity) => {
      if (idToken.current === props.idToken) {
        return;
      }
      idToken.current = props.idToken;
      IdentityFactory.buildIdentity(props.idToken).then(setIdentity);
    },
    [identity],
  );
  return [identity, setIdToken];
}
