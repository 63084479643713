import React, {forwardRef, FunctionComponent, useCallback, useEffect, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import size from 'lodash/size';
import {nanoid} from 'nanoid';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Textarea from '@amzn/meridian/textarea';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import Theme from '@amzn/meridian/theme';
import brandedLightTokens from '../../../../theme/branded-light';
import {QuizQuestionAuthorType, QuizQuestionCard} from './QuizQuestionCard';
import {GadgetEditorProps} from '../models';
import {QuizQuestionType} from '../../../../components/gadgets/quiz';
import GadgetContainer from '../../../../components/containers/gadget/GadgetContainer';
import {useCourseAuthoringContext} from '../../../context';
import {QuizGadgetConfigBase} from '../../../../components/gadgets/quiz/QuizGadget';
import {
  AssessmentGadgetSettings,
  AssessmentGadgetSettingsType
} from '../../assessment-gadget-settings/AssessmentGadgetSettings';
import {QuizGadgetI18nStrings} from '../../../../context/course/models/I18n';

const ITEMS_ID_SIZE = 4;

/**
 * Quiz Gadget Author configuration
 */
export interface QuizGadgetAuthorConfig extends QuizGadgetConfigBase {
  /**
   * Quiz questions
   */
  questions: QuizQuestionAuthorType[]
}

/**
 * Quiz gadget props
 */
export type QuizGadgetProps = GadgetEditorProps<QuizGadgetAuthorConfig, QuizGadgetI18nStrings>

/**
 * You can use this gadget to add quizzes to your course.
 * As of right now, the Quiz gadget only supports multiple choice questions. You can add questions that can either have single or multiselect answers.
 * The gadget uses the questions (which contain the question ID and label) provided as props to render the quiz.
 * When the learner selects and submits the answers, the learner state gets sent to the backend. The backend will evaluate the answers and return a QuizEvaluationResults with the evaluation results.
 */
export const QuizGadget: FunctionComponent<QuizGadgetProps> = forwardRef<HTMLElement | undefined, QuizGadgetProps>((
  {
    id: gadgetId,
    config,
    i18nStrings
  }: QuizGadgetProps,
  ref
) => {
  const {updateGadget} = useCourseAuthoringContext();

  const [title, setTitle] = useState<string>(config.title || '');
  const [instructions, setInstructions] = useState<string>(config.instructions || '');
  const [questions, setQuestions] = useState<QuizQuestionAuthorType[]>(config.questions || []);
  const [gadgetSettings, setGadgetSettings] = useState<AssessmentGadgetSettingsType>(config.settings);

  const addQuestion = useCallback(() => {
    setQuestions(currentQuestions => {
      return [...currentQuestions, generateEmptyQuestion()];
    });
  }, []);

  const onDeleteChallenge = useCallback((i: number) => {
    let newQuestions: QuizQuestionAuthorType[];
    if(size(questions) <= 1) {
      newQuestions = [generateEmptyQuestion()];
    } else {
      newQuestions = [...questions];
      newQuestions.splice(i, 1);
    }
    setQuestions(newQuestions);
  }, [questions]);

  const onCopyQuestion = useCallback((i: number) => {
    setQuestions(currentQuestions => {
      const clonedQuestion = cloneDeep(currentQuestions[i]);
      clonedQuestion.id = nanoid(ITEMS_ID_SIZE);
      const newQuestions = [...currentQuestions, clonedQuestion];
      return newQuestions;
    });
  }, []);

  const onUpdateQuestion = useCallback((i: number, value: QuizQuestionAuthorType) => {
    setQuestions(currentQuestions => {
      const newQuestions = [...currentQuestions];
      newQuestions[i] = value;
      return newQuestions;
    });
  }, []);

  // Update global state on config update
  useEffect(() => {
    updateGadget(gadgetId, {
      title,
      instructions,
      questions,
      settings: gadgetSettings
    } as QuizGadgetAuthorConfig);
  }, [title, instructions, questions, updateGadget, gadgetId, gadgetSettings]);

  return (
    <GadgetContainer id={gadgetId} ref={ref}>
      <Theme tokens={brandedLightTokens}>
        <div>
          <Column spacing='small' spacingInset='none none xlarge none'>
            <Input
              value={title}
              onChange={setTitle}
              type='text'
              placeholder={'Quiz title'}
              size='xlarge'
            />
            <Textarea
              value={instructions}
              onChange={setInstructions}
              label={'Quiz instructions'}
              size='medium'
              helperText={'Quiz title and instructions are optional but recommended'}
            />
          </Column>

          <Column>
            <AssessmentGadgetSettings
              settings={gadgetSettings}
              updateAssessmentGadgetSettings={setGadgetSettings}
              gadgetName={'Quiz'}
            />
          </Column>

          <Column spacing='xlarge' spacingInset='medium none'>
            {questions && questions.map((question, i) => {
              const elementKey = `${gadgetId}-question=${question.id}`;
              return (
                <QuizQuestionCard
                  key={elementKey}
                  id={elementKey}
                  question={question}
                  index={i}
                  onDeleteQuestion={() => onDeleteChallenge(i)}
                  onUpdateQuestion={(value: QuizQuestionAuthorType) => onUpdateQuestion(i, value)}
                  onCopyQuestion={() => onCopyQuestion(i)}
                  i18nStrings={i18nStrings}
                />
              );
            }
            )}
          </Column>
          <Box spacingInset={'400'}>
            <Button onClick={addQuestion}>
              <Icon tokens={plusTokens} /> Add a question
            </Button>
          </Box>
        </div>
      </Theme>
    </GadgetContainer>
  );
});

const generateEmptyQuestion = () => {
  return {
    id: nanoid(ITEMS_ID_SIZE),
    title: '',
    type: QuizQuestionType.Single,
    choices: [{
      id: nanoid(ITEMS_ID_SIZE),
      value: '',
      isCorrect: false
    }]
  };
};
QuizGadget.displayName = 'QuizGadget';
