/**
 * @description Initializes all the Shaka player configurations required for
 * streaming video
 */
import {SHAKA_PLAYER_CONFIG} from '../constants/Config';
import {MuteButton} from '../shaka/MuteButton';
import {CUSTOM_FAST_FORWARD_15_BUTTON_NAME, CUSTOM_REWIND_15_BUTTON_NAME} from '../shaka/CustomRewindButton';
import {PlayButton} from '../shaka/PlayButton';
import {CaptionsButton} from '../shaka/CaptionsButton';
import {PipButton} from '../shaka/PipButton';
import {SettingsButton} from '../shaka/SettingsButton';
import {FullscreenButton} from '../shaka/FullscreenButton';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {isIPhone} from '../VideoGadget';

export const shakaPlayerCustomConfiguration = (): object => {
  const { MANIFEST, STREAMING } = SHAKA_PLAYER_CONFIG;
  const { MANIFEST_RETRY_PARAMETERS, HLS, DASH } = MANIFEST;
  const {
    MANIFEST_TIMEOUT,
    MANIFEST_STALL_TIMEOUT,
    MANIFEST_CONNECTIONS_TIMEOUT,
    MANIFEST_MAX_ATTEMPTS,
    MANIFEST_BASE_DELAY,
    MANIFEST_BACKOFF_FACTOR,
    MANIFEST_FUZZ_FACTOR,
  } = MANIFEST_RETRY_PARAMETERS;
  const { USE_FULL_SEGMENTS_FOR_START_TIME } = HLS;
  const { IGNORE_MIN_BUFFER_TIME } = DASH;
  const { STREAMING_RETRY_PARAMETERS } = STREAMING;
  const {
    STREAMING_TIMEOUT,
    STREAMING_STALL_TIMEOUT,
    STREAMING_CONNECTIONS_TIMEOUT,
    STREAMING_MAX_ATTEMPTS,
    STREAMING_BASE_DELAY,
    STREAMING_BACKOFF_FACTOR,
    STREAMING_FUZZ_FACTOR,
  } = STREAMING_RETRY_PARAMETERS;
  return {
    manifest: {
      retryParameters: {
        timeout: MANIFEST_TIMEOUT,
        stallTimeout: MANIFEST_STALL_TIMEOUT,
        connectionTimeout: MANIFEST_CONNECTIONS_TIMEOUT,
        maxAttempts: MANIFEST_MAX_ATTEMPTS,
        baseDelay: MANIFEST_BASE_DELAY,
        backoffFactor: MANIFEST_BACKOFF_FACTOR,
        fuzzFactor: MANIFEST_FUZZ_FACTOR,
      },
      hls: {
        useFullSegmentsForStartTime: USE_FULL_SEGMENTS_FOR_START_TIME,
      },
      dash: {
        ignoreMinBufferTime: IGNORE_MIN_BUFFER_TIME
      }
    },
    streaming: {
      retryParameters: {
        timeout: STREAMING_TIMEOUT,
        stallTimeout: STREAMING_STALL_TIMEOUT,
        connectionTimeout: STREAMING_CONNECTIONS_TIMEOUT,
        maxAttempts: STREAMING_MAX_ATTEMPTS,
        baseDelay: STREAMING_BASE_DELAY,
        backoffFactor: STREAMING_BACKOFF_FACTOR,
        fuzzFactor: STREAMING_FUZZ_FACTOR,
      },
      ignoreTextStreamFailures: true,
    }
  };
};

export const shakaPlayerCustomUIConfiguration = (): object => {

  const baseUIConfig = {
    seekBarColors: {
      base: 'rgba(255,255,255,.2)',
      buffered: 'rgba(255,255,255,.4)',
      played: 'rgb(0, 179, 255)',
    },
    overflowMenuButtons: ['playback_rate'],
    enableTooltips: !(isMobileOnly || isTablet),
    enableKeyboardPlaybackControls: false,
    enableFullscreenOnRotation: false,
    keyboardSeekDistance: 15
  };

  if (isIPhone()) {
    return {
      ...baseUIConfig,
      doubleClickForFullscreen: false,
      controlPanelElements: [
        'time_and_duration',
        'spacer',
        PlayButton.BUTTON_NAME,
        'spacer',
        CaptionsButton.BUTTON_NAME,
        PipButton.BUTTON_NAME,
        SettingsButton.BUTTON_NAME
      ]
    }
  }
  return {
    ...baseUIConfig,
    controlPanelElements: [
      'time_and_duration',
      MuteButton.BUTTON_NAME,
      'volume',
      'spacer',
      CUSTOM_REWIND_15_BUTTON_NAME,
      PlayButton.BUTTON_NAME,
      CUSTOM_FAST_FORWARD_15_BUTTON_NAME,
      'spacer',
      CaptionsButton.BUTTON_NAME,
      PipButton.BUTTON_NAME,
      SettingsButton.BUTTON_NAME,
      FullscreenButton.BUTTON_NAME
    ]
  }
}
