import React, {forwardRef} from 'react';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Checkbox from '@amzn/meridian/checkbox';
import RadioButton from '@amzn/meridian/radio-button';
import Input from '@amzn/meridian/input';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import closeSmallTokens from '@amzn/meridian-tokens/base/icon/close-small';
import {QuizQuestionType} from '../../../../components/gadgets/quiz';
import {QuizQuestionChoiceAuthorType} from './QuizQuestionCard';

/**
 * Quiz Question Choice props
 */
export interface QuizQuestionChoiceEntryProps {
  /**
   * Choice base-0 index
   */
  index: number,

  /**
   * Question choice
   */
  choice: QuizQuestionChoiceAuthorType,

  type: QuizQuestionType,

  /**
   * Event handler for when a question choice is selected
   */
  onSelectChoice: () => void,

  onDeleteAnswer: () => void,

  onUpdateChoice: (value: string) => void
}

/**
 * Renders a Quiz Question Choice
 */
export const QuizQuestionChoiceEntry = forwardRef<HTMLElement | undefined, QuizQuestionChoiceEntryProps>((
  {
    index,
    choice,
    type,
    onSelectChoice,
    onDeleteAnswer,
    onUpdateChoice
  }: QuizQuestionChoiceEntryProps,
  ref
) => {
  return (
    <Row widths={['grid-10', 'grid-2']} spacing='none'>
      <Row widths={['fit', 'fill']} spacing='none'>
        {type === QuizQuestionType.Single
          ? <RadioButton
            checked={choice.isCorrect}
            onChange={onSelectChoice}
          />
          : <Box spacingInset='none medium none none'>
            <Checkbox
              checked={choice.isCorrect}
              onChange={onSelectChoice}
            />
          </Box>
        }
        <Input
          ref={ref}
          onChange={onUpdateChoice}
          placeholder={'add answer'}
          value={choice.value}
        />
      </Row>
      <Box>
        <Button type='icon' onClick={onDeleteAnswer}>
          <Icon tokens={closeSmallTokens} />
        </Button>
      </Box>
    </Row>
  );
});

QuizQuestionChoiceEntry.displayName = 'QuizQuestionChoiceEntry';
