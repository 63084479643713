import React, {forwardRef, memo} from 'react';
import Box from '@amzn/meridian/box';
import Responsive from '@amzn/meridian/responsive';
import {isEqual} from 'lodash';
import './styles/GadgetContainer.scss';
import responsiveStyles from '../../../styles/responsive.module.scss';

export interface GadgetContainerProps {
  /**
   * gadget id
   */
  id: string,

  width?: string,

  /**
   * the actual gadget to render in the container.
   */
  children: React.ReactNode
}

/**
 * Renders a gadget inside the canvas.
 *
 * This function uses memo to avoid re-rendering gadgets if their state hasn't changed
 */
const GadgetContainer = forwardRef<HTMLElement | undefined, GadgetContainerProps>((props: GadgetContainerProps, ref) => {
  const {
    id,
    width = '673px',
    children
  } = props;

  return (
    <Responsive
      query='max-width'
      props={{
        maxWidth: {
          default: '100%',
          [responsiveStyles.tablet_breakpoint]: 'none'
        },
        containerWidth: {
          default: width,
          [responsiveStyles.tablet_breakpoint]: '100%'
        }
      }}
    >
      {props => 
        <Box
          ref={ref}
          tabIndex='-1'
          data-gadget-id={id}
          type='outline'
          spacingInset='450'
          className='gadget__content'
          backgroundColor='primary'
          width={props.containerWidth}
          maxWidth={props.maxWidth}
        >
          {children}
        </Box>
      }
    </Responsive>
  );
});

GadgetContainer.displayName = 'GadgetContainer';

export default memo(GadgetContainer, (prevProps: Readonly<GadgetContainerProps>, nextProps: Readonly<GadgetContainerProps>) => {
  return isEqual(prevProps, nextProps);
});
