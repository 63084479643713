import { debounce, forEach, set } from "lodash";
import {
  AssetModel,
  AssetType,
  CourseApi,
  CourseLearnerState,
  CoursePosition,
  CourseProgressUpdateResponse,
  DisplaySection,
  GadgetLearnerState,
  ProgressStatus,
  VideoAssetMetadata,
} from "@amzn/soju-ui-framework";
import { CourseStatusUpdateResponse } from "@amzn/soju-ui-framework/dist/umd/context/course";
import {
  BaseCoursePlayerApi,
  LEARNER_SESSION_HEADER_NAME,
  SOJU_CUSTOMER_HEADER_NAME,
} from "./BaseCoursePlayerApi";
import { CloudLabEmbedInfo } from "@amzn/soju-ui-framework/dist/umd/components/gadgets/lab/models/CloudLabEmbedInfo";
import { API, graphqlOperation } from "@aws-amplify/api";
import { getAsset, getLearnerState } from "../graphql/queries";
import {
  updateCoursePosition,
  updateCourseStatus,
  updateGadgetLearnerState,
  updateLessonStatus,
} from "../graphql/mutations";
import { Asset, LearnerState } from "../graphql/model";
import { StringToRecordConverter } from "./utils/StringToRecordConverter";
import { parseStringToDate } from "./utils/parseStringToDate";

export class SojuCourseApi extends BaseCoursePlayerApi implements CourseApi {
  async loadLearnerState(courseId: string): Promise<CourseLearnerState> {
    const response = (await API.graphql(
      graphqlOperation(getLearnerState, {
        request: {
          courseId: courseId,
        },
      }),
      {
        ...this.getHeaders(),
      },
    )) as {
      data: {
        getLearnerState: LearnerState;
      };
      error: object;
    };

    let progressStatus: ProgressStatus;
    switch (response.data.getLearnerState.courseProgressInPercentage) {
      case 0:
        progressStatus = ProgressStatus.NOT_STARTED;
        break;
      case 100:
        progressStatus = ProgressStatus.COMPLETED;
        break;
      default:
        progressStatus = ProgressStatus.IN_PROGRESS;
    }

    const courseLearnerState: CourseLearnerState = {
      updatedAt: parseStringToDate(
        response.data.getLearnerState.updatedAt,
        new Date(),
      ),
      coursePosition: {
        displaySection: DisplaySection.LESSON,
        lessonId:
          response.data.getLearnerState.coursePosition?.currentLessonId || "",
        gadgetId:
          response.data.getLearnerState.coursePosition?.currentGadgetId || "",
        updatedAt: parseStringToDate(
          response.data.getLearnerState.coursePosition?.updatedAt,
          new Date(),
        ),
      },
      courseProgress: {
        status: progressStatus,
        completionPercentage:
          response.data.getLearnerState.courseProgressInPercentage || 0,
        lessonStatusById: StringToRecordConverter.convert(
          response.data.getLearnerState.lessonStatusById,
        ),
      },
      gadgetsState: StringToRecordConverter.convert(
        response.data.getLearnerState.gadgetStates,
      ) as Record<string, any>,
      gadgetTypes: StringToRecordConverter.convert(
        response.data.getLearnerState.gadgetTypes,
      ) as Record<string, any>,
    };
    const updatedGadgetsState = courseLearnerState.gadgetsState || {};
    const gadgetTypes = courseLearnerState.gadgetTypes;
    if (courseLearnerState.gadgetsState) {
      forEach(
        courseLearnerState.gadgetsState,
        (learnerState: GadgetLearnerState, gadgetId: string) => {
          const gadgetType = gadgetTypes?.[gadgetId];
          let updatedLearnerState = learnerState;
          if (
            gadgetType &&
            this.converterRegistry.shouldConvertGadgetType(gadgetType)
          ) {
            updatedLearnerState = this.converterRegistry.toGadgetLearnerState(
              gadgetType,
              learnerState,
            );
          }

          set(updatedGadgetsState, gadgetId, updatedLearnerState);
        },
      );
    }

    courseLearnerState.gadgetsState = updatedGadgetsState;

    return {
      ...courseLearnerState,
      gadgetsState: updatedGadgetsState,
    };
  }

  debounceSetCoursePosition = debounce((courseId, position) => {
    return API.graphql(
      graphqlOperation(updateCoursePosition, {
        request: {
          courseId: courseId,
          displaySection: position.displaySection,
          currentLessonId: position.lessonId,
          currentGadgetId: position.gadgetId,
        },
      }),
      {
        ...this.getHeaders(),
      },
    );
  }, 1000);

  async setCoursePosition(
    courseId: string,
    position: CoursePosition,
  ): Promise<void> {
    await this.debounceSetCoursePosition(courseId, position);
  }

  async setCourseStatus(
    courseId: string,
    status: ProgressStatus,
    certificateId: string,
  ): Promise<CourseStatusUpdateResponse> {
    const response = (await API.graphql(
      graphqlOperation(updateCourseStatus, {
        request: {
          courseId: courseId,
          status: status,
          externalCertificateMetadata: JSON.stringify({
            certificateId: certificateId,
          }),
        },
      }),
      {
        ...this.getHeaders(),
      },
    )) as {
      data: {
        updateCourseStatus: CourseStatusUpdateResponse;
      };
    };
    return response.data.updateCourseStatus;
  }

  async setLessonStatus(
    courseId: string,
    lessonId: string,
    status: ProgressStatus,
  ): Promise<CourseProgressUpdateResponse> {
    const response = (await API.graphql(
      graphqlOperation(updateLessonStatus, {
        request: {
          courseDraftId: courseId,
          lessonId: lessonId,
          progress: status,
        },
      }),
      {
        ...this.getHeaders(),
      },
    )) as {
      data: {
        updateLessonStatus: CourseProgressUpdateResponse;
      };
    };
    return response.data.updateLessonStatus;
  }

  async setGadgetLearnerState<T extends GadgetLearnerState>(
    courseId: string,
    gadgetId: string,
    gadgetType: string,
    learnerState: T,
  ): Promise<T> {
    const shouldConvert =
      this.converterRegistry.shouldConvertGadgetType(gadgetType);

    let convertedState = learnerState;
    if (shouldConvert) {
      convertedState = this.converterRegistry.fromGadgetLearnerState(
        gadgetType,
        learnerState,
      );
    }

    const response = (await API.graphql(
      graphqlOperation(updateGadgetLearnerState, {
        request: {
          courseDraftId: courseId,
          gadgetId: gadgetId,
          ...convertedState,
        },
      }),
      {
        ...this.getHeaders(),
      },
    )) as {
      data;
    };

    let convertedGadgetLearnerState: T = response.data.updateGadgetLearnerState;
    if (shouldConvert) {
      convertedGadgetLearnerState = this.converterRegistry.toGadgetLearnerState(
        gadgetType,
        convertedGadgetLearnerState,
      );
    }

    return convertedGadgetLearnerState;
  }

  submitGadgetLearnerStateAssessment<T extends GadgetLearnerState>(
    courseId: string,
    gadgetId: string,
    gadgetType: string,
    learnerState: T,
  ): Promise<T> {
    const submitLearnerState = {
      ...learnerState,
      submit: true,
    };
    return this.setGadgetLearnerState(
      courseId,
      gadgetId,
      gadgetType,
      submitLearnerState,
    );
  }

  async getAssetModel(courseId: string, assetId: string): Promise<AssetModel> {
    const response = (await API.graphql(
      graphqlOperation(getAsset, {
        request: {
          assetId: assetId,
          isDraft: false,
        },
      }),
      {
        ...this.getHeaders(),
      },
    )) as unknown as {
      data: {
        getAsset: Asset;
      };
      error: object;
    };
    const assetModel: AssetModel = {
      id: response.data.getAsset.id || "",
      title: response.data.getAsset.title || undefined,
      size: response.data.getAsset.size || 0,
      type: response.data.getAsset.type as AssetType,
      contentType: response.data.getAsset.contentType || "",
      location: response.data.getAsset.location || undefined,
      status: response.data.getAsset.status as
        | "NEW"
        | "AVAILABLE"
        | "NOT_READY"
        | "THREAT_DETECTED"
        | "ERROR",
      tags: (response.data.getAsset.tags as string[]) || undefined,
      assetMetadata: StringToRecordConverter.convert(
        response.data.getAsset.assetMetadata,
      ),
    };
    if (assetModel.type === "VIDEO") {
      assetModel.assetMetadata = assetModel.assetMetadata
        ? this.toVideoAssetMetadata(
            assetId,
            assetModel.assetMetadata,
            "/api/catalogs/staged/courses/drm_license",
          )
        : {};
    }
    return assetModel;
  }

  async submitLearnerFeedback(): Promise<void> {
    return Promise.resolve(undefined);
  }

  /**
   * Convert Soju video asset metadata to the Gadget expected metadata
   * @param assetId asset id
   * @param metadata metadata as stored in Soju
   * @param drmLicenseUrl drm license URL
   * @private
   */
  private toVideoAssetMetadata(
    assetId: string,
    metadata: Record<string, unknown>,
    drmLicenseUrl: string,
  ): VideoAssetMetadata {
    return {
      hlsEgressEndpoint: metadata.hlsEgressEndpoint
        ? metadata.hlsEgressEndpoint
        : metadata.hlsUrl,
      dashEgressEndpoint: metadata.dashEgressEndpoint
        ? metadata.dashEgressEndpoint
        : metadata.hlsUrl,
      drmLicenseUrl: drmLicenseUrl,
      getDrmRequest: (
        drmType: string,
        licenseChallenge: string,
        contentId: string,
      ) => {
        const wrapped: Record<string, any> = {};
        wrapped.drmType = drmType;
        wrapped.licenseChallenge = licenseChallenge;
        wrapped.assetId = assetId;
        wrapped.contentId = contentId;
        return {
          headers: {
            Accept: drmType === "FAIRPLAY" ? "text/plain" : "application/json",
            [LEARNER_SESSION_HEADER_NAME]: this.learnerSession.learnerToken,
            [SOJU_CUSTOMER_HEADER_NAME]: this.learnerSession.resourceId,
            "Content-Type": "application/json",
            "cache-control": "no-cache",
            pragma: "no-cache",
          },
          body: JSON.stringify(wrapped),
        };
      },
    } as VideoAssetMetadata;
  }

  getCloudLabEmbedInfo(
    courseId: string,
    gadgetId: string,
  ): Promise<CloudLabEmbedInfo> {
    throw new Error("Not Implemented");
  }
}
