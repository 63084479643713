import React, {FunctionComponent, useState, useEffect} from 'react';
import cx from 'classnames';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import Expander from '@amzn/meridian/expander';
import closeKnockoutTokens from '@amzn/meridian-tokens/base/icon/close-knockout';
import checkKnockoutTokens from '@amzn/meridian-tokens/base/icon/check-knockout';
import {QuizQuestion, QuizQuestionChoice, QuizQuestionType} from './QuizGadget';
import {getAnswerLetterFromIndex} from './helpers';
import {QuizQuestionChoiceEntry} from './QuizQuestionChoiceEntry';
import {Evaluation} from '../../../context/course/models/Evaluation';
import {AssetModel, useCourseContext} from '../../../context/course';

import './styles/assessments.scss';
import './styles/quiz.scss';
import { QuizGadgetI18nStrings, WithI18nStringsProps } from '../../../context/course/models/I18n';
import {ImageWithLightbox} from '../../image/ImageWithLightbox';

/**
 * Quiz Question Card props
 */
export interface QuizQuestionCardProps extends QuizQuestion, WithI18nStringsProps<QuizGadgetI18nStrings> {

  /**
   * gadget id, for unique keys and html ids
   */
  gadgetId: string,

  /**
   * Currently selected choices
   */
  selectedChoices: string[],

  /**
   * Whether or not the question has been graded
   */
  isGraded: boolean,

  /**
   * Optional question evaluation. If provided, the question will be in a 'graded' state
   */
  questionEvaluation?: Evaluation,

  /**
   * Optional correct choices response.
   */
  correctChoices?: string[]

  /**
   * Event handler for when a question choice is selected
   */
  onSelectChoice: (type: QuizQuestionType, questionId: string, choiceId: string) => void

  /**
   * Flag to hide answers once quiz is evaluated
   */
  shouldShowAnswers: boolean,
}

interface QuizQuestionExplanationProps extends WithI18nStringsProps<QuizGadgetI18nStrings> {
  gadgetId: string,
  questionId: string,
  selectedChoiceIds: string[],
  correctChoiceIds: string[],
  choices: QuizQuestionChoice[],
}

const trailingDoublePeriodRegex = new RegExp('\\.\\.$');

const QuizQuestionExplanation: FunctionComponent<QuizQuestionExplanationProps> = ({
  gadgetId,
  questionId,
  selectedChoiceIds,
  correctChoiceIds,
  choices,
  i18nStrings,
}) => {
  const answerIdToIndexMap = choices.reduce((acc: Record<string, number>, choice, index) => {
    acc[choice.id] = index;
    return acc;
  }, {});
  const learnerAnswerIndices = selectedChoiceIds.map(choiceId => answerIdToIndexMap[choiceId]);
  const answerKeyAnswerIndices = correctChoiceIds.map(choiceId => answerIdToIndexMap[choiceId]);

  const learnerAnswerStrings = learnerAnswerIndices.map(index => `(${getAnswerLetterFromIndex(index)}) ${choices[index].value}`);
  const answerKeyAnswerStrings = answerKeyAnswerIndices.map(index => `(${getAnswerLetterFromIndex(index)}) ${choices[index].value}`);

  const noCorrectAnswers = answerKeyAnswerStrings.length === 0;
  const noLearnerAnswers = learnerAnswerStrings.length === 0;

  const oneCorrectAnswer = answerKeyAnswerStrings.length === 1;
  const oneLearnerAnswer = learnerAnswerStrings.length === 1;

  const multipleCorrectAnswers = answerKeyAnswerStrings.length > 1;
  const multipleLearnerAnswers = learnerAnswerStrings.length > 1;

  return <React.Fragment>
    <div className='quiz_gadget_answer_explanation_container'>
      <div className='quiz_gadget_answer_explanation_heading'>
        <Text>
          {noCorrectAnswers && i18nStrings.noCorrectAnswers}
          {oneCorrectAnswer && i18nStrings.oneCorrectAnswer(answerKeyAnswerStrings[0].trim()).replace(trailingDoublePeriodRegex, '.')}
          {multipleCorrectAnswers && i18nStrings.multipleCorrectAnswers}
        </Text>
      </div>
      {!oneCorrectAnswer && <div className='quiz_gadget_answer_explanation_list'>
        <Text tag='ul'>
          {answerKeyAnswerStrings.map((correctAnswer, index) => <li
            key={`${gadgetId}-${questionId}-correct-${index}`}>{correctAnswer}</li>)}
        </Text>
      </div>}
    </div>
    <div className='quiz_gadget_answer_explanation_container'>
      <div className='quiz_gadget_answer_explanation_heading'>
        <Text>
          {noLearnerAnswers && i18nStrings.noLearnerAnswers}
          {oneLearnerAnswer && i18nStrings.oneLearnerAnswer(learnerAnswerStrings[0].trim()).replace(trailingDoublePeriodRegex, '.')}
          {multipleLearnerAnswers && i18nStrings.multipleLearnerAnswers}
        </Text>
      </div>
      {!oneLearnerAnswer && <div className='quiz_gadget_answer_explanation_list'>
        <Text tag='ul'>
          {learnerAnswerStrings.map((learnerAnswer, index) => <li
            key={`${gadgetId}-${questionId}-selected-${index}`}>{learnerAnswer}</li>)}
        </Text>
      </div>}
    </div>
  </React.Fragment>;
};

type RefType = HTMLElement | undefined;

/**
 * Renders a Quiz question
 */
export const QuizQuestionCard = React.forwardRef<RefType, QuizQuestionCardProps>(
  (props: QuizQuestionCardProps, ref) => {
    const {
      gadgetId,
      id,
      type,
      title,
      image,
      choices,
      selectedChoices,
      isGraded,
      questionEvaluation,
      correctChoices,
      onSelectChoice,
      shouldShowAnswers,
      i18nStrings,
    } = props;

    const isCorrect = questionEvaluation === Evaluation.Correct;
    const questionTitleStyles = cx(
      'AssessmentGadgetQuestion__title',
      isGraded && isCorrect && 'AssessmentGadgetQuestion__title--passed',
      isGraded && !isCorrect && 'AssessmentGadgetQuestion__title--failed'
    );
    const [showExplanation, setShowExplanation] = useState(false);
    const [mediaAsset, setMediaAsset] = useState<AssetModel>();
    const {
      getAssetModel
    } = useCourseContext();

    // Call getAssetModel API to fetch asset ID location for the question's image
    useEffect(() => {
      (async () => {
        if(image) {
          const fullMediaAsset = await getAssetModel(image.id);
          setMediaAsset(fullMediaAsset);
        }
      })();
    }, [image, getAssetModel]);

    return (
      <fieldset className='AssessmentGadgetQuestion' name={`${gadgetId}-question-${id}`}>
        <legend className={questionTitleStyles}>
          <div className='AssessmentGadgetQuestion__title-main'>
            <div className='AssessmentGadgetQuestion__title-main-text'>
              <Heading ref={ref} tabIndex='-1' type='h300' level={4}>
                {title}
              </Heading>
              {type === QuizQuestionType.Multiselect &&
              <div className='AssessmentGadgetQuestion__help-text'>
                <Text color='secondary' type='h100'>{i18nStrings.selectCorrectAnswers}</Text>
              </div>}
            </div>
            {isGraded &&
            <Icon
              className='AssessmentGadgetQuestion__title-icon'
              tokens={isCorrect ? checkKnockoutTokens : closeKnockoutTokens}>
              {isCorrect ? i18nStrings.correct : i18nStrings.incorrect}
            </Icon>
            }
          </div>

          {isGraded && !isCorrect && shouldShowAnswers &&
          <Expander open={showExplanation} onChange={setShowExplanation} title={i18nStrings.whyWrongAnswer} type='list'>
            <QuizQuestionExplanation
              gadgetId={gadgetId}
              questionId={id}
              selectedChoiceIds={selectedChoices}
              correctChoiceIds={correctChoices || []}
              choices={choices}
              i18nStrings={i18nStrings}
            />
          </Expander>}
        </legend>
        {mediaAsset?.location && <div className='AssessmentGadgetQuestion__image'>
          <ImageWithLightbox
            id={mediaAsset.id}
            image={{
              src: mediaAsset.location,
              alt: mediaAsset.assetMetadata?.altText as string
            }}
            i18nStrings={i18nStrings.imageLightbox} />
        </div>}

        <div className='quiz_gadget_answers_container'>
          {choices?.map((choice: QuizQuestionChoice, index: number) =>
            <QuizQuestionChoiceEntry
              key={choice.id}
              index={index}
              gadgetId={gadgetId}
              choice={choice}
              question={{id, type, isGraded}}
              isSelected={selectedChoices.indexOf(choice.id) >= 0}
              isCorrectChoice={correctChoices ? correctChoices.indexOf(choice.id) >= 0 : false}
              onSelectChoice={onSelectChoice}
              shouldShowAnswers={shouldShowAnswers}
            />
          )}
        </div>
      </fieldset>
    );

  }
);
QuizQuestionCard.displayName = 'QuizQuestionCard';
