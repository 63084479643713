import React, {FunctionComponent} from 'react';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';

import Modal, { ModalFooter } from '@amzn/meridian/modal';
import AceEditor from 'react-ace';


export type ScriptModalProps = {
  isModalOpen: boolean,
  onChange: (content: string) => void,
  onClose: () => void,
  content?: string,
  title?: string
};

export const ScriptModal: FunctionComponent<ScriptModalProps> = (
  {
    isModalOpen,
    onClose,
    onChange,
    content,
    title
  }: ScriptModalProps
) => {

  const [editorValue, setEditorValue] = React.useState<string | undefined>(content);
  const updateContent = (newContent: string) => {
    setEditorValue(newContent);
    onChange(newContent);
  };

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onClose={onClose}
      scrollContainer='viewport'
      closeLabel='Close'
      describedById='modal-description'
      width={'800px'}
    >
      <AceEditor
        width={'100%'}
        mode='json'
        theme='tomorrow'
        minLines={10}
        maxLines={Infinity}
        name='LabScript'
        onChange={updateContent}
        fontSize={14}
        showPrintMargin={false}
        showGutter={true}
        highlightActiveLine={false}
        wrapEnabled={true}
        value={editorValue}
        setOptions={{
          minLines: 10,
          maxLines: Infinity,
          showLineNumbers: true
        }}
      />
      <ModalFooter>
        <Row alignmentHorizontal='right' widths='fit'>
          <Button type='primary' size='small' onClick={onClose}>
            Close
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};
