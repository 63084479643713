import React, {FunctionComponent, useState, ReactNode} from 'react';
import Theme from '@amzn/meridian/theme';
import Modal, {ModalFooter} from '@amzn/meridian/modal';
import Column from '@amzn/meridian/column';
import Responsive from '@amzn/meridian/responsive';
import Row from '@amzn/meridian/row';
import Checkbox from '@amzn/meridian/checkbox';
import Button from '@amzn/meridian/button';
import {brandedLightTokens} from '../../theme';
import {isEmpty} from 'lodash';
import {LinkWarningI18nStrings, WithI18nStringsProps} from '../../context/course/models/I18n';
import responsiveStyles from '../../styles/responsive.module.scss';

export interface DownloadLinkMetadata {
  fileName?: string;
  fileSize?: string;
}

export interface LinkWarningModalProps extends WithI18nStringsProps<LinkWarningI18nStrings> {
  open: boolean;
  onClose: () => void;
  href?: string;
  title: string;
  proceedButtonTitle: string;
  setHideFutureWarningsSession: (hideFutureDownloadWarningsSession: boolean) => void;
  children: ReactNode
}

export const LinkWarningModal: FunctionComponent<LinkWarningModalProps> = ({
  open,
  onClose,
  title,
  proceedButtonTitle,
  href,
  children,
  setHideFutureWarningsSession,
  i18nStrings
}) => {
  const [hideFutureWarningsChecked, setHideFutureWarningsChecked] = useState(false);

  const onAccept = () => {
    setHideFutureWarningsSession(hideFutureWarningsChecked);
    setHideFutureWarningsChecked(false);
    href && window.open(href, '_blank');
    onClose();
  };

  const onCancel = () => {
    // Our current thinking is that canceling this alert shouldn't have any side effects,
    // so it resets the checkbox status to unchecked if the user cancels
    setHideFutureWarningsChecked(false);
    onClose();
  };

  return <Responsive
    query='max-width'
    props={{
      buttonProps: {
        default: undefined,
        [responsiveStyles.tablet_breakpoint]: {minWidth: '100%'}
      },
      wrapper: {
        default: Row,
        [responsiveStyles.tablet_breakpoint]: Column
      },
      wrapperProps: {
        default: {reverse: true, alignmentHorizontal: 'right'},
        [responsiveStyles.tablet_breakpoint]: undefined
      }
    }}
  >
    {props =>
      <Theme tokens={{...brandedLightTokens}}>
        <Modal
          open={!isEmpty(href) && open}
          onClose={onClose}
          title={title}
          closeLabel={i18nStrings.closeButtonLabel}
          width='750px'
        >
          <Column spacing='400'>
            {children}
          </Column>
          <ModalFooter>
            <props.wrapper widths={['fill', 'fit', 'fit']}>
              <Checkbox onChange={setHideFutureWarningsChecked} checked={hideFutureWarningsChecked}>
                {i18nStrings.dontShowAgainLabel}
              </Checkbox>
              <props.wrapper {...props.wrapperProps}>
                <Button onClick={onAccept} {...props.buttonProps}>
                  {proceedButtonTitle}
                </Button>
                <Button type='secondary' onClick={onCancel} {...props.buttonProps}>
                  {i18nStrings.cancelButton}
                </Button>
              </props.wrapper>
            </props.wrapper>
          </ModalFooter>
        </Modal>
      </Theme>}
  </Responsive>;
};
