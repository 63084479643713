import React, {forwardRef} from 'react';
import { Quill } from 'react-quill';
import {DeltaOperation} from 'quill';
import Box from '@amzn/meridian/box';
import Heading from '@amzn/meridian/heading';
import Theme from '@amzn/meridian/theme';
import brandedLightTokens from '../../../theme/branded-light';
import {GadgetProps} from '../models';
import {GadgetConfig, GadgetLearnerState, GadgetType} from '../../../context/course';
import GadgetContainer from '../../containers/gadget/GadgetContainer';
import {defaultI18nStrings, ReadingGadgetI18nStrings} from '../../../context/course/models/I18n';
import {CustomReactQuill} from '../../quill/CustomReactQuill';
import 'react-quill/dist/quill.snow.css';
import './styles/reading-gadget.scss';

const Delta = Quill.import('delta');

export interface ReadingGadgetConfig extends GadgetConfig {
  body: {
    ops: DeltaOperation[]
  }
}

export type ReadingGadgetProps = GadgetProps<ReadingGadgetConfig, GadgetLearnerState, ReadingGadgetI18nStrings>

/**
 * You can use this gadget to add reading sections to your course.
 * The content will be generated by a WYSIWYG text editor, which allows the author to add basic formatting to the text, as well as media assets (only images supported).
 * The body of the gadget is a Quill Delta object: https://quilljs.com/docs/delta/
 */
export const ReadingGadget = forwardRef<HTMLElement | undefined, ReadingGadgetProps>((props: ReadingGadgetProps, ref) => {
  const {
    id,
    config: {title, body},
    i18nStrings = defaultI18nStrings.gadgets[GadgetType.READING]
  } = props;
  return (
    <GadgetContainer ref={ref} id={id}>
      <Theme tokens={brandedLightTokens}>
        <>
          <Box className='reading-gadget-container'>
            <div className='reading-gadget-title'>
              <Heading level={3}>{title}</Heading>
            </div>
            <CustomReactQuill value={new Delta(body?.ops || [])} i18nStrings={i18nStrings ? {
              imageLightbox: i18nStrings?.imageLightbox,
              linkWarning: i18nStrings?.linkWarning
            } : undefined} />
          </Box>
        </>
      </Theme>
    </GadgetContainer>
  );
});
ReadingGadget.displayName = 'ReadingGadget';
