import React, {FunctionComponent, useEffect, useState, useRef} from 'react';
import isEmpty from 'lodash/isEmpty';
import Alert from '@amzn/meridian/alert';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import Theme from '@amzn/meridian/theme';
import Loader from '@amzn/meridian/loader';
import {brandedLightTokens} from '../../../../theme';
import {LabIcon} from '../../../../icons';
import {LabsGadgetConfig} from './models';

import {useCourseAuthoringContext} from '../../../context';
import {CloudLabStatus} from '../../../../context/course';
import './styles/lab-authoring-preview.scss';

export type LabPreviewProps = {
  id: string;
  config: LabsGadgetConfig
};

export const LabPreview: FunctionComponent<LabPreviewProps> = (
  {
    id: gadgetId,
    config
  }: LabPreviewProps
) => {
  const {title, challenges, teaser} = config;

  const [labBuildStatus, setLabBuildStatus] = useState<CloudLabStatus>(CloudLabStatus.NOT_BUILT);
  const timerRef = useRef<number | undefined>();
  const { enterGadgetFullEditMode, getCloudLabInfo } = useCourseAuthoringContext();

  const getLabStatus = async () => {
    const response = await getCloudLabInfo(gadgetId);
    setLabBuildStatus(response.labStatus);

    // If lab hasn't finished building, keep polling backend
    if(response.labStatus !== CloudLabStatus.BUILT) {
      timerRef.current = window.setTimeout(() => getLabStatus(), 5000);
    }
  };

  useEffect( () => {
    if(config.isSubmit) {
      getLabStatus();
    }
    return () => clearTimeout(timerRef.current);
  }, [config.isSubmit]);

  const onShowTrackSetup = () => {
    enterGadgetFullEditMode(gadgetId);
  };

  if (isEmpty(challenges)) {
    return (
      <Column>
        <Heading level={3}>Untitled Hands-on Experience</Heading>
        <Alert
          type='warning'
          title='Hands-on experience not configured'
        >
          Configure to setup type, environment scripts, and exercises
        </Alert>
        <Row alignmentHorizontal='right'>
          <Button data-testid='labGadget-editTrack' onClick={onShowTrackSetup}>Setup hands-on experience</Button>
        </Row>
      </Column>
    )
  }

  const totalTimeToComplete = challenges?.reduce((total, challenge) => (total + challenge.timeToCompleteInMinutes), 0) || 0;
  const isBuildInProgress = labBuildStatus !== CloudLabStatus.BUILT;

  return (
    <Theme tokens={brandedLightTokens}>
      <div className='LabAuthoringPreview'>
        <div className='LabAuthoringPreview__header'>
          <Row widths={['fill', 'fit']}>
            <Row spacing='400'>
              <LabIcon />
              <Text type='h100'>Hands-on Activity</Text>
            </Row>
            {
              isBuildInProgress &&
              <Row>
                <Loader size="small" />
                <Text type='b200'>Building activity, please wait.</Text>
              </Row>
            }
          </Row>
        </div>
        {isBuildInProgress && <div className='LabAuthoringPreview__build-in-progress-overlay'></div>}
        <Column spacing='500'>
          <Column>
            <Heading level={3}>{title}</Heading>
          </Column>
          <Column>
            <Text>{teaser}</Text>
            <Row widths={['fill', 'fit']}>
              <Column spacing='none'>
                <Text>{challenges?.length} exercises</Text>
                <Text><strong>Total time: {totalTimeToComplete} minutes</strong></Text>
              </Column>
              <Button data-testid='labGadget-editTrack' type='tertiary' onClick={onShowTrackSetup}>Edit hands-on experience</Button>
            </Row>
          </Column>
        </Column>
        <hr aria-hidden className='LabAuthoringPreview__divider' />
        <div>
          <ol className='LabAuthoringPreview__challenge-list'>
            {challenges?.map((challenge, index) => {
              return <li key={`${gadgetId}-challenge-${challenge.id}`} className='LabAuthoringPreview__challenge-list-item'>
                <Row alignmentVertical='top' spacing='500'>
                  <span className='LabAuthoringPreview__challenge-list-item-number' aria-hidden>{index + 1}</span>
                  <div className='LabAuthoringPreview__challenge-list-item-details'>
                    <Column spacing='300'>
                      <Text type='h200'>{challenge.title}</Text>
                      <Text>{challenge.teaser}</Text>
                      <Text color='secondary'>{challenge.timeToCompleteInMinutes} minutes</Text>
                    </Column>
                  </div>
                </Row>
              </li>;
            })}
          </ol>
        </div>
      </div>
    </Theme>
  );
};