import React, {FunctionComponent, useEffect, useState} from 'react';
import {ImageComponentProps} from '../models/ImageComponentProps';
import {ASSET_ID_REGEX} from './Constants';
import Row from '@amzn/meridian/row';
import Loader from '@amzn/meridian/loader';
import {ImageWithLightbox} from '../../image/ImageWithLightbox';
import {ImageI18nStrings, WithI18nStringsProps} from '../../../context/course/models/I18n';
import {useCourseContext} from '../../../context/course';

import './styles/custom-image.scss';

export interface CustomImageComponentProps extends WithI18nStringsProps<ImageI18nStrings> {
  image: ImageComponentProps
}

const CustomImageComponent: FunctionComponent<CustomImageComponentProps> = ({
  image: {src, alt},
  i18nStrings
}) => {
  const {getAssetModel} = useCourseContext();

  const [image, setImage] = useState<{id?: string, location?: string}>();

  useEffect(() => {
    (async () => {
      if (src && ASSET_ID_REGEX.test(src)) {
        const assetId = src.replace('asset/', '');
        try {
          const assetModel = await getAssetModel(assetId);
          setImage({
            id: assetModel?.id,
            location: assetModel?.location
          });
        } catch (e) {
          setImage({
            id: undefined,
            location: undefined
          });
        }
      } else {
        setImage({
          id: undefined,
          location: undefined
        });
      }
    })();
  }, [getAssetModel, src]);

  return (
    <div className={'markdown-image'}>
      {image === undefined ? (
        <Row alignmentHorizontal={'center'}>
          <Loader/>
        </Row>
      ) : (
        (image.location && image.id)
          ? (
            <div className='markdown-image__image'>
              <ImageWithLightbox
                id={image.id}
                image={{src: image.location, alt: alt || ''}}
                i18nStrings={i18nStrings}
              />
            </div>
          )
          // render broken image
          : (
            <img
              src={''}
              alt={alt || ''}
            />
          )
      )}
    </div>
  );
};

export default CustomImageComponent;
