export enum Stage {
  "dev" = "dev",
  "dev-dub" = "dev-dub",
  "alpha-iad" = "alpha-iad",
  "alpha-dub" = "alpha-dub",
  "beta-iad" = "beta-iad",
  "beta-dub" = "beta-dub",
  "gamma-iad" = "gamma-iad",
  "gamma-dub" = "gamma-dub",
  "prod-iad" = "prod-iad",
  "prod-dub" = "prod-dub",
}
