/**
 * Some Activity Types can be found here - https://registry.tincanapi.com/#home/activityTypes
 */
export enum LearnerObject {
  COURSE = 'COURSE',
  LESSON = 'LESSON',
  ASSESSMENT = 'ASSESSMENT',
  QUESTION = 'QUESTION',
  SLIDE = 'SLIDE',
}
