import React, {FunctionComponent} from 'react';
import Modal from '@amzn/meridian/modal';
import {Image} from './Image';
import Row from '@amzn/meridian/row';
import {
  defaultImageI18nStrings,
  ImageI18nStrings,
  WithI18nStringsProps
} from '../../context/course/models/I18n';
import {isEmpty} from 'lodash';

export type ImageAttributes = {
  src: string;
  alt?: string;
}

export interface ImageLightboxProps extends WithI18nStringsProps<ImageI18nStrings> {
  open: boolean
  image?: ImageAttributes;
  onClose: () => void;
}

export const ImageLightbox: FunctionComponent<ImageLightboxProps> = ({
  open,
  image,
  onClose,
  i18nStrings = defaultImageI18nStrings
}) => {
  return <Modal
    scrollContainer='modal'
    open={!isEmpty(image) && open}
    bodySpacingInset='500'
    onClose={onClose}
    width='100vw'
    closeLabel={i18nStrings.lightboxCloseButtonLabel}
  >
    <Row alignmentHorizontal='center' maxHeight='70vh'>
      {image && <Image {...image} fill />}
    </Row>
  </Modal>;
};
