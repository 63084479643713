import {FunctionComponent, useEffect, useState} from 'react';
import {useCourseContext} from '../../context/course';
import {usePageVisibility} from '../../hooks/page-visibility/PageVisibility';
import {LearnerCourseActivity} from '../../activity/models/learner-activities';
import {LearnerVerb} from '../../activity/models/verbs';
import {LearnerObject} from '../../activity/models/objects';
import {useCurrentCourse, useCurrentCoursePosition} from '../../state/recoil';

/**
 * Only initialize this component after the course as been loaded.
 */
export const CoursePlayerVisibilityActivity: FunctionComponent = () => {
  const coursePosition = useCurrentCoursePosition();
  const course = useCurrentCourse();

  const isVisible = usePageVisibility();
  const [previousVisibility, setPreviousVisibility] = useState<boolean>(false);
  const [isLaunched, setIsLaunched] = useState<boolean>(true);

  const { emitLearnerActivity } = useCourseContext();

  // Only emit a metric when Course Player is launched, suspended, or resumed.
  useEffect(() => {
    // Only emit the launched metric after the Course as been retrieved and the Course Position is set
    if (!course || !coursePosition) {
      return;
    }

    if (isVisible !== previousVisibility) {
      let learnerCourseActivity;

      if (isVisible) {
        let learnerVerb;
        if (isLaunched) {
          learnerVerb = LearnerVerb.LAUNCHED;
          setIsLaunched(!isLaunched);
        } else {
          learnerVerb = LearnerVerb.RESUMED;
        }

        learnerCourseActivity = new LearnerCourseActivity(
          learnerVerb,
          LearnerObject.COURSE,
          coursePosition?.lessonId,
        );

      } else {
        learnerCourseActivity = new LearnerCourseActivity(
          LearnerVerb.SUSPENDED,
          LearnerObject.COURSE,
          coursePosition?.lessonId,
        );
      }

      emitLearnerActivity(learnerCourseActivity);
      setPreviousVisibility(isVisible);
    }
  }, [course, isVisible, previousVisibility, isLaunched, emitLearnerActivity, coursePosition, coursePosition?.lessonId]);

  return null;
};
