import {FunctionComponent, ReactElement, RefObject, useLayoutEffect} from 'react';
import isNil from 'lodash/isNil';
import {ScrollToGadgetId} from '../../../context/course';
import {LessonCanvasTitleId} from '../lesson-canvas/LessonCanvas';

const SCROLL_TO_BEHAVIOR = 'auto';

export interface ScrollToGadgetProps {
  gadgetRefsByGadgetId: Record<string, RefObject<HTMLDivElement>>
  scrollContainer?: HTMLElement | undefined | null
  scrollToGadgetId?: ScrollToGadgetId
  clearScrollToGadgetId: () => void
}

export const ScrollToGadget: FunctionComponent<ScrollToGadgetProps> = ({
  gadgetRefsByGadgetId,
  scrollContainer,
  scrollToGadgetId,
  clearScrollToGadgetId,
  children
}) => {
  const {gadgetId, focus} = scrollToGadgetId || {};

  useLayoutEffect(() => {
    // If scrollToGadgetId object is undefined, means we don't want to perform any scrolling
    if(isNil(scrollToGadgetId)) {
      return;
    }
  
    if (gadgetId) {
      const gadgetRef = gadgetRefsByGadgetId[gadgetId];
      if (gadgetRef) {
        if (scrollContainer) {
          const gadgetOffsetTop = gadgetRef.current?.offsetTop || 0;
          const scrollContainerTop = scrollContainer.offsetTop || 0;
          scrollContainer.scrollTo({
            top: gadgetOffsetTop - scrollContainerTop,
            left: 0,
            behavior: SCROLL_TO_BEHAVIOR
          });
        } else {
          gadgetRef.current?.scrollIntoView({
            behavior: SCROLL_TO_BEHAVIOR
          });
        }
        if (focus) {
          gadgetRef.current?.focus();
        }
      }
    } else {
      scrollContainer?.scrollTo({
        top: 0,
        left: 0,
        behavior: SCROLL_TO_BEHAVIOR
      });
      if (focus) {
        const lessonTitleElement = document.getElementById(LessonCanvasTitleId);
        lessonTitleElement?.focus();
      }
    }

    // Clear value after performing scroll, either to gadget or top
    clearScrollToGadgetId();
  }, [focus, scrollContainer, gadgetRefsByGadgetId, gadgetId]);

  return children as ReactElement;
};
