import React, {forwardRef, FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Theme from '@amzn/meridian/theme';
import isEmpty from 'lodash/isEmpty';
import {CaptionsAndTranscript} from './CaptionsAndTranscript';
import {useCourseAuthoringContext} from '../../../context';
import {VideoComponent} from './VideoComponent';
import {pollAssetStatus} from './helpers';
import GadgetContainer from '../../../../components/containers/gadget/GadgetContainer';
import {GadgetEditorProps} from '../models';
import {VideoGadgetConfig} from '../../../../components/gadgets/video';
import {ShakaPlayerRef} from '../../../../components/gadgets/video/ShakaPlayer';
import {useCourseContext, VideoAssetMetadata} from '../../../../context/course';
import {AssetDataModel, InvalidFile} from '../../asset-uploader/AssetUploader';
import {InvalidFileUploadAlert} from '../../alerts/invalid-file-upload-alert/InvalidFileUploadAlert';
import {MarkdownGadgetI18nStrings, VideoGadgetI18nStrings} from '../../../../context/course/models/I18n';
import {MarkdownEditor} from '../markdown/MarkdownEditor';
import {LABELS} from './Constants';

import brandedLightTokens from '../../../../theme/branded-light';
import './styles/video-gadget.scss';

export interface AssetMetaData {
  fileName: string;
  assetMetadata?: VideoAssetMetadata;
}

export type VideoGadgetProps = GadgetEditorProps<VideoGadgetConfig, { video: VideoGadgetI18nStrings, markdown: MarkdownGadgetI18nStrings}>

export const VideoGadget: FunctionComponent<VideoGadgetProps> = forwardRef<HTMLElement | undefined, VideoGadgetProps>((
  {
    id: gadgetId,
    config,
    i18nStrings,
  }: VideoGadgetProps,
  ref
) => {

  const {updateGadget} = useCourseAuthoringContext();
  const {getAssetModel} = useCourseContext();

  const [title, setTitle] = useState<string>(config.title || '');
  const [video, setVideo] = useState<{ assetId: string } | undefined>(config?.video || undefined);
  const [videoCaptions, setVideoCaptions] = useState<{ assetId: string } | undefined>(config?.videoCaptions || undefined);
  const [videoTextTranscript, setVideoTextTranscript] = useState<{ assetId: string } | undefined>(config?.videoTextTranscript || undefined);
  const [videoMetadata, setVideoMetadata] = useState<AssetMetaData>();
  const [invalidFiles, setInvalidFiles] = useState<InvalidFile[]>([]);
  const [videoDescription, setVideoDescription] = useState<string>(config?.videoDescription || '');
  const [transcriptFileGetUrl, setTranscriptFileGetUrl] = useState<string | undefined>();

  const videoPlayerRef = useRef<ShakaPlayerRef>(null);

  const fetchVideoMetadata = useCallback(() => {
    pollAssetStatus(config.video.assetId, setVideoAssetInState, getAssetModel);
  }, [config, getAssetModel]);

  useEffect(() => {
    if (config?.video?.assetId) {
      fetchVideoMetadata();
    }
  }, [config, fetchVideoMetadata]);

  useEffect(() => {
    updateGadget(gadgetId, {
      video,
      videoCaptions,
      videoDescription,
      videoTextTranscript,
      title
    } as VideoGadgetConfig);
  }, [title, video, videoDescription, videoCaptions, videoTextTranscript, gadgetId, updateGadget]);

  const setVideoAssetInState = (asset: AssetMetaData) => {
    setVideoMetadata(asset);
  };

  const updateAssetData = useCallback((configUpdateData: AssetDataModel) => {

    const startPollingForAssetStatus = () => {
      const videoAssetId = configUpdateData?.video?.assetId as string;
      pollAssetStatus(videoAssetId, setVideoAssetInState, getAssetModel);
    };

    if (configUpdateData?.video?.assetId) {
      setTimeout(() => {
        startPollingForAssetStatus();
      }, 90000);
    }

    if (configUpdateData?.video) {
      setVideo(configUpdateData.video);
    } else if (configUpdateData?.videoCaptions) {
      setVideoCaptions(configUpdateData?.videoCaptions);
    } else if (configUpdateData?.videoTextTranscript) {
      setVideoTextTranscript(configUpdateData?.videoTextTranscript);
    }
  }, [getAssetModel]);

  const onAlertClose = useCallback((index: number) => {
    const fileNames = Array.from(invalidFiles) as InvalidFile[];
    fileNames.splice(index, 1);
    setInvalidFiles(fileNames);
  }, [invalidFiles]);

  return (
    <GadgetContainer id={gadgetId} ref={ref}>
      <Theme tokens={{...brandedLightTokens}}>
        <Column spacing='small' spacingInset='none none none none' className={'video-gadget__container'}>
          {!isEmpty(invalidFiles) &&
            <Box>
              <InvalidFileUploadAlert
                invalidFiles={invalidFiles}
                onAlertClose={onAlertClose}
              />
            </Box>
          }
          <Box>
            <Input
              constraintText={LABELS.titleConstraintPlaceholder}
              onChange={setTitle}
              placeholder={LABELS.titlePlaceholder}
              size={'xlarge'}
              value={title}
            />
          </Box>
          <Box>
            <MarkdownEditor
              markdownText={videoDescription}
              handleMarkdownTextChange={setVideoDescription}
              minLines={4}
              i18nStrings={{
                ...i18nStrings.markdown,
                editorLabel: i18nStrings.video.editorLabel,
                editorPlaceholder: i18nStrings.video.editorPlaceholder
              }}
            />
          </Box>
          <Box>
            <VideoComponent
              gadgetId={gadgetId}
              updateAssetData={updateAssetData}
              video={config?.video}
              videoCaptions={config?.videoCaptions}
              videoMetadata={videoMetadata}
              videoPlayerRef={videoPlayerRef}
              onRejection={setInvalidFiles}
              i18nStrings={i18nStrings.video}
              transcriptFileGetUrl={transcriptFileGetUrl}
            />
          </Box>
          <Box>
            <CaptionsAndTranscript
              gadgetId={gadgetId}
              updateAssetData={updateAssetData}
              videoCaptions={config?.videoCaptions}
              isVideoMetadataAvailable={!isEmpty(videoMetadata)}
              videoTextTranscript={config?.videoTextTranscript}
              videoElement={videoPlayerRef.current?.videoElement as HTMLVideoElement}
              onRejection={setInvalidFiles}
              i18nStrings={i18nStrings.video}
              videoMetadata = {videoMetadata}
              updateTranscriptFileGetUrl = {setTranscriptFileGetUrl}
            />
          </Box>
        </Column>
      </Theme>
    </GadgetContainer>
  );
});
VideoGadget.displayName = 'VideoGadget';
