export const formatBytes = (bytes: number, decimal?: number) => {
  const marker = 1024;
  const kiloBytes = marker; // One Kilobyte is 1024 bytes
  const megaBytes = marker * marker; // One MB is 1024 KB
  const gigaBytes = marker * marker * marker; // One GB is 1024 MB

  if(bytes < kiloBytes) {
    return bytes + ' Bytes';
  } else if (bytes < megaBytes) {
    return(bytes / kiloBytes).toFixed(decimal) + ' KB';
  } else if (bytes < gigaBytes) {
    return(bytes / megaBytes).toFixed(decimal) + ' MB';
  } else {
    return(bytes / gigaBytes).toFixed(decimal) + ' GB';
  }
};
