import {GadgetLearnerStateConverter} from './GadgetLearnerStateConverter';
import {Evaluation} from '../../models/Evaluation';
import {ResponseEvaluationResults, ResponseGadgetLearnerState} from '../../../../components/gadgets/response';
import {AssessmentEvaluationStatus} from '../../models/AssessmentEvaluationStatus';
import {GadgetType} from '../models/GadgetType';

/**
 * Quiz State object used in SCARS
 */
interface ResponseState {
  updatedAt?: Date | null;

  responses?: { response: string, correct: boolean }[] | null;

  evaluationResults?: ResponseEvaluationResults;
}

export class ResponseGadgetLearnerStateConverter implements GadgetLearnerStateConverter<ResponseGadgetLearnerState> {
  getGadgetType(): string {
    return GadgetType.RESPONSE;
  }

  fromGadgetLearnerState(learnerState: ResponseGadgetLearnerState): ResponseState {
    return {
      updatedAt: learnerState.updatedAt,
      responses: learnerState.responses?.map((response: string) => { return { response: response }; }),
    } as ResponseState;
  }

  toGadgetLearnerState(state: ResponseState): ResponseGadgetLearnerState {
    const responses = state.responses
      ? state.responses.map((response: { response: string } ) => {
        return response.response;
      }) : undefined;

    let evaluationResults;
    // If the evaluations results exist that means it was submitted from SojuUIFramework, so use those results
    // If not, parse the responses array and response objects to create the evaluationResults
    if (state.evaluationResults) {
      evaluationResults = state.evaluationResults;
      evaluationResults.status =  evaluationResults.status ? AssessmentEvaluationStatus.Pass : AssessmentEvaluationStatus.Fail;
      evaluationResults.evaluations = evaluationResults?.evaluations?.map((result: string) =>
        result === 'correct'
          ? Evaluation.Correct
          : Evaluation.Incorrect
      );
    } else {
      const responseEvaluations = state.responses?.map((response: { correct: boolean } ) => {
        return response.correct ? Evaluation.Correct : Evaluation.Incorrect;
      });

      evaluationResults = state.responses
        ? {
          status: responseEvaluations?.reduce((status: boolean, responseEvaluation: Evaluation) => {
            return status && responseEvaluation === Evaluation.Correct;
          }, true) ? AssessmentEvaluationStatus.Pass : AssessmentEvaluationStatus.Fail,
          evaluations: responseEvaluations,
        } as ResponseEvaluationResults : undefined;
    }

    return {
      responses,
      evaluationResults,
    } as ResponseGadgetLearnerState;
  }
}
