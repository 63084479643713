import BlotFormatter, { Action } from 'quill-blot-formatter';
import AltTextInput, {AltTextInputInterface} from './AltTextInput';

export default class AltTextAction extends Action {
  textInput: AltTextInputInterface;

  constructor(formatter: BlotFormatter) {
    super(formatter);
    this.textInput = new AltTextInput();
  }

  onCreate() {
    const textInput = this.textInput.create(this.formatter);
    this.formatter.overlay.appendChild(textInput);
  }

  onDestroy() {
    const textInput = this.textInput.getElement();
    if (!textInput) {
      return;
    }

    this.formatter.overlay.removeChild(textInput);
    this.textInput.destroy();
  }
}