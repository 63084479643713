import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Toggle from '@amzn/meridian/toggle';
import Icon from '@amzn/meridian/icon';
import alertKnockoutTokens from '@amzn/meridian-tokens/base/icon/alert-knockout';
import isEmpty from 'lodash/isEmpty';

import {colorPalette} from '../../../theme/index';

/**
 * Assessment Gadget settings type
 *
 */
export type AssessmentGadgetSettingsType = {
  /**
   * Gadget assessment outcome required for the certificate or not
   */
  isRequiredForCertificate: boolean;

  /**
   * Question order randomized or not
   */
  isQuestionOrderRandomized: boolean;

  /**
   * Minimum pass percentage required to pass the assessment
   */
  passPercentage: number;
}

export interface AssessmentGadgetSettingsInterface {
  /**
   * Gadget settings
   */
  settings: AssessmentGadgetSettingsType

  /**
   * Function to update gadget settings
   */
  updateAssessmentGadgetSettings: (assessmentGadgetSettings: AssessmentGadgetSettingsType) => void;

  /**
   * Gadget name
   */
  gadgetName: string;
}

export const AssessmentGadgetSettings: FunctionComponent<AssessmentGadgetSettingsInterface> = (
  {
    gadgetName,
    settings,
    updateAssessmentGadgetSettings
  }: AssessmentGadgetSettingsInterface
) => {

  const [isRequiredForCertificate, setIsRequiredForCertificate] =
    useState<boolean>(!isEmpty(settings) ? settings?.isRequiredForCertificate : true);
  const [isQuestionOrderRandomized, setIsQuestionOrderRandomized] =
    useState<boolean>(!isEmpty(settings) ? settings?.isQuestionOrderRandomized : true);
  const [passPercentage, setPassPercentage] = useState<number>(!isEmpty(settings) ? settings?.passPercentage : 90);

  useEffect(() => {
    updateAssessmentGadgetSettings({
      isRequiredForCertificate,
      isQuestionOrderRandomized,
      passPercentage
    });
  }, [updateAssessmentGadgetSettings, isRequiredForCertificate, isQuestionOrderRandomized, passPercentage]);

  const onPassPercentageChange = useCallback((value: string) => {
    const newPassPercentage = Number(value);
    if(!isNaN(newPassPercentage)) {
      if(newPassPercentage <= 100) {
        setPassPercentage(newPassPercentage);
      }
    } else {
      setPassPercentage(0);
    }
  }, []);

  return (
    <Box type={'outline'}>
      <Row>
        <Box spacingInset={'400 0 0 400'}>
          <Heading level={1} type="h200">{gadgetName} settings</Heading>
        </Box>
      </Row>
      <Column>
        <Box spacingInset={'400'}>
          <Column spacing={'300'}>
            <Box spacingInset={'200'}>
              <Row spacing={'400'}>
                <Toggle
                  checked={isRequiredForCertificate}
                  onChange={setIsRequiredForCertificate}
                  id='required-for-certificate'
                />
                <Text tag='label' type='b200' color='primary' htmlFor='required-for-certificate'>
                  Required for certificate
                </Text>
              </Row>
            </Box>
            {isRequiredForCertificate &&
              <>
                <Box spacingInset={'200'}>
                  <Row alignmentVertical={'top'} spacing={'400'}>
                    <Toggle
                      checked={isQuestionOrderRandomized}
                      onChange={setIsQuestionOrderRandomized}
                      id={'reorder-questions'}
                    />
                    <Column>
                      <Text tag={'label'} type={'b200'} color={'primary'} htmlFor={'reorder-questions'}>
                        Randomize question order
                      </Text>
                      <Row alignmentVertical={'top'} spacing={'300'}>
                        <div
                          style={{
                            color: colorPalette.tangerine400
                          }}
                        >
                          <Icon tokens={alertKnockoutTokens}/>
                        </div>
                        <Text type={'b100'}> Learners will not see a randomized questions until an
                          upcoming date. Turn on this toggle to save your decision.
                        </Text>
                      </Row>
                    </Column>
                  </Row>
                </Box>
                <Box spacingInset={'400 200 200 200'}>
                  <Column spacing='300'>
                    <Text type='h100' htmlFor='pass-percentage'>
                      Percentage needed to pass quiz
                    </Text>
                    <Row>
                      <Box width={'25%'}>
                        <Input
                          id='pass-percentage'
                          value={passPercentage}
                          onChange={onPassPercentageChange}
                          placeholder='Pass percentage'
                          suffix="%"
                        />
                      </Box>
                    </Row>
                  </Column>
                </Box>
              </>
            }
          </Column>
        </Box>
      </Column>
    </Box>
  );
};
