import React, {FunctionComponent} from 'react';
import Text from '@amzn/meridian/text';
import {HideExternalLinkWarningStorageKey} from './Constants';
import useSessionStorage from '../../hooks/useSessionStorage';
import {LinkWarningModal} from './LinkWarningModal';
import {LinkWarningI18nStrings, WithI18nStringsProps} from '../../context/course/models/I18n';

export interface ExternalLinkWarningModalProps extends WithI18nStringsProps<LinkWarningI18nStrings> {
  open: boolean;
  onClose: () => void;
  href?: string;
}

export const ExternalLinkWarningModal: FunctionComponent<ExternalLinkWarningModalProps> = ({
  open,
  href,
  onClose,
  i18nStrings
}) => {
  const [/* hideFutureExternalLinkWarningsSession */, setHideFutureExternalLinkWarningsSession] = useSessionStorage<boolean>(HideExternalLinkWarningStorageKey, false);

  return (<LinkWarningModal
    open={open}
    onClose={onClose}
    href={href}
    title={i18nStrings.externalLinkTitle}
    i18nStrings={i18nStrings}
    proceedButtonTitle={i18nStrings.proceedToExternalLinkButton}
    setHideFutureWarningsSession={setHideFutureExternalLinkWarningsSession}
  >
    <Text>
      {i18nStrings.aboutToVisit(href)}
    </Text>
    <Text>
      {i18nStrings.externalContentWarning}
    </Text>
    <Text>
      {i18nStrings.proceedWarning}
    </Text>
  </LinkWarningModal>);
};
