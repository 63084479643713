import React, { FunctionComponent } from "react";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";

/**
 * This screen appears if an error is thrown. See react-error-boundary for more
 * information: https://github.com/bvaughn/react-error-boundary
 */
const GlobalErrorRecovery: FunctionComponent = () => {
  // Clear local storage and refresh the page when a user presses the
  // "Reset and reload app" button
  const onReset = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  return (
    <Column
      alignmentHorizontal="center"
      alignmentVertical="center"
      spacing="600"
      spacingInset="600"
    >
      <Column spacing="300" alignmentHorizontal="center">
        <Text type="b400">Houston, we have a problem!</Text>
      </Column>
      <Row>
        <Button
          type="tertiary"
          size="large"
          href="https://issues.amazon.com/issues/create?template="
          target="_blank"
          rel="noopener noreferrer"
        >
          Create bug report
        </Button>
        <Button onClick={onReset} size="large">
          Reset and reload app
        </Button>
      </Row>
    </Column>
  );
};

export default GlobalErrorRecovery;
