import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const Home = () => {
  const history = useHistory();

  useEffect(() => {
    const redirectPath = sessionStorage.getItem("redirectPath");
    if (redirectPath) {
      history.push(redirectPath);
    }
  });

  return <div className="App">Hi there!</div>;
};

export default Home;
