import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import { CourseAuthoringPage } from "../pages/CourseAuthoringPage";
import { AppRoutes } from "./AppRoutes";
import { CourseDraftPreviewPage } from "../pages/CourseDraftPreviewPage";
import { CoursePlayerPage } from "../pages/CoursePlayerPage";

const CurrentPage: FunctionComponent = () => {
  /*
    const location = useLocation()

    // Publish counter for the new session and hit on initial page load. We only want
    // this to happen once, which is why we pass an empty array as the second argument.
    useEffect(() => {
        publishCounter("All", "sessions")
    }, [])

    // Every time a user loads our page and or changes route we publish a counter
    // with the pathname, so we can track it with Katal.
    useEffect(() => {
        publishCounter(location.pathname, "hits")
        publishCounter("All", "hits")
    }, [location.pathname])*/

  return (
    <Switch>
      <Route path={AppRoutes.AUTHORING_PAGE}>
        <CourseAuthoringPage />
      </Route>
      <Route path={AppRoutes.PREVIEW}>
        <CourseDraftPreviewPage />
      </Route>
      <Route path={AppRoutes.COURSE_PLAYER_PAGE}>
        <CoursePlayerPage />
      </Route>
      <Route path={AppRoutes.HOME}>
        <Home />
      </Route>
    </Switch>
  );
};

export default CurrentPage;
