import isEmpty from 'lodash/isEmpty';
import {AssetModel, VideoAssetMetadata, VideoTranscriptionStatus} from '../../../../context/course';
import {AssetMetaData} from './VideoGadget';
import {POLL_ASSET_INTERVAL_MILLISECONDS, POLL_ASSET_MAXIMUM_ATTEMPTS} from './Constants';
import {videoProcessingWorkflowStatusType} from './VideoComponent';

const isVideoProcessed = (videoAssetMetadata?: VideoAssetMetadata) => {
  const videoProcessingWorkflowStatus = videoAssetMetadata?.videoProcessingWorkflowStatus?.toUpperCase() as videoProcessingWorkflowStatusType;
  return [videoProcessingWorkflowStatusType.COMPLETE, videoProcessingWorkflowStatusType.ERROR].includes(videoProcessingWorkflowStatus);
};

const isTranscriptionCompleted = (videoAssetMetadata?: VideoAssetMetadata) => {
  const videoTranscriptionStatus = videoAssetMetadata?.videoTranscriptionStatus as VideoTranscriptionStatus;
  return [VideoTranscriptionStatus.COMPLETED, VideoTranscriptionStatus.FAILED].includes(videoTranscriptionStatus);
}

/**
 * Methods to poll for video processing status
 */
export const pollAssetStatus = (
  assetId: string,
  callback: (asset: AssetMetaData) => void,
  getAssetModel: (assetId: string) => Promise<AssetModel | undefined>
) => {

  let attempts = 0;

  const pollAssetInterval = setInterval(() => {
    attempts += 1;
    if (attempts >= POLL_ASSET_MAXIMUM_ATTEMPTS) {
      clearPoller();
    }
    callGetAsset();
  }, POLL_ASSET_INTERVAL_MILLISECONDS);

  const clearPollAssetInterval = () => {
    pollAssetInterval && clearInterval(pollAssetInterval);
  };

  const clearPoller = () => {
    clearPollAssetInterval();
  };

  const checkPollingComplete = (asset: AssetMetaData) => {
    if ( asset.assetMetadata?.videoProcessingWorkflowStatus?.toUpperCase() === videoProcessingWorkflowStatusType.ERROR || (isVideoProcessed(asset.assetMetadata) && isTranscriptionCompleted(asset.assetMetadata)) ){
      clearPoller();
    }
    callback(asset);
  };

  const callGetAsset = () => {
    try {
      getAssetModel(assetId).then((asset: AssetModel | undefined) => {
        if (asset) {
          const assetData = {
            assetMetadata: asset.assetMetadata,
            fileName: asset.title,
          } as AssetMetaData;

          checkPollingComplete(assetData);
        }
      });
    } catch (e) {
      // handle err
    }
  };

  callGetAsset();

};
