/**
 * Possible types of feedback
 */
export enum LearnerFeedbackType {
  /**
   * Thumbs up
   */
  GOOD = '1',

  /**
   * Thumbs down
   */
  BAD = '0'
}