import React, {FunctionComponent, RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import AppLayout, {appLayoutContentId} from '@amzn/meridian/app-layout';
import get from 'lodash/get';
import set from 'lodash/set';
import isNil from 'lodash/isNil';
import {Sidebar} from '../../navigation/sidebar';
import {LessonCanvas} from '../lesson-canvas';
import Column from '@amzn/meridian/column';
import Responsive from '@amzn/meridian/responsive';
import Theme from '@amzn/meridian/theme';
import brandedLightTokens from '../../../theme/branded-light';
import TopNav from '../../navigation/topnav';
import Footer from '../../navigation/footer';
import noop from 'lodash/noop';
import CourseContextProvider, {
  Course,
  CourseApi,
  CourseContextProps,
  CoursePosition,
  DisplaySection,
  GadgetLearnerState,
  InitialPosition,
  ProgressStatus,
  useCourseContext,
  Logger,
} from '../../../context/course';
import {SidebarState} from './index';
import {RecoilRoot, useRecoilState, useSetRecoilState} from 'recoil';
import {
  currentCourse,
  currentCourseGadgetsLearnerState,
  currentCourseLearnerState,
  currentCourseLessonsPartitionedByViewMode,
  currentCoursePosition,
  currentCourseProgress,
  currentCourseProgressStatus,
  currentLessonPartitionIndex,
  currentScrollToGadgetId,
  currentSidebarState,
  useCurrentCourse,
  useCurrentCourseDisplaySection,
  useCurrentCourseGadgetsLearnerState,
  useCurrentCourseLesson,
  useCurrentCoursePosition,
  useCurrentCourseProgress,
  useCurrentLessonPartition,
  useCurrentLessonPartitionedByViewMode,
  useCurrentLessonPartitionIndex,
  useCurrentLessonViewMode,
  useCurrentPartitionTitle,
  useSidebarState,
} from '../../../state/recoil';
import {CourseCompletedProps, DefaultCourseCompleted} from './DefaultCourseCompleted';
import {ErrorBoundary} from 'react-error-boundary';
import {GracefulError, GracefulErrorTheme} from '../../errors/GracefulError';
import {LearnerActivity} from '../../../activity/models/learner-activities';
import {CoursePlayerVisibilityActivity} from '../../learner-activites/CoursePlayerVisibilityActivity';
import {ActiveGadgetList} from '../active-gadget/ActiveGadgetList';
import {ScrollToGadget} from '../active-gadget/ScrollToGadget';
import {CourseLoader} from './components/CourseLoader';
import {CourseExitProps} from './components/CourseExitButton';
import {getPartitionIndexByGadgetId, partitionCourseLessonsByViewMode} from '../../../utils/LessonPartitionUtils';
import {CourseRequirementsIncompleteModal} from './components/CourseRequirementsIncompleteModal';
import {DEFAULT_GADGETS_REGISTRY, GadgetRenderer} from '../../../gadgets/registry';
import {
  FeedbackI18nStrings,
  FooterI18nStrings,
  GracefulErrorI18nStrings,
  I18nStringsConfig,
  PageFooterI18nStrings,
  SidebarI18nStrings,
  TopNavI18nStrings
} from '../../../context/course/models/I18n';
import {getAssetModelForLearner, getCloudLabEmbedInfo, mergeI18nStringsWithConfig} from '../../../utils/CourseUtils';

import './CoursePlayer.scss';
import responsiveStyles from '../../../styles/responsive.module.scss';

export interface ErrorFallbackProps {
  error: Error
}

/**
 * Course Player props
 */
export interface CoursePlayerProps {
  /**
   * ID of the course to be used in this context
   */
  courseId: string;

  /**
   * Course's ASIN
   */
  asin: string;

  /**
   * Course position set in the URL
   */
  initialPosition?: InitialPosition;

  /**
   * Course API implementation to be used
   */
  courseApi: CourseApi;

  /**
   * props for Top Nav button that exits the course player. if none are provided, button is not rendered.
   */
  courseExitButtonProps?: CourseExitProps;

  /**
   * optional override for the course complete page.
   */
  courseCompletePage?: FunctionComponent<CourseCompletedProps>;

  /**
   * Handler for course position change updates (optional)
   * @param position new course position
   */
  onPositionChange?: (position: CoursePosition) => void;

  /**
   * Handler for course updates (optional)
   * @param course new course value
   */
  onCourseChange?: (course: Course) => void,

  /**
   * callback function that is called if the course player experiences uncaught errors (outside of any gadgets)
   * @param error the js Error object passed by React's error boundary code
   * @param info React's error info object
   */
  onError?: (error: Error, info: React.ErrorInfo) => void,

  /**
   * optional function to render a fallback view in case there is an uncaught error somewhere in the course player.
   * @param props object containing the error object thrown
   */
  errorFallbackRender?: (props: ErrorFallbackProps) =>
    React.ReactElement<unknown, string | React.FunctionComponent | typeof React.Component> | null;

  /**
   * Handler for Learner events (optional)
   * @param activity
   */
  onLearnerActivityEvent?: (learnerActivity: LearnerActivity) => void;

  /**
   * Handler for Katal Metrics Counter (optional)
   * @param metricName
   */
  handleCounterMetrics?: (metricName: string) => void;

  /**
   * Handler for Katal Metrics Timer (optional)
   * @param metricName, time
   */
  handleTimerMetrics?: (metricName: string, timer: number) => void;

  /**
   * If provided, it overrides the gadget types with the specified components.
   */
  gadgetOverrides?: GadgetRenderer;

  /**
   * Set of translation strings and functions for static text throughout the components
   * Set of translation strings and functions for static text throughout the components
   */
  i18nStringsConfig?: I18nStringsConfig;

  /**
   * Logger object that contains functions to log: "debug", "info", "warn", "error", "fatal"
   * The implementation of each function is optional, if any is left empty, we will call noop
   */
  logger?: Logger;
}

/**
 * LessonCanvas HOC using Recoil State
 */
export const LessonCanvasWithRecoilState: FunctionComponent<{ gadgetOverrides?: GadgetRenderer, i18nStrings?: I18nStringsConfig}> = ({
  gadgetOverrides, i18nStrings
}) => {
  const currentLesson = useCurrentCourseLesson();
  const lessonId = currentLesson?.id;
  const currentLessonPartition = useCurrentLessonPartition();
  const title = currentLesson?.title;

  const gadgetRegistry = mergeI18nStringsWithConfig(gadgetOverrides || DEFAULT_GADGETS_REGISTRY, i18nStrings?.gadgets);

  const {setCoursePosition} = useCourseContext();

  const gadgetLearnerStates = useCurrentCourseGadgetsLearnerState();
  const [scrollToGadgetId, setScrollToGadgetId] = useRecoilState(currentScrollToGadgetId);

  const scrollContainer = document.getElementById(appLayoutContentId);

  const gadgetIds = useMemo(() => (currentLessonPartition?.gadgets.map(gadget => gadget.id) || []), [currentLessonPartition]);

  const gadgetRefsByGadgetId = useMemo(() => {
    const gadgets = currentLessonPartition?.gadgets || [];
    return gadgets.reduce((gadgetRefs, gadget) => {
      return {
        ...gadgetRefs,
        [gadget.id]: React.createRef<HTMLDivElement>()
      };
    }, {} as Record<string, RefObject<HTMLDivElement>>) || {};
  }, [currentLessonPartition]);

  const onActiveGadgetChanged = useCallback((gadgetId?: string) => {
    if (gadgetId) {
      const newCoursePosition = {
        displaySection: DisplaySection.LESSON,
        lessonId,
        gadgetId: gadgetId,
        updatedAt: new Date()
      } as CoursePosition;
      setCoursePosition(newCoursePosition);
    }
  }, [setCoursePosition, lessonId]);

  const clearScrollToGadgetId = useCallback(() => {
    setScrollToGadgetId(undefined);
  }, [setScrollToGadgetId]);

  if (!currentLesson || !currentLessonPartition) {
    return null;
  }

  const partitionWithLearnerState = currentLessonPartition?.gadgets.map(gadget => {
    return {
      ...gadget,
      learnerState: gadgetLearnerStates[gadget.id]
    };
  });

  return (
    <ScrollToGadget
      gadgetRefsByGadgetId={gadgetRefsByGadgetId}
      scrollContainer={scrollContainer}
      scrollToGadgetId={scrollToGadgetId}
      clearScrollToGadgetId={clearScrollToGadgetId}
    >
      <ActiveGadgetList
        scrollContainer={scrollContainer}
        onActiveGadgetChanged={onActiveGadgetChanged}
        gadgetIds={gadgetIds}
      >
        <LessonCanvas
          gadgets={partitionWithLearnerState}
          gadgetsRegistry={gadgetRegistry}
          gadgetRefsByGadgetId={gadgetRefsByGadgetId}
          title={title}
        />
      </ActiveGadgetList>
    </ScrollToGadget>
  );
};

/**
 * Sidebar HOC using Recoil State
 */
export const SidebarWithRecoilState: FunctionComponent<{
  enableCourseProgress?: boolean,
  enableFeedbackWidget?: boolean,
  i18nStrings?: {sidebar: SidebarI18nStrings, feedback: FeedbackI18nStrings},
}> = props => {
  const {
    enableCourseProgress = true,
    enableFeedbackWidget = true,
    i18nStrings,
  } = props;

  const sidebarState = useSidebarState();
  const currentCourse = useCurrentCourse();
  const coursePosition = useCurrentCoursePosition();
  const courseProgress = useCurrentCourseProgress();
  const setScrollToGadgetId = useSetRecoilState(currentScrollToGadgetId);

  if (!currentCourse || sidebarState === SidebarState.COLLAPSED) {
    return null;
  }

  return (<Sidebar
    course={currentCourse}
    coursePosition={coursePosition}
    courseProgress={courseProgress}
    enableCourseProgress={enableCourseProgress}
    enableFeedbackWidget={enableFeedbackWidget}
    onLessonClick={() => {
      setScrollToGadgetId({gadgetId: undefined, focus: true});
    }}
    onGadgetClick={gadgetId => {
      setScrollToGadgetId({gadgetId, focus: true});
    }}
    i18nStrings={i18nStrings}
  />);
};

/**
 * Footer HOC using Recoil State
 */
const FooterWithRecoilState: FunctionComponent<{i18nStrings?: {footer: FooterI18nStrings, pageFooter: PageFooterI18nStrings}}> = ({i18nStrings}) => {
  const currentCourse = useCurrentCourse();
  const currentLesson = useCurrentCourseLesson();
  const coursePosition = useCurrentCoursePosition();
  const setScrollToGadgetId = useSetRecoilState(currentScrollToGadgetId);

  const currentLessonPartitions = useCurrentLessonPartitionedByViewMode();
  const currentPartitionIndex = useCurrentLessonPartitionIndex();
  const currentViewMode = useCurrentLessonViewMode();
  const sectionName = useCurrentPartitionTitle();

  const {setCoursePosition} = useCourseContext();

  const onCompleteClick = useCallback((lessonId: string | null) => {
    if (!lessonId) {
      return;
    }

    setScrollToGadgetId({gadgetId: undefined, focus: true});
  }, [setScrollToGadgetId]);

  const onPartitionIndexChanged = useCallback((newIndex: number) => {
    if (currentLesson) {
      const newPartition = currentLessonPartitions?.partitions[newIndex];
      const firstGadgetOnNewPartition = newPartition ? newPartition.gadgets[0] : null;
      const firstGadgetId = firstGadgetOnNewPartition?.id;
      setCoursePosition({
        displaySection: DisplaySection.LESSON,
        lessonId: currentLesson.id,
        gadgetId: firstGadgetId || '',
        updatedAt: new Date()
      });
      setScrollToGadgetId({gadgetId: firstGadgetId, focus: true});
    }
  }, [currentLesson, currentLessonPartitions]);

  if (!currentCourse) {
    return null;
  }

  return (<Footer
    lessonTitle={currentLesson?.title || ''}
    currentPartitionIndex={currentPartitionIndex}
    totalPartitions={currentLessonPartitions?.partitions.length || 0}
    onPartitionIndexChanged={onPartitionIndexChanged}
    viewMode={currentViewMode}
    partitionTitle={sectionName}
    course={currentCourse}
    coursePosition={coursePosition}
    onCompleteClick={onCompleteClick}
    i18nStrings={i18nStrings}
  />);
};

/**
 * TopNav HOC using Recoil State
 */
export const TopNavWithRecoilState: FunctionComponent<{
  courseExitButtonProps?: CourseExitProps,
  i18nStrings?: TopNavI18nStrings,
}> = ({courseExitButtonProps, i18nStrings}) => {
  const [sidebarState, setSidebarState] = useRecoilState(currentSidebarState);
  const currentCourse = useCurrentCourse();
  const coursePosition = useCurrentCoursePosition();
  const setScrollToGadgetId = useSetRecoilState(currentScrollToGadgetId);

  const onLessonClick = useCallback(() => {
    setScrollToGadgetId({gadgetId: undefined, focus: false});
  }, [setScrollToGadgetId]);

  // Collapse the sidebar in non-desktop devices by default.
  useEffect(() => {
    const {tablet_breakpoint} = responsiveStyles;
    const breakpoint = parseInt(tablet_breakpoint.substring(0, tablet_breakpoint.length - 2), 10);
    if (window.innerWidth <= breakpoint) {
      setSidebarState(SidebarState.COLLAPSED);
    }
  }, [])

  if (!currentCourse) {
    return null;
  }

  return (<TopNav
    lessons={currentCourse.lessons}
    coursePosition={coursePosition}
    sidebarState={sidebarState}
    onClickMenu={() => {
      setSidebarState(sidebarState === SidebarState.EXPANDED
        ? SidebarState.COLLAPSED
        : SidebarState.EXPANDED);
    }}
    courseExitButtonProps={courseExitButtonProps}
    onNextLessonClick={onLessonClick}
    onPreviousLessonClick={onLessonClick}
    i18nStrings={i18nStrings}
  />);
};

/**
 * Course Player using Recoil state
 */
const CoursePlayerWithRecoilState: FunctionComponent<CoursePlayerProps> = (
  {
    courseId,
    asin,
    courseApi,
    initialPosition,
    courseExitButtonProps,
    courseCompletePage,
    onPositionChange,
    onCourseChange,
    onLearnerActivityEvent,
    handleCounterMetrics = noop,
    handleTimerMetrics = noop,
    gadgetOverrides,
    i18nStringsConfig,
    logger,
  }: CoursePlayerProps
) => {
  const [course, setCourse] = useRecoilState(currentCourse);
  const setCourseLearnerState = useSetRecoilState(currentCourseLearnerState);
  const setCoursePosition = useSetRecoilState(currentCoursePosition);
  const setCourseProgress = useSetRecoilState(currentCourseProgress);
  const setCourseProgressStatus = useSetRecoilState(currentCourseProgressStatus);
  const setGadgetsState = useSetRecoilState(currentCourseGadgetsLearnerState);
  const displaySection = useCurrentCourseDisplaySection();
  const setScrollToGadgetId = useSetRecoilState(currentScrollToGadgetId);
  const setPartitionIndex = useSetRecoilState(currentLessonPartitionIndex);
  const setLessonPartitions = useSetRecoilState(currentCourseLessonsPartitionedByViewMode);
  const [isMissingRequirementsModalOpened, setIsMissingRequirementsModalOpened] = useState<boolean>(false);
  const [missingRequirements, setMissingRequirements] = useState<string[]>([]);

  const [contextValue] = useState<CourseContextProps>({
    setCoursePosition: async position => {
      setCoursePosition(position);
      onPositionChange && onPositionChange(position);
      await courseApi.setCoursePosition(courseId, position);
    },
    setCourseProgress: async progressStatus => {
      const response = await courseApi.setCourseStatus(courseId, progressStatus, asin);
      if(response.statusTransitionSuccessful) {
        setCourseProgressStatus(progressStatus);
      } else {
        if(progressStatus === ProgressStatus.COMPLETED && response.gadgetIds) {
          setMissingRequirements(response.gadgetIds);
          setIsMissingRequirementsModalOpened(true);
        }
      }
      return response;
    },
    setLessonProgress: async (lessonId, progress) => {
      const updatedProgress = await courseApi.setLessonStatus(courseId, lessonId, progress);
      setCourseProgress(courseProgress => {
        return {
          ...courseProgress,
          completionPercentage: !isNil(updatedProgress.completionPercentage) ? updatedProgress.completionPercentage : courseProgress.completionPercentage,
          lessonStatusById: {
            ...courseProgress.lessonStatusById,
            [lessonId]: progress
          }
        };
      });
    },
    setGadgetLearnerState: async (gadgetId, gadgetType, gadgetLearnerState, persistState = true) => {
      let updatedGadgetState: GadgetLearnerState = gadgetLearnerState;
      if (persistState) {
        updatedGadgetState = await courseApi.setGadgetLearnerState(courseId, gadgetId, gadgetType, gadgetLearnerState);
      }

      setGadgetsState(gadgetsState => { return {
        ...gadgetsState,
        [gadgetId]: updatedGadgetState,
      };});
    },
    submitGadgetLearnerStateAssessment: async (gadgetId, gadgetType, learnerState) => {
      const updatedGadgetState = await courseApi.submitGadgetLearnerStateAssessment(courseId, gadgetId, gadgetType, learnerState);
      setGadgetsState(gadgetsState => { return {
        ...gadgetsState,
        [gadgetId]: updatedGadgetState
      };});
    },
    getAssetModel: getAssetModelForLearner(courseId, courseApi),
    getCloudLabEmbedInfo: gadgetId => getCloudLabEmbedInfo(courseId, gadgetId, courseApi),
    emitLearnerActivity: (activity: LearnerActivity) => {
      if (!onLearnerActivityEvent) {
        return;
      }

      onLearnerActivityEvent(activity);
    },
    submitLearnerFeedback: async learnerFeedback => {
      await courseApi.submitLearnerFeedback(asin, learnerFeedback);
    },
    emitCounterMetrics: (metricName: string) => {
      handleCounterMetrics(metricName);
    },
    emitTimerMetrics: (metricName: string, timer: number) => {
      handleTimerMetrics(metricName, timer);
    },
    logger: logger
  });

  useEffect(() => {
    (async () => {
      const course = await courseApi.loadCourse(courseId);
      setCourse(course);
      onCourseChange && onCourseChange(course);
      const courseLearnerState = await courseApi.loadLearnerState(courseId);

      // set up alternate lesson view partitions and associated references
      const lessonViewMode = course.lessonViewMode;
      const lessonPartitions = partitionCourseLessonsByViewMode(course.lessons, lessonViewMode);
      setLessonPartitions(lessonPartitions);

      // special case for when the course hasn't been started yet and there's no initial position
      // we pick the first gadget of the first lesson to start with and store it as the starting point
      if (!courseLearnerState.coursePosition && !initialPosition) {
        courseLearnerState.coursePosition = {
          displaySection: DisplaySection.LESSON,
          lessonId: course.lessons[0].id,
          gadgetId: course.lessons[0].gadgets[0].id,
          updatedAt: new Date()
        };
        await courseApi.setCoursePosition(courseId, courseLearnerState.coursePosition);
      }

      // If we get an initial position from the URL
      // Build CoursePosition object using those indexes and set it in the learner's state
      // (it may replace a previously stored position)
      if(initialPosition) {
        const {
          lessonIndex,
          gadgetIndex
        } = initialPosition;
        const initialCoursePosition = {
          displaySection: DisplaySection.LESSON,
          lessonId: get(course, `lessons[${lessonIndex - 1}].id`),
          gadgetId: gadgetIndex && get(course, `lessons[${lessonIndex - 1}].gadgets[${gadgetIndex - 1}].id`),
          updatedAt: new Date()
        } as CoursePosition;

        // Replace learnerState value and update currentPosition with API
        set(courseLearnerState, 'coursePosition', initialCoursePosition);
        await courseApi.setCoursePosition(courseId, initialCoursePosition);
      }

      // Set partition index based on initial course position, or first partition as a fallback
      if (courseLearnerState.coursePosition?.gadgetId) {
        setPartitionIndex(getPartitionIndexByGadgetId(
          courseLearnerState.coursePosition?.gadgetId,
          lessonPartitions[courseLearnerState.coursePosition.lessonId].partitions
        ) || 0);
      }
      setCourseLearnerState(courseLearnerState);
      setScrollToGadgetId({gadgetId: courseLearnerState.coursePosition?.gadgetId});
      onPositionChange && courseLearnerState.coursePosition && onPositionChange(courseLearnerState.coursePosition);
    })();
  }, [courseId, courseApi, initialPosition, setCourse, setCourseLearnerState, setScrollToGadgetId, onCourseChange, onPositionChange]);

  if (!course || !contextValue) {
    return <CourseLoader i18nStrings={i18nStringsConfig?.loader}/>;
  }

  const isCompletionPage = displaySection === DisplaySection.COMPLETION_PAGE;
  const CourseCompletePage = courseCompletePage || DefaultCourseCompleted;

  return (
    <CourseContextProvider {...contextValue}>
      <CoursePlayerVisibilityActivity/>
      <Theme tokens={brandedLightTokens}>
        <Responsive
          query='max-width'
          props={{
            spacingInset: {
              default: undefined,
              [responsiveStyles.tablet_breakpoint]: '600 none none none'
            }
          }}
        >
          {
            props =>
              <AppLayout
                headerComponent={TopNavWithRecoilState}
                sidebarComponent={SidebarWithRecoilState}
                footerComponent={FooterWithRecoilState}
                backgroundColor={isCompletionPage ? 'accent' : 'alternateSecondary'}
                mainClassName='CoursePlayer__main'
                spacingInset={props.spacingInset}
              >
                <TopNavWithRecoilState
                  courseExitButtonProps={courseExitButtonProps}
                  i18nStrings={i18nStringsConfig?.topNav}
                />
                <SidebarWithRecoilState i18nStrings={i18nStringsConfig ? {
                  sidebar: i18nStringsConfig.sidebar,
                  feedback: i18nStringsConfig.feedback
                } : undefined}/>
                {
                  !isCompletionPage &&
                  <Column height='100%' heights={['fill', 'fit']} alignmentHorizontal='center'>
                    <LessonCanvasWithRecoilState gadgetOverrides={gadgetOverrides} i18nStrings={i18nStringsConfig}/>
                    <FooterWithRecoilState i18nStrings={i18nStringsConfig ? {
                      footer: i18nStringsConfig.footer,
                      pageFooter: i18nStringsConfig.pageFooter
                    } : undefined}/>
                  </Column>
                }
                {
                  isCompletionPage &&
                  <CourseCompletePage course={course} i18nStrings={i18nStringsConfig?.courseCompleted}/>
                }
                <CourseRequirementsIncompleteModal
                  isModalOpen={isMissingRequirementsModalOpened}
                  onModalClose={() => setIsMissingRequirementsModalOpened(false)}
                  requiredGadgetIds={missingRequirements}
                  i18nStrings={i18nStringsConfig?.reqsIncomplete}
                />
              </AppLayout>
          }
        </Responsive>
      </Theme>
    </CourseContextProvider>
  );
};

// eslint-disable-next-line react/display-name
export const fallbackRender = (i18nStrings?: GracefulErrorI18nStrings) => () => {
  return <GracefulError
    theme={GracefulErrorTheme.dark}
    i18nStrings={i18nStrings}
  />;
};

/**
 * Course player component
 */
export const CoursePlayer: FunctionComponent<CoursePlayerProps> = (
  coursePlayerProps: CoursePlayerProps
) => {
  return (
    <RecoilRoot>
      <div className='CoursePlayer'>
        <ErrorBoundary
          fallbackRender={
            coursePlayerProps.errorFallbackRender
            || fallbackRender(coursePlayerProps.i18nStringsConfig?.errors)
          }
          onError={coursePlayerProps.onError}
        >
          <CoursePlayerWithRecoilState {...coursePlayerProps} />
        </ErrorBoundary>
      </div>
    </RecoilRoot>
  );
};
