import {GadgetConfigAdapter} from './GadgetConfigAdapter';
import {GadgetType} from '../models/GadgetType';
import {VideoGadgetConfig} from '../../../../components/gadgets/video';

export class VideoGadgetConfigAdapter implements GadgetConfigAdapter<VideoGadgetConfig> {
  getGadgetType(): string {
    return GadgetType.VIDEO;
  }
  
  adapt(config: any): VideoGadgetConfig {
    return {
      ...config,
      title: config.title || config.tocTitle
    };
  }
}
