/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./model";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getAsset = /* GraphQL */ `query GetAsset($request: GetAssetInput!) {
  getAsset(request: $request) {
    assetMetadata
    contentType
    id
    location
    size
    status
    tags
    title
    type
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAssetQueryVariables, APITypes.GetAssetQuery>;
export const getCourseDetails = /* GraphQL */ `query GetCourseDetails($request: GetCourseDetailsInput!) {
  getCourseDetails(request: $request) {
    contents {
      contentType
      gadgets
      id
      isBlocking
      status
      title
      __typename
    }
    courseProgressInPercentage
    coverImageAssetId
    createdAt
    currentPosition {
      currentGadgetId
      currentLessonId
      displaySection
      updatedAt
      __typename
    }
    description
    id
    lastModified
    lessonViewMode
    shortDescription
    status
    tags
    title
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCourseDetailsQueryVariables,
  APITypes.GetCourseDetailsQuery
>;
export const getLatestCourseRevision = /* GraphQL */ `query GetLatestCourseRevision($request: CourseId!) {
  getLatestCourseRevision(request: $request) {
    courseId
    courseRevisionId
    externalId
    revisionId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLatestCourseRevisionQueryVariables,
  APITypes.GetLatestCourseRevisionQuery
>;
export const getLearnerState = /* GraphQL */ `query GetLearnerState($request: CourseId!) {
  getLearnerState(request: $request) {
    coursePosition {
      currentGadgetId
      currentLessonId
      displaySection
      updatedAt
      __typename
    }
    courseProgressInPercentage
    gadgetStates
    gadgetTypes
    lessonStatusById
    status
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLearnerStateQueryVariables,
  APITypes.GetLearnerStateQuery
>;
