import React, {FunctionComponent} from 'react';
import Modal, {ModalFooter} from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Responsive from '@amzn/meridian/responsive';
import Text from '@amzn/meridian/text';
import Theme from '@amzn/meridian/theme';
import {DEFAULT_GADGETS_REGISTRY} from '../../../../gadgets/registry';
import {useCurrentCourse} from '../../../../state/recoil';
import {CoursePosition, DisplaySection, Lesson, useCourseContext} from '../../../../context/course';
import brandedLight from '../../../../theme/branded-light';
import {reduce} from 'lodash';
import findIndex from 'lodash/findIndex';
import {getLessonIdFromGadgetId} from '../../../../utils/CourseUtils';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Link from '@amzn/meridian/link';
import Divider from '@amzn/meridian/divider';
import {colorPalette} from '../../../../theme';
import {
  CourseRequirementsIncompleteI18nStrings,
  defaultI18nStrings,
} from '../../../../context/course/models/I18n';
import responsiveStyles from '../../../../styles/responsive.module.scss';

interface CourseRequirementsIncompleteModalProps {
  isModalOpen: boolean;
  onModalClose: () => void;
  requiredGadgetIds: string[];
  i18nStrings?: CourseRequirementsIncompleteI18nStrings;
}

export const CourseRequirementsIncompleteModal: FunctionComponent<CourseRequirementsIncompleteModalProps> = (
  {
    isModalOpen,
    onModalClose,
    requiredGadgetIds,
    i18nStrings = defaultI18nStrings.reqsIncomplete,
  }: CourseRequirementsIncompleteModalProps
) => {
  const {setCoursePosition} = useCourseContext();
  const currentCourse = useCurrentCourse();

  const getDefaultGadgetIcon = (gadgetType: string) => {
    return DEFAULT_GADGETS_REGISTRY[gadgetType]?.iconUrl;
  };

  const onClickRequiredGadget = (lessonId: string, gadgetId: string) => {
    const newCoursePosition = {
      displaySection: DisplaySection.LESSON,
      lessonId,
      gadgetId: gadgetId,
      updatedAt: new Date()
    } as CoursePosition;
    setCoursePosition(newCoursePosition);
    onModalClose();
  };

  const aggregatedGadgets: Record<string, string[]> = reduce(requiredGadgetIds, (acc: Record<string, string[]>, gadgetId: string) => {
    const lessonId = getLessonIdFromGadgetId(currentCourse?.lessons as Lesson[], gadgetId);
    return {
      ...acc,
      [lessonId]: [
        ...(acc[lessonId] || []),
        gadgetId
      ]};
  }, {});

  const getLessonTitle = (lessonId: string) => {
    const lessonIndex = findIndex(currentCourse?.lessons, ['id', lessonId]);
    return currentCourse?.lessons[lessonIndex].title;
  };

  const getGadgetData = (lessonId: string, gadgetId: string) => {
    const lessonIndex = findIndex(currentCourse?.lessons, ['id', lessonId]);
    const gadgetIndex = findIndex(currentCourse?.lessons[lessonIndex].gadgets, ['id', gadgetId]);
    return currentCourse?.lessons[lessonIndex].gadgets[gadgetIndex];
  };

  const getGadgetTitle = (lessonId: string, gadgetId: string) => {
    const gadget = getGadgetData(lessonId, gadgetId);
    return gadget?.config.title;
  };

  const getGadgetIcon = (lessonId: string, gadgetId: string) => {
    const gadget = getGadgetData(lessonId, gadgetId);
    return gadget?.config.icon || getDefaultGadgetIcon(gadget?.type as string);
  };

  const textToken = {'textColorPrimary': colorPalette.wave500};

  return (
    <Theme tokens={brandedLight}>
      <Modal
        title={i18nStrings.courseRequirementsIncomplete}
        open={isModalOpen}
        onClose={onModalClose}
        scrollContainer='modal'
        closeLabel={i18nStrings?.closeModalLabel}
        aria-describedby='course-requirements-incomplete'
        width='550px'
        bodySpacingInset={'400 400 600 450'}
      >
        <Text id='course-requirements-incomplete'>{i18nStrings.requirementsToCompleteThisCourse}</Text>

        {Object.keys(aggregatedGadgets).map((lessonId: string, lessonIndex: number) =>
          <Column spacingInset={'400 0'} width={'fit-content'} key={lessonIndex}>
            <Heading level={1} type='h100'>{getLessonTitle(lessonId)}</Heading>
            <Column>
              {aggregatedGadgets[lessonId].map((gadgetId: string, gadgetIndex: number) =>
                <Row spacingInset={'100 0'} alignmentVertical={'center'} spacing={'300'} key={gadgetIndex}>
                  <img
                    src={getGadgetIcon(lessonId, gadgetId)}
                    className='gadget-icon'
                    alt='gadget icon'
                    aria-hidden={true}
                    width={'16px'}
                    height={'16px'}
                  />
                  <Link type={'secondary'} onClick={() => onClickRequiredGadget(lessonId, gadgetId)}>
                    <Theme tokens={textToken}>
                      <Text type='b300'>
                        {getGadgetTitle(lessonId, gadgetId)}
                      </Text>
                    </Theme>
                  </Link>
                </Row>
              )}
            </Column>
            <Divider/>
          </Column>
        )}
        <ModalFooter>
          <Row alignmentHorizontal='right' widths='fit'>
            <Responsive
              query='max-width'
              props={{
                buttonProps: {
                  default: undefined,
                  [responsiveStyles.tablet_breakpoint]: {minWidth: '100%'}
                }
              }}
            >
              {(props: { buttonProps: Record<string, string> | undefined }) =>
                <Button type='primary' size='medium' onClick={onModalClose} {...props.buttonProps}>
                  {i18nStrings.returnToCourse}
                </Button>}
            </Responsive>
          </Row>
        </ModalFooter>
      </Modal>
    </Theme>
  );
};
