/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import shaka from 'shaka-player/dist/shaka-player.ui';

export const CUSTOM_REWIND_15_BUTTON_NAME = 'soju_rewind_15';
export const CUSTOM_FAST_FORWARD_15_BUTTON_NAME = 'soju_fast_forward_15';

/**
 * Custom Shaka UI button for seeking video
 */
export class CustomSeekButton extends shaka.ui.Element {

  button: HTMLButtonElement;

  static goBackwardXSeconds = (timeInSeconds: number) => `Move backward ${timeInSeconds} seconds`;
  static goForwardXSeconds = (timeInSeconds: number) => `Move forward ${timeInSeconds} seconds`;

  static setCustomSeekTooltipMessages(goBackward: (timeInSeconds: number) => string, goForward: (timeInSeconds: number) => string) {
    this.goBackwardXSeconds = goBackward;
    this.goForwardXSeconds = goForward;
  }

  constructor(parent: HTMLElement, controls: shaka.ui.Controls, seekDelta: number) {
    super(parent, controls);

    this.button = document.createElement('button');
    this.button.classList.add('shaka-controls-soju-seek', 'shaka-tooltip');
    this.button.ariaLabel = this.getTooltip(seekDelta);

    // setting seekButtonType based on seek value
    if(seekDelta > 0) {
      this.button.classList.add('forward-button', 'shaka-fast-forward-button');
    } else {
      this.button.classList.add('rewind-button', 'shaka-rewind-button');
    }

    parent.appendChild(this.button);

    // @ts-ignore
    this.eventManager.listen(controls.getVideoContainer(), 'keydown', async e => {
      if (e.key === 'ArrowLeft' && seekDelta < 0 || e.key === 'ArrowRight' && seekDelta > 0) {
        // Change current time, if volume control bar not in focus.
        if (!document?.activeElement?.classList.contains('shaka-volume-bar')) {
          this.changePlaybackTime(seekDelta);
          e.preventDefault();
        }
      }
    });

    // @ts-ignore
    this.eventManager.listen(this.button, 'click', async () => {
      this.changePlaybackTime(seekDelta);
    });
  }

  changePlaybackTime(seekDelta = 0) {
    // @ts-ignore
    if (!this.video.duration) {
      return;
    }

    // @ts-ignore
    this.video.currentTime += seekDelta;
  }

  /**
   * Returns tooltip message.
   */
  getTooltip(delta: number) {
    return delta < 0
      ? CustomSeekButton.goBackwardXSeconds(Math.abs(delta))
      : CustomSeekButton.goForwardXSeconds(delta);
  }

}


export class CustomSeekButtonFactory {
  seekDelta: number

  constructor( seekDelta: number) {
    this.seekDelta = seekDelta;
  }

  create(rootElement: HTMLElement, controls: shaka.ui.Controls) {
    return new CustomSeekButton(rootElement, controls, this.seekDelta);
  }
}

shaka.ui.Controls.registerElement(
  CUSTOM_REWIND_15_BUTTON_NAME,
  new CustomSeekButtonFactory(-15)
);
shaka.ui.Controls.registerElement(
  CUSTOM_FAST_FORWARD_15_BUTTON_NAME,
  new CustomSeekButtonFactory(15)
);
