/**
 * Possible course display sections
 */
export enum DisplaySection {
  /**
   * Lesson section
   */
  LESSON = 'LESSON',

  /**
   * Course has been completed
   */
  COMPLETION_PAGE = 'COMPLETION_PAGE',
}
