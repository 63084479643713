import {atom, DefaultValue, selector, selectorFamily, useRecoilValue} from 'recoil';
import {find} from 'lodash';
import {
  Course,
  CourseLearnerState,
  CoursePosition,
  CourseProgress,
  DisplaySection,
  Gadget,
  GadgetLearnerState,
  Lesson,
  ProgressStatus,
  ScrollToGadgetId
} from '../context/course';
import {PlayerUIModes, SidebarState} from '../components/containers/course-player';
import {LessonViewMode} from '../context/course/models/LessonViewMode';
import {LessonPartition, PartitionedLesson} from '../context/course/models/Lesson';
import {getPartitionIndexByGadgetId} from '../utils/LessonPartitionUtils';

export const currentCourse = atom<Course | null>({
  key: 'Course',
  default: null
});

export const currentCourseLearnerState = atom<CourseLearnerState | null>({
  key: 'CourseLearnerState',
  default: null
});

export const currentScrollToGadgetId = atom<ScrollToGadgetId | undefined>({
  key: 'ScrollToGadgetId',
  default: undefined
});

export const playerUIModes = atom<PlayerUIModes>({
  key: 'playerUIModes',
  default: {sidebarState: SidebarState.EXPANDED}
});

export const currentSidebarState = selector<SidebarState>({
  key: 'PlayerSidebarState',
  get: ({get}) => get(playerUIModes)?.sidebarState,
  set: ({set, get}, sidebarState) => {
    set(playerUIModes, {
      ...get(playerUIModes),
      sidebarState: (sidebarState instanceof DefaultValue ? SidebarState.EXPANDED : sidebarState)
    });
  }
});

export const currentCoursePosition = selector<CoursePosition | null>({
  key: 'CoursePosition',
  get: ({get}) => get(currentCourseLearnerState)?.coursePosition || null,
  set: ({set, get}, coursePosition) => {
    set(currentCourseLearnerState, learnerState => {
      return {
        ...learnerState,
        updatedAt: new Date(),
        coursePosition: (coursePosition instanceof DefaultValue ? null : coursePosition)
      };
    });
    const {lessonId, gadgetId} = coursePosition as CoursePosition;
    const partitionedLessons = get(currentCourseLessonsPartitionedByViewMode);
    const partitionedLesson = partitionedLessons[lessonId];
    set(currentLessonPartitionIndex, lessonPartitionIndex => {
      if (partitionedLesson?.partitions && gadgetId) {
        return getPartitionIndexByGadgetId(gadgetId, partitionedLesson?.partitions);
      } else {
        return lessonPartitionIndex;
      }
    });
  }
});

export const currentCoursePositionDisplaySection = selector<DisplaySection>({
  key: 'CoursePositionDisplaySection',
  get: ({get}) => get(currentCoursePosition)?.displaySection || DisplaySection.LESSON,
});

export const currentCourseProgress = selector<CourseProgress>({
  key: 'CourseProgress',
  get: ({get}) => get(currentCourseLearnerState)?.courseProgress || {
    status: ProgressStatus.NOT_STARTED,
    completionPercentage: 0,
    lessonStatusById: {}
  },
  set: ({set, get}, courseProgress) => {
    set(currentCourseLearnerState, {
      ...get(currentCourseLearnerState),
      updatedAt: new Date(),
      courseProgress: (courseProgress instanceof DefaultValue ? null : courseProgress)
    });
  }
});

export const currentCourseProgressStatus = selector<ProgressStatus>({
  key: 'CourseProgressStatus',
  get: ({get}) => {
    const courseProgressStatus = get(currentCourseLearnerState)?.courseProgress?.status;
    return courseProgressStatus ? courseProgressStatus : ProgressStatus.NOT_STARTED;
  },
  set: ({set, get}, progressStatus) => {
    set(currentCourseProgress, courseProgress => {
      return {
        ...courseProgress,
        status: progressStatus instanceof DefaultValue ? ProgressStatus.NOT_STARTED : progressStatus
      };
    });
  },
});

export const currentCourseLessonStatus = selectorFamily<ProgressStatus, string>({
  key: 'CourseLessonProgressStatus',
  get: lessonId => ({get}) => {
    const lessonStatusById = get(currentCourseProgress)?.lessonStatusById;
    return lessonStatusById ? lessonStatusById[lessonId] : ProgressStatus.NOT_STARTED;
  },
  set: lessonId => ({set, get}, progressStatus) => {
    set(currentCourseProgress, courseProgress => {
      return {
        ...courseProgress,
        lessonStatusById: {
          ...courseProgress?.lessonStatusById,
          [lessonId]: progressStatus instanceof DefaultValue ? ProgressStatus.NOT_STARTED : progressStatus
        }
      };
    });
  },
});

export const currentCourseLesson = selector<Lesson | null>({
  key: 'CurrentCourseLesson',
  get: ({get}) => {
    const coursePosition = get(currentCourseLearnerState)?.coursePosition;
    const course = get(currentCourse);
    return find(course?.lessons, {id: coursePosition?.lessonId}) || null;
  },
});

export const currentPartitionTitle = selector<string | undefined>({
  key: 'currentPartitionTitle',
  get: ({get}) => {
    const partition = get(currentLessonPartition);
    if (partition) {
      return partition.title;
    } else {
      return undefined;
    }
  }
});

export const currentLessonViewMode = selector<LessonViewMode>({
  key: 'CurrentLessonViewMode',
  get: ({get}) => {
    const course = get(currentCourse);
    return course?.lessonViewMode || LessonViewMode.All;
  }
});

export const currentLessonPartitionIndex = atom<number>({
  key: 'CurrentLessonPartitionIndex',
  default: 0
});

export const currentCourseLessonsPartitionedByViewMode = atom<Record<string, PartitionedLesson>>({
  key: 'CurrentCourseLessonsPartitionedByViewMode',
  default: {}
});

export const currentLessonPartitionedByViewMode = selector<PartitionedLesson | null>({
  key: 'CurrentLessonPartitionedByViewMode',
  get: ({get}) => {
    const coursePosition = get(currentCourseLearnerState)?.coursePosition;
    const lessonsPartitioned = get(currentCourseLessonsPartitionedByViewMode);
    return find(lessonsPartitioned, {id: coursePosition?.lessonId}) || null;
  }
});

export const currentLessonPartition = selector<LessonPartition | null>({
  key: 'CurrentLessonPartition',
  get: ({get}) => {
    const currentLessonPartitioned = get(currentLessonPartitionedByViewMode);
    const partitionIndex = get(currentLessonPartitionIndex);
    return currentLessonPartitioned?.partitions[partitionIndex] || null;
  }
});

export const currentCourseLessonGadget = selector<Gadget | null>({
  key: 'CurrentCourseLessonGadget',
  get: ({get}) => {
    const coursePosition = get(currentCourseLearnerState)?.coursePosition;
    const lesson = get(currentCourseLesson);
    return find(lesson?.gadgets, {id: coursePosition?.gadgetId}) || null;
  },
});

export const currentCourseGadgetsLearnerState = selector<Record<string, GadgetLearnerState>>({
  key: 'CurrentCourseGadgetsLearnerState',
  get: ({get}) => {
    return get(currentCourseLearnerState)?.gadgetsState || {};
  },
  set: ({get,set}, gadgetsState) => {
    set(currentCourseLearnerState, learnerState => { return {
      ...learnerState,
      updatedAt: new Date(),
      gadgetsState: gadgetsState instanceof DefaultValue ? {} : gadgetsState
    };});
  }
});

/**
 * React hook to use the current course
 */
export const useCurrentCourse = (): Course | null => {
  return useRecoilValue(currentCourse);
};

/**
 * React hook to use the learner state for the current course
 */
export const useCurrentCourseLearnerState = (): CourseLearnerState | null => {
  return useRecoilValue(currentCourseLearnerState);
};

/**
 * React hook to use the learner current position on the course
 */
export const useCurrentCoursePosition = (): CoursePosition | null => {
  return useRecoilValue(currentCoursePosition);
};

/**
 * React hook to use the learner current progress on the course
 */
export const useCurrentCourseProgress = (): CourseProgress | null => {
  return useRecoilValue(currentCourseProgress);
};

/**
 * React hook to use the current lesson
 */
export const useCurrentCourseLesson = (): Lesson | null => {
  return useRecoilValue(currentCourseLesson);
};

export const useCurrentLessonViewMode = (): LessonViewMode => {
  return useRecoilValue(currentLessonViewMode);
};

export const useCurrentLessonPartitionIndex = (): number => {
  return useRecoilValue(currentLessonPartitionIndex);
};

export const useCurrentLessonPartitionedByViewMode = (): PartitionedLesson | null => {
  return useRecoilValue(currentLessonPartitionedByViewMode);
};

export const useCurrentLessonPartition = (): LessonPartition | null => {
  return useRecoilValue(currentLessonPartition);
};

export const useCurrentPartitionTitle = (): string | undefined => {
  return useRecoilValue(currentPartitionTitle);
};

/**
 * React hook to use the gadget's current learner state
 */
export const useCurrentCourseGadgetsLearnerState = (): Record<string, GadgetLearnerState> => {
  return useRecoilValue(currentCourseGadgetsLearnerState);
};

/**
 * React hook to know the sidebar state
 */
export const useSidebarState = (): SidebarState => {
  return useRecoilValue(currentSidebarState);
};

/**
 * React hook to know the current display section
 */
export const useCurrentCourseDisplaySection = (): DisplaySection => {
  return useRecoilValue(currentCoursePositionDisplaySection);
};


export const useScrollToGadgetId = (): ScrollToGadgetId | undefined => {
  return useRecoilValue(currentScrollToGadgetId);
};
