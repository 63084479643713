export enum ScriptAction {
  CHECK = 'CHECK',
  SETUP = 'SETUP',
  CLEANUP = 'CLEANUP'
}

export enum OtherScriptTypes {
  POLICY = 'POLICY'
};

export type AllScriptTypes = ScriptAction | OtherScriptTypes;

export type TScript = {
  action: ScriptAction,
  content: string
};
