import {Gadget, GadgetConfig} from '../../../context/course';
import {AuthoringGadgetRenderer} from '../gadgets/registry';
import {GadgetEditorProps} from '../gadgets/models';
import React, {FunctionComponent, RefObject} from 'react';
import Row from '@amzn/meridian/row';

import './styles/gadget-editor-container.scss';
import {I18nStrings} from '../../../context/course/models/I18n';
import Box from '@amzn/meridian/box';

/**
 * Full screen Gadget's editor container rendering props
 */
interface FullScreenGadgetEditorContainerProps {
  /**
   * Gadget to edit
   */
  gadget: Gadget,

  /**
   * Gadgets registry to use
   */
  gadgetsRegistry: AuthoringGadgetRenderer,

  /**
   * map of gadget ids to ref object of the gadget's container
   */
  gadgetRefsByGadgetId: Record<string, RefObject<HTMLElement>>;
}

/**
 * Renders a gadget's editor inside the lesson editor in Full Screen mode.
 *
 * This function uses memo to avoid re-rendering gadgets if their state hasn't changed
 */
export const FullScreenGadgetEditorContainer: FunctionComponent<FullScreenGadgetEditorContainerProps> =  ({
  gadget,
  gadgetsRegistry,
  gadgetRefsByGadgetId,
}: FullScreenGadgetEditorContainerProps) => {

  const {
    editorComponent: GadgetEditorComponent,
    i18nStrings
  } = gadgetsRegistry[gadget.type];

  const gadgetComponentProps = {
    id: gadget.id,
    type: gadget.type,
    config: gadget.config,
    isFullScreen: true
  } as GadgetEditorProps<GadgetConfig, I18nStrings>;

  return (
    <>
      <Box
        className='gadgetEditor__fullScreen'
      >
        {GadgetEditorComponent &&
          <GadgetEditorComponent
            {...gadgetComponentProps}
            i18nStrings={i18nStrings}
            ref={gadgetRefsByGadgetId[gadget.id]}
          />}
        {!GadgetEditorComponent && <div>Invalid Gadget</div>}
      </Box>
    </>
  );
};
