import React, {FunctionComponent} from 'react';
import Alert from '@amzn/meridian/alert';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import {InvalidFile} from '../../asset-uploader/AssetUploader';
import {isEmpty} from 'lodash';

import '../AuthoringAlerts.scss';

export interface InvalidFileUploadAlertProps {
  invalidFiles: InvalidFile[],
  onAlertClose: (index: number) => void
}

export const InvalidFileUploadAlert: FunctionComponent<InvalidFileUploadAlertProps> = (
  {
    invalidFiles,
    onAlertClose
  }: InvalidFileUploadAlertProps) => {

  if (isEmpty(invalidFiles)) {
    return null;
  }

  return (
    <Column spacingInset={'450'} className={'invalid__file__upload__alert__overlay'}>
      {invalidFiles.map((invalidFile: InvalidFile, index: number) =>
        <Box width={'100%'} key={index}>
          <Alert type='error' onClose={() => onAlertClose(index)} title={invalidFile.error.title}>
            {invalidFile.error.message}
          </Alert>
        </Box>
      )}
    </Column>
  );
};

InvalidFileUploadAlert.defaultProps = {
  invalidFiles: []
};
