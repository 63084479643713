import {GadgetConfigAdapter} from './GadgetConfigAdapter';
import {GadgetType} from '../models/GadgetType';
import {ResponseChallenge, ResponseGadgetConfig} from '../../../../components/gadgets/response';
import map from 'lodash/map';
import {head, isArray, isString} from 'lodash';
import {shortId} from '../../../../gadgets/utils/shortId';

export class ResponseGadgetConfigAdapter implements GadgetConfigAdapter<ResponseGadgetConfig> {
  getGadgetType(): string {
    return GadgetType.RESPONSE;
  }

  adapt(config: any): ResponseGadgetConfig {
    return {
      ...config,
      title: config.title || config.tocTitle,
      challenges: map(config.challenges, it => {
        if (isArray(it.answers) && isString(head(it.answers))) {
          return {
            ...it,
            id: it.id || shortId(),
            answers: map(it.answers, answer => {
              return {
                id: shortId(),
                answer
              };
            })
          } as ResponseChallenge;
        }
        return it;
      })
    };
  }
}
