import React, {FunctionComponent, useCallback, useState} from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import { v4 as uuidv4 } from 'uuid';

import Alert from '@amzn/meridian/alert';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Input from '@amzn/meridian/input';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import closeSmallTokens from '@amzn/meridian-tokens/base/icon/close-small';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';

import {ResponseChallenge, ResponseChallengeAnswer} from './';

import './styles/response-gadget.scss';

const LABELS = {
  questionLabel: 'Question',
  questionPlaceholder: 'Enter your question',
  deleteButtonAriaLabel: 'Delete question',
  answerPlaceholder: 'Type your answer...',
  deleteAnswerButtonAriaLabel: 'Delete answer',
  addAnswerButtonAriaLabel: 'Add new answer',
  deleteQuestionAlertTitle: 'Delete question',
  deleteQuestionAlertPlaceholder: 'Are you sure? This cannot be undone.',
  deleteQuestionCancelButtonPlaceholder: 'Cancel',
  deleteQuestionDeleteButtonPlaceholder: 'Delete question',
  questionAnswersTitle: (i: number) => `Question ${i} answer options`
};

interface ResponseChallengeProps extends ResponseChallenge {
  prompt: string,
  answers: ResponseChallengeAnswer[],
  challengeKey: string,
  index: number,
  onDeleteChallenge: () => void,
  onUpdateChallenge: (val: ResponseChallenge) => void
}

interface ResponseChallengeAnswerProps extends ResponseChallengeAnswer {
  answer: string,
  onAddAnswer: () => void,
  onDeleteAnswer: () => void,
  onUpdateAnswer: (value: string) => void
}

export const ResponseGadgetChallenge: FunctionComponent<ResponseChallengeProps> = (
  props : ResponseChallengeProps
) => {
  const {
    prompt: initialPrompt,
    answers: initialAnswers,
    challengeKey,
    index,
    onDeleteChallenge

  } = props;

  const [shouldConfirmDelete, setShouldConfirmDelete] = useState<boolean>(false);

  const updatePrompt = useCallback((newPrompt: string) => {
    props.onUpdateChallenge({
      id: props.id,
      answers: props.answers,
      prompt: newPrompt
    } as ResponseChallenge);
  }, [props.id, props.prompt, props.answers, props.onUpdateChallenge]);

  const onAddAnswer = useCallback(() => {
    props.onUpdateChallenge({
      id: props.id,
      prompt: props.prompt,
      answers: [...props.answers, generateEmptyAnswer()]
    } as ResponseChallenge);
  }, [props.id, props.prompt, props.answers, props.onUpdateChallenge]);

  const onDeleteAnswer = useCallback((i: number) => {
    let newAnswers: ResponseChallengeAnswer[];
    if(size(props.answers) > 1) {
      newAnswers = [...props.answers];
      newAnswers.splice(i, 1);
    } else {
      newAnswers = [generateEmptyAnswer()];
    }
    props.onUpdateChallenge({
      id: props.id,
      prompt: props.prompt,
      answers: newAnswers
    } as ResponseChallenge);
  }, [props.id, props.prompt, props.answers, props.onUpdateChallenge]);

  const onUpdateAnswer = useCallback((i: number, id: string, value: string) => {
    const newAnswers: ResponseChallengeAnswer[] = [...props.answers];
    newAnswers[i] = {id, answer: value};
    props.onUpdateChallenge({
      id: props.id,
      prompt: props.prompt,
      answers: newAnswers
    } as ResponseChallenge);
    
  }, [props.id, props.prompt, props.answers, props.onUpdateChallenge]);

  const showDeleteQuestionWarning = useCallback(() => {
    setShouldConfirmDelete(true);
  }, []);

  const dismissDeleteQuestionConfirmation = useCallback(() => {
    setShouldConfirmDelete(false);
  }, []);

  const deleteQuestion = useCallback(() => {
    onDeleteChallenge();
    setShouldConfirmDelete(false);
  }, []);

  return (
    <Box className={'response-gadget__question__container'}>
      {shouldConfirmDelete &&
        <Box type="outline" className={'response-gadget__question__delete-overlay'}>
          <Column spacingInset='medium' spacing='medium'>
            <Alert
              title={LABELS.deleteQuestionAlertTitle}
              type='warning'
            >
              {LABELS.deleteQuestionAlertPlaceholder}
            </Alert>
            <Row widths={['fit', 'fit']} alignmentHorizontal='end'>
              <Button type='link'
                onClick={dismissDeleteQuestionConfirmation}>{LABELS.deleteQuestionCancelButtonPlaceholder}</Button>
              <Button type='primary' onClick={deleteQuestion}>{LABELS.deleteQuestionDeleteButtonPlaceholder}</Button>
            </Row>
          </Column>
        </Box>
      }
      <Column>
        <Row widths={['fill', 'fit']}>
          <Text type='h100' htmlFor={challengeKey}>{LABELS.questionLabel} {index + 1}</Text>
          <Button
            type='icon'
            aria-label={`${LABELS.deleteButtonAriaLabel} ${index}`}
            onClick={showDeleteQuestionWarning}
          >
            <Icon tokens={trashTokens} />
          </Button>
        </Row>
        <Input
          id={challengeKey}
          value={initialPrompt}
          onChange={updatePrompt}
          type='text'
          placeholder={LABELS.questionPlaceholder}
        />
      </Column>
      <Column spacing='medium' spacingInset='large none none none'>
        <Text type='h100'>{LABELS.questionAnswersTitle(index + 1)}</Text>
        {map(initialAnswers, (answer: ResponseChallengeAnswer, i: number) => 
          <ChallengeAnswer
            key={`${challengeKey}-answer-${answer.id}`}
            answer={answer.answer}
            id={answer.id}
            onAddAnswer={onAddAnswer}
            onDeleteAnswer={() => onDeleteAnswer(i)}
            onUpdateAnswer={(value: string) => onUpdateAnswer(i, answer.id, value)}
          />
        )}
      </Column>
    </Box>
  );
};

export const ChallengeAnswer: FunctionComponent<ResponseChallengeAnswerProps> = (
  {
    answer,
    onUpdateAnswer,
    onDeleteAnswer,
    onAddAnswer
  } : ResponseChallengeAnswerProps
) => {
  return (
    <Row widths={['fill', 'fit']}>
      <Input
        value={answer}
        onChange={onUpdateAnswer}
        type='text'
        placeholder={LABELS.answerPlaceholder}
      />
      <Box>
        <Button
          type='icon'
          aria-label={LABELS.deleteAnswerButtonAriaLabel}
          onClick={onDeleteAnswer}
        >
          <Icon tokens={closeSmallTokens} />
        </Button>
        <Button
          type='icon'
          aria-label={LABELS.addAnswerButtonAriaLabel}
          onClick={onAddAnswer}
        >
          <Icon tokens={plusTokens} />
        </Button>
      </Box>
    </Row>
  );
};

const generateEmptyAnswer = () => {
  return {
    id: uuidv4(),
    answer: ''
  };
};
