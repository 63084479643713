import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';
import checkKnockoutTokens from '@amzn/meridian-tokens/base/icon/check-knockout';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import {ScriptModal} from '../ScriptModal';
import {ScriptAction, AllScriptTypes, TScript} from '../models/Script';

import './styles/lab-challenge-scripts.scss';

export type LabChallengeScriptsProps = {
  /**
   * Track scripts: CLEANUP and SETUP
   */
  challengeScripts?: TScript[],

  /**
   * Callback to call when a track script is modified
   */
  onTrackScriptChange: (trackScript: TScript) => void
};

export const LabChallengeScripts: FunctionComponent<LabChallengeScriptsProps> = (
  {
    challengeScripts,
    onTrackScriptChange,
  }: LabChallengeScriptsProps
) => {

  const [isScriptModalOpen, setIsScriptModalOpen] = React.useState<boolean>(false);
  const [scripts, setScripts] = React.useState<TScript[]>(challengeScripts || []);
  const [activeScriptType, setActiveScriptType] = React.useState<ScriptAction>();

  const onScriptChange = (newScript: string) => {
    if (activeScriptType) {
      const scriptIndex = findIndex(scripts, ['action', activeScriptType]);

      const updateScript = {
        action: activeScriptType,
        content: newScript
      } as TScript

      const newTrackScripts = [...(scripts || [])];
      if(scriptIndex === -1) {
        newTrackScripts.push(updateScript);
      } else {
        newTrackScripts[scriptIndex] = updateScript;
      }
      setScripts(newTrackScripts);

      onTrackScriptChange(updateScript);
    }
  };

  const openModal = (scriptType: ScriptAction) => {
    setActiveScriptType(scriptType);
    setIsScriptModalOpen(true);
  };

  // Gets the content that will be passed down to the Ace editor modal
  const getActiveContent = () => {
    const content = (find(scripts, ['action', activeScriptType]) as TScript)?.content;
    return content;
  };

  const setupScript = find(scripts, ['action', ScriptAction.SETUP]);
  const checkScript = find(scripts, ['action', ScriptAction.CHECK]);
  const cleanupScript = find(scripts, ['action', ScriptAction.CLEANUP]);
  const hasSetupScript = setupScript && !isEmpty(setupScript.content);
  const hasCheckScript = checkScript && !isEmpty(checkScript.content);
  const hasCleanupScript = cleanupScript && !isEmpty(cleanupScript.content);

  return (
    <>
      <Box backgroundColor='secondary' spacingInset={'400 450'}>
        <Column>
          <Row>
            <Text type='h100'>Exercise scripts</Text>
          </Row>

          <Box>
            <Column>
              <Row widths={['fill', 'fit']}>
                <Box>
                  <Text type='b200'>
                    <Icon
                      tokens={hasSetupScript ? checkKnockoutTokens : infoKnockoutTokens}
                      className={cx('labChallengeScripts__statusIcon', {'labChallengeScripts__statusIcon--completed': hasSetupScript})}
                    /> 
                    <strong>Setup</strong> {hasSetupScript ? 'script provided' : 'script not defined'}
                  </Text>
                </Box>
                <Button
                  type={hasSetupScript ? 'primary' : 'tertiary'}
                  onClick={() => openModal(ScriptAction.SETUP)}
                >
                  <Icon tokens={exportSmallTokens} /> Edit
                </Button>
              </Row>
              <Row widths={['fill', 'fit']}>
                <Box>
                  <Text type='b200'>
                    <Icon
                      tokens={hasCheckScript ? checkKnockoutTokens : infoKnockoutTokens}
                      className={cx('labChallengeScripts__statusIcon', {'labChallengeScripts__statusIcon--completed': hasCheckScript})}
                    /> 
                    <strong>Check</strong> {hasCheckScript ? 'script provided' : 'script not defined'}
                  </Text>
                </Box>
                <Button
                  type={hasCheckScript ? 'primary' : 'tertiary'}
                  onClick={() => openModal(ScriptAction.CHECK)}
                >
                  <Icon tokens={exportSmallTokens} /> Edit
                </Button>
              </Row>
              <Row widths={['fill', 'fit']}>
                <Box>
                  <Text type='b200'>
                    <Icon
                      tokens={hasCleanupScript ? checkKnockoutTokens : infoKnockoutTokens}
                      className={cx('labChallengeScripts__statusIcon', {'labChallengeScripts__statusIcon--completed': hasCleanupScript})}
                    /> 
                    <strong>Cleanup</strong> {hasCleanupScript ? 'script provided' : 'script not defined'}
                  </Text>
                </Box>
                <Button
                  type={hasCleanupScript ? 'primary' : 'tertiary'}
                  onClick={() => openModal(ScriptAction.CLEANUP)}
                >
                  <Icon tokens={exportSmallTokens} /> Edit
                </Button>
              </Row>
            </Column>
          </Box>
        </Column>
      </Box>
      {isScriptModalOpen &&
        <ScriptModal
          isModalOpen={isScriptModalOpen}
          title='Edit Setup Script'
          onClose={() => setIsScriptModalOpen(false)}
          onChange={onScriptChange}
          content={getActiveContent()}
        />
      }
    </>
  );
};
