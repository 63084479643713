import Theme from '@amzn/meridian/theme';
import {brandedLightTokens} from '../../../../theme';
import Modal, {ModalFooter} from '@amzn/meridian/modal';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Checkbox from '@amzn/meridian/checkbox';
import Button from '@amzn/meridian/button';
import editTokens from '@amzn/meridian-tokens/base/icon/edit';
import Icon from '@amzn/meridian/icon';
import React, {FunctionComponent, useState} from 'react';
import {FileProcessingInfoI18nStrings, WithI18nStringsProps} from '../../../../context/course/models/I18n';
import useSessionStorage from '../../../../hooks/useSessionStorage';

export const HideFileProcessingInfoStorageKey = '__HideFileProcessingInfo__';

export interface FileProcessingInfoModalProps extends WithI18nStringsProps<FileProcessingInfoI18nStrings> {
  open: boolean;
  onClose: () => void;
}

export const FileProcessingInfoModal: FunctionComponent<FileProcessingInfoModalProps> = ({
  open,
  onClose,
  i18nStrings
}: FileProcessingInfoModalProps)=> {

  const [/* hideFutureFileProcessingInfoModalsSession */, setHideFutureFileProcessingInfoModalsSession] = useSessionStorage<boolean>(HideFileProcessingInfoStorageKey, false);
  const [hideFutureFileProcessingInfoModalsChecked, setHideFutureFileProcessingInfoModalsChecked] = useState<boolean>(false);
  
  const onDismiss = () => {
    setHideFutureFileProcessingInfoModalsSession(hideFutureFileProcessingInfoModalsChecked);
    setHideFutureFileProcessingInfoModalsChecked(false);
    onClose();
  };

  return (
    <Theme tokens={{...brandedLightTokens}}>
      <Modal
        open={open}
        onClose={onClose}
        title={i18nStrings.title}
        closeLabel={i18nStrings.dismissLabel}
        width='750px'
      >
        <Column spacing='400'>
          <Text>
            {i18nStrings.checkStatusLabel} <Icon tokens={editTokens} />
          </Text>
        </Column>
        <ModalFooter>
          <Row alignmentHorizontal='right' widths={['fill', 'fit']}>
            <Checkbox onChange={setHideFutureFileProcessingInfoModalsChecked} checked={hideFutureFileProcessingInfoModalsChecked}>
              {i18nStrings.dontShowAgainLabel}
            </Checkbox>
            <Button onClick={onDismiss}>
              {i18nStrings.dismissLabel}
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </Theme>
  );
};
