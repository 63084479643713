import React, {FunctionComponent, useCallback, useState} from 'react';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Theme from '@amzn/meridian/theme';
import Text from '@amzn/meridian/text';
import Responsive from '@amzn/meridian/responsive';
import {brandedLightTokens} from '../../../theme';
import waveTokens from '../../../theme/wave';
import Icon from '@amzn/meridian/icon';
import checkCircleTokens from '@amzn/meridian-tokens/base/icon/check-circle';
import {Course, CoursePosition, DisplaySection, ProgressStatus, useCourseContext} from '../../../context/course';
import {findIndex, noop} from 'lodash';
import {LearnerLessonActivity} from '../../../activity/models/learner-activities';
import {LearnerVerb} from '../../../activity/models/verbs';
import {LearnerObject} from '../../../activity/models/objects';

import {PageFooter} from './PageFooter';
import {LessonViewMode} from '../../../context/course/models/LessonViewMode';
import {
  defaultI18nStrings,
  FooterI18nStrings,
  PageFooterI18nStrings,
  WithI18nStringsProps
} from '../../../context/course/models/I18n';

import './styles/course-nav.scss';
import responsiveStyles from '../../../styles/responsive.module.scss';

/**
 * PlayerFooter props
 */
export interface FooterProps extends WithI18nStringsProps<{
  footer: FooterI18nStrings;
  pageFooter: PageFooterI18nStrings;
} | undefined> {

  /**
   * Current course
   */
  course: Course;

  /**
   * Learner's position in the course
   */
  coursePosition: CoursePosition | null;

  /**
   * callback for when partition nav buttons are clicked.
   * @param newIndex the partition index to navigate to
   */
  onPartitionIndexChanged: (newIndex: number) => void;

  /**
   * the current partition index for the current lesson
   */
  currentPartitionIndex: number;

  /**
   * total number of partitions for the current lesson
   */
  totalPartitions: number;

  /**
   * the current view mode for the current lesson
   */
  viewMode: LessonViewMode;

  /**
   * current lesson's title. used in place of the section title if there are no sections in the
   * lesson, or if the current partition is before the first section in the lesson.
   *
   * also used to provide more context to screen readers in aria-labels
   */
  lessonTitle: string;

  /**
   * the title of the current partition. used to display the title of the section
   * that the current partition belongs to.
   */
  partitionTitle?: string;

  /**
   * Callback when the learner clicks the mark complete lesson button.
   */
  onCompleteClick?: (lessonId: string | null) => void;
}

/**
 * Player footer component
 */
export const Footer: FunctionComponent<FooterProps> = ({
  course,
  coursePosition,
  currentPartitionIndex,
  onPartitionIndexChanged,
  totalPartitions,
  viewMode,
  lessonTitle,
  partitionTitle,
  onCompleteClick = noop,
  i18nStrings = {footer: defaultI18nStrings.footer, pageFooter: defaultI18nStrings.pageFooter},
}: FooterProps) => {
  const {
    setCoursePosition,
    setLessonProgress,
    setCourseProgress,
    emitLearnerActivity,
  } = useCourseContext();

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const currentLessonIndex = findIndex(course.lessons, {id: coursePosition?.lessonId});

  const hasPartitions = totalPartitions > 1;
  const isLastPartition = currentPartitionIndex === totalPartitions - 1;
  const isLastLesson = currentLessonIndex === course.lessons.length - 1;

  const onCompleteLesson = useCallback(async () => {
    if (!course || !coursePosition) {
      return;
    }

    // Moved mark course as complete update to the top. Last lesson will be marked as complete only after the mark
    // course as complete update. Otherwise, mark course as complete modal will be shown in the course complete page.
    if (isLastLesson) {
      setIsButtonDisabled(true);
      const response = await setCourseProgress(ProgressStatus.COMPLETED);
      if (response.statusTransitionSuccessful) {
        setCoursePosition({
          ...coursePosition,
          displaySection: DisplaySection.COMPLETION_PAGE,
          updatedAt: new Date(),
        });
      }
      setIsButtonDisabled(false);
    }
    setLessonProgress(coursePosition.lessonId, ProgressStatus.COMPLETED);
    emitLearnerActivity(
      new LearnerLessonActivity(
        LearnerVerb.COMPLETED,
        LearnerObject.LESSON,
        coursePosition?.lessonId,
      )
    );

    const currentLessonIndex = findIndex(course.lessons, {id: coursePosition?.lessonId});
    if (!isLastLesson) {
      const nextLesson = course.lessons[currentLessonIndex + 1];
      const nextGadgetId = nextLesson.gadgets[0]?.id || '';
      setCoursePosition({
        displaySection: DisplaySection.LESSON,
        lessonId: nextLesson.id,
        gadgetId: nextGadgetId,
        updatedAt: new Date()
      });
      onCompleteClick(nextLesson.id);
    }
    onCompleteClick(null);
  }, [course, coursePosition, emitLearnerActivity, isLastLesson, onCompleteClick, setCoursePosition, setCourseProgress, setLessonProgress]);

  const pageFooter = <PageFooter
    lessonTitle={lessonTitle}
    onPartitionIndexChanged={onPartitionIndexChanged}
    currentPartitionIndex={currentPartitionIndex}
    totalPartitions={totalPartitions}
    viewMode={viewMode}
    partitionTitle={partitionTitle}
    i18nStrings={i18nStrings.pageFooter}
  />;

  if (!course || !coursePosition) {
    return null;
  }

  if (hasPartitions && !isLastPartition) {
    return <Theme tokens={brandedLightTokens}>
      <div className='courseNav__page-footer'>
        {pageFooter}
      </div>
    </Theme>;
  }

  return (
    <>
      <Theme tokens={{...waveTokens}}>
        <Column backgroundColor='primary' className='courseNav__container' width='100%'>
          <Responsive
            query='max-width'
            props={{
              spacingInset: {
                default: '600',
                [responsiveStyles.tablet_breakpoint]: '600 400'
              },
              wrapper: {
                default: Row,
                [responsiveStyles.tablet_breakpoint]: Column
              },
              wrapperProps: {
                default: {
                  spacing: '600',
                  widths: ['fill', 'fit'],
                  alignmentVertical: 'top'
                },
                [responsiveStyles.tablet_breakpoint]: {
                  spacing: '400'
                }
              }
            }}
          >
            {
              props =>
                <Box className='courseNav__container__inner' spacingInset={props.spacingInset}>
                  <props.wrapper {...props.wrapperProps}>
                    <Box>
                      {!isLastLesson ?
                        <Text type='h600'>{i18nStrings.footer.readyForNextLesson}</Text> :
                        <Text type='h600'>{i18nStrings.footer.completedLastLesson(course?.title)}</Text>
                      }
                    </Box>
                    <Box>
                      <Button size='large' type='secondary' onClick={onCompleteLesson} disabled={isLastLesson && isButtonDisabled}>
                        <Icon tokens={checkCircleTokens} />{isLastLesson ? i18nStrings.footer.markCourseComplete : i18nStrings.footer.markCompleteAndContinue}
                      </Button>
                    </Box>
                  </props.wrapper>
                </Box>
            }
          </Responsive>
          {hasPartitions && <div className='courseNav__last-page-footer'>
            <hr aria-hidden className='courseNav__last-page-footer-divider' />
            {pageFooter}
          </div>}
        </Column>
      </Theme>
    </>
  );
};
