import React, {FunctionComponent, useCallback, useEffect, useState, useRef} from 'react';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import Alert from '@amzn/meridian/alert';
import Card from '@amzn/meridian/card';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Box from '@amzn/meridian/box';
import Checkbox from '@amzn/meridian/checkbox';
import RadioButton from '@amzn/meridian/radio-button';
import Input from '@amzn/meridian/input';
import Text from '@amzn/meridian/text';
import Textarea from '@amzn/meridian/textarea';
import Button from '@amzn/meridian/button';
import ButtonGroup, {ButtonOption} from '@amzn/meridian/button-group';
import Icon from '@amzn/meridian/icon';
import copyTokens from '@amzn/meridian-tokens/base/icon/copy';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import {QuizQuestionChoiceEntry} from './QuizQuestionChoiceEntry';
import {QuizQuestionChoice, QuizQuestionType} from '../../../../components/gadgets/quiz';
import {shortId} from '../../../../gadgets/utils/shortId';
import {InvalidFileUploadAlert} from '../../alerts/invalid-file-upload-alert/InvalidFileUploadAlert';
import {QuizQuestionBaseType} from '../../../../components/gadgets/quiz/QuizGadget';
import {AssetDataModel, AssetType, AssetUploader, InvalidFile} from '../../asset-uploader/AssetUploader';
import {AssetModel, useCourseContext} from '../../../../context/course';
import usePrevious from '../../../../hooks/usePrevious';
import {imageFileTypes} from '../../asset-uploader/Constants';
import {Image} from '../../../../components/image/Image';
import {
  QuizGadgetI18nStrings,
  WithI18nStringsProps
} from '../../../../context/course/models/I18n';

import './styles/quiz-gadget.scss';
import '../../../../components/gadgets/quiz/styles/assessments.scss';

const LABELS = {
  questionLabel: 'Question',
  imageUploadButtonPlaceholder: 'Image',
  multiselectButtonPlaceholder: 'Multiselect',
  imageUploadPlaceholder: 'Drag file here or click to upload image',
  replaceImagePlaceholder: 'Change image',
  answerPlaceholder: 'Add an answer choice',
  questionPlaceholder: 'Enter question here',
  deleteQuestionAlertTitle: 'Delete question',
  deleteQuestionAlertPlaceholder: 'Are you sure? This cannot be undone.',
  deleteQuestionCancelButtonPlaceholder: 'Cancel',
  deleteQuestionDeleteButtonPlaceholder: 'Delete question',
};

const OPTION_VALUE_IMAGE = 'image';
const OPTION_VALUE_MULTISELECT = 'multiselect';

/**
 * Quiz question model
 */
export type QuizQuestionAuthorType = QuizQuestionBaseType & {
  /**
   * Question answer choices for author
   */
  choices: QuizQuestionChoiceAuthorType[]
}

export interface QuizQuestionChoiceAuthorType extends QuizQuestionChoice {
  /**
   * Choice status. Correct choice or not.
   */
  isCorrect: boolean
}

interface QuizQuestionProps extends WithI18nStringsProps<QuizGadgetI18nStrings> {
  index: number;
  id: string;
  question: QuizQuestionAuthorType;
  onDeleteQuestion: () => void;
  onUpdateQuestion: (value: QuizQuestionAuthorType) => void;
  onCopyQuestion: () => void;
}

/**
 * Renders a Quiz question
 */
export const QuizQuestionCard: FunctionComponent<QuizQuestionProps> = (
  props: QuizQuestionProps
) => {
  const {
    index,
    id,
    question,
    onDeleteQuestion,
    onUpdateQuestion,
    onCopyQuestion,
    i18nStrings
  } = props;

  const {getAssetModel} = useCourseContext();
  const lastInputRef = useRef<HTMLElement>();
  const prevQuestionProp = usePrevious(question);

  const supportedImageFileTypes = {'image/*': imageFileTypes};
  const [options, setOptions] = useState<Set<string>>(new Set([
    question.image ? OPTION_VALUE_IMAGE : '',
    question.type === QuizQuestionType.Multiselect ? OPTION_VALUE_MULTISELECT : ''
  ].filter(x => x !== '')));
  const [isImageActive, setIsImageActive] = useState<boolean>(!!question.image);
  const [shouldConfirmDelete, setShouldConfirmDelete] = useState<boolean>(false);
  const [invalidFiles, setInvalidFiles] = useState<InvalidFile[]>([]);
  const [mediaAsset, setMediaAsset] = useState<AssetModel>();
  const [imgAltText, setImgAltText] = useState<string>(question.image?.assetMetadata?.altText as string || '');

  // Call getAssetModel API to fetch asset ID location for the question's image
  useEffect(() => {
    (async () => {
      if (question.image) {
        const fullMediaAsset = await getAssetModel(question.image.id);
        setMediaAsset(fullMediaAsset);
      }
    })();
  }, [question.image, getAssetModel]);

  useEffect(() => {
    // If choice added, focus on last choice input
    if (prevQuestionProp.choices < question.choices && lastInputRef.current) {
      lastInputRef.current.focus();
    }
  }, [question.choices]);

  const toggleIsImageActive = useCallback(() => {
    if (isImageActive && !isEmpty(question?.image)) {
      const {image, ...questionRest} = question;
      onUpdateQuestion({
        ...questionRest
      });
    }
    setIsImageActive(isCurrentlyActive => {
      // Update options for ButtonGroup
      const newOptions = new Set(options);
      if(isCurrentlyActive) {
        newOptions.delete(OPTION_VALUE_IMAGE);
      } else {
        newOptions.add(OPTION_VALUE_IMAGE);
      }
      setOptions(newOptions);

      // Return new state value
      return !isCurrentlyActive;
    });
  }, [options, question, isImageActive,  setIsImageActive]);

  const toggleType = useCallback(() => {
    // Update options for ButtonGroup
    const newOptions = new Set(options);
    if(question.type === QuizQuestionType.Multiselect) {
      newOptions.delete(OPTION_VALUE_MULTISELECT);
    } else {
      newOptions.add(OPTION_VALUE_MULTISELECT);
    }
    setOptions(newOptions);

    const newType: QuizQuestionType = question.type === QuizQuestionType.Single
      ? QuizQuestionType.Multiselect
      : QuizQuestionType.Single;

    let newChoices = question.choices;
    if(question.type === QuizQuestionType.Multiselect) {
      let isFirstCorrectChoice = true;
      let isCorrect;
      newChoices = question.choices.map(choice => {
        isCorrect = false;
        if(choice.isCorrect && isFirstCorrectChoice) {
          isCorrect = true;
          isFirstCorrectChoice = false;
        }
        return {
          ...choice,
          isCorrect
        };

      });
    }

    onUpdateQuestion({
      ...question,
      choices: newChoices,
      type: newType
    });
  }, [options, question, onUpdateQuestion]);

  const updateImgAltText = (newAltText: string) => {
    const updatedAssetMetadata =  {
      ...question?.image?.assetMetadata,
      altText: newAltText
    };
    onUpdateQuestion({
      ...question,
      image: question.image && {
        ...question.image,
        assetMetadata: updatedAssetMetadata
      }
    });
    setImgAltText(newAltText);
  };

  const updateTitle = useCallback((newTitle: string) => {
    onUpdateQuestion({
      ...question,
      title: newTitle
    });
  }, [question, onUpdateQuestion]);

  const onAddChoice = useCallback(() => {
    onUpdateQuestion({
      ...question,
      choices: [...question.choices, generateEmptyChoice()]
    });
  }, [question, onUpdateQuestion]);

  const onDeleteChoice = useCallback((i: number) => {
    let newChoices: QuizQuestionChoiceAuthorType[];
    if(size(question.choices) > 1) {
      newChoices = [...question.choices];
      newChoices.splice(i, 1);
    } else {
      newChoices = [generateEmptyChoice()];
    }
    onUpdateQuestion({
      ...question,
      choices: newChoices
    });
  }, [question, onUpdateQuestion]);

  const onUpdateChoice = useCallback((i: number, id: string, value: string, isCorrect: boolean) => {
    const newChoices: QuizQuestionChoiceAuthorType[] = [...question.choices];
    newChoices[i] = {id, value, isCorrect};
    onUpdateQuestion({
      ...question,
      choices: newChoices
    });
    
  }, [question, onUpdateQuestion]);

  const showDeleteQuestionWarning = useCallback(() => {
    setShouldConfirmDelete(true);
  }, []);

  const dismissDeleteQuestionConfirmation = useCallback(() => {
    setShouldConfirmDelete(false);
  }, []);

  const deleteQuestion = useCallback(() => {
    onDeleteQuestion();
    setShouldConfirmDelete(false);
  }, [onDeleteQuestion]);

  const onAlertClose = useCallback((index: number) => {
    const fileNames = Array.from(invalidFiles) as InvalidFile[];
    fileNames.splice(index, 1);
    setInvalidFiles(fileNames);
  }, [invalidFiles]);

  const onSelectCorrectChoice = useCallback((choiceId: string) => {
    const newChoices: QuizQuestionChoiceAuthorType[] = question.choices.map(choice => {
      let isCorrect;
      if(question.type === QuizQuestionType.Single) {
        // Single select has only one choice at a time.
        isCorrect = choice.id === choiceId;
      } else {
        // For multi select add the newly selected choice to the existing choice list.
        isCorrect = choice.id === choiceId ? !choice.isCorrect : choice.isCorrect;
      }
      return {
        ...choice,
        isCorrect
      };
    });
    onUpdateQuestion({
      ...question,
      choices: newChoices
    });
  }, [question]);

  const updateAssetData = useCallback((assetData: AssetDataModel) => {
    onUpdateQuestion({
      ...question,
      image: {
        id: assetData?.image?.assetId as string,
        type: AssetType.IMAGE,
        contentType: assetData?.image?.contentType as string
      }
    });
  }, [question, onUpdateQuestion]);

  const onRejection = useCallback((invalidFiles: InvalidFile[]) => {
    setInvalidFiles(invalidFiles);
  }, []);

  return (
    <Box className={'quiz-gadget__question__container'}>
      {shouldConfirmDelete &&
        <Box type="outline" className={'quiz-gadget__question__overlay'}>
          <Column spacingInset='medium' spacing='medium'>
            <Alert
              title={LABELS.deleteQuestionAlertTitle}
              type='warning'
            >
              {LABELS.deleteQuestionAlertPlaceholder}
            </Alert>
            <Row widths={['fit', 'fit']} alignmentHorizontal='end'>
              <Button type='link'
                onClick={dismissDeleteQuestionConfirmation}>{LABELS.deleteQuestionCancelButtonPlaceholder}</Button>
              <Button type='primary' onClick={deleteQuestion}>{LABELS.deleteQuestionDeleteButtonPlaceholder}</Button>
            </Row>
          </Column>
        </Box>
      }
      <InvalidFileUploadAlert
        invalidFiles={invalidFiles}
        onAlertClose={onAlertClose}
      />
      <Column spacing='xsmall' spacingInset='none none small none'>
        <Row widths={['grid-10', 'grid-2']} spacing='xxsmall'>
          <Textarea
            value={question.title}
            onChange={updateTitle}
            label={`${LABELS.questionLabel} ${index + 1}*`}
            placeholder={LABELS.questionPlaceholder}
            size='medium'
            rows={1}
          />
          <Row spacing='small' widths={['grid-6', 'grid-6']}>
            <Button
              type='icon'
              onClick={onCopyQuestion}
            >
              <Icon tokens={copyTokens} />
            </Button>
            <Button
              type='icon'
              onClick={showDeleteQuestionWarning}
            >
              <Icon tokens={trashTokens} />
            </Button>
          </Row>
        </Row>
        <ButtonGroup value={Array.from(options)}>
          <ButtonOption value={OPTION_VALUE_MULTISELECT} onClick={toggleType}>{LABELS.multiselectButtonPlaceholder}</ButtonOption>
          <ButtonOption value={OPTION_VALUE_IMAGE} onClick={toggleIsImageActive}>{LABELS.imageUploadButtonPlaceholder}</ButtonOption>
        </ButtonGroup>
      </Column>

      {isImageActive && question.image && mediaAsset?.location &&
        <div className='AssessmentGadgetQuestion__image'>
          <Image src={mediaAsset.location} alt={mediaAsset.assetMetadata?.altText as string} />
        </div>
      }

      {isImageActive &&
        <Box spacingInset={'medium none'}>
          <Card spacingInset={'medium'}>
            <AssetUploader
              supportedFileTypes={supportedImageFileTypes}
              assetType={AssetType.IMAGE}
              updateAssetData={updateAssetData}
              onUploadFailure={noop}
              onUploadSuccess={noop}
              onRejection={onRejection}
              i18nStrings={i18nStrings.assetUpload}
            >
              <Text type='b300' alignment='center'>
                {isEmpty(question?.image) ? LABELS.imageUploadPlaceholder : LABELS.replaceImagePlaceholder}
              </Text>
            </AssetUploader>
          </Card>
        </Box>
      }

      {isImageActive && question.image && mediaAsset?.location &&
        <Box spacingInset={'none none 400'}>
          <Textarea
            value={imgAltText}
            onChange={updateImgAltText}
            label={'Alternate image text'}
            helperText={'Alternate text is recommended for the best learner experience'}
          />
        </Box>
      }

      <div>
        <Column spacing='small'>
          <Text type='b300' color='secondary'>Answer</Text>
          { question.choices && question.choices.map((choice, i) =>
            <QuizQuestionChoiceEntry
              ref={i === size(question.choices) - 1 ? lastInputRef : undefined}
              key={`${id}-answer-${choice.id}`}
              index={i}
              choice={choice}
              type={question.type}
              onDeleteAnswer={() => onDeleteChoice(i)}
              onUpdateChoice={(value: string) => onUpdateChoice(i, choice.id, value, choice.isCorrect)}
              onSelectChoice={() => onSelectCorrectChoice(choice.id)}
            />
          )}

          {/* Dummy question answer that add new question */}
          <Row widths={['grid-10']} spacing='none'>
            <Row widths={['fit', 'fill']} spacing='none'>
              {question.type === QuizQuestionType.Single
                ? <RadioButton disabled />
                : <Box spacingInset='none medium none none'>
                  <Checkbox disabled />
                </Box>
              }

              <Input
                placeholder={LABELS.answerPlaceholder}
                onFocus={e => {
                  e.target.blur();
                  onAddChoice();
                }}
              />
            </Row>
          </Row>
        </Column>
      </div>
    </Box>
  );
};

const generateEmptyChoice = () => {
  return {
    id: shortId(),
    value: '',
    isCorrect: false
  };
};
