import React, {FunctionComponent} from 'react';
import brandedDarkTokens from '../../../../theme/branded-dark';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';
import Theme from '@amzn/meridian/theme';
import {defaultI18nStrings, LoaderI18nStrings} from '../../../../context/course/models/I18n';

export const CourseLoader: FunctionComponent<{i18nStrings?: LoaderI18nStrings}> = ({
  i18nStrings = defaultI18nStrings.loader
}) => {
  return (
    <Theme tokens={brandedDarkTokens}>
      <Column height='100vh' alignmentHorizontal='center' alignmentVertical='center' spacing='large'>
        <Loader />
        <Text color='secondary'>{i18nStrings.gettingYourCourseReady}</Text>
      </Column>
    </Theme>
  );
};
