import React, {FunctionComponent, ReactNode, useState} from 'react';

import {HideExternalLinkWarningStorageKey} from '../../link-warning/Constants';
import {getItemFromSessionStorage} from '../../../hooks/useSessionStorage';
import Link from '@amzn/meridian/link';
import {linkIsInternal} from '../../../utils/LinkUtils';
import {MarkdownGadgetI18nStrings, WithI18nStringsProps} from '../../../context/course/models/I18n';
import {ExternalLinkWarningModal} from '../../link-warning/ExternalLinkWarningModal';

export interface ExternalLinkProps extends WithI18nStringsProps<MarkdownGadgetI18nStrings> {
  href?: string,
  children: ReactNode | string
}

const ExternalLink: FunctionComponent<ExternalLinkProps> = (
  {
    href,
    children,
    i18nStrings
  }
) => {

  const [modalOpen, setModalOpen] = useState(false);
  const onModalClose = () => {
    setModalOpen(false);
  };

  const onClick = () => {
    if (!href) {
      return;
    }
    const isExternalLinkWarningHidden = getItemFromSessionStorage(HideExternalLinkWarningStorageKey, false);
    if (!isExternalLinkWarningHidden && !linkIsInternal(href)) {
      setModalOpen(true);
    } else {
      window.open(href, '_blank');
    }
  };

  return <>
    <ExternalLinkWarningModal
      open={modalOpen}
      onClose={onModalClose}
      href={href}
      i18nStrings={i18nStrings.linkWarning}
    />
    <Link onClick={onClick}>
      <span className='link_text'>
        {children}
      </span>
    </Link>
  </>;
};

export default ExternalLink;
