import React, {FunctionComponent, useState} from 'react';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import find from 'lodash/find';

import {useCourseAuthoringContext} from '../../../context';
import {LabsGadgetConfig, LabChallenge} from './models';
import {LabTrackSetup} from './track/LabTrackSetup';
import {LabChallengeSetup} from './challenges/LabChallengeSetup';

import './styles/lab-edit-layout.scss';
import Button from '@amzn/meridian/button';

export type LabEditLayoutProps = {
  gadgetId: string
  config: LabsGadgetConfig
};

export const LabEditLayout: FunctionComponent<LabEditLayoutProps> = (
  props: LabEditLayoutProps
) => {
  const {gadgetId, config} = props;
  const [activeChallengeId, setActiveChallengeId] = useState<string | undefined>(undefined);

  const { exitGadgetFullEditMode, updateGadget, getCloudLabInfo } = useCourseAuthoringContext();

  const onCloseButtonClick = () => {
    if (activeChallengeId) {
      setActiveChallengeId(undefined);
    } else {
      updateGadget(gadgetId, {
        ...config,
        isSubmit: true
      });
      exitGadgetFullEditMode();
    }
  };

  const onEditChallenge = (challengeId: string) => {
    setActiveChallengeId(challengeId);
  };

  return (
    <Box>
      <Column height={'100%'} heights={['fit', 'fill', 'fit']} spacing='none'>
        <Box spacingInset={'500 600'} className='labEditLayout__header'>
          <Heading level={1} type='d50'>Setup Hands-on Experience</Heading>
        </Box>
        <Box>
          {!activeChallengeId
            ? <LabTrackSetup gadgetId={gadgetId} config={config} onEditChallenge={onEditChallenge} />
            : <LabChallengeSetup gadgetId={gadgetId} activeChallengeId={activeChallengeId} config={config} />
          }
        </Box>
        <Box spacingInset={'500 600'} className='labEditLayout__footer'>
          <Button data-testid='trackSetup-exitSetup' onClick={onCloseButtonClick}>Close</Button>
        </Box>
      </Column>
    </Box>
  );
};
export default LabEditLayout;
