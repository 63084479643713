import {Gadget, GadgetConfig, GadgetLearnerState} from '../../../context/course';
import {AuthoringGadgetRenderer} from '../gadgets/registry/AuthoringGadgetRenderer';
import {GadgetEditorProps} from '../gadgets/models/GadgetEditorProps';
import {GadgetProps} from '../../../components/gadgets/models';
import React, {FunctionComponent, RefObject, useCallback, useState} from 'react';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import Icon from '@amzn/meridian/icon';
import Loader from '@amzn/meridian/loader';
import menuTokens from '@amzn/meridian-tokens/base/icon/menu';
import deleteTokens from '@amzn/meridian-tokens/base/icon/trash';
import editTokens from '@amzn/meridian-tokens/base/icon/edit';
import {ConnectDragPreview, ConnectDragSource, ConnectDropTarget, DragPreviewImage} from 'react-dnd';
import Text from '@amzn/meridian/text';
import cx from 'classnames';

import './styles/gadget-editor-container.scss';
import {I18nStrings} from '../../../context/course/models/I18n';

export const GADGET_DRAG_ITEM_TYPE = '___Gadget___';

/**
 * Gadget drag item to be used when
 */
export type GadgetDragItem = {
  /**
   * Gadget ID
   */
  id: string
}

/**
 * Gadget's editor container rendering props
 */
interface GadgetEditorContainerProps {
  /**
   * Gadget to edit
   */
  gadget: Gadget,

  /**
   * Boolean value that tells if a gadget has been selected via the select checkbox
   */
  isGadgetSelected: boolean;

  /**
   * Function to handle checkbox onChange event to select gadget
   */
  toggleSelectedGadget: () => void;

  /**
   * Function to find the gadget index in the lesson
   */
  findGadgetIndex: (gadgetId: string) => number;

  /**
   * Moves the gadget to a new position
   * @param gadgetId ID of the gadget to move
   * @param index new index
   */
  moveGadget: (gadgetId: string, index: number) => void;

  /**
   * Gadgets registry to use
   */
  gadgetsRegistry: AuthoringGadgetRenderer,

  /**
   * Event to handle gadget removal
   * @param gadgetId id of the gadget to be removed
   */
  onRemoveLessonGadget: (gadgetId: string) => void

  dragPreview?: ConnectDragPreview;
  dragSource?: ConnectDragSource;
  dropTarget?: ConnectDropTarget;

  /**
   * map of gadget ids to ref object of the gadget's container
   */
  gadgetRefsByGadgetId: Record<string, RefObject<HTMLElement>>;

  /**
   * Gadget deletion is happening or not
   */
  isDeleteInProgress: boolean;
}

/**
 * Renders a gadget's editor inside the lesson editor.
 *
 * This function uses memo to avoid re-rendering gadgets if their state hasn't changed
 */
export const GadgetEditorContainer: FunctionComponent<GadgetEditorContainerProps> =  ({
  gadget,
  isGadgetSelected,
  toggleSelectedGadget,
  gadgetsRegistry,
  onRemoveLessonGadget,
  gadgetRefsByGadgetId,
  dragPreview,
  dragSource,
  isDeleteInProgress
}: GadgetEditorContainerProps) => {

  const {
    component: GadgetPreviewComponent,
    editorComponent: GadgetEditorComponent,
    i18nStrings
  } = gadgetsRegistry[gadget.type];

  const [mode, setMode] = useState<'Edit' | 'Preview'>('Edit');
  const toggleSetMode = useCallback(() => {
    setMode(mode === 'Preview' ? 'Edit' : 'Preview');
  }, [mode]);
  const isEditMode = useCallback(() => {
    return mode === 'Edit';
  }, [mode]);

  const [isDeleting, setDeleting] = useState(false);

  const onRemoveGadget = useCallback(
    () => {
      onRemoveLessonGadget(gadget.id);
      setDeleting(false);
    },
    [gadget.id, onRemoveLessonGadget]
  );

  const gadgetControlStyles = cx('gadgetEditorContainer__control', {'gadgetEditorContainer__control__hidden': isGadgetSelected});
  const gadgetCheckboxStyles = cx('gadgetEditorContainer__control__checkbox', {'gadgetEditorContainer__control__checkbox__checked': isGadgetSelected});

  const GadgetComponent = isEditMode() ? GadgetEditorComponent : GadgetPreviewComponent;

  let gadgetComponentProps = {
    id: gadget.id,
    type: gadget.type,
    config: gadget.config
  } as GadgetEditorProps<GadgetConfig, I18nStrings>;

  // If not edit mode, send learner mode props
  if (!isEditMode()) {
    gadgetComponentProps = {
      ...gadgetComponentProps,
      learnerState: gadget.learnerState
    } as GadgetProps<GadgetConfig, GadgetLearnerState, I18nStrings>;
  }

  const GadgetDeleteConfirmation = () =>
    <Box type='fill' className={'gadgetConfirmOverlay'}>
      <Column spacing='medium' alignmentHorizontal='center'>
        <Text type='h200'>
          Are you sure you want to delete this {gadgetsRegistry[gadget.type].defaultTitle} gadget?
        </Text>
        <Row spacing='medium'>
          <Button
            type='secondary'
            onClick={() => {setDeleting(false); }}
            label='Cancel delete gadget'
          >Cancel</Button>
          <Button
            onClick={onRemoveGadget}
            label='Confirm delete gadget'
          >Delete</Button>
        </Row>
      </Column>
    </Box>;

  return (
    <>
      {dragPreview && <DragPreviewImage connect={dragPreview} src={gadgetsRegistry[gadget.type].iconUrl}/>}
      <Row
        className='gadgetEditorContainer'
        widths={['fit', 'fill', 'fit']}
        alignmentVertical="stretch"
      >
        <Column>
          <Column
            width='40px'
            height='40px'
            className={gadgetCheckboxStyles}
            alignmentHorizontal='center'
            alignmentVertical='center'
          >
            <Checkbox checked={isGadgetSelected} onChange={toggleSelectedGadget} />
          </Column>
          <Row
            className={cx(gadgetControlStyles, 'gadgetEditorContainer__control__draggable')}
            alignmentHorizontal='center'
            alignmentVertical='center'
            ref={dragSource}
          >
            <Icon tokens={menuTokens}>Move</Icon>
          </Row>
          <Row className={gadgetControlStyles}>
            <Button type="icon" onClick={() => {setDeleting(true);}} >
              <Icon tokens={deleteTokens}>Delete {gadgetsRegistry[gadget.type].defaultTitle} gadget</Icon>
            </Button>
          </Row>
        </Column>
        <Row className='gadgetEditorContainer__gadgetContainer' width={'100%'} maxWidth={'561px'} minWidth='0'>
          {isGadgetSelected && <div className='gadgetEditorContainer__gadgetContainer__selected__overlay'/>}
          {GadgetComponent &&
            <GadgetComponent
              {...gadgetComponentProps}
              i18nStrings={i18nStrings}
              ref={gadgetRefsByGadgetId[gadget.id]}
            />}
          {!GadgetComponent && <div>Invalid Gadget</div>}
          {isDeleting && <GadgetDeleteConfirmation/>}
          {isDeleteInProgress &&
            <Column className={'gadgetConfirmOverlay'} alignmentHorizontal={'center'} alignmentVertical={'center'}>
              <Loader/>
              <Text color={'primary'}>Removing gadget from the lesson</Text>
            </Column>
          }
        </Row>
        <Column>
          <Row className={gadgetControlStyles}>
            <Button type={mode === 'Edit' ? 'primary' : 'icon'} onClick={toggleSetMode} >
              <Icon tokens={editTokens}>Edit</Icon>
            </Button>
          </Row>
        </Column>
      </Row>
    </>
  );
};
