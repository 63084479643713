import {LearnerActivity} from './LearnerActivity';
import {LearnerActivityType} from './LearnerActivityType';

export class LearnerLessonActivity extends LearnerActivity {
  public readonly lessonId: string;

  constructor(
    verb: string,
    object: string,
    lessonId: string
  ) {
    super(LearnerActivityType.LESSON, verb, object);
    this.lessonId = lessonId;
  }
}
