/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./model";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createAsset = /* GraphQL */ `mutation CreateAsset($request: CreateAssetInput!) {
  createAsset(request: $request) {
    assetMetadata
    contentType
    id
    location
    size
    status
    tags
    title
    type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssetMutationVariables,
  APITypes.CreateAssetMutation
>;
export const createCourse = /* GraphQL */ `mutation CreateCourse($request: CreateCourseInput!) {
  createCourse(request: $request) {
    courseDraftId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCourseMutationVariables,
  APITypes.CreateCourseMutation
>;
export const createCourseContent = /* GraphQL */ `mutation CreateCourseContent($request: CreateCourseContentInput!) {
  createCourseContent(request: $request) {
    contentType
    gadgets
    id
    isBlocking
    status
    title
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCourseContentMutationVariables,
  APITypes.CreateCourseContentMutation
>;
export const createCourseRevision = /* GraphQL */ `mutation CreateCourseRevision($request: CourseId!) {
  createCourseRevision(request: $request) {
    courseId
    courseRevisionId
    externalId
    revisionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCourseRevisionMutationVariables,
  APITypes.CreateCourseRevisionMutation
>;
export const createLearnerSession = /* GraphQL */ `mutation CreateLearnerSession($request: CreateLearnerSessionInput!) {
  createLearnerSession(request: $request) {
    token
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLearnerSessionMutationVariables,
  APITypes.CreateLearnerSessionMutation
>;
export const createLessonGadgets = /* GraphQL */ `mutation CreateLessonGadgets($request: CreateLessonGadgetsInput!) {
  createLessonGadgets(request: $request) {
    config
    id
    lastModifiedRevision
    title
    type
    userState
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLessonGadgetsMutationVariables,
  APITypes.CreateLessonGadgetsMutation
>;
export const deleteContent = /* GraphQL */ `mutation DeleteContent($request: DeleteContentInput!) {
  deleteContent(request: $request)
}
` as GeneratedMutation<
  APITypes.DeleteContentMutationVariables,
  APITypes.DeleteContentMutation
>;
export const deleteLessonGadgets = /* GraphQL */ `mutation DeleteLessonGadgets($request: DeleteLessonGadgetsInput!) {
  deleteLessonGadgets(request: $request)
}
` as GeneratedMutation<
  APITypes.DeleteLessonGadgetsMutationVariables,
  APITypes.DeleteLessonGadgetsMutation
>;
export const processAsset = /* GraphQL */ `mutation ProcessAsset($request: AssetId!) {
  processAsset(request: $request)
}
` as GeneratedMutation<
  APITypes.ProcessAssetMutationVariables,
  APITypes.ProcessAssetMutation
>;
export const updateCourseDraft = /* GraphQL */ `mutation UpdateCourseDraft($request: UpdateCourseDraftInput!) {
  updateCourseDraft(request: $request) {
    contents {
      contentType
      gadgets
      id
      isBlocking
      status
      title
      __typename
    }
    courseProgressInPercentage
    coverImageAssetId
    createdAt
    currentPosition {
      currentGadgetId
      currentLessonId
      displaySection
      updatedAt
      __typename
    }
    description
    id
    lastModified
    lessonViewMode
    shortDescription
    status
    tags
    title
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCourseDraftMutationVariables,
  APITypes.UpdateCourseDraftMutation
>;
export const updateCoursePosition = /* GraphQL */ `mutation UpdateCoursePosition($request: UpdateCoursePositionInput!) {
  updateCoursePosition(request: $request) {
    currentGadgetId
    currentLessonId
    displaySection
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCoursePositionMutationVariables,
  APITypes.UpdateCoursePositionMutation
>;
export const updateCourseStatus = /* GraphQL */ `mutation UpdateCourseStatus($request: UpdateCourseStatusInput!) {
  updateCourseStatus(request: $request) {
    certificateId
    gadgetIds
    statusTransitionSuccessful
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCourseStatusMutationVariables,
  APITypes.UpdateCourseStatusMutation
>;
export const updateGadgetLearnerState = /* GraphQL */ `mutation UpdateGadgetLearnerState($request: UpdateGadgetLearnerStateInput!) {
  updateGadgetLearnerState(request: $request) {
    coursePosition {
      currentGadgetId
      currentLessonId
      displaySection
      updatedAt
      __typename
    }
    courseProgressInPercentage
    gadgetStates
    gadgetTypes
    lessonStatusById
    status
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGadgetLearnerStateMutationVariables,
  APITypes.UpdateGadgetLearnerStateMutation
>;
export const updateLessonGadget = /* GraphQL */ `mutation UpdateLessonGadget($request: UpdateLessonGadgetInput!) {
  updateLessonGadget(request: $request)
}
` as GeneratedMutation<
  APITypes.UpdateLessonGadgetMutationVariables,
  APITypes.UpdateLessonGadgetMutation
>;
export const updateLessonStatus = /* GraphQL */ `mutation UpdateLessonStatus($request: UpdateLessonStatusInput!) {
  updateLessonStatus(request: $request) {
    completionPercentage
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLessonStatusMutationVariables,
  APITypes.UpdateLessonStatusMutation
>;
