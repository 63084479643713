import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import muxjs from 'mux.js';
import {detect} from 'detect-browser';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import shaka from 'shaka-player/dist/shaka-player.ui';

import './styles/shaka.scss';
import 'shaka-player/dist/controls.css';
import {DrmType} from './models/DrmType';
import {shakaPlayerCustomConfiguration, shakaPlayerCustomUIConfiguration} from './config/PlayerConfiguration';
import { VideoGadgetI18nStrings, WithI18nStringsProps } from '../../../context/course/models/I18n';
import {FullscreenButton} from './shaka/FullscreenButton';
import {CaptionsButton} from './shaka/CaptionsButton';
import {CustomSeekButton} from './shaka/CustomRewindButton';
import {MuteButton} from './shaka/MuteButton';
import {PipButton} from './shaka/PipButton';
import {PlayButton} from './shaka/PlayButton';
import {SettingsButton} from './shaka/SettingsButton';
import {isSafari} from './VideoGadget';

/**
 * Shaka Player props
 */
export interface ShakaPlayerProps extends WithI18nStringsProps<VideoGadgetI18nStrings> {
  /**
   * Unique video player ID
   */
  videoPlayerId: string,

  /**
   * Video Captions URL (optional)
   */
  videoCaptionsUrl?: string

  /**
   * Playback URL
   */
  videoPlaybackUrl: string,

  /**
   * License URL for DRM protected videos
   */
  drmLicenseUrl?: string,

  /**
   * DRM type if the video is DRM protected
   */
  drmType: DrmType,

  /**
   * Function to build the DRM license request if DRM is used
   * @param drmType drm type
   * @param licenseChallenge challenge data
   */
  getDrmRequest?: (drmType: DrmType, licenseChallenge: string, contentId: string) => {
    headers: Record<string, string>,
    body: string
  },

  /**
   * Emits a counter metric using Katal metrics library
   */
  emitCounterMetrics?: (metricName: string)  => void,

  /**
   * Emits a timer metric using Katal metrics library
   */
  emitTimerMetrics?: (metricName: string, timer: number)  => void
}

/**
 * Shaka Player ref to retrieve the underlying Shaka Player component for further manipulation
 */
export interface ShakaPlayerRef {
  /**
   * Shaka Player instance
   */
  get player(): shaka.Player

  /**
   * Video HTML element
   */
  get videoElement(): HTMLVideoElement
}

function setTooltips(i18nStrings: VideoGadgetI18nStrings) {
  FullscreenButton.setFullScreenTooltipMessage(i18nStrings.fullScreen);
  CaptionsButton.setCaptionsTooltipMessages(i18nStrings.closedCaptions, i18nStrings.closedCaptionsUnavailable);
  CustomSeekButton.setCustomSeekTooltipMessages(i18nStrings.moveBackwardXSeconds, i18nStrings.moveForwardXSeconds);
  MuteButton.setMuteButtonTooltipMessages(i18nStrings.mute, i18nStrings.unmute);
  PipButton.setPipTooltipMessage(i18nStrings.pictureInPicture);
  PlayButton.setPlayButtonTooltipMessages(i18nStrings.play, i18nStrings.pause);
  SettingsButton.setSettingsButtonTooltipMessage(i18nStrings.settings);
}

export const ShakaPlayer = forwardRef((
  {
    videoPlayerId,
    videoCaptionsUrl,
    videoPlaybackUrl,
    drmLicenseUrl,
    drmType,
    getDrmRequest,
    emitCounterMetrics,
    emitTimerMetrics,
    i18nStrings,
  }: ShakaPlayerProps, ref
) => {

  const videoRef = useRef<HTMLVideoElement>(null);
  const uiRef = React.useRef(null);

  const [player, setPlayer] = useState<shaka.Player | null>(null);
  const [ui, setUi] = React.useState<shaka.ui.Overlay>(null);
  let startTimeForDrmLicenseCall: number;
  const browser = detect();

  async function getFpsCertificate() {
    //TODO: Move this to backend api
    const req = await fetch('https://fp-keyos-aaph.licensekeyserver.com/cert/54007721a014fd0ec14f4bfadff06227.der');
    const cert = await req.arrayBuffer();

    return new Uint8Array(cert);
  }

  function configureShakaPlayerDrmServerUrls(player: shaka.Player) {
    player.configure({
      drm: {
        servers: {
          'com.widevine.alpha': drmLicenseUrl,
          'com.microsoft.playready': drmLicenseUrl,
          'com.apple.fps.1_0': drmLicenseUrl
        }
      }
    });
  }

  // Register network request filter for Shaka player
  // This will be invoked on all DRM license, segment, and manifest calls,
  // but will only modify DRM license requests
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function registerShakaPlayerNetworkRequestFilter(player: shaka.Player, getDrmRequest: any, drmType: DrmType) {
    let contentId = '';
    if(drmType === DrmType.FAIRPLAY) {
      const startTimeForFairplayCertificateCall = Date.now();
      const cert = await getFpsCertificate();
      const endTimeForFairplayCertificateCall = Date.now();
      emitTimerMetrics?.('Video_FairplayCertCallTime', endTimeForFairplayCertificateCall - startTimeForFairplayCertificateCall);
      player.configure('drm.advanced.com\\.apple\\.fps\\.1_0.serverCertificate', cert);
      player.configure('drm.initDataTransform', (initData: any, initDataType: any) => {
        if (initDataType != 'skd')
        {return initData;}

        // 'initData' is a buffer containing an 'skd://' URL as a UTF-8 string.
        const skdUri = shaka.util.StringUtils.fromBytesAutoDetect(initData);
        contentId = skdUri.split('skd://')[1].substr(0, 32);
        const cert = player.drmInfo()?.serverCertificate;

        return shaka.util.FairPlayUtils.initDataTransform(initData, contentId, cert);
      });
    }

    // Register filter for outgoing requests
    player.getNetworkingEngine()!.registerRequestFilter(function (type: any, request: any) {

      // If this is a DRM license request
      if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
        emitCounterMetrics?.('Video_DrmType_' + drmType);
        startTimeForDrmLicenseCall = Date.now();
        const Uint8ArrayUtils = shaka.util.Uint8ArrayUtils;
        let base64LicenseChallenge = Uint8ArrayUtils.toStandardBase64(new Uint8Array(request.body));
        if (drmType === DrmType.FAIRPLAY) {
          const params = 'spc=' + base64LicenseChallenge + '&assetId=' + contentId;
          base64LicenseChallenge = shaka.util.StringUtils.toUTF8(params);
          base64LicenseChallenge = Uint8ArrayUtils.toStandardBase64(base64LicenseChallenge);
        }
        const drmRequest = getDrmRequest(drmType, base64LicenseChallenge, contentId);
        request.headers = drmRequest.headers;
        request.body = drmRequest.body;
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function registerShakaPlayerNetworkResponseFilter(player: shaka.Player, drmType: DrmType) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    player.getNetworkingEngine().registerResponseFilter((type, response) => {
      if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
        const responseText = shaka.util.StringUtils.fromUTF8(response.data).trim();

        // Decode the base64-encoded data into the format the browser expects.
        response.data = shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
        const endTimeForDrmLicenseCall = Date.now();
        emitTimerMetrics?.('Video_DrmLicenseCallTime', endTimeForDrmLicenseCall - startTimeForDrmLicenseCall);
      }
    });
  }


  // This hook creates a shaka.Player instance and needs to be the first effect to run.
  useEffect(() => {
    (async () => {

      if (shaka.Player.isBrowserSupported()) {
      // Much of the HLS content out there has MPEG2-TS streams, which many browsers don't support.
      // To work around this issue, Shaka Player supports transmuxing TS to MP4 so the browser can play it.
      // This is done using the mux.js library.
      // https://stackoverflow.com/questions/55965244/shaka-player-cannot-load-hls-on-google-chrome
        // @ts-ignore
        window.muxjs = muxjs;
        shaka.polyfill.installAll();
        shaka.polyfill.PatchedMediaKeysApple.install();
        const player = new shaka.Player(videoRef.current);
        setTooltips(i18nStrings);

        configureShakaPlayerDrmServerUrls(player);
        await registerShakaPlayerNetworkRequestFilter(player, getDrmRequest, drmType);
        registerShakaPlayerNetworkResponseFilter(player, drmType);

        player.configure(shakaPlayerCustomConfiguration());

        setPlayer(player);

        const ui = new shaka.ui.Overlay(
          player,
          uiRef.current,
          videoRef.current
        );
        setUi(ui);
        ui.configure(shakaPlayerCustomUIConfiguration());
      } else {
      // This browser does not have the minimum set of APIs we need.
        emitCounterMetrics?.('Video_PlaybackError_UnsupportedBrowser');
      }

      return () => {
        if (player) {
          player.destroy();
        }
        if (ui) {
          ui.destroy();
        }
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18nStrings, FullscreenButton, CaptionsButton, CustomSeekButton, MuteButton, PipButton, PlayButton, SettingsButton]);

  useEffect(() => {
    if (player && videoPlaybackUrl) {
      const startTimeForLoadingPlayer = Date.now();
      player.load(videoPlaybackUrl)
        .then(async () => {

          if (isSafari()) {
            const track = document.createElement('track');
            Object.assign(track, {
              kind: 'captions',
              label: i18nStrings.language,
              srclang: i18nStrings.twoLetterLocalePrefix,
              default: true,
              src: videoCaptionsUrl
            });

            if (videoRef.current && track) {
              track.track.mode = 'hidden';
              videoRef.current.appendChild(track);
            }
          }

          if (videoCaptionsUrl && !isSafari()) {
            // if there are captions, load them
            await player.addTextTrackAsync(
              videoCaptionsUrl,
              i18nStrings.twoLetterLocalePrefix,
              'captions',
              'text/vtt',
              'vtt',
              i18nStrings.language
            );
          }
          const endTimeForLoadingPlayer = Date.now();
          emitCounterMetrics?.('Video_Loaded');
          emitTimerMetrics?.('Video_LoadedTime', endTimeForLoadingPlayer - startTimeForLoadingPlayer);
        })
        .catch((error: never) => {
          emitCounterMetrics?.('Video_PlaybackError');
        });
    }
  }, [player, videoCaptionsUrl, videoPlaybackUrl, i18nStrings]);

  useEffect(() => {
    return () => {
      // Exit picture-in-picture mode on lesson change.
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      }
    };
  }, []);


  // forward refs to be able to access shaka player and video element
  useImperativeHandle(
    ref,
    () => ({
      get player() {
        return player;
      },
      get videoElement() {
        return videoRef.current;
      }
    } as ShakaPlayerRef),
    [player]
  );

  return (
    <div ref={uiRef} className='soju-theme'>
      <video aria-hidden id={videoPlayerId} playsInline ref={videoRef} crossOrigin='' />
    </div>
  );
});
ShakaPlayer.displayName = 'ShakaPlayer';
