import React, { createContext, useCallback, useEffect, useState } from "react";
import configureAmplify from "../api/utils/configureAmplify";
import { AuthApi } from "../api/AuthApi";
import { GandalfConfig } from "../configuration/gandalf-config";
import { EMPTY_IDENTITY, Identity } from "../api/utils/IdentityFactory";
import { useIdentity } from "../hooks/useIdentity";

export const IdentityContext = createContext<Identity>(EMPTY_IDENTITY);
export const CourseIdContent = createContext<string>("");
const REFRESH_INTERVAL_MILLIS = 15 * 60 * 1000;

function IdentityProvider(
  props: React.PropsWithChildren<{ config: GandalfConfig }>,
) {
  const [identity, setIdentity] = useIdentity();
  const [courseId] = useState<string>("");

  configureAmplify({ config: props.config });

  useEffect(() => {
    return AuthApi.registerAuthListener(setIdentity);
  }, [setIdentity]);

  const refreshToken = useCallback(() => {
    AuthApi.getIdentity().then(setIdentity);
  }, [setIdentity]);

  useEffect(() => {
    AuthApi.autoLogin().then((identity) => {
      if (identity) {
        setIdentity(identity);
      }
    });
    const intervalId = setInterval(() => {
      refreshToken();
    }, REFRESH_INTERVAL_MILLIS);
    return () => {
      clearInterval(intervalId);
    };
  }, [identity.idToken, refreshToken, setIdentity]);
  return (
    <IdentityContext.Provider value={identity}>
      <CourseIdContent.Provider value={courseId}>
        {!identity.idToken ? <></> : <>{props.children}</>}
      </CourseIdContent.Provider>
    </IdentityContext.Provider>
  );
}
export default IdentityProvider;
