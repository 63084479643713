import {GadgetConfigAdapter} from './GadgetConfigAdapter';
import {GadgetConfig} from '../../models/GadgetConfig';
import {QuizGadgetConfigAdapter} from './QuizGadgetConfigAdapter';
import {VideoGadgetConfigAdapter} from './VideoGadgetConfigAdapter';
import {SectionGadgetConfigAdapter} from './SectionGadgetConfigAdapter';
import {SlideshowGadgetConfigAdapter} from './SlideshowGadgetConfigAdapter';
import {ResponseGadgetConfigAdapter} from './ResponseGadgetConfigAdapter';
import {ReadingGadgetConfigAdapter} from './ReadingGadgetConfigAdapter';

export class GadgetConfigAdapterRegistry {
  private readonly registry: Record<string, GadgetConfigAdapter<any>>

  constructor() {
    this.registry = {};

    this.registerAdapter(new QuizGadgetConfigAdapter());
    this.registerAdapter(new VideoGadgetConfigAdapter());
    this.registerAdapter(new SectionGadgetConfigAdapter());
    this.registerAdapter(new SlideshowGadgetConfigAdapter());
    this.registerAdapter(new ResponseGadgetConfigAdapter());
    this.registerAdapter(new ReadingGadgetConfigAdapter());
  }

  private registerAdapter(gadgetConfigAdapter: GadgetConfigAdapter<any>): void {
    this.registry[gadgetConfigAdapter.getGadgetType()] = gadgetConfigAdapter;
  }

  shouldAdaptGadgetType(gadgetType: string): boolean {
    return this.registry[gadgetType] !== undefined;
  }

  adaptConfig<C extends GadgetConfig>(gadgetType: string, state: any): C {
    return this.registry[gadgetType].adapt(state);
  }
}
