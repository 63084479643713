import React, {FunctionComponent} from 'react';
import useMessageListener from '../../../hooks/useMessageListener';
import {LabEvents} from './models/LabEvents';

export interface LabPlayerProps {
  embedUrl: string,
  origin: string,
  onTrackStarted?: (trackSlug: string) => void,
  onTrackCompleted?: (trackSlug: string) => void,
  onChallengeStarted?: (trackSlug: string, challengeSlug: string) => void,
  onChallengeSkipped?: (trackSlug: string, challengeSlug: string) => void,
  onChallengeCompleted?: (trackSlug: string, challengeSlug: string) => void,
}

export const handleMessage = (
  messageEvent: MessageEvent,
  onTrackStarted?: (trackSlug: string) => void,
  onTrackCompleted?: (trackSlug: string) => void,
  onChallengeStarted?: (trackSlug: string, challengeSlug: string) => void,
  onChallengeSkipped?: (trackSlug: string, challengeSlug: string) => void,
  onChallengeCompleted?: (trackSlug: string, challengeSlug: string) => void,
) => {
  const {event, params} = messageEvent.data;
  const {track_slug, challenge_slug} = params;
  switch (event) {
    case LabEvents.TrackStarted:
      onTrackStarted?.(track_slug);
      break;
    case LabEvents.TrackCompleted:
      onTrackCompleted?.(track_slug);
      break;
    case LabEvents.ChallengeStarted:
      onChallengeStarted?.(track_slug, challenge_slug);
      break;
    case LabEvents.ChallengeSkipped:
      onChallengeSkipped?.(track_slug, challenge_slug);
      break;
    case LabEvents.ChallengeCompleted:
      onChallengeCompleted?.(track_slug, challenge_slug);
      break;
  }
};

export const LabPlayer: FunctionComponent<LabPlayerProps> = ({
  embedUrl,
  origin,
  onTrackStarted,
  onTrackCompleted,
  onChallengeStarted,
  onChallengeSkipped,
  onChallengeCompleted,
}) => {
  useMessageListener(messageEvent =>
    handleMessage(messageEvent, onTrackStarted, onTrackCompleted, onChallengeStarted, onChallengeSkipped, onChallengeCompleted),
  origin
  );

  return <iframe src={embedUrl} />;
};
