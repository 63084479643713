import React, {FunctionComponent} from 'react';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import arrowLeftLarge from '@amzn/meridian-tokens/base/icon/arrow-left-large';
import arrowRightLarge from '@amzn/meridian-tokens/base/icon/arrow-right-large';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Responsive from '@amzn/meridian/responsive';
import {LessonViewMode} from '../../../context/course/models/LessonViewMode';
import Text from '@amzn/meridian/text';
import { defaultI18nStrings, PageFooterI18nStrings, WithI18nStringsProps } from '../../../context/course/models/I18n';

import './styles/PageFooter.scss';
import responsiveStyles from '../../../styles/responsive.module.scss';

export interface PageFooterProps extends WithI18nStringsProps<PageFooterI18nStrings | undefined> {
  /**
   * callback function for when next/prev partition buttons are clicked
   * @param index the new partition index to navigate to
   */
  onPartitionIndexChanged: (index: number) => void;

  /**
   * the current position within the list of partitions
   */
  currentPartitionIndex: number;

  /**
   * the total number of partitions. If this is <= 1, this component has no value and will not render.
   */
  totalPartitions: number;

  /**
   * optional title of the current partition (e.g. section title).
   */
  partitionTitle?: string;

  /**
   * the title of the partitioned lesson. Used to provide more context in aria-labels, and as a fallback to the
   * partitionTitle if one isn't provided for the current partition.
   */
  lessonTitle: string;

  /**
   * the current partition strategy. Used to provide accurate button label language and more context in aria-labels.
   */
  viewMode: LessonViewMode;
}

/**
 * user-friendly language for each partition.
 */
const partitionNames: (i18nStrings: PageFooterI18nStrings) => Record<LessonViewMode, string | undefined> = i18nStrings => ({
  [LessonViewMode.Section]: i18nStrings.section,
  [LessonViewMode.Gadget]: i18nStrings.page,
  [LessonViewMode.All]: undefined
});

/**
 * visual labels for the next/prev navigation buttons
 */
const navButtonLabels: (i18nStrings: PageFooterI18nStrings) => Record<LessonViewMode, Record<string, string>> = i18nStrings =>  ({
  [LessonViewMode.Section]: {
    next: i18nStrings.nextSection,
    prev: i18nStrings.previousSection
  },
  [LessonViewMode.Gadget]: {
    next: i18nStrings.nextPage,
    prev: i18nStrings.previousPage
  },
  [LessonViewMode.All]: {}
});

/**
 * screen reader labels for next/prev navigation buttons.
 * @param viewMode
 * @param lessonTitle
 * @param currentIndex
 * @param totalPages
 * @param i18nStrings
 */
const getNavButtonAriaLabels = (viewMode: LessonViewMode, lessonTitle: string, currentIndex: number, totalPages: number, i18nStrings: PageFooterI18nStrings) => {
  if (viewMode === LessonViewMode.Section) {
    return {
      next: i18nStrings.goToNextSection(currentIndex + 2, totalPages, lessonTitle),
      prev: i18nStrings.goToPreviousSection(currentIndex, totalPages, lessonTitle),
    };
  }
  if (viewMode === LessonViewMode.Gadget) {
    return {
      next: i18nStrings.goToNextPage(currentIndex + 2, totalPages, lessonTitle),
      prev: i18nStrings.goToPreviousPage(currentIndex, totalPages, lessonTitle),
    };
  }
  return null;
};

/**
 * screen reader label for the entire nav landmark element
 * @param viewMode
 * @param lessonTitle
 */
const getNavigationLabel = (viewMode: LessonViewMode, i18nStrings: PageFooterI18nStrings, lessonTitle?: string) => {
  if (!lessonTitle) {
    return null;
  }
  if (viewMode === LessonViewMode.Section) {
    return i18nStrings.navigateSections(lessonTitle);
  }
  if (viewMode === LessonViewMode.Gadget) {
    return i18nStrings.navigatePages(lessonTitle);
  }
  return null;
};

export const PageFooter: FunctionComponent<PageFooterProps> = ({
  onPartitionIndexChanged,
  currentPartitionIndex,
  totalPartitions,
  partitionTitle,
  lessonTitle,
  viewMode,
  i18nStrings = defaultI18nStrings.pageFooter,
}) => {

  // If there is not more than one partition, don't render this footer.
  if (totalPartitions <= 1) {
    return null;
  }

  const partitionPositionText = `${partitionNames(i18nStrings)[viewMode]} ${currentPartitionIndex + 1}/${totalPartitions}`;
  const pageButtonText = navButtonLabels(i18nStrings)[viewMode];
  const pageButtonAriaLabels = getNavButtonAriaLabels(viewMode, lessonTitle, currentPartitionIndex, totalPartitions, i18nStrings);
  const title = partitionTitle || lessonTitle;
  return <nav className='PageFooter' aria-label={getNavigationLabel(viewMode, i18nStrings, lessonTitle) || undefined}>
    <Responsive
      query='max-width'
      props={{
        isStackMode: {
          default: false,
          [responsiveStyles.tablet_breakpoint]: true
        },
        wrapper: {
          default: Row,
          [responsiveStyles.mobile_sm_breakpoint]: Column
        },
        wrapperProps: {
          default: {
            width: '100%',
            widths: ['50%', '50%'],
            alignmentVertical: 'center'
          },
          [responsiveStyles.tablet_breakpoint]: {
            spacingInset: 'none none 600 none',
            alignmentHorizontal: 'justify'
          },
          [responsiveStyles.mobile_sm_breakpoint]: {
            alignmentHorizontal: 'center',
            spacingInset: 'none none 600 none'
          }
        }
      }}
    >
      {
        props =>
          !props.isStackMode
            ? <Row
              width='100%'
              spacingInset='450 500'
              widths={['50%', '25%', '25%']} // Visual render order and DOM/focus order are deliberately different here
              alignmentVertical='center'
            >
              {/*The order of these children is the desired focus order. Visual render order is set in the CSS classes*/}
              {/*Middle section, partition title and position info*/}
              <div className='PageFooter__partition-title'>
                <Row alignmentHorizontal='center'>
                  <Column alignmentHorizontal='center' alignmentVertical='center' spacing='none'>
                    <Text>{partitionPositionText}</Text>
                    {title && <Text>{title}</Text>}
                  </Column>
                </Row>
              </div>

              {/*Right section, next button*/}
              <div className='PageFooter__next-button'>
                <Row alignmentHorizontal='end'>
                  {pageButtonText &&
                    currentPartitionIndex < totalPartitions - 1 &&
                    <Button
                      label={pageButtonAriaLabels?.next}
                      type='primary'
                      onClick={() => onPartitionIndexChanged(currentPartitionIndex + 1)}
                    >
                      {pageButtonText.next}<Icon tokens={arrowRightLarge}/>
                    </Button>
                  }
                </Row>
              </div>

              {/*Left section, previous button*/}
              <div className='PageFooter__prev-button'>
                <Row alignmentHorizontal='start'>
                  {pageButtonText &&
                    currentPartitionIndex > 0 &&
                    <Button
                      label={pageButtonAriaLabels?.prev}
                      type='link'
                      onClick={() => onPartitionIndexChanged(currentPartitionIndex - 1)}
                    >
                      <Icon tokens={arrowLeftLarge}/>{pageButtonText.prev}
                    </Button>
                  }
                </Row>
              </div>
            </Row>
            :
            <Column spacingInset='450 400' width='100%'>
              <Row width='100%' alignmentHorizontal='center'>
                <div className='PageFooter__partition-title'>
                  <Row alignmentHorizontal='center'>
                    <Column alignmentHorizontal='center' alignmentVertical='center' spacing='none'>
                      <Text>{partitionPositionText}</Text>
                      {title && <Text>{title}</Text>}
                    </Column>
                  </Row>
                </div>
              </Row>
              <props.wrapper {...props.wrapperProps}>
                {/*Right section, next button*/}
                <Box className='PageFooter__next-button'>
                  <Row alignmentHorizontal='end'>
                    {pageButtonText &&
                      currentPartitionIndex < totalPartitions - 1 &&
                      <Button
                        label={pageButtonAriaLabels?.next}
                        type='primary'
                        onClick={() => onPartitionIndexChanged(currentPartitionIndex + 1)}
                      >
                        {pageButtonText.next}<Icon tokens={arrowRightLarge}/>
                      </Button>
                    }
                  </Row>
                </Box>

                {/*Left section, previous button*/}
                <Box className='PageFooter__prev-button'>
                  <Row alignmentHorizontal='start'>
                    {pageButtonText &&
                      currentPartitionIndex > 0 &&
                      <Button
                        label={pageButtonAriaLabels?.prev}
                        type='link'
                        onClick={() => onPartitionIndexChanged(currentPartitionIndex - 1)}
                      >
                        <Icon tokens={arrowLeftLarge}/>{pageButtonText.prev}
                      </Button>
                    }
                  </Row>
                </Box>
              </props.wrapper>
            </Column>
      }
    </Responsive>
  </nav>;
};
