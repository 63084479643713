import {GadgetConfigAdapter} from './GadgetConfigAdapter';
import {GadgetType} from '../models/GadgetType';
import {GadgetConfig} from '../../models/GadgetConfig';

export class SectionGadgetConfigAdapter implements GadgetConfigAdapter<GadgetConfig> {
  getGadgetType(): string {
    return GadgetType.HEADER;
  }
  
  adapt(config: any): GadgetConfig {
    return {
      ...config,
      title: config.title || config.content
    };
  }
}
