import React, {forwardRef, useCallback, useEffect, useState} from 'react';
import {useEmblaCarousel} from 'embla-carousel/react';
import {Quill} from 'react-quill';
import {DeltaOperation} from 'quill';
import cloneDeep from 'lodash/cloneDeep';
import {RequireAtLeastOne} from 'type-fest';
import Heading from '@amzn/meridian/heading';
import Responsive from '@amzn/meridian/responsive';
import Theme from '@amzn/meridian/theme';
import brandedLightTokens from '../../../theme/branded-light';
import Pagination from '@amzn/meridian/pagination';
import {AssetModel, GadgetConfig, GadgetLearnerState, GadgetType, useCourseContext} from '../../../context/course';
import {isEmpty} from 'lodash';
import {ImageWithLightbox} from '../../image/ImageWithLightbox';
import {GadgetProps} from '../models';
import {LearnerGadgetActivity} from '../../../activity/models/learner-activities';
import {LearnerVerb} from '../../../activity/models/verbs';
import {LearnerObject} from '../../../activity/models/objects';
import GadgetContainer from '../../containers/gadget/GadgetContainer';
import {defaultI18nStrings, SlideshowGadgetI18nStrings} from '../../../context/course/models/I18n';
import CustomImage from '../reading/CustomImage';
import {CustomReactQuill} from '../../quill/CustomReactQuill';

import './styles/slideshow.scss';
import responsiveStyles from '../../../styles/responsive.module.scss';

const Delta = Quill.import('delta');

type SlideContentTypes = {
  image: AssetModel,
  content: {
    ops: DeltaOperation[]
  }
}

type SlideType = RequireAtLeastOne<SlideContentTypes>

export interface SlideshowGadgetConfig extends GadgetConfig {
  slides: SlideType[]
}

export type SlideshowGadgetProps = GadgetProps<SlideshowGadgetConfig, GadgetLearnerState, SlideshowGadgetI18nStrings>

/**
 * You can use this gadget to add a slideshow to your course.
 * When opened on a mobile device, the learner can navigate by swiping through the slides.
 * Each slide can contain a media asset (only images supported for now) and/or rich-text; the slide should contain at least one of either items.
 * For the rich-text portion, the gadget supports a [Quill Delta object](https://quilljs.com/docs/delta/).
 */
export const SlideshowGadget = forwardRef<HTMLElement | undefined, SlideshowGadgetProps>((props: SlideshowGadgetProps, ref) => {
  const {
    id,
    type,
    config: {
      title,
      slides: propSlides
    },
    i18nStrings = defaultI18nStrings.gadgets[GadgetType.SLIDESHOW] as SlideshowGadgetI18nStrings
  } = props;
  const {
    getAssetModel,
    emitLearnerActivity,
  } = useCourseContext();

  CustomImage.getAssetModel = getAssetModel;

  const [currentSlide, setCurrentSlide] = useState(1);
  const [viewportRef, carouselApi] = useEmblaCarousel({skipSnaps: false});
  const [slides, setSlides] = useState<SlideType[] | null>(null);

  /**
   * largestSlideHeight is used for fixing height of text only slides in Image + text slideshows, in learner mode.
   *
   * For its calculation, only slides that contain images are considered.
   */
  const [largestSlideHeight, setLargestSlideHeight] = useState(0);

  /**
   * largestImageHeight is used for storing the largest image height among all images in a slideshow.
   *
   * This is then used for fixing image div heights of all images in slideshow.
   * If images are smaller than largestImageHeight, they would be vertically center aligned, via CSS.
   * */
  const [largestImageHeight, setLargestImageHeight] = useState(0);

  /**
   * Function triggered after initial loading of an image, to compare its height with largestImageHeight that has been calculated so far.
   *
   * If given image's height is larger than largestImageHeight, it becomes the largestImageHeight.
   * */
  const determineLargestImageHeight = useCallback((image?: HTMLImageElement) => {
    if (!carouselApi) {
      return;
    }
    if (image && image.clientHeight > largestImageHeight) {
      setLargestImageHeight(image.clientHeight);
    }
  }, [carouselApi, largestImageHeight]);

  const onSelect = useCallback(() => {
    if (!carouselApi) {
      return;
    }
    setCurrentSlide(carouselApi.selectedScrollSnap() + 1);
  }, [carouselApi]);

  // Callback to handle pagination onClick event
  const scrollTo = useCallback(page => {
    if (carouselApi) {
      carouselApi.scrollTo(page - 1);
      setCurrentSlide(page);

      emitLearnerActivity(
        new LearnerGadgetActivity(
          LearnerVerb.VIEWED,
          LearnerObject.SLIDE,
          id,
          type,
        )
      );
    }
  }, [carouselApi, emitLearnerActivity, id, type]);

  // Call getAssetModel API to fetch asset ID location for the slides content and
  // any images in Quill content
  useEffect(() => {
    (async () => {
      const slidesWithAssetLocation = cloneDeep(propSlides);
      for (const slide of slidesWithAssetLocation) {
        if (slide.image && !isEmpty(slide.image)) {
          const assetMetadata = slide?.image?.assetMetadata || {};
          const mediaAsset = await getAssetModel(slide.image.id);
          if (mediaAsset) {
            slide.image = {
              ...mediaAsset,
              assetMetadata
            };
          }
        }
      }

      setSlides(slidesWithAssetLocation);
    })();
  }, [propSlides, getAssetModel]);

  useEffect(() => {
    if (!carouselApi) {
      return;
    }
    carouselApi
      .on('select', onSelect);
    onSelect();
  }, [carouselApi, onSelect]);

  /**
   * Effect used for calculating largestSlideHeight, which is used in learner mode only.
   *
   * Effect triggers when largestImageHeight is modified.
   * */
  useEffect(() => {
    if (!carouselApi || !slides) {
      return;
    }
    let largestSlideHeight = 0;
    carouselApi.slideNodes().forEach((slideNode, index) => {
      if (slides[index] && !isEmpty(slides[index].image)) {
        const slideInnerNode = slideNode.querySelector('.carousel__slide__inner');
        largestSlideHeight = Math.max(slideInnerNode?.getBoundingClientRect().height || 0, largestSlideHeight);
      }
    });
    setLargestSlideHeight(largestSlideHeight);
  }, [carouselApi, slides, largestImageHeight]);

  const renderImage = (assetModel: AssetModel) => {
    return (
      <div className='carousel__slide__image'
        style={{minHeight: largestImageHeight > 0 ? `${largestImageHeight}px` : undefined}}>
        {assetModel.location &&
          <ImageWithLightbox
            id={assetModel.id}
            image={{
              src: assetModel.location,
              alt: assetModel.assetMetadata?.altText as string,
            }}
            onLoad={determineLargestImageHeight}
            i18nStrings={i18nStrings.imageLightbox}
          />
        }
      </div>
    );
  };

  const renderText = (slide: SlideType) => {
    const slideContainsImage = !isEmpty(slide.image);
    return (
      <CustomReactQuill
        className={
          (slideContainsImage
            ? (largestImageHeight === 0 ? 'no-image-loaded-yet' : 'with-image')
            : (largestImageHeight === 0 ? 'no-image-slideshow' : ''))
        }
        value={new Delta(slide.content)}
        style={{height: (largestImageHeight > 0 && !slideContainsImage && largestSlideHeight > 0) ? `${largestSlideHeight}px` : undefined}}
        i18nStrings={i18nStrings ? {
          imageLightbox: i18nStrings?.imageLightbox,
          linkWarning: i18nStrings?.linkWarning
        } : undefined}
      />
    );
  };

  return (
    <GadgetContainer id={id} ref={ref}>
      {slides && <Theme tokens={brandedLightTokens}>
        <React.Fragment>
          <div className='slideshow-gadget-title'>
            <Heading level={3}>{title}</Heading>
          </div>
          <div className='carousel'>
            <div className='carousel__viewport' ref={viewportRef}>
              <div className='carousel__container'>
                {slides.map((slide, i) =>
                  <div key={i} className='carousel__slide'>
                    <div className='carousel__slide__inner'>
                      {slide.image && !isEmpty(slide.image) && renderImage(slide.image)}
                      {slide.content && renderText(slide)}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='carousel__nav'>
              <Responsive
                query='max-width'
                props={{
                  showPaginationArrows: {
                    default: true,
                    [responsiveStyles.tablet_breakpoint]: false
                  }
                }}
              >
                {props =>
                  <Pagination
                    numberOfPages={slides.length}
                    onChange={page => scrollTo(page)}
                    currentPage={currentSlide}
                    showSkipArrows={props.showPaginationArrows}
                  />}
              </Responsive>
            </div>
          </div>
        </React.Fragment>
      </Theme>}
    </GadgetContainer>
  );
});
SlideshowGadget.displayName = 'SlideshowGadget';
