import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import AppLayout from '@amzn/meridian/app-layout';
import get from 'lodash/get';
import set from 'lodash/set';
import Column from '@amzn/meridian/column';
import brandedLightTokens from '../../../theme/branded-light';
import noop from 'lodash/noop';
import CourseContextProvider, {
  Course,
  CourseApi,
  CourseContextProps, CourseLearnerState,
  CoursePosition,
  DisplaySection,
  GadgetLearnerState,
  InitialPosition,
  useCourseContext,
  Logger,
} from '../../../context/course';
import {RecoilRoot, useRecoilState, useSetRecoilState} from 'recoil';
import {
  currentCourse,
  currentCourseGadgetsLearnerState,
  currentCourseLearnerState,
  currentCourseLessonsPartitionedByViewMode,
  currentCoursePosition,
  currentScrollToGadgetId,
  useCurrentCourse,
  useCurrentCourseLesson,
  useCurrentLessonPartitionedByViewMode,
  useCurrentLessonPartitionIndex,
  useCurrentLessonViewMode,
  useCurrentPartitionTitle,
} from '../../../state/recoil';
import Theme from '@amzn/meridian/theme';
import {ErrorBoundary} from 'react-error-boundary';
import {LearnerActivity} from '../../../activity/models/learner-activities';
import {CoursePlayerVisibilityActivity} from '../../learner-activites/CoursePlayerVisibilityActivity';
import {CourseLoader} from './components/CourseLoader';
import {
  fallbackRender,
  LessonCanvasWithRecoilState,
  SidebarWithRecoilState,
  TopNavWithRecoilState
} from './CoursePlayer';
import {CourseExitProps} from './components/CourseExitButton';
import {PaywallProps} from '../../preview/DefaultPaywall';
import {partitionCourseLessonsByViewMode} from '../../../utils/LessonPartitionUtils';
import {PageFooter} from '../../navigation/footer/PageFooter';
import {GadgetRenderer} from '../../../gadgets/registry';
import {I18nStringsConfig, PageFooterI18nStrings} from '../../../context/course/models/I18n';

import '../../navigation/footer/styles/course-nav.scss';
import './CoursePreviewPlayer.scss';
import {AxiosError} from 'axios';
import {getAssetModelForLearner, getCloudLabEmbedInfo} from '../../../utils/CourseUtils';

export interface ErrorFallbackProps {
  error: Error
}

/**
 * Preview Course Player props
 */
export interface CoursePreviewPlayerProps {
  /**
   * ID of the course to be used in this context
   */
  courseId: string;

  /**
   * Course position set in the URL
   */
  initialPosition?: InitialPosition;

  /**
   * Course API implementation to be used
   */
  courseApi: CourseApi;

  /**
   * props for Top Nav button that exits the course player. if none are provide, button is not rendered.
   */
  courseExitButtonProps?: CourseExitProps;

  /**
   * Paywall page over lesson canvas for non preview-able lessons.
   */
  paywallPage: FunctionComponent<PaywallProps>;

  /**
   * Handler for course position change updates (optional)
   * @param position new course position
   */
  onPositionChange?: (position: CoursePosition) => void;

  /**
   * Handler for course updates (optional)
   * @param course new course value
   */
  onCourseChange?: (course: Course) => void,

  /**
   * callback function that is called if the course player experiences uncaught errors (outside of any gadgets)
   * @param error the js Error object passed by React's error boundary code
   * @param info React's error info object
   */
  onError?: (error: Error, info: React.ErrorInfo) => void,

  /**
   * optional function to render a fallback view in case there is an uncaught error somewhere in the course player.
   * @param props object containing the error object thrown
   */
  errorFallbackRender?: (props: ErrorFallbackProps) =>
    React.ReactElement<unknown, string | React.FunctionComponent | typeof React.Component> | null;

  /**
   * Handler for Learner events (optional)
   * @param activity
   */
  onLearnerActivityEvent?: (learnerActivity: LearnerActivity) => void;

  /**
   * Handler for Katal Metrics Counter (optional)
   * @param metricName
   */
  handleCounterMetrics?: (metricName: string) => void;

  /**
   * Handler for Katal Metrics Timer (optional)
   * @param metricName, time
   */
  handleTimerMetrics?: (metricName: string, timer: number) => void;

  /**
   * If provided, it overrides the gadget types with the specified components.
   */
  gadgetOverrides?: GadgetRenderer;

  /**
   * Set of translation strings and functions for static text throughout the components
   * Set of translation strings and functions for static text throughout the components
   */
  i18nStringsConfig?: I18nStringsConfig;

  /**
   * Optional Logger object that contains functions to log: "debug", "info", "warn", "error", "fatal"
   * The implementation of each function is optional, if any is left empty, we will call noop
   */
  logger?: Logger;
}

/**
 * LessonCanvas HOC using Recoil State
 */
const LessonPreviewCanvasWithRecoilState: FunctionComponent<{
  previewableLessonIds: Set<string>,
  paywallPage: FunctionComponent<PaywallProps>,
  gadgetOverrides?: GadgetRenderer,
  i18nStringsConfig?: I18nStringsConfig,
}> = props => {
  const {
    previewableLessonIds,
    paywallPage,
    gadgetOverrides,
    i18nStringsConfig,
  } = props;
  const currentLesson = useCurrentCourseLesson();
  const lessonId = currentLesson?.id;

  if (!currentLesson) {
    return null;
  }

  if (lessonId && previewableLessonIds.has(lessonId)) {
    return <LessonCanvasWithRecoilState gadgetOverrides={gadgetOverrides} i18nStrings={i18nStringsConfig}/>;
  }

  const PaywallPage = paywallPage;

  return <PaywallPage i18nStrings={i18nStringsConfig?.paywall} />;
};

const PageFooterWithRecoilState: FunctionComponent<{i18nStrings?: PageFooterI18nStrings}> = ({i18nStrings}) => {
  const currentCourse = useCurrentCourse();
  const currentLesson = useCurrentCourseLesson();
  const setScrollToGadgetId = useSetRecoilState(currentScrollToGadgetId);

  const currentLessonPartitions = useCurrentLessonPartitionedByViewMode();
  const currentPartitionIndex = useCurrentLessonPartitionIndex();
  const currentViewMode = useCurrentLessonViewMode();
  const sectionName = useCurrentPartitionTitle();

  const {setCoursePosition} = useCourseContext();

  const onPartitionIndexChanged = useCallback((newIndex: number) => {
    if (currentLesson) {
      const newPartition = currentLessonPartitions?.partitions[newIndex];
      const firstGadgetOnNewPartition = newPartition ? newPartition.gadgets[0] : null;
      const firstGadgetId = firstGadgetOnNewPartition?.id;
      setCoursePosition({
        displaySection: DisplaySection.LESSON,
        lessonId: currentLesson.id,
        gadgetId: firstGadgetId || '',
        updatedAt: new Date()
      });
      setScrollToGadgetId({gadgetId: firstGadgetId, focus: true});
    }
  }, [currentLesson, currentLessonPartitions]);

  if (!currentCourse) {
    return null;
  }

  return <Theme tokens={brandedLightTokens}>
    <div className='courseNav__page-footer'>
      <PageFooter
        onPartitionIndexChanged={onPartitionIndexChanged}
        currentPartitionIndex={currentPartitionIndex}
        totalPartitions={currentLessonPartitions?.partitions.length || 0}
        lessonTitle={currentLesson?.title || ''}
        viewMode={currentViewMode}
        partitionTitle={sectionName}
        i18nStrings={i18nStrings}
      />
    </div>
  </Theme>;
};

/**
 * Course Player using Recoil state
 */
const CoursePreviewPlayerWithRecoilState: FunctionComponent<CoursePreviewPlayerProps> = (
  {
    courseId,
    courseApi,
    initialPosition,
    courseExitButtonProps,
    paywallPage,
    onPositionChange,
    onCourseChange,
    onLearnerActivityEvent,
    handleCounterMetrics = noop,
    handleTimerMetrics = noop,
    gadgetOverrides,
    i18nStringsConfig,
    logger,
  }: CoursePreviewPlayerProps
) => {
  const [course, setCourse] = useRecoilState(currentCourse);
  const setCourseLearnerState = useSetRecoilState(currentCourseLearnerState);
  const setCoursePosition = useSetRecoilState(currentCoursePosition);
  const setGadgetsState = useSetRecoilState(currentCourseGadgetsLearnerState);
  const setScrollToGadgetId = useSetRecoilState(currentScrollToGadgetId);
  const setLessonPartitions = useSetRecoilState(currentCourseLessonsPartitionedByViewMode);

  const [contextValue] = useState<CourseContextProps>({
    setCoursePosition: async position => {
      setCoursePosition(position);
      onPositionChange && onPositionChange(position);
      // User State is not persisted in Course Preview
      return;
    },
    setCourseProgress: async progressStatus => {
      // User State is not persisted in Course Preview
      return Promise.resolve({certificateId: '',gadgetIds: [],statusTransitionSuccessful: true});
    },
    setLessonProgress: async (lessonId, progress) => {
      // User State is not persisted in Course Preview
      return;
    },
    setGadgetLearnerState: async (gadgetId, gadgetType, gadgetLearnerState, persistState = true) => {
      let updatedGadgetState: GadgetLearnerState = gadgetLearnerState;
      if (persistState) {
        updatedGadgetState = await courseApi.setGadgetLearnerState(courseId, gadgetId, gadgetType, gadgetLearnerState);
      }

      setGadgetsState(gadgetsState => { return {
        ...gadgetsState,
        [gadgetId]: updatedGadgetState,
      };});
    },
    submitGadgetLearnerStateAssessment: async (gadgetId, gadgetType, learnerState) => {
      const updatedGadgetState = await courseApi.submitGadgetLearnerStateAssessment(courseId, gadgetId, gadgetType, learnerState);
      setGadgetsState(gadgetsState => { return {
        ...gadgetsState,
        [gadgetId]: updatedGadgetState
      };});
    },
    getAssetModel: getAssetModelForLearner(courseId, courseApi),
    getCloudLabEmbedInfo: gadgetId => getCloudLabEmbedInfo(courseId, gadgetId, courseApi),
    emitLearnerActivity: (activity: LearnerActivity) => {
      if (!onLearnerActivityEvent) {
        return;
      }

      onLearnerActivityEvent(activity);
    },
    submitLearnerFeedback: async learnerFeedback => {
      // Learner Feedback is not supported in Course Preview
      return;
    },
    emitCounterMetrics: (metricName: string) => {
      handleCounterMetrics(metricName);
    },
    emitTimerMetrics: (metricName: string, timer: number) => {
      handleTimerMetrics(metricName, timer);
    },
    logger: logger
  });

  useEffect(() => {
    (async () => {
      const course = await courseApi.loadCourse(courseId);
      setCourse(course);
      onCourseChange && onCourseChange(course);
      // Default (Empty) Course Learner State
      const courseLearnerState: CourseLearnerState = {
        updatedAt: new Date(),
      };
      const lessonViewMode = course.lessonViewMode;
      const lessonPartitions = partitionCourseLessonsByViewMode(course.lessons, lessonViewMode);
      setLessonPartitions(lessonPartitions);

      let initialCoursePosition: CoursePosition;

      if (initialPosition) {
        const {
          lessonIndex,
          gadgetIndex
        } = initialPosition;

        initialCoursePosition = {
          displaySection: DisplaySection.LESSON,
          lessonId: get(course, `lessons[${lessonIndex - 1}].id`),
          gadgetId: gadgetIndex && get(course, `lessons[${lessonIndex - 1}].gadgets[${gadgetIndex - 1}].id`),
          updatedAt: new Date(),
        } as CoursePosition;
      } else {
        initialCoursePosition = {
          displaySection: DisplaySection.LESSON,
          lessonId: course.lessons[0].id,
          gadgetId: course.lessons[0].gadgets[0].id,
          updatedAt: new Date(),
        } as CoursePosition;
      }

      set(courseLearnerState, 'coursePosition', initialCoursePosition);
      
      // Set Learner State so that it's not null and Course Position is set to an initial value
      setCourseLearnerState(courseLearnerState);
      setScrollToGadgetId({ gadgetId: courseLearnerState.coursePosition?.gadgetId });
      onPositionChange && courseLearnerState.coursePosition && onPositionChange(courseLearnerState.coursePosition);
    })();
  }, [courseId, courseApi, initialPosition, setCourse, setCourseLearnerState, setScrollToGadgetId, onCourseChange, onPositionChange]);

  if (!course || !contextValue) {
    return <CourseLoader i18nStrings={i18nStringsConfig?.loader}/>;
  }

  // Hardcoding to the first lesson for now until backend starts passing which lesson is previewable
  const previewableLessonIds = new Set([course.lessons[0].id]);

  return (
    <CourseContextProvider {...contextValue}>
      <CoursePlayerVisibilityActivity/>
      <Theme tokens={brandedLightTokens}>
        <AppLayout
          headerComponent={TopNavWithRecoilState}
          sidebarComponent={SidebarWithRecoilState}
          footerComponent={PageFooterWithRecoilState}
          backgroundColor={'alternateSecondary'}
          mainClassName='CoursePlayer__main'
        >
          <TopNavWithRecoilState
            courseExitButtonProps={courseExitButtonProps}
            i18nStrings={i18nStringsConfig?.topNav}
          />
          <SidebarWithRecoilState
            enableCourseProgress={false}
            enableFeedbackWidget={false}
            i18nStrings={i18nStringsConfig ? {sidebar: i18nStringsConfig.sidebar, feedback: i18nStringsConfig.feedback} : undefined}
          />
          <Column height='100%' heights={['fill', 'fit']} alignmentHorizontal='center'>
            <LessonPreviewCanvasWithRecoilState
              previewableLessonIds={previewableLessonIds}
              paywallPage={paywallPage}
              gadgetOverrides={gadgetOverrides}
              i18nStringsConfig={i18nStringsConfig}
            />
            <PageFooterWithRecoilState i18nStrings={i18nStringsConfig?.pageFooter}/>
          </Column>
        </AppLayout>
      </Theme>
    </CourseContextProvider>
  );
};

/**
 * Course Preview Player component
 */
export const CoursePreviewPlayer: FunctionComponent<CoursePreviewPlayerProps> = (
  previewCoursePlayerProps: CoursePreviewPlayerProps
) => {
  return (
    <RecoilRoot>
      <div className='CoursePreviewPlayer'>
        <ErrorBoundary
          fallbackRender={
            previewCoursePlayerProps.errorFallbackRender
            || fallbackRender(previewCoursePlayerProps.i18nStringsConfig?.errors)
          }
          onError={previewCoursePlayerProps.onError}
        >
          <CoursePreviewPlayerWithRecoilState {...previewCoursePlayerProps} />
        </ErrorBoundary>
      </div>
    </RecoilRoot>
  );
};
