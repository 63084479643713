import {GadgetConfigAdapter} from './GadgetConfigAdapter';
import {
  QuizGadgetConfig,
  QuizQuestion,
  QuizQuestionChoice,
  QuizQuestionType
} from '../../../../components/gadgets/quiz';
import {GadgetType} from '../models/GadgetType';
import {head, map, indexOf, get} from 'lodash';

export class QuizGadgetConfigAdapter implements GadgetConfigAdapter<QuizGadgetConfig> {
  getGadgetType(): string {
    return GadgetType.QUIZ;
  }
  
  adapt(config: any): QuizGadgetConfig {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (head(config.questions)?.type) {
      return config; // using new format, nothing to do
    }
    return {
      ...config,
      title: config.title || config.tocTitle,
      allowRetries: config.lessonBlocking !== 'noRetries',
      questions: map(config.questions, question => {
        return {
          id: question.id,
          title: question.questionTitle,
          image: question?.image?.image,
          type: question.isMultiselect ? QuizQuestionType.Multiselect : QuizQuestionType.Single,
          choices: map(question.answers, answer => {
            const choice = {
              id: answer.id,
              value: answer.answer
            };

            // if answerKey object provided, check if choice is a correct answer
            if (config.answerKey) {
              const answers = get(config, `answerKey[${question.id}]`, []);

              return {
                ...choice,
                isCorrect: indexOf(answers, answer.id) !== -1
              } as QuizQuestionChoice;
            } else {
              return choice as QuizQuestionChoice;
            }
          })
        } as QuizQuestion;
      })
    } as QuizGadgetConfig;
  }
}
