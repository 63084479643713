import React, {FunctionComponent, useCallback, useState} from 'react';

import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Modal from '@amzn/meridian/modal';
import Theme from '@amzn/meridian/theme';

import {DisplaySection, ProgressStatus, useCourseContext} from '../../../../context/course';

import lightTokens from '../../../../theme/branded-light';
import {CompleteCourseModalI18nStrings, WithI18nStringsProps} from '../../../../context/course/models/I18n';

/**
 * Progress Tracker info popover props
 */
interface CompleteCourseModalProps extends WithI18nStringsProps<CompleteCourseModalI18nStrings> {

  /**
   * Callback to close Modal
   */
   closeModal: () => void;

   /**
    * Content that will go inside the modal
    */
   children: React.ReactNode;
}

/**
 * InfoPopover for progress tracker indicator
 */
const CompleteCourseModal: FunctionComponent<CompleteCourseModalProps> = (
  {
    closeModal,
    children,
    i18nStrings,
  }: CompleteCourseModalProps
) => {

  const {setCourseProgress, setCoursePosition} = useCourseContext();
  const [returnToElement] = useState<HTMLElement | null>(document.activeElement as HTMLElement);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  // useEffect(() => setReturnToElement(document.activeElement as HTMLElement), []);

  const handleMarkAsCompletedOnClick = useCallback(async () => {
    setIsButtonDisabled(true);
    const response = await setCourseProgress(ProgressStatus.COMPLETED);
    if(response.statusTransitionSuccessful) {
      setCoursePosition({
        lessonId: '',
        gadgetId: '',
        displaySection: DisplaySection.COMPLETION_PAGE,
        updatedAt: new Date(),
      });

    }
    setIsButtonDisabled(false);
    closeModal();
    returnToElement?.focus();
  }, [returnToElement, closeModal]);

  return (
    <Theme tokens={{
      ...lightTokens
    }}>
      <Modal
        open
        onClose={closeModal}
        scrollContainer='viewport'
        closeLabel='Close'
        width='500px'
        bodySpacingInset='600'
      >
        {/*
          * Adding left padding to compensate for the extra right padding
          * This has already been reported to the Meridian team. We will do it this way until they fix the issue on their end.
          */}
        <Column alignmentHorizontal='center' spacing='450' spacingInset='none none none 500'>
          {children}
          <Button type='primary' onClick={handleMarkAsCompletedOnClick} disabled={isButtonDisabled}>{i18nStrings.markComplete}</Button>
          <Button type='link' onClick={() => {
            closeModal();
            returnToElement?.focus();
          }}>{i18nStrings.returnToCourse}</Button>
        </Column>
      </Modal>
    </Theme>
  );
};

export default CompleteCourseModal;
