import {GadgetLearnerStateConverter} from './GadgetLearnerStateConverter';
import {QuizGadgetLearnerStateConverter} from './QuizGadgetLearnerStateConverter';
import {ResponseGadgetLearnerStateConverter} from './ResponseGadgetLearnerStateConverter';
import {GadgetLearnerState} from '../../models/GadgetLearnerState';

export class GadgetLearnerStateConverterRegistry {
  private readonly registry: Record<string, GadgetLearnerStateConverter<any>>

  constructor() {
    this.registry = {};

    this.registerConverter(new QuizGadgetLearnerStateConverter());
    this.registerConverter(new ResponseGadgetLearnerStateConverter());
  }

  private registerConverter(gadgetLearnerStateConverter: GadgetLearnerStateConverter<any>): void {
    this.registry[gadgetLearnerStateConverter.getGadgetType()] = gadgetLearnerStateConverter;
  }

  shouldConvertGadgetType(gadgetType: string): boolean {
    return this.registry[gadgetType] !== undefined;
  }

  fromGadgetLearnerState<T extends GadgetLearnerState>(gadgetType: string, state: T): any {
    return this.registry[gadgetType].fromGadgetLearnerState(state);
  }

  toGadgetLearnerState<T extends GadgetLearnerState>(gadgetType: string, state: any): T {
    return this.registry[gadgetType].toGadgetLearnerState(state);
  }
}
