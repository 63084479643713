import {QuizQuestionChoice, QuizQuestionType} from './QuizGadget';
import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import checkKnockoutTokens from '@amzn/meridian-tokens/base/icon/check-knockout';
import closeKnockoutTokens from '@amzn/meridian-tokens/base/icon/close-knockout';
import {getAnswerLetterFromIndex} from './helpers';

/**
 * Quiz Question Choice props
 */
export interface QuizQuestionChoiceEntryProps {
  /**
   * Choice base-0 index
   */
  index: number,

  /**
   * gadget id, for unique keys and html ids
   */
  gadgetId: string,

  /**
   * Question choice
   */
  choice: QuizQuestionChoice,

  /**
   * Question this choice belongs to
   */
  question: {
    /**
     * ID of the question this choice belongs to
     */
    id: string,

    /**
     * Question type
     */
    type: QuizQuestionType,

    /**
     * Whether or not the question has been graded
     */
    isGraded: boolean,
  }

  /**
   * Whether or not this choice is selected
   */
  isSelected: boolean,

  /**
   * Whether or not this is the correct choice (optional)
   */
  isCorrectChoice?: boolean,

  /**
   * Event handler for when a question choice is selected
   */
  onSelectChoice: (type: QuizQuestionType, questionId: string, choiceId: string) => void

  /**
   * Flag to hide answers once quiz is evaluated
   */
  shouldShowAnswers: boolean
}

/**
 * Renders a Quiz Question Choice
 */
export const QuizQuestionChoiceEntry: FunctionComponent<QuizQuestionChoiceEntryProps> = (
  {
    index,
    gadgetId,
    choice,
    question: {id: questionId, type: questionType, isGraded},
    isSelected,
    isCorrectChoice,
    onSelectChoice,
    shouldShowAnswers
  }: QuizQuestionChoiceEntryProps
) => {

  const isRelevantToGrade = isGraded && (isSelected || isCorrectChoice);
  const isMultiselect = questionType === QuizQuestionType.Multiselect;

  const answerClass = cx(
    'quiz_gadget_answer_container',
    isSelected && 'quiz_gadget_answer_container--selected',
    isGraded && 'quiz_gadget_answer_container--graded',
    isRelevantToGrade && shouldShowAnswers && isCorrectChoice && 'quiz_gadget_answer_container--passed',
    isRelevantToGrade && shouldShowAnswers && !isCorrectChoice && 'quiz_gadget_answer_container--failed'
  );

  const selectedLabelClass = cx(
    'quiz_gadget_answer_selected',
    isRelevantToGrade && shouldShowAnswers && isCorrectChoice && 'quiz_gadget_answer_selected--passed',
    isRelevantToGrade && shouldShowAnswers && !isCorrectChoice && 'quiz_gadget_answer_selected--failed'
  );

  const style = cx(
    'quiz_gadget_answer_letter',
    isMultiselect ? 'quiz_gadget_answer_letter--multiselect' : 'quiz_gadget_answer_letter--singleselect',
    isSelected && 'quiz_gadget_answer_letter--selected',
    isGraded && shouldShowAnswers && isCorrectChoice && 'quiz_gadget_answer_letter--passed',
    isGraded && shouldShowAnswers && !isCorrectChoice && 'quiz_gadget_answer_letter--failed'
  );

  const inputName = `${gadgetId}-question-${questionId}`;
  const inputId = `${inputName}-answer-${choice.id}`;
  const answerLetter = getAnswerLetterFromIndex(index);
  const gradeLabel = isCorrectChoice ? 'correct choice' : 'incorrect choice';

  return (
    <div className='quiz_gadget_answer'>
      <input
        className='quiz_gadget_radio'
        id={inputId}
        type={isMultiselect ? 'checkbox' : 'radio'}
        value={choice.id}
        checked={isSelected}
        onChange={_ => {
          onSelectChoice(questionType, questionId, choice.id);
        }}
        name={inputName}
        disabled={isGraded}
        aria-label={isGraded ?
          `${answerLetter}: ${choice.value}, ${gradeLabel}` :
          `${answerLetter}: ${choice.value}`}
      />
      <label aria-hidden className={answerClass} htmlFor={inputId}>
        <span style={{display: 'block'}} aria-hidden className={style}>{answerLetter}</span>
        <Text type='b300' tag='span' className='quiz_gadget_answer_text'>
          {choice.value}
        </Text>

        {isRelevantToGrade && shouldShowAnswers && <span aria-hidden style={{display: 'block'}} className={selectedLabelClass}>
          <Icon
            className='quiz_gadget_answer_selected_icon'
            tokens={isCorrectChoice ? checkKnockoutTokens : closeKnockoutTokens}
          />
        </span>}
      </label>
    </div>
  );
};
