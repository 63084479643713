import React, {FunctionComponent} from 'react';
import Icon from '@amzn/meridian/icon';
import closeSmallTokens from '@amzn/meridian-tokens/base/icon/close-small';
import Button from '@amzn/meridian/button';
import Responsive from '@amzn/meridian/responsive';
import Theme from '@amzn/meridian/theme';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

import responsiveStyles from '../../../../styles/responsive.module.scss';

/**
 * provides props for a button/link that is intended to exit the course player and take
 * the user to a different page, typically the page which served as the entry point.
 *
 * should provide either an href or onClick function (or both). If neither are provided, no button or link
 * will be rendered.
 */
export interface CourseExitProps {
  /**
   * visual text label for the button.
   */
  buttonText: string;
  
  /**
   * optional non-visual label for assistive technologies (i.e. screenreaders). Can use to provide
   * additional context if the visual label isn't enough.
   */
  label?: string;

  /**
   * url to navigate to.
   */
  href?: string;

  /**
   * click handler, in case side effects are required. receives the href prop value as a parameter to facilitate
   * navigation afterwards.
   * @param href the value of the href prop if provided.
   */
  onClick?: (href?: string) => void;

  /**
   * exit button tokens to override button style.
   */
  exitButtonTokenOverrides?: any;

  /**
   * boolean to display 'X' icon or not
   */
  displayIcon?: boolean;
}

export const CourseExitButton: FunctionComponent<CourseExitProps> = props => {
  const {
    onClick,
    href,
    label,
    buttonText,
    exitButtonTokenOverrides,
    displayIcon = true,
  } = props;

  return (
    <Theme tokens={exitButtonTokenOverrides || {}}>
      <Button
        type='link'
        onClick={onClick}
        href={href}
        label={label}
      >
        <Row alignmentVertical={'center'}>
          <Responsive
            query='max-width'
            props={{
              showLabel: {
                default: true,
                [responsiveStyles.tablet_breakpoint]: false
              }
            }}
          >
            {props => props.showLabel &&
              <Box>
                <Text>{buttonText}</Text>
              </Box>
            }
          </Responsive>
          {displayIcon && <Icon tokens={closeSmallTokens}/>}
        </Row>
      </Button>
    </Theme>
  );
};
