import React, {FunctionComponent, useEffect, useRef} from 'react';

import Heading from '@amzn/meridian/heading';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Responsive from '@amzn/meridian/responsive';
import Row from '@amzn/meridian/row';
import Theme from '@amzn/meridian/theme';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import {Course} from '../../../context/course';

import waveTokens from '../../../theme/wave';
import brandedLight from '../../../theme/branded-light';
import {
  CourseCompletedI18nStrings,
  defaultI18nStrings,
} from '../../../context/course/models/I18n';
import responsiveStyles from '../../../styles/responsive.module.scss';

/**
 * Course Completed props
 */
export interface CourseCompletedProps {

  /**
   * Course title to be displayed in header
   */
  course: Course;

  /**
   * static text
   */
  i18nStrings?: CourseCompletedI18nStrings;
}

/**
 * Course completion page
 */
export const DefaultCourseCompleted: FunctionComponent<CourseCompletedProps> = ({
  course,
  i18nStrings = defaultI18nStrings.courseCompleted,
}: CourseCompletedProps) => {
  const heading = i18nStrings.congratulationsTitle(course.title);
  const headingRef = useRef<HTMLElement>();
  useEffect(() => {
    headingRef.current?.focus();
  }, [headingRef]);

  return (

    <Theme tokens={waveTokens}>
      <Row alignmentHorizontal='center' spacingInset='600 none'>
        <Column height='100%' spacingInset='600 300' alignmentVertical={'center'} spacing='600' maxWidth='912px'>
          <Box maxWidth='65%'>
            <Heading ref={headingRef} tabIndex='-1' level={1}>{heading}</Heading>
          </Box>
          <Theme tokens={brandedLight}>
            <Responsive
              query='max-width'
              props={{
                wrapper: {
                  default: Row,
                  [responsiveStyles.tablet_breakpoint]: Column
                },
                wrapperProps: {
                  default: {
                    widths: ['grid-6', 'grid-6'],
                    alignmentHorizontal: 'center',
                    alignmentVertical: 'stretch'
                  },
                  [responsiveStyles.tablet_breakpoint]: {alignmentHorizontal: 'center'}
                },
                buttonWrapperProps: {
                  default: {spacing: 'medium', reverse: true},
                  [responsiveStyles.tablet_breakpoint]: undefined
                },
                boxWrapperProps: {
                  default: undefined,
                  [responsiveStyles.tablet_breakpoint]: { minWidth: '100%'}
                },
                buttonProps: {
                  default: undefined,
                  [responsiveStyles.tablet_breakpoint]: {minWidth: '100%'}
                }
              }}
            >
              {props =>
                <props.wrapper {...props.wrapperProps}>
                  <Box type='fill' {...props.boxWrapperProps}>
                    <Column height='100%' spacingInset='450 400' heights={['fill', 'fit']}>
                      <Column spacing='300'>
                        <Text type='h300'>{i18nStrings.whatDidYouThink}</Text>
                        <Text type='b300'>{i18nStrings.shareYourExperience}</Text>
                      </Column>
                      <Box>
                        <Button disabled={true} {...props.buttonProps}>{i18nStrings.writeReview}</Button>
                      </Box>
                    </Column>
                  </Box>
                  <Box type='fill' {...props.boxWrapperProps}>
                    <Column height='100%' spacingInset='450 400' heights={['fill', 'fit']}>
                      <Column spacing='300'>
                        <Text type='h300'>{i18nStrings.keepUpYourStreak}</Text>
                        <Text type='b300'>{i18nStrings.findNewCourse}</Text>
                      </Column>
                      <props.wrapper {...props.buttonWrapperProps}>
                        <Button type='tertiary'
                          disabled={true}  {...props.buttonProps}>{i18nStrings.discoverNewCourses}</Button>
                        <Button disabled={true} {...props.buttonProps}>{i18nStrings.returnToYourCourses}</Button>
                      </props.wrapper>
                    </Column>
                  </Box>
                </props.wrapper>}
            </Responsive>
          </Theme>
        </Column>
      </Row>
    </Theme>
  );
};
