import React, {forwardRef, FunctionComponent, useState} from 'react';
import {GadgetConfig, GadgetType} from '../../../../context/course';
import {GadgetEditorProps} from '../models';
import {useCourseAuthoringContext} from '../../../context';
import GadgetContainer from '../../../../components/containers/gadget/GadgetContainer';
import Input from '@amzn/meridian/input';
import Theme from '@amzn/meridian/theme';
import Column from '@amzn/meridian/column';
import Box from '@amzn/meridian/box';
import brandedLightTokens from '../../../../theme/branded-light';
import {MarkdownEditor} from './MarkdownEditor';
import {defaultI18nStrings, MarkdownGadgetI18nStrings} from '../../../../context/course/models/I18n';

export interface MarkdownGadgetConfig extends GadgetConfig {
  body: {
    source?: string
  }
}

export type MarkdownGadgetProps = GadgetEditorProps<MarkdownGadgetConfig, MarkdownGadgetI18nStrings>;

export const MarkdownGadget: FunctionComponent<MarkdownGadgetProps> = forwardRef<HTMLElement | undefined, MarkdownGadgetProps>((
  {
    id,
    config,
    i18nStrings = defaultI18nStrings.gadgets[GadgetType.MARKDOWN]!,
  }: MarkdownGadgetProps,
  ref
) => {
  const {updateGadget} = useCourseAuthoringContext();

  const [title, setTitle] = useState<string>(config.title || '');
  const [markdownText, setMarkdownText] = useState<string>(config.body.source || '');

  const updateTitle = (title: string) => {
    updateGadget(id, {
      title,
      body: {
        source: markdownText
      }
    } as MarkdownGadgetConfig);
    setTitle(title);
  };

  const updateMarkdown = (markdownText: string) => {
    updateGadget(id, {
      title,
      body: {
        source: markdownText
      }
    } as MarkdownGadgetConfig);
    setMarkdownText(markdownText);
  };

  return (
    <GadgetContainer id={id} ref={ref}>
      <Theme tokens={brandedLightTokens}>
        <Column>
          <Box>
            <Input
              value={title}
              onChange={updateTitle}
              placeholder={'Markdown title'}
              constraintText={'Title is optional but recommended'}
              size={'xlarge'}
            />
          </Box>
          <Box>
            <MarkdownEditor
              markdownText={markdownText}
              handleMarkdownTextChange={updateMarkdown}
              i18nStrings={i18nStrings}
            />
          </Box>
        </Column>
      </Theme>
    </GadgetContainer>
  );
});
MarkdownGadget.displayName = 'MarkdownGadget';
