import {CourseLearnerState} from '../models/CourseLearnerState';
import {CoursePosition} from '../models/CoursePosition';
import {ProgressStatus} from '../models/ProgressStatus';
import {CourseProgressUpdateResponse} from '../models/CourseProgressUpdateResponse';
import LearnerFeedback from '../models/LearnerFeedback';
import {debounce, forEach} from 'lodash';
import {AbstractSojuCourseApi} from './AbstractSojuCourseApi';
import {GadgetLearnerState} from '../models/GadgetLearnerState';
import set from 'lodash/set';
import {CourseStatusUpdateResponse} from '../index';

export class SojuCourseApi extends AbstractSojuCourseApi {

  async loadLearnerState(courseId: string): Promise<CourseLearnerState> {
    const {data: courseLearnerState} = await this.axiosInstance.get<CourseLearnerState>(`/catalogs/staged/courses/${courseId}/learner`);
    
    const updatedGadgetsState = courseLearnerState.gadgetsState || {};
    const gadgetTypes = courseLearnerState.gadgetTypes;
    if (courseLearnerState.gadgetsState) {
      forEach(courseLearnerState.gadgetsState, (learnerState: GadgetLearnerState, gadgetId: string) => {
        const gadgetType = gadgetTypes?.[gadgetId];
        let updatedLearnerState = learnerState;
        if (gadgetType && this.converterRegistry.shouldConvertGadgetType(gadgetType)) {
          updatedLearnerState = this.converterRegistry.toGadgetLearnerState(gadgetType, learnerState);
        }

        set(updatedGadgetsState, gadgetId, updatedLearnerState);
      });
    }

    courseLearnerState.gadgetsState = updatedGadgetsState;

    return {
      ...courseLearnerState,
      gadgetsState: updatedGadgetsState,
    };
  }

  debounceSetCoursePosition = debounce((courseId, position) => {
    return this.axiosInstance.put(
      `/courses/${courseId}/position`,
      {
        displaySection: position.displaySection,
        currentLessonId: position.lessonId,
        currentGadgetId: position.gadgetId === '' ? null : position.gadgetId // don't send empty string as gadgetId
      }
    );
  }, 1000)

  async setCoursePosition(courseId: string, position: CoursePosition): Promise<void> {
    await this.debounceSetCoursePosition(courseId, position);
  }

  async setCourseStatus(courseId: string, status: ProgressStatus, asin: string): Promise<CourseStatusUpdateResponse> {
    // check whether the ASIN is valid or not. In preview draft/crm preview, ASIN value could be a courseDraftId or courseRevisionId.
    const requestData = asin?.startsWith('amzn1') ?
      {status: status, externalId: null} : {
        status: status,
        externalId: {
          id: asin,
          externalSource: 'ASIN'
        }
      };
    const axiosResponse = await this.axiosInstance.put(
      `/courses/${courseId}/status`,
      requestData
    );
    return {
      certificateId: axiosResponse.data.certificateId,
      gadgetIds: axiosResponse.data.gadgetIds,
      statusTransitionSuccessful: axiosResponse.data.statusTransitionSuccessful
    } as CourseStatusUpdateResponse;
  }

  async setLessonStatus(courseId: string, lessonId: string, status: ProgressStatus): Promise<CourseProgressUpdateResponse> {
    const axiosResponse = await this.axiosInstance.put<any>(
      `/learner/course/${courseId}/lesson/${lessonId}/progress`,
      {
        progress: status
      }
    );
    return {
      status: axiosResponse.data?.progressStatus,
      completionPercentage: axiosResponse.data?.courseProgress
    } as CourseProgressUpdateResponse;
  }

  submitLearnerFeedback(courseId: string, learnerFeedback: LearnerFeedback): Promise<void> {
    return this.axiosInstance.post(
      '/learner/feedback',
      {
        asin: courseId,
        ...learnerFeedback
      }
    );
  }
}
