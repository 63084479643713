import {CourseLearnerState} from '../models/CourseLearnerState';
import {CoursePosition} from '../models/CoursePosition';
import {ProgressStatus} from '../models/ProgressStatus';
import {CourseProgressUpdateResponse} from '../models/CourseProgressUpdateResponse';
import LearnerFeedback from '../models/LearnerFeedback';
import {AbstractSojuCourseApi} from './AbstractSojuCourseApi';
import {CourseStatusUpdateResponse} from '../index';

export class SojuCoursePreviewApi extends AbstractSojuCourseApi {

  async loadLearnerState(courseId: string): Promise<CourseLearnerState> {
    return {
      updatedAt: new Date(),
    };
  }

  async setCoursePosition(courseId: string, position: CoursePosition): Promise<void> {
    return;
  }

  async setCourseStatus(courseId: string, status: ProgressStatus, asin: string): Promise<CourseStatusUpdateResponse> {
    return {certificateId: '',gadgetIds: [],statusTransitionSuccessful: true};
  }

  async setLessonStatus(courseId: string, lessonId: string, status: ProgressStatus): Promise<CourseProgressUpdateResponse> {
    return {};
  }

  async submitLearnerFeedback(courseId: string, learnerFeedback: LearnerFeedback): Promise<void> {
    return Promise.resolve(undefined);
  }
}
