import {CourseApi} from '../CourseApi';
import axios, {AxiosInstance, AxiosRequestHeaders, InternalAxiosRequestConfig} from 'axios';
import {SojuCourseApiConfig} from './SojuCourseApiConfig';
import {Course, CourseModel} from '../models/Course';
import {CourseLearnerState} from '../models/CourseLearnerState';
import {CoursePosition} from '../models/CoursePosition';
import {ProgressStatus} from '../models/ProgressStatus';
import {AssetModel} from '../models/AssetModel';
import {CourseProgressUpdateResponse} from '../models/CourseProgressUpdateResponse';
import LearnerFeedback from '../models/LearnerFeedback';
import {SojuLearnerSession} from './SojuLearnerSession';
import {VideoAssetMetadata} from '../models/VideoAssetMetadata';
import {DrmType} from '../../../components/gadgets/video/models/DrmType';
import {GadgetLearnerStateConverterRegistry} from './converters/GadgetLearnerStateConverterRegistry';
import {GadgetLearnerState} from '../models/GadgetLearnerState';
import {GadgetConfigAdapterRegistry} from './adapters/GadgetConfigAdapterRegistry';
import {CourseStatusUpdateResponse} from '../index';
import {toCourseWithLessonsArray} from '../../../utils';
import LRU from 'lru-cache';
import {CloudLabEmbedInfo} from '../../../components/gadgets/lab/models/CloudLabEmbedInfo';

const LEARNER_SESSION_HEADER_NAME = 'sid';
const CSRF_TOKEN_HEADER_NAME = 'anti-csrftoken-a2z';

// for expired learner tokens we return 401 status code
const HTTP_STATUS_CODE_ON_EXPIRED_LEARNER_SESSION = 401;
// when the csrf expires, horizonte returns 403
const HTTP_STATUS_CODE_ON_EXPIRED_CSRF = 403;

/**
 * Simple in-memory LRU cache to avoid making repetitive calls to resolve the asset locations
 */
const lruCache = new LRU({
  max: 500,
  ttl: 1000 * 60 * 100, // 100 minutes (CAS expiration is 2 hours - this lower value allow us to use a higher ttlResolution to improve performance
  ttlResolution: 1000 * 60 * 5, // 5 minutes
  allowStale: false
});

export abstract class AbstractSojuCourseApi implements CourseApi {

  protected readonly axiosInstance: AxiosInstance;
  protected readonly apiConfig: SojuCourseApiConfig;
  protected learnerSession: SojuLearnerSession;
  protected readonly adapterRegistry: GadgetConfigAdapterRegistry;
  protected readonly converterRegistry: GadgetLearnerStateConverterRegistry;

  /**
   * Creates a new instance of the SojuCourseApi
   * @param config api configuration
   * @param session initial LearnerSession
   */
  constructor(private config: SojuCourseApiConfig, private session: SojuLearnerSession) {
    this.apiConfig = config;
    this.learnerSession = session;

    // axios instance for making API calls
    this.axiosInstance = axios.create({
      baseURL: config.baseUrl,
    });

    // interceptor to add learner/csrf tokens
    this.axiosInstance.interceptors.request.use(requestConfig => this.addTokenHeaders(requestConfig));

    // global errors interceptor
    this.axiosInstance.interceptors.response.use(
      res => res,
      error => {
        return this.handleError(error);
      }
    );

    this.adapterRegistry = new GadgetConfigAdapterRegistry();
    this.converterRegistry = new GadgetLearnerStateConverterRegistry();
  }

  async loadCourse(courseId: string): Promise<Course> {
    const {data: courseModel} = await this.axiosInstance.get<CourseModel>(
      `${this.apiConfig.courseUrlPathOverride || '/catalogs/courses'}/${courseId}`
    );

    return toCourseWithLessonsArray(courseModel, this.adapterRegistry, this.converterRegistry);
  }

  abstract loadLearnerState(courseId: string): Promise<CourseLearnerState>

  abstract setCoursePosition(courseId: string, position: CoursePosition): Promise<void>

  abstract setCourseStatus(courseId: string, status: ProgressStatus, asin: string): Promise<CourseStatusUpdateResponse>

  abstract setLessonStatus(courseId: string, lessonId: string, status: ProgressStatus): Promise<CourseProgressUpdateResponse>

  async setGadgetLearnerState<T extends GadgetLearnerState>(courseId: string, gadgetId: string, gadgetType: string, learnerState: T): Promise<T> {
    const shouldConvert = this.converterRegistry.shouldConvertGadgetType(gadgetType);

    let convertedState = learnerState;
    if (shouldConvert) {
      convertedState = this.converterRegistry.fromGadgetLearnerState(gadgetType, learnerState);
    }

    const axiosResponse = await this.axiosInstance.put<T>(
      `/courses/${courseId}/gadgets/${gadgetId}/userstate`,
      {
        ...convertedState
      }
    );

    let convertedGadgetLearnerState: T = axiosResponse.data;
    if (shouldConvert) {
      convertedGadgetLearnerState = this.converterRegistry.toGadgetLearnerState(gadgetType, axiosResponse.data);
    }

    return convertedGadgetLearnerState;
  }

  submitGadgetLearnerStateAssessment<T extends GadgetLearnerState>(courseId: string, gadgetId: string, gadgetType: string, learnerState: T): Promise<T> {
    const submitLearnerState = {
      ...learnerState,
      submit: true
    };
    return this.setGadgetLearnerState(courseId, gadgetId, gadgetType, submitLearnerState);
  }

  async getAssetModel(courseId: string, assetId: string): Promise<AssetModel> {
    // try to use cached asset response if available
    // @ts-ignore
    const cachedAssetModel = lruCache.get<AssetModel>(assetId);
    if (cachedAssetModel) {
      // @ts-ignore
      return Promise.resolve(cachedAssetModel);
    }
    const {data: assetModel} = await this.axiosInstance.get<AssetModel>(`/catalogs/staged/courses/${courseId}/assets/${assetId}/model`);
    if (assetModel.type === 'VIDEO') {
      assetModel.assetMetadata = assetModel.assetMetadata
        ? this.toVideoAssetMetadata(assetId, assetModel.assetMetadata, this.apiConfig.baseUrl + '/catalogs/staged/courses/learner/drm_license')
        : {};
    }
    // cache the asset if it's available
    if (assetModel.status === 'AVAILABLE') {
      lruCache.set(assetId, assetModel);
    }
    return assetModel;
  }

  async getCloudLabEmbedInfo(courseId: string, gadgetId: string): Promise<CloudLabEmbedInfo> {
    const {data: cloudLabInfo} = await this.axiosInstance.get<CloudLabEmbedInfo>(`/course/${courseId}/gadget/${gadgetId}/cloudLabEmbedInfo`);
    return cloudLabInfo;
  }

  abstract submitLearnerFeedback(courseId: string, learnerFeedback: LearnerFeedback): Promise<void>

  /**
   * Convert Soju video asset metadata to the Gadget expected metadata
   * @param assetId asset id
   * @param metadata metadata as stored in Soju
   * @private
   */
  protected toVideoAssetMetadata(assetId: string, metadata: Record<string, unknown>, drmLicenseUrl: string) {
    return {
      hlsEgressEndpoint: metadata.hlsEgressEndpoint ? metadata.hlsEgressEndpoint : metadata.hlsUrl,
      dashEgressEndpoint: metadata.dashEgressEndpoint ? metadata.dashEgressEndpoint : metadata.hlsUrl,
      drmLicenseUrl: drmLicenseUrl,
      transcriptFileGetUrl: metadata.transcriptFileGetUrl,
      getDrmRequest: (drmType: string, licenseChallenge: string, contentId: string) => {
        const wrapped: Record<string, any> = {};
        wrapped.drmType = drmType;
        wrapped.licenseChallenge = licenseChallenge;
        wrapped.assetId = assetId;
        wrapped.contentId = contentId;
        return {
          headers: {
            'Accept': drmType === DrmType.FAIRPLAY ? 'text/plain' : 'application/json',
            [LEARNER_SESSION_HEADER_NAME]: this.learnerSession.learnerToken,
            [CSRF_TOKEN_HEADER_NAME]: this.learnerSession.csrfToken,
            'Content-Type': 'application/json',
            'cache-control': 'no-cache',
            'pragma': 'no-cache'
          },
          body: JSON.stringify(wrapped)
        };
      }
    } as VideoAssetMetadata;
  }

  /**
   * Adds the required headers from the learner session to the request
   * @param requestConfig axios request
   */
  private addTokenHeaders(requestConfig: InternalAxiosRequestConfig) {
    requestConfig.headers = {
      ...requestConfig.headers,
      [LEARNER_SESSION_HEADER_NAME]: this.learnerSession.learnerToken,
      [CSRF_TOKEN_HEADER_NAME]: this.learnerSession.csrfToken
    } as unknown as AxiosRequestHeaders
    return requestConfig;
  }

  /**
   * API errors handler
   * @param error API response error
   */
  private async handleError(error: any) {
    const {response} = error;
    switch (response.status) {
      // handle expired tokens
      case HTTP_STATUS_CODE_ON_EXPIRED_CSRF:
      case HTTP_STATUS_CODE_ON_EXPIRED_LEARNER_SESSION: {
        if (!error.config._retrying) {
          try {
            // refresh the learner session
            this.learnerSession = await this.apiConfig.refreshSession();

            // repeat the request
            return this.axiosInstance.request({
              ...error.config,
              _retrying: true // avoids infinite loop
            });
          } catch (e) {
            return Promise.reject(e);
          }
        }
        return Promise.reject(error);
      }
      default: {
        return Promise.reject(error);
      }
    }
  }
}
