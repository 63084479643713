import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SideMenu, { SideMenuLink } from "@amzn/meridian/side-menu";
import useBreakpoint from "@amzn/meridian/_use-breakpoint";
import { useCoursesAppContext } from "../../context/CoursesAppContextProvider";
import { isCoursePlayerRoute } from "../../routing/AppRoutes";

const overlayBreakpoint = "600px";

/**
 * The side navigation for the application.
 */
export const AppSideMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const { sideMenu } = useCoursesAppContext();
  const [open, setOpen] = sideMenu;
  const [coursesGroupOpen, setCoursesGroupOpen] = useState(
    location.pathname.includes("/courses"),
  );
  const breakpointState = useBreakpoint(overlayBreakpoint);
  const mobile = breakpointState === "smaller";

  // When the browser resizes from desktop -> mobile, close the side menu
  // and vice-versa
  useEffect(() => {
    setOpen(!mobile);
  }, [mobile, setOpen]);

  // On mobile, clicking on a link will close the menu
  const onClickLink = useCallback(
    (href) => {
      history.push(href);
      if (mobile) {
        setOpen(false);
      }
    },
    [mobile, history, setOpen],
  );

  const onMobileClose = () => setOpen(false);

  if (isCoursePlayerRoute(location.pathname)) {
    return null;
  }

  return (
    <SideMenu
      open={open}
      type={mobile ? "overlay" : "default"}
      onClose={mobile ? onMobileClose : undefined}
    >
      <SideMenuLink
        href="/dashboard"
        onClick={onClickLink}
        selected={location.pathname === "/dashboard"}
      >
        Dashboard
      </SideMenuLink>
      <SideMenuLink
        onClick={() => setCoursesGroupOpen(!coursesGroupOpen)}
        open={coursesGroupOpen}
      >
        Courses
        <SideMenuLink
          href="/courses/all"
          onClick={onClickLink}
          selected={location.pathname === "/courses/all"}
          onEscape={() => setCoursesGroupOpen(false)}
        >
          My courses
        </SideMenuLink>
        <SideMenuLink
          href="/courses/new"
          onClick={onClickLink}
          selected={location.pathname === "/courses/new"}
          onEscape={() => setCoursesGroupOpen(false)}
        >
          Create course
        </SideMenuLink>
      </SideMenuLink>
    </SideMenu>
  );
};
