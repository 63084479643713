import {useEffect} from 'react';

/**
 * hook for listening to window.postMessage events.
 * @param listener callback to be run when a message is received. The raw message event is passed to this callback
 * @param origin (optional) if provided, this hook will verify the origin of the message and ignore messages from other
 *                          origins. Highly recommended you use this.
 */
const useMessageListener = (listener: (messageEvent: MessageEvent) => void, origin?: string) => {
  useEffect(() => {
    const originVerifier = (e: MessageEvent) => {
      if (origin && e.origin !== origin) {
        return;
      }
      listener(e);
    };

    window.addEventListener('message', originVerifier);
    return () => {
      console.info('removing listener');
      window.removeEventListener('message', originVerifier);
    };
  }, []);
};

export {
  useMessageListener
};

export default useMessageListener;
