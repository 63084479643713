
import { AlignAction, ResizeAction, ImageSpec } from 'quill-blot-formatter';
import AltTextAction from '../actions/alt-text/AltTextAction';
import CustomDeleteAction from '../actions/CustomDeleteAction';

class CustomImageSpec extends ImageSpec {
  getActions() {
    return [AlignAction, ResizeAction, AltTextAction, CustomDeleteAction];
  }
}

export default CustomImageSpec;
