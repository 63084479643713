import React, {FunctionComponent, useState} from 'react';
import {ExternalLinkWarningModal} from '../link-warning/ExternalLinkWarningModal';
import LinkWithWarning from '../link-warning/LinkWithWarning';
import {LinkWarningI18nStrings, WithI18nStringsProps} from '../../context/course/models/I18n';

export type QuillExternalLinkWarningProps = WithI18nStringsProps<LinkWarningI18nStrings>

export const QuillExternalLinkWarning: FunctionComponent<QuillExternalLinkWarningProps> = ({
  i18nStrings
}) => {
  const [externalLinkHref, setExternalLinkHref] = useState<string>();
  const [open, setOpen] = useState(false);

  LinkWithWarning.onExternalLinkClicked = (href: string) => {
    setExternalLinkHref(href);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setExternalLinkHref(undefined);
  };

  return <ExternalLinkWarningModal
    open={open}
    onClose={onClose}
    href={externalLinkHref}
    i18nStrings={i18nStrings}
  />;

};
