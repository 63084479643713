import React, { FunctionComponent, PropsWithChildren, useContext } from "react";
import { SojuApi } from "../api/SojuApi";

/**
 * Courses App Context
 */
export interface CoursesAppContextProps {
  /**
   * Soju Midway API implementation
   */
  sojuApi: SojuApi;

  /**
   * Side menu state
   */
  sideMenu: any;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// we don't provide a value, because we want the context to be created using the custom function
const CourseContext = React.createContext<CoursesAppContextProps>({});

/**
 * Course Context Provider to be consumed by the components
 */
export const CoursesAppContextProvider: FunctionComponent<
  CoursesAppContextProps
> = ({
  children,
  ...contextValue
}: PropsWithChildren<CoursesAppContextProps>) => {
  return (
    <CourseContext.Provider value={contextValue}>
      {children}
    </CourseContext.Provider>
  );
};

/**
 * React hook to use the course context
 */
export const useCoursesAppContext = (): CoursesAppContextProps => {
  return useContext(CourseContext);
};
