import React, {FunctionComponent, PropsWithChildren, useContext} from 'react';
import {CoursePosition} from './models/CoursePosition';
import {ProgressStatus} from './models/ProgressStatus';
import {GadgetLearnerState} from './models/GadgetLearnerState';
import {AssetModel} from './models/AssetModel';
import LearnerFeedback from './models/LearnerFeedback';
import {LearnerActivity} from '../../activity/models/learner-activities';
import {CourseStatusUpdateResponse, Logger} from './index';
import {CloudLabEmbedInfo} from '../../components/gadgets/lab/models/CloudLabEmbedInfo';

/**
 * Course context
 */
export interface CourseContextProps {

  /**
   * Sets the course position
   * @param position new position
   */
  setCoursePosition: (position: CoursePosition) => void;

  /**
   * Sets the progress for the course
   * @param progress new course progress status value
   */
  setCourseProgress: (progress: ProgressStatus) => Promise<CourseStatusUpdateResponse>;

  /**
   * Sets the progress for a given lesson
   * @param lessonId lesson ID
   * @param progress new lesson progress status value
   */
  setLessonProgress: (lessonId: string, progress: ProgressStatus) => void;

  /**
   * Sets the gadget's learner state
   * @param gadgetId gadget id
   * @param gadgetType gadget type
   * @param learnerState new learner state
   */
  setGadgetLearnerState: <T extends GadgetLearnerState>(gadgetId: string, gadgetType: string, learnerState: T, persistState?: boolean) => void;

  /**
   * Assess the gadget's learner state
   * @param gadgetId gadget id
   * @param gadgetType gadget type
   * @param learnerState learner state to be assessed
   */
  submitGadgetLearnerStateAssessment: <T extends GadgetLearnerState>(gadgetId: string, gadgetType: string, learnerState: T) => void;

  /**
   * Retrieves the asset model for a given asset Id
   * @param assetId asset ID
   */
  getAssetModel: (assetId: string) => Promise<AssetModel | undefined>;

  getCloudLabEmbedInfo: (gadgetId: string) => Promise<CloudLabEmbedInfo | undefined>;

  /**
   * Emits a learner activity
   */
  emitLearnerActivity: (activity: LearnerActivity) => void;

  /**
   * Submits feedback provided by learner
   * @param learnerFeedback api call payload
   */
  submitLearnerFeedback: (learnerFeedback: LearnerFeedback) => Promise<void>;

  /**
   * Emits a counter metric using Katal metrics library
   */
  emitCounterMetrics: (metricName: string) => void;

  /**
   * Emits a timer metric using Katal metrics library
   */
  emitTimerMetrics: (metricName: string, timer: number) => void;

  /**
   * Logger object that contains functions to log: "debug", "info", "warn", "error", "fatal"
   */
  logger?: Logger;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// we don't provide a value, because we want the context to be created using the custom function
const CourseContext = React.createContext<CourseContextProps>({});

/**
 * Course Context Provider to be consumed by the components
 */
export const CourseContextProvider: FunctionComponent<CourseContextProps> = ({
  children,
  ...contextValue
}: PropsWithChildren<CourseContextProps>) => {

  return (
    <CourseContext.Provider value={contextValue}>
      {children}
    </CourseContext.Provider>
  );
};

/**
 * React hook to use the course context
 */
export const useCourseContext = (): CourseContextProps => {
  return useContext(CourseContext);
};
