import React from 'react';
import Input from '@amzn/meridian/input';
import Text from '@amzn/meridian/text';
import {GradedResponse} from './GradedResponse';
import {ResponseChallengeAnswer} from './ResponseGadget';
import {ResponseGadgetI18nStrings, WithI18nStringsProps} from '../../../context/course/models/I18n';

import './styles/learner-question.scss';

export interface ResponseGadgetQuestionProps extends WithI18nStringsProps<ResponseGadgetI18nStrings> {
  id: string,
  onAnswerChange: (type: string) => void,
  prompt: string,
  correctAnswers:  ResponseChallengeAnswer[],
  learnerAnswer: string,
  isGraded: boolean,
  isCorrect: boolean

  /**
   * Flag to hide answers once quiz is evaluated
   */
  shouldShowAnswers: boolean
}

type RefType = HTMLInputElement;

export const ResponseGadgetQuestion = React.forwardRef<RefType, ResponseGadgetQuestionProps>((props: ResponseGadgetQuestionProps, ref) => {
  const {
    id,
    onAnswerChange,
    prompt,
    correctAnswers,
    learnerAnswer,
    isGraded,
    isCorrect,
    shouldShowAnswers,
    i18nStrings,
  } = props;
  const oneCorrectAnswer = correctAnswers.length === 1;
  const correctAnswersLabelId = `${id}-correct-answers`;

  return (
    <div className="LearnerQuestion">
      <div className='LearnerQuestion__question'>
        <Text
          tag='label'
          type='b400'
          htmlFor={id}
        >
          {prompt}
        </Text>
      </div>
      <div className='LearnerQuestion__answer'>
        {!isGraded ? <Input
          ref={ref}
          className='LearnerQuestion__answer-input'
          id={id}
          onChange={onAnswerChange}
          value={learnerAnswer}
          type='text'
          placeholder={i18nStrings.answerInputPlaceholder}
        /> : <div className='LearnerQuestion__graded-response'>
          <GradedResponse id={id} response={learnerAnswer} correct={isCorrect} ref={ref} i18nStrings={i18nStrings} />
        </div>}
      </div>
      {isGraded && shouldShowAnswers && !isCorrect && <div className={'LearnerQuestion__correct-answers'}>
        <Text id={correctAnswersLabelId} type='h100' color='secondary'>{oneCorrectAnswer ? i18nStrings.correctAnswer : i18nStrings.correctAnswers}</Text>
        {oneCorrectAnswer ?
          <Text>{correctAnswers[0].answer}</Text> :
          <ul
            aria-labelledby={correctAnswersLabelId}
            className={'LearnerQuestion__correct-answers-list'}
          >
            {correctAnswers.map(correctAnswer =>
              (<li key={`${id}-correct-answer-${correctAnswer.id}`}><Text>{correctAnswer.answer}</Text></li>))}
          </ul>
        }
      </div>}
    </div>
  );
});
ResponseGadgetQuestion.displayName = 'ResponseGadgetQuestion';
