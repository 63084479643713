import React, {FunctionComponent, useState} from 'react';
import {ConnectDragSource} from 'react-dnd';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import SearchField from '@amzn/meridian/search-field';
import {DnDListItem} from '../drag-and-drop/DnDListItem';

import './styles/gadgetTray.scss';

export const GADGET_TRAY_WIDTH = '300px';
export const GADGET_TRAY_DRAG_ITEM_TYPE = '___GadgetTray___';

/**
* Gadget drag item to be used when
*/
export type GadgetTrayDragItem = {
 /**
  * Gadget type
  */
 type: string,
}

/**
 * Represents an item in the Gadgets' tray
 */
export type GadgetTrayItem = {

  /**
   * Gadget's name
   */
  name: string,

  /**
   * Gadget's type
   */
  type: string,

  /**
   * Gadget's short description
   */
  description: string,

  /**
   * Gadget's icon
   */
  icon: string
}

/**
 * Props required by the Gadget's tray
 */
export interface GadgetTrayProps {

  /**
   * Gadgets to render in the tray
   */
  gadgets: GadgetTrayItem[]
}

/**
 * Gadget Item props
 */
interface GadgetTrayItemProps {

  /**
   * The gadget item to render
   */
  gadgetItem: GadgetTrayItem;

  dragSource?: ConnectDragSource;
}

export const GadgetTrayItemCard: FunctionComponent<GadgetTrayItemProps> = ({ gadgetItem, dragSource }: GadgetTrayItemProps) => {
  const { name, icon } = gadgetItem;
  return (
    <Box ref={dragSource} className='gadgetTray__list__gadgetContainer' type='outline'>
      <Column spacingInset="400 500" spacing="medium" alignmentHorizontal='center'>
        <img src={icon} alt={`${name} gadget`} width='40' height='40' />
        <Text>{name}</Text>
      </Column>
    </Box>
  );
};

export const GadgetTray: FunctionComponent<GadgetTrayProps> = ({ gadgets }: GadgetTrayProps) => {
  const [query, setQuery] = useState<string>('');
  return (
    <Column className='gadgetTray' spacingInset='medium'>
      <SearchField
        value={query}
        onChange={setQuery}
        placeholder="Search gadget"
        onSubmit={noop}
      />
      <Row className='gadgetTray__list' spacing='medium' spacingInset='none' widths={'grid-6'} wrap='down'>
        {gadgets.map((gadget, index) =>
          (isEmpty(query) || includes(toUpper(gadget.name), toUpper(query)))
          && <DnDListItem
            key={gadget.name}
            dragType={GADGET_TRAY_DRAG_ITEM_TYPE}
            item={{
              id: gadget.type,
              index
            }}
          >
            <GadgetTrayItemCard gadgetItem={gadget}  />
          </DnDListItem>)}
      </Row>
    </Column>
  );
};
