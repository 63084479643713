export enum AppRoutes {
  HOME = "/",
  AUTHORING_PAGE = "/courses/edit/:courseDraftId/(lessons)?/:lesson?/(gadgets)?/:gadget?",
  PREVIEW = "/courses/preview/draft/:courseDraftId/(lessons)?/:lesson?/(gadgets)?/:gadget?",
  COURSE_PLAYER_PAGE = "/courses/learn/:courseRevisionId/(lessons)?/:lesson?/(gadgets)?/:gadget?",
}

export const isCoursePlayerRoute = (path: string) => {
  return (
    path.startsWith("/courses/edit") ||
    path.startsWith("/courses/preview") ||
    path.startsWith("/courses/learn")
  );
};
