import React, {FunctionComponent, useEffect, useState} from 'react';
import { parse, Caption, Captions } from 'subtitle';
import { le } from 'binary-search-bounds';
import Theme from '@amzn/meridian/theme';
import Expander from '@amzn/meridian/expander';
import {throttle} from 'lodash';
import { VideoGadgetI18nStrings, WithI18nStringsProps } from '../../../context/course/models/I18n';

import './styles/transcript.css';

/**
 * Video transcript component props
 */
export interface VideoTranscriptProps extends WithI18nStringsProps<VideoGadgetI18nStrings> {
  /**
   * Content ID
   */
  contentId: string

  /**
   * Content of the transcript
   */
  transcriptContent: string,

  /**
   * Transcript content type
   */
  transcriptType: 'vtt' | 'text',

  /**
   * Video Element playing the video for the transcript (Optional)
   */
  videoElement?: HTMLVideoElement
}

const expanderTokens = {
  expanderForegroundColorDefaultHover: '#232F3E',
  expanderBorderWidth: 0
};

const captionsByStart = (c1: Caption, c2: Caption) => { return c1.start - c2.start; };

const UPDATE_CAPTIONS_INTERVAL_IN_MS = 500;

export const VideoTranscript: FunctionComponent<VideoTranscriptProps> = (
  {
    contentId,
    transcriptContent,
    transcriptType,
    videoElement,
    i18nStrings,
  }: VideoTranscriptProps
) => {

  const [showTranscript, setShowTranscript] = useState(false);
  const [captions, setCaptions] = useState<Captions | null>(null);
  const [currentCueStart, setCurrentCueStart] = useState(-1);

  useEffect(() => {
    if (transcriptType === 'vtt') {
      try {
        setCaptions(parse(transcriptContent));
      } catch (e) {
        // TODO: log to our system
      }
    }
  }, [transcriptContent, transcriptType]);

  // effect to highlight captions for VTT format
  useEffect(() => {
    if (transcriptType === 'vtt' && videoElement && captions && captions.length > 0) {

      // update as video current time changes
      const onTimeUpdate = throttle(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const currentCueIndex = le(captions, { start: videoElement.currentTime * 1000 }, captionsByStart);
        setCurrentCueStart(captions[currentCueIndex]?.start || -1);
      }, UPDATE_CAPTIONS_INTERVAL_IN_MS, { leading: true });

      // remove highlighting when done.
      const onPlayEnded = () => {
        setCurrentCueStart(-1);
      };

      // initialize event listeners to keep the captions highlights updated
      videoElement.addEventListener('timeupdate', onTimeUpdate);
      videoElement.addEventListener('ended', onPlayEnded);

      return () => {
        // remove event listeners when destroying the component
        videoElement.removeEventListener('timeupdate', onTimeUpdate);
        videoElement.removeEventListener('ended', onPlayEnded);
      };
    }
  }, [captions, transcriptType, videoElement]);

  const toggleTranscript = () => {
    setShowTranscript(!showTranscript);
  };

  const renderedTranscript = transcriptType === 'vtt'
    ? captions?.map(caption => {
      return (
        <span className={`transcript-phrase ${caption.start === currentCueStart ? 'active' : ''}`}
          key={`transcript-item-${contentId}-${caption.start}`}
          data-cue-start={caption.start}>
          {caption.text && caption.text.replace(/<[^>]*>?/gm, '')}{' '}
        </span>
      );
    })
    : transcriptContent?.replace(/<[^>]*>?/gm, '');

  return (
    <div className='transcript_container-wrapper'>
      <div id={contentId} className='transcript_container'>
        { renderedTranscript &&
          <Theme tokens={expanderTokens}>
            <Expander
              open={showTranscript}
              onChange={toggleTranscript}
              title={i18nStrings.transcript}
              aria-controls='transcript_content'
            >
              <div className={`transcript_interactive transcript_type_${transcriptType}`}
                aria-live='off'
                aria-atomic='true'
                aria-relevant='all'
                tabIndex={0}
                role='article'>
                {renderedTranscript}
              </div>
            </Expander>
          </Theme>
        }
      </div>
    </div>
  );
};
