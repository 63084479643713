import React, {FunctionComponent, useState} from 'react';
import {ImageAttributes, ImageLightbox} from './ImageLightbox';
import {
  defaultImageI18nStrings,
  ImageI18nStrings,
  WithI18nStringsProps
} from '../../context/course/models/I18n';
import {WithAriaDescription} from '../../utils/a11y/AriaDescription';
import {Image} from './Image';

export interface ImageWithLightboxProps extends WithI18nStringsProps<ImageI18nStrings> {
  id: string,
  image: ImageAttributes,
  fill?: boolean,
  onLoad?: (event?: HTMLImageElement) => void;
}

export const ImageWithLightbox: FunctionComponent<ImageWithLightboxProps> = ({
  id,
  image,
  fill,
  onLoad,
  i18nStrings = defaultImageI18nStrings
}) => {
  const [open, setOpen] = useState(false);
  const openLightbox = () => setOpen(true);
  const closeLightbox = () => setOpen(false);

  const descriptionId = `${id}-img-description`;

  return image.src ? <>
    <WithAriaDescription description={i18nStrings.clickableImageLinkLabel} id={descriptionId}>
      <button
        className='SojuImage__link'
        onClick={openLightbox}
      >
        <Image {...image} fill={fill} onLoad={onLoad} />
      </button>
    </WithAriaDescription>
    <ImageLightbox open={open} image={image} onClose={closeLightbox} i18nStrings={i18nStrings} />
  </> : null;
};
