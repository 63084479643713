import BlotFormatter from 'quill-blot-formatter';
import './styles/alt-text.scss';

export interface AltTextInputInterface {
  create(formatter: BlotFormatter): HTMLElement;
  destroy(): void;
  getElement(): HTMLElement | null;
}

export default class AltTextInput {
  textInput: HTMLElement | null;

  constructor() {
    this.textInput = null;
  }

  create(formatter: BlotFormatter) {
    // Create entire container
    const altTextContainer = document.createElement('div');
    altTextContainer.classList.add('altTextAction__container');

    // Create inner box that will contain the input
    const altTextInnerContainer = document.createElement('div');
    altTextInnerContainer.classList.add('altTextAction__container__innerBox');

    // Create alt text span
    const altTextSpan: HTMLSpanElement = document.createElement('span') as HTMLSpanElement;
    altTextSpan.textContent = 'Alt text: ';

    // Create text input
    const textInput: HTMLInputElement = document.createElement('input') as HTMLInputElement;
    textInput.type = 'text';
    textInput.placeholder = 'Provide accessibility text';
    textInput.title = 'Alternate text is recommended for the best learner experience';
    textInput.value = this.initialValue(formatter);
    textInput.addEventListener('keyup', () => {
      this.setAltValue(textInput, formatter);
    });

    // Build HTML tree
    altTextContainer.append(altTextInnerContainer);
    altTextInnerContainer.append(altTextSpan, textInput);

    this.textInput = altTextContainer;
    return this.textInput;
  }

  getElement(): HTMLElement | null {
    return this.textInput;
  }

  destroy() {
    this.textInput = null;
  }

  initialValue(
    formatter: BlotFormatter
  ) {
    const formatterTarget = this.getFormatterTarget(formatter);
    if (!formatterTarget) {
      return '';
    }

    return formatterTarget.getAttribute('alt') || '';
  }

  setAltValue(input: HTMLInputElement, formatter: BlotFormatter) {
    const formatterTarget = this.getFormatterTarget(formatter);
    if (formatterTarget) {
      formatterTarget.setAttribute('alt', input.value);
      formatter.update();
    }
  }

  getFormatterTarget(formatter: BlotFormatter): HTMLElement | null {
    if (!formatter.currentSpec) {
      return null;
    }

    const target = formatter.currentSpec.getTargetElement();
    if (!target) {
      return null;
    }

    return target;
  }
}
