import {Quill} from 'react-quill';
import {HideExternalLinkWarningStorageKey} from './Constants';
import {getItemFromSessionStorage} from '../../hooks/useSessionStorage';
import {linkIsInternal} from '../../utils/LinkUtils';

const Link = Quill.import('formats/link');

class LinkWithWarning extends Link {
  static onExternalLinkClicked: (href: string) => void;

  static create(value: string) {
    const node: HTMLElement = super.create(value);
    node.onclick = (e: Event) => {
      const warningHidden = getItemFromSessionStorage(HideExternalLinkWarningStorageKey, false);
      if (!warningHidden && !linkIsInternal(value) && this.onExternalLinkClicked) {
        e.preventDefault();
        this.onExternalLinkClicked(value);
      }
    };
    return node;
  }
}

export default LinkWithWarning;
