import React, {FunctionComponent} from 'react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { ConnectDragSource } from 'react-dnd';

import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import pencilTokens from '@amzn/meridian-tokens/base/icon/pencil';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';
import checkKnockoutTokens from '@amzn/meridian-tokens/base/icon/check-knockout';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import menuTokens from '@amzn/meridian-tokens/base/icon/menu';
import Button from '@amzn/meridian/button';

import {DnDListItem, DnDListItemType} from '../../../drag-and-drop/DnDListItem';
import {LabChallenge} from '../models';
import {ScriptAction} from '../models/Script';

import './styles/challenges-overview.scss';

const CHALLENGE_DRAG_ITEM_TYPE = '___challenge___';

type LabChallengesOverviewProps = {
  gadgetId: string;
  challenges: LabChallenge[];
  onEditChallenge: (challengeId: string) => void;
  deleteChallenge: (challengeId: string) => void;
  reorderChallenge: (challengeId: string, newIndex: number) => void;
};

type LabChallengesItemProps = {
  index: number;
  challenge: LabChallenge;
  onEditChallenge: (challengeId: string) => void;
  deleteChallenge: (challengeId: string) => void;

  dragSource?: ConnectDragSource;
};

export const LabChallengesOverview: FunctionComponent<LabChallengesOverviewProps> = (
  {
    gadgetId,
    challenges,
    onEditChallenge,
    deleteChallenge,
    reorderChallenge
  }: LabChallengesOverviewProps
) => {

  const onDropDndList = async (draggedItem: DnDListItemType, dropIndex: number) => {
    reorderChallenge(draggedItem.id, dropIndex);
  };

  return (
    <Column spacing='500'>
      <Box>
        <Heading level={2} type='h300'>Exercises</Heading>
        <Text type='b200' color='secondary'>Learners will need to complete exercises in the order specified</Text>
      </Box>
      <Column>
          {challenges?.map((challenge, index) => {
            return (
              <DnDListItem
                key={`${gadgetId}-challengeOverview-${challenge.id}`}
                dragType={CHALLENGE_DRAG_ITEM_TYPE}
                dropTypes={[CHALLENGE_DRAG_ITEM_TYPE]}
                item={{
                  id: challenge.id,
                  index
                }}
                onDrop={onDropDndList}
                overrideDnDZones={{drag: true}}
              >
                <LabChallengeItem
                  index={index}
                  challenge={challenge}
                  onEditChallenge={onEditChallenge}
                  deleteChallenge={deleteChallenge}
                />
              </DnDListItem>
            );
          })}
      </Column>
    </Column>
  );
};

export const LabChallengeItem: FunctionComponent<LabChallengesItemProps> = (
  {
    index,
    challenge,
    onEditChallenge,
    deleteChallenge,
    dragSource
  }: LabChallengesItemProps
) => {
  const setupScript = find(challenge?.challengeScripts, ['action', ScriptAction.SETUP]);
  const checkScript = find(challenge?.challengeScripts, ['action', ScriptAction.CHECK]);
  const cleanupScript = find(challenge?.challengeScripts, ['action', ScriptAction.CLEANUP]);

  const setupIconCss = cx(
    setupScript && !isEmpty(setupScript.content)
      ? 'trackChallenge__card__script--completed'
      : 'trackChallenge__card__script--incompleted'
  );
  const checkIconCss = cx(
    checkScript && !isEmpty(checkScript.content)
      ? 'trackChallenge__card__script--completed'
      : 'trackChallenge__card__script--incompleted'
  );
  const cleanupIconCss = cx(
    cleanupScript && !isEmpty(cleanupScript.content)
      ? 'trackChallenge__card__script--completed'
      : 'trackChallenge__card__script--incompleted'
  );
  return (
    <Box className='trackChallenge__card' spacingInset={'400'}>
      <Row spacing='500' widths={['fit', 'fill', 'fit']} alignmentVertical='top'>
        <Text type='d50'>{index + 1}</Text>
        <Column spacing='400'>
          <Heading level={3} type='h300'>{challenge.title}</Heading>
          <Column spacing={'300'}>
            <Text type='b300'>{challenge.teaser}</Text>
            <Text type='b200' color='secondary'>{challenge.timeToCompleteInMinutes} minutes</Text>
            <Row>
              <Text type='b200'><Icon className={setupIconCss} tokens={setupScript ? checkKnockoutTokens : infoKnockoutTokens} /> Setup</Text>

              <Text type='b200'><Icon className={checkIconCss} tokens={checkScript ? checkKnockoutTokens : infoKnockoutTokens} /> Check</Text>

              <Text type='b200'><Icon className={cleanupIconCss}  tokens={cleanupScript ? checkKnockoutTokens : infoKnockoutTokens} /> Cleanup</Text>
            </Row>
          </Column>
        </Column>
        <Column>
          <Button type='icon' onClick={() => onEditChallenge(challenge.id)}>
            <Icon tokens={pencilTokens}>Edit challenge {index + 1}</Icon>
          </Button>
          <Button type='icon' ref={dragSource}>
            <Icon tokens={menuTokens}>Reorder challenge {index + 1}</Icon>
          </Button>
          <Button type='icon' onClick={() => deleteChallenge(challenge.id)}>
            <Icon tokens={trashTokens}>Delete challenge {index + 1}</Icon>
          </Button>
        </Column>
      </Row>
    </Box>
  )
};
