import {Dispatch, SetStateAction, useEffect, useState} from 'react';

export const getItemFromSessionStorage = <T>(key: string, defaultValue: T) => {
  const storedValue = window.sessionStorage.getItem(key);
  if (!storedValue) {
    return defaultValue;
  } else {
    return JSON.parse(storedValue);
  }
};

/**
 * Hook to allow a React component to tie a state variable to window.sessionStorage.
 *
 * @param key
 * @param defaultValue
 */
const useSessionStorage = <T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(getItemFromSessionStorage<T>(key, defaultValue));

  useEffect(() => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(value));
      // eslint-disable-next-line
    } catch (e) {}
  }, [value]);

  return [value, setValue];
};

export default useSessionStorage;
