import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {GradedLearnerState} from '../../../context/course/models/GradedLearnerState';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import Responsive from '@amzn/meridian/responsive';
import Theme from '@amzn/meridian/theme';
import brandedLightTokens from '../../../theme/branded-light';
import redoTokens from '@amzn/meridian-tokens/base/icon/redo';
import {QuizQuestionCard} from './QuizQuestionCard';
import {concat, indexOf, isNil, without, get, round} from 'lodash';
import {GadgetProps} from '../models';
import {AssetModel, AssessmentEvaluationResults, AssessmentEvaluationStatus, GadgetConfig, useCourseContext} from '../../../context/course';
import {Evaluation} from '../../../context/course/models/Evaluation';
import {LearnerGadgetActivity} from '../../../activity/models/learner-activities';
import {LearnerVerb} from '../../../activity/models/verbs';
import {LearnerObject} from '../../../activity/models/objects';
import {allQuestionsAnswered} from './helpers';
import GadgetContainer from '../../containers/gadget/GadgetContainer';
import {AssessmentGadgetSettingsType} from '../../../authoring';
import {ScoreBreakdown} from '../../scoring/ScoreBreakdown';
import {QuizGadgetI18nStrings} from '../../../context/course/models/I18n';
import './styles/assessments.scss';
import responsiveStyles from '../../../styles/responsive.module.scss';

/**
 * Supported quiz question types
 */
export enum QuizQuestionType {
  /**
   * A single choice can be selected as an answer
   */
  Single = 'Single',

  /**
   * Multiple choices can be selected as answers
   */
  Multiselect = 'Multiselect'
}

/**
 * Quiz question choice model
 */
export type QuizQuestionChoice = {
  /**
   * Unique choice ID
   */
  id: string,

  /**
   * Choice value
   */
  value: string
}

/**
 * Quiz question Base model
 */
export type QuizQuestionBaseType = {
  /**
   * Unique question Id
   */
  id: string,

  /**
   * Question title
   */
  title: string,

  /**
   * Question Image
   */
  image?: AssetModel,

  /**
   * Question type
   */
  type: QuizQuestionType
}

/**
 * Quiz question Model
 */
export type QuizQuestion = QuizQuestionBaseType & {
  /**
   * Question answer choices
   */
  choices: QuizQuestionChoice[]
}

/**
 * Quiz evaluation results model
 */
export interface QuizEvaluationResults extends AssessmentEvaluationResults {
  /**
   * Correct questions count
   */
  correctQuestionsCount: number,

  /**
   * Incorrect questions count
   */
  incorrectQuestionsCount: number

  /**
   * Question evaluations by question IDs
   */
  evaluationsByQuestionId: Record<string, Evaluation>

  /**
   * Correct choices by question IDs. Optional value that can be used to display which are the correct answers
   */
  correctChoicesByQuestionId: Record<string, string[]>
}

/**
 * Learner state model for Quiz gadgets
 */
export interface QuizGadgetLearnerState extends GradedLearnerState<QuizEvaluationResults> {
  /**
   * Learner choice IDs by question IDs
   */
  choicesByQuestionId?: Record<string, string[]> | null,
}

/**
 * Quiz Gadget configuration
 */
export interface QuizGadgetConfigBase extends GadgetConfig {
  /**
   * Quiz instructions
   */
  instructions?: string
  /**
   * Whether or not retries are allowed for the quiz
   */
  allowRetries: boolean
  /**
   * Assessment gadget settings
   */
  settings: AssessmentGadgetSettingsType
}

/**
 * Quiz Gadget configuration
 */
export interface QuizGadgetConfig extends QuizGadgetConfigBase {
  /**
   * Quiz questions
   */
  questions: QuizQuestion[]
}

/**
 * Quiz gadget props
 */
export type QuizGadgetProps = GadgetProps<QuizGadgetConfig, QuizGadgetLearnerState, QuizGadgetI18nStrings>

/**
 * You can use this gadget to add quizzes to your course.
 * As of right now, the Quiz gadget only supports multiple choice questions. You can add questions that can either have single or multiselect answers.
 * The gadget uses the questions (which contain the question ID and label) provided as props to render the quiz.
 * When the learner selects and submits the answers, the learner state gets sent to the backend. The backend will evaluate the answers and return a QuizEvaluationResults with the evaluation results.
 */
export const QuizGadget = forwardRef<HTMLElement | undefined, QuizGadgetProps>((props: QuizGadgetProps, ref) => {
  const {
    id,
    type,
    config: {
      title,
      instructions,
      questions,
      settings,
      allowRetries = true
    },
    learnerState,
    i18nStrings,
  } = props;

  const {
    evaluationResults,
    choicesByQuestionId
  } = learnerState || {} as QuizGadgetLearnerState;

  const {
    emitLearnerActivity,
  } = useCourseContext();

  // Only allow submission if all questions are answered
  const [choicesByQuestion, setChoicesByQuestion] = useState(choicesByQuestionId || {});
  const areAllQuestionsAnswered = allQuestionsAnswered(questions, choicesByQuestion);
  const [canSubmit, setCanSubmit] = useState<boolean>(areAllQuestionsAnswered);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isGraded, setIsGraded] = useState(false);

  const firstInputRef = useRef<HTMLElement>();
  const submitButtonRef = useRef<HTMLElement>();

  const isRequiredForCertificate = get(settings, 'isRequiredForCertificate', false);
  let learnerScore: number;
  let totalCorrectAnswers: number;
  const totalQuestions = questions.length;

  if (isGraded && evaluationResults) {
    totalCorrectAnswers = evaluationResults.correctQuestionsCount;
    learnerScore = round((evaluationResults.correctQuestionsCount * 100) / (totalQuestions));
  }


  useEffect(() => {
    // whenever evaluationResults changes, we can reset the canSubmit state
    setCanSubmit(allQuestionsAnswered(questions, choicesByQuestion));
    setIsSubmitted(false);
    setIsGraded(!isNil(evaluationResults));
  }, [choicesByQuestion, evaluationResults, questions]);

  useEffect(() => {
    // whenever learnerState changes, we can reset the internal choices state
    setChoicesByQuestion(choicesByQuestionId || {});
  }, [choicesByQuestionId]);

  useEffect(() => {
    setCanSubmit(allQuestionsAnswered(questions, choicesByQuestion));
  }, [choicesByQuestion, questions]);

  const {
    setGadgetLearnerState,
    submitGadgetLearnerStateAssessment,
  } = useCourseContext();

  const onClickSubmit = () => {
    if (canSubmit) {
      // disable button before sending event to avoid duplicated submissions
      setCanSubmit(false);
      setIsSubmitted(true);
      submitGadgetLearnerStateAssessment<QuizGadgetLearnerState>(id, type,{
        updatedAt: new Date(), // TODO: is this needed?
        choicesByQuestionId: choicesByQuestion,
      });

      emitLearnerActivity(
        new LearnerGadgetActivity(
          LearnerVerb.SUBMITTED,
          LearnerObject.ASSESSMENT,
          id,
          type,
        )
      );
    }
  };

  const onClickRetry = () => {
    if (allowRetries) {
      setIsGraded(false);
      setChoicesByQuestion({});
      firstInputRef.current?.focus();
      setGadgetLearnerState<QuizGadgetLearnerState>(id, type, {
        updatedAt: new Date(),
        choicesByQuestionId: null,
      }, false);
    }
  };

  const onSelectChoice = (quizQuestionType: QuizQuestionType, questionId: string, choiceId: string) => {
    if (isGraded || isSubmitted) {
      // don't do anything if the quiz is in a graded state or submitting
      return;
    }
    // array of choices - default for non-multiselect, the array is just the selected choice
    let questionChoices = [choiceId];

    // for multiselect, we add/remove the choice id depending on if already exists, this allows
    // us to check/uncheck choices
    if (quizQuestionType === QuizQuestionType.Multiselect) {
      const currentChoices = choicesByQuestion[questionId] || [];
      questionChoices = (indexOf(currentChoices, choiceId) > -1)
        ? without(currentChoices, choiceId)
        : concat(currentChoices, [choiceId]);
    }

    const newChoicesByQuestion = {
      ...choicesByQuestion,
      [questionId]: questionChoices
    };

    // update gadget state
    setChoicesByQuestion(newChoicesByQuestion);

    emitLearnerActivity(
      new LearnerGadgetActivity(
        LearnerVerb.SELECTED,
        LearnerObject.QUESTION,
        id,
        type,
      )
    );
  };

  const submitButtonText = isGraded ? <><Icon tokens={redoTokens} /> {i18nStrings.retakeQuiz}</> : i18nStrings.checkYourAnswers;

  return (
    <GadgetContainer id={id} ref={ref}>
      <Theme tokens={brandedLightTokens}>
        <div className='AssessmentGadgetLayout'>
          <div className='AssessmentGadgetLayout__header'>
            {title && <div className='AssessmentGadgetLayout__header-title'>
              <Heading level={3}>
                {title}
              </Heading>
            </div>}
            {instructions && <Text type='b300' color='secondary'>
              {instructions}
            </Text>}
          </div>
          <div className='AssessmentGadgetLayout__body'>
            {questions?.map((question, index) =>
              <QuizQuestionCard
                ref={index === 0 ? firstInputRef : undefined}
                gadgetId={id}
                key={question.id}
                {...question}
                isGraded={isGraded}
                questionEvaluation={evaluationResults?.evaluationsByQuestionId[question.id]}
                selectedChoices={choicesByQuestion[question.id] || []}
                correctChoices={evaluationResults?.correctChoicesByQuestionId[question.id]}
                onSelectChoice={onSelectChoice}
                shouldShowAnswers={!isRequiredForCertificate}
                i18nStrings={i18nStrings}
              />
            )}
          </div>
          <div className='AssessmentGadgetLayout__footer'>
            <Responsive
              query='max-width'
              props={{
                wrapper: {
                  default: Row,
                  [responsiveStyles.tablet_breakpoint]: Column
                },
                wrapperProps: {
                  default: {
                    widths: isGraded ? ['fill','fit']: undefined,
                    reverse: true,
                    alignmentHorizontal: !isGraded ? 'end': undefined
                  },
                  [responsiveStyles.tablet_breakpoint]: undefined
                },
                buttonProps: {
                  default: undefined,
                  [responsiveStyles.tablet_breakpoint]: { minWidth: '100%' }
                }
              }}
            >
              {props =>
                <props.wrapper {...props.wrapperProps}>
                  {isGraded && <ScoreBreakdown
                    status={evaluationResults?.status || AssessmentEvaluationStatus.Error}
                    numberCorrect={totalCorrectAnswers || 0}
                    totalQuestions={totalQuestions}
                    learnerScore={learnerScore || 0}
                    passPercentage={get(settings, 'passPercentage', 0)}
                    isRequiredForCertificate={isRequiredForCertificate}
                    onJumpToFirstQuestion={() => firstInputRef.current?.focus()}
                    i18nStrings={i18nStrings.scoreBreakdown}
                  />}
                  {/* we use the same button here to avoid re-rendering the button and losing keyboard focus after submitting */}
                  <Button
                    ref={submitButtonRef}
                    size='large'
                    type={isGraded ? 'tertiary' : 'primary'}
                    onClick={isGraded ? onClickRetry : onClickSubmit}

                    // If quiz has already been graded, enable if retries are allowed
                    // If not, enable once learner has selected answers for all questions
                    disabled={(isGraded && !allowRetries) || (!isGraded && !areAllQuestionsAnswered)}
                    {...props.buttonProps}
                  >
                    {submitButtonText}
                  </Button>
                </props.wrapper>}
            </Responsive>
          </div>
        </div>
      </Theme>
    </GadgetContainer>
  );
});
QuizGadget.displayName = 'QuizGadget';
