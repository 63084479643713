import React, {FunctionComponent} from 'react';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import checkCircleFilledTokens from '@amzn/meridian-tokens/base/icon/check-circle-filled';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import Theme from '@amzn/meridian/theme';
import {brandedLightTokens} from '../../../theme';
import {LabIcon, CertificateIcon} from '../../../icons';
import {LabGadgetConfig} from './LabGadget';
import {GadgetType} from '../../../context/course';
import {defaultI18nStrings, LabGadgetI18nStrings, WithI18nStringsProps} from '../../../context/course/models/I18n';

import './styles/LabPreview.scss';


export interface LabPreviewProps extends WithI18nStringsProps<LabGadgetI18nStrings> {
  id: string,
  config: LabGadgetConfig,
  onOpenActivity: () => void,
  completed: boolean,
}

export const LabPreview: FunctionComponent<LabPreviewProps> = ({
  id,
  config: {
    title,
    teaser,
    challenges,
    settings: {
      isRequiredForCertificate
    }
  },
  onOpenActivity,
  completed,
  i18nStrings = defaultI18nStrings.gadgets[GadgetType.LAB]!
}) => {
  const totalTimeToComplete = challenges.reduce((total, challenge) => (total + challenge.timeToCompleteInMinutes), 0);

  return <Theme tokens={brandedLightTokens}>
    <div className="LabPreview">
      <div className="LabPreview__header">
        <Row widths={['fill', 'fit']}>
          <Row alignmentVertical='center' spacing='400'>
            <LabIcon />
            <span>{i18nStrings.header}</span>
          </Row>
          <div className={completed ? 'LabPreview__header--completed' : 'LabPreview__header--incomplete'}>
            <Row alignmentVertical='center' spacing='300'>
              {completed ?
                <>
                  <Icon tokens={checkCircleFilledTokens} /> <span>{i18nStrings.completed}</span>
                </> :
                <span>{i18nStrings.incomplete}</span>}
            </Row>
          </div>
        </Row>
      </div>
      <Column spacing='500'>
        <Column>
          <Heading level={3}>{title}</Heading>
          {isRequiredForCertificate && <div className='LabPreview__certificate'>
            <Row alignmentVertical='top' spacing='400'>
              <CertificateIcon />
              <Text type='h200' color='secondary'>{i18nStrings.requiredForCert}</Text>
            </Row>
          </div>}
        </Column>
        <Column>
          <Text>{teaser}</Text>
          <Row widths={['fill', 'fit']}>
            <Column spacing="none">
              <Text>{challenges.length} exercises</Text>
              <Text><strong>{i18nStrings.totalTime(totalTimeToComplete)}</strong></Text>
            </Column>
            <Button type={completed ? 'tertiary' : 'primary'} minWidth='13rem' size='large' onClick={onOpenActivity}>
              {completed ? i18nStrings.restartActivityCta : i18nStrings.startActivityCta}
            </Button>
          </Row>
        </Column>
      </Column>
      <hr aria-hidden className="LabPreview__divider" />
      <div>
        <ol className="LabPreview__challenge-list">
          {challenges.map((challenge, index) => {
            return <li key={`${id}-challenge-${index}`} className="LabPreview__challenge-list-item">
              <Row alignmentVertical="top" spacing='500'>
                <span className="LabPreview__challenge-list-item-number" aria-hidden>{index + 1}</span>
                <div className="LabPreview__challenge-list-item-details">
                  <Column spacing='300'>
                    <Text type="h200">{challenge.title}</Text>
                    <Text>{challenge.teaser}</Text>
                    <Text color="secondary">{i18nStrings.exerciseTimeLimit(challenge.timeToCompleteInMinutes)}</Text>
                  </Column>
                </div>
              </Row>
            </li>;
          })}
        </ol>
      </div>
    </div>
  </Theme>;
};
