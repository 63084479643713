import React, {
  cloneElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useState
} from 'react';
import {ConnectDragPreview, ConnectDragSource, ConnectDropTarget, useDrag, useDrop} from 'react-dnd';
import cx from 'classnames';

import './styles/DnDListItem.scss';

const NULL_DND_TYPE = '__NullDnDType__';
export const enum RelativePosition {
  Over = 'over',
  Under = 'under'
}

export interface DnDListItemType {
  id: string;
  index: number;
  parentId?: string;
}

export interface DndProps {
  dragPreview: ConnectDragPreview,
  dragSource?: ConnectDragSource,
  dropTarget?: ConnectDropTarget,
  dropZoneExtender?: ReactNode
}

export interface DnDListItemProps<T extends DnDListItemType> {
  /**
   * data about this item. passed to the onDrop callback
   */
  item: T;

  /**
   * the drag item type of this item. If not provided, the item cannot be dragged and
   * can act solely as a drop target for the dropTypes provided
   */
  dragType?: string;

  /**
   * optional array of acceptable drop types. If none are supplied, a default null drop type
   * is used (to prevent React from complaining about hook order) and drop events are ignored
   */
  dropTypes?: string[];

  /**
   * Optional callback for when dropping an item onto a drop zone.
   * @param draggedItem the item being dragged
   * @param droppedAt the item being dropped onto
   * @param dropType the type of the dropped item
   */
  onDrop?: (draggedItem: T, dropIndex: number, dropType: string) => Promise<void>;

  /**
   * optional callback to be called when all drag/drop events are over
   */
  onEnd?: () => void;

  /**
   * optional flags. If present, specified dnd connectors are passed to the children as props,
   * which can then be placed anywhere in the children. Connectors not specified here will
   * default to being placed on a div that wraps the item's children
   */
  overrideDnDZones?: {
    drag?: boolean,
    drop?: boolean
  };

  /**
   * Optional ReactNode to render when an item is being dropped into the list.
   */
  onDroppingElement?: ReactNode;
}

export type DnDListItemPropsWithChildren<T extends DnDListItemType> = PropsWithChildren<DnDListItemProps<T>>

export const DnDListItem = <T extends DnDListItemType>({
  children,
  dragType = NULL_DND_TYPE,
  dropTypes = [NULL_DND_TYPE],
  item,
  onDrop,
  onEnd,
  overrideDnDZones = {},
  onDroppingElement
}: DnDListItemPropsWithChildren<T>) => {
  const canBeDropped = dropTypes[0] !== NULL_DND_TYPE;
  const canBeDragged = dragType !== NULL_DND_TYPE;
  const nodeRef = React.createRef<HTMLDivElement>();

  const [isDropping, setIsDropping] = useState(false);

  const [{ opacity }, drag, dragPreview] = useDrag(
    () => ({
      type: dragType,
      item: item as T,
      canDrag: () => canBeDragged,
      collect: monitor => ({
        isBeingDragged: monitor.isDragging(),
        opacity: monitor.isDragging() ? 0.4 : 1
      }),
      end: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          onEnd && onEnd();
        }
      },
    }), [item, onEnd]);

  const [collect, drop] = useDrop(
    () => ({
      accept: dropTypes,
      canDrop: () => canBeDropped,
      async drop(draggedItem: T, monitor) {
        if (canBeDropped && draggedItem.id !== item.id) {
          if (onDrop) {
            setIsDropping(true);
            await onDrop(
              draggedItem,
              item.index,
              monitor.getItemType() as string);
            setIsDropping(false);
          }
        }
      },
      collect(monitor) {
        const {id: draggedItemId, index: draggedItemIndex}: T = monitor.getItem() || {};
        const sameType = monitor.getItemType() as string === dragType;
        if (sameType) {
          const relativePosition = draggedItemIndex < item.index ?
            RelativePosition.Under :
            RelativePosition.Over;
          return {
            relativePosition,
            hovered: canBeDropped &&
              monitor.isOver({shallow: true}) &&
              draggedItemId !== item.id
          };
        } else {
          return {
            relativePosition: RelativePosition.Over,
            hovered: canBeDropped && monitor.isOver({shallow: true})
          };
        }
      }
    }),
    [item, nodeRef.current, onDrop],
  );

  const className = cx(
    'DnDListItem',
    collect.hovered && `DnDListItem--hover-${collect.relativePosition}`
  );

  const extendedChildren = cloneElement(children as ReactElement, {
    dragPreview,
    dragSource: overrideDnDZones.drag ? drag : undefined,
    dropTarget: overrideDnDZones.drop ? drop : undefined,
  } as DndProps);

  return <div
    ref={node => {
      let finalNode: HTMLDivElement | ReactElement | null = node;
      if (!overrideDnDZones.drag) {
        finalNode = drag(finalNode);
      }
      if (!overrideDnDZones.drop) {
        finalNode = drop(finalNode);
      }
      return finalNode;
    }}
    style={{opacity}}
    className={className}
  >
    <div ref={nodeRef}>
      {isDropping && onDroppingElement}
      {extendedChildren}
    </div>
  </div>;
};
