import React, {forwardRef, useEffect, useRef, useState} from 'react';
import uniqueId from 'lodash/uniqueId';
import axios from 'axios';
import Heading from '@amzn/meridian/heading';
import Theme from '@amzn/meridian/theme';
import brandedLightTokens from '../../../theme/branded-light';
import {
  AssetModel,
  GadgetConfig,
  GadgetLearnerState,
  useCourseContext,
  VideoAssetMetadata
} from '../../../context/course';
import {VideoTranscript} from './VideoTranscript';
import {ShakaPlayer, ShakaPlayerRef} from './ShakaPlayer';
import {GadgetProps} from '../models';
import {throttle} from 'lodash';
import {DrmType} from './models/DrmType';
import {detect} from 'detect-browser';
import GadgetContainer from '../../containers/gadget/GadgetContainer';
import {
  MarkdownGadgetI18nStrings,
  VideoGadgetI18nStrings
} from '../../../context/course/models/I18n';
import {MarkdownRenderer} from '../markdown/MarkdownRenderer';

import './styles/VideoGadget.scss';

/**
 * Video Gadget config
 */
export interface VideoGadgetConfig extends GadgetConfig {
  /**
   * Video information
   */
  video: {

    /**
     * Video asset ID
     */
    assetId: string
  },

  /**
   * Video Description
   */
  videoDescription?: string

  /**
   * Video captions asset (if available)
   */
  videoCaptions?: {

    /**
     * Captions asset Id
     */
    assetId: string
  }

  /**
   * Video text transcript asset (if available)
   */
  videoTextTranscript?: {

    /**
     * Text transcript asset Id
     */
    assetId: string
  }
}

/**
 * Learner state for the video gadget
 */
export interface VideoGadgetLearnerState extends GadgetLearnerState {
  currentTime: number
}

const handleUnsupportedDevices = () => {
  const browser = detect();
  if (isSafari() || isIPhone() || browser?.os === 'iOS') {
    return DrmType.FAIRPLAY;
  } else if (browser?.os === 'Android OS') {
    return DrmType.WIDEVINE;
  }
  return DrmType.UNSUPPORTED;
};

/**
 * Helper function to determine what DRM type based on the browser
 */

export const getDrmType = () => {
  const browser = detect();
  switch (browser?.name) {
    case 'chrome':
    case 'firefox':
    case 'opera':
    case 'edge-chromium':  //shaka player chooses widevine over playready when both are available and our videos are protected with both
      return DrmType.WIDEVINE;

    case 'edge':
    case 'ie':
      return DrmType.PLAYREADY;

    case 'safari':
      return DrmType.FAIRPLAY;

    default:
      return handleUnsupportedDevices();
  }
};

export const isIOSSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
  const webkit = ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
};

export const isIPhone = () => {
  const ua = window.navigator.userAgent;
  return ua.match(/iPhone/i);
};

export const isSafari = () => {
  const browser = detect();
  return browser?.name === 'safari' || isIOSSafari();
};

export type VideoGadgetProps = GadgetProps<VideoGadgetConfig, VideoGadgetLearnerState, { video: VideoGadgetI18nStrings, markdown: MarkdownGadgetI18nStrings}>

const UPDATE_VIDEO_PROGRESS_INTERVAL_IN_MS = 10 * 1000;

/**
 * You can use this gadget to add videos to your course. You need only need to provide an ```assetId```, which the gadget will use to call the API, which should return back the URL asset location.
 * If available, you can also provide an ```assetId``` that maps to your video captions. If provided, the gadget will fetch them from the API and add the captions to your video.
 */
export const VideoGadget = forwardRef<HTMLElement | undefined, VideoGadgetProps>((props: VideoGadgetProps, ref) => {
  const {
    id,
    type,
    config: {
      title,
      video,
      videoDescription,
      videoCaptions,
      videoTextTranscript
    },
    learnerState,
    i18nStrings: {
      video: videoGadgetI18nStrings,
      markdown: markdownGadgetI18nStrings
    },
  } = props;

  const [videoMetadata, setVideoMetadata] = useState<VideoAssetMetadata>();
  const [videoCaptionsAssetModel, setVideoCaptionsAssetModel] = useState<AssetModel>();
  const [videoTextTranscriptAssetModel, setVideoTextTranscriptAssetModel] = useState<AssetModel>();
  const [transcriptContent, setTranscriptContent] = useState<string | null>(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [playerId] = useState(uniqueId('video-player-'));
  const [initialCurrentTime] = useState<number | null>(learnerState?.currentTime || null);

  const {getAssetModel, setGadgetLearnerState, emitCounterMetrics, emitTimerMetrics} = useCourseContext();


  useEffect(() => {
    console.log("videoCaptions: " + videoCaptions);
    (async () => {
      if (videoCaptions) {
        setVideoCaptionsAssetModel(await getAssetModel(videoCaptions.assetId));
      }
      // prefer captions, load transcript only if available and there are no captions
      if (!videoCaptions && videoTextTranscript) {
        setVideoTextTranscriptAssetModel(await getAssetModel(videoTextTranscript.assetId));
      }
      const videoModel = await getAssetModel(video.assetId);
      if (videoModel) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setVideoMetadata(videoModel.assetMetadata);
      }
    })();
  }, [video, getAssetModel, videoCaptions, videoTextTranscript]);

  useEffect(() => {
    console.log("videoCaptionsAssetModel: " + videoCaptionsAssetModel);
    (async () => {
      const transcriptLocation = videoCaptionsAssetModel?.location || videoTextTranscriptAssetModel?.location || videoMetadata?.transcriptFileGetUrl;
      if (transcriptLocation) {
        axios.get<string>(transcriptLocation)
          .then(axiosResponse => {
            setTranscriptContent(axiosResponse.data);
          })
          .catch(reason => {
            console.log('failed to load subtitles from ', transcriptLocation);
          });
      }
    })();
  }, [videoCaptionsAssetModel?.location, videoTextTranscriptAssetModel?.location, videoMetadata?.transcriptFileGetUrl]);

  const shakaRef = useRef<ShakaPlayerRef>(null);

  // effect to detect the player has loaded, and we have the references available
  useEffect(() => {
    console.log("shakaRef" + shakaRef);
    if (shakaRef.current) {
      setVideoLoaded(true);
    }
  }, [videoMetadata]);

  // initialize the player events and set any learner state
  // this is meant to run only once - after the video player has loaded
  useEffect(() => {
    console.log("videoLoaded:" + videoLoaded);
    if (videoLoaded && shakaRef.current?.videoElement) {
      const videoElement = shakaRef.current.videoElement;

      // set learner state currentTime if needed
      if (initialCurrentTime) {
        videoElement.currentTime = initialCurrentTime;
      }

      // event listeners to update video progress
      const onTimeUpdate = throttle(() => {
        if (initialCurrentTime !== videoElement.currentTime) {
          setGadgetLearnerState<VideoGadgetLearnerState>(id, type, {
            currentTime: videoElement.currentTime,
            updatedAt: new Date()
          });
        }
      }, UPDATE_VIDEO_PROGRESS_INTERVAL_IN_MS);

      videoElement.addEventListener('timeupdate', onTimeUpdate);

      return () => {
        // remove event listeners when destroying the component
        videoElement.removeEventListener('timeupdate', onTimeUpdate);
      };
    }
  }, [id, setGadgetLearnerState, videoLoaded, initialCurrentTime, type]);

  // Destroy player instance on lesson change.
  useEffect(() => {
    return () => {
      const playerInstance = shakaRef?.current?.player;
      playerInstance && playerInstance.destroy();
    };
  }, []);

  const drmType = getDrmType();
  const browser = detect();

  // for safari, always use HLS and DASH is our default
  const videoPlaybackUrl = isSafari() || browser?.os === 'iOS' || isIPhone()
    ? videoMetadata?.hlsEgressEndpoint :
    videoMetadata?.dashEgressEndpoint;

  return (
    <GadgetContainer id={id} ref={ref}>
      <Theme tokens={brandedLightTokens}>
        <div className={'video-gadget'}>
          {title && <Heading className='video-gadget-title' level={3}>{title}</Heading>}
          {videoDescription && <MarkdownRenderer markdownText={videoDescription} i18nStrings={markdownGadgetI18nStrings}/>}
          {videoMetadata && videoPlaybackUrl &&
          <ShakaPlayer
            videoPlayerId={playerId}
            videoPlaybackUrl={videoPlaybackUrl}
            drmLicenseUrl={videoMetadata?.drmLicenseUrl}
            videoCaptionsUrl={videoCaptionsAssetModel?.location || videoMetadata?.transcriptFileGetUrl}
            drmType={drmType}
            getDrmRequest={videoMetadata.getDrmRequest}
            emitCounterMetrics={emitCounterMetrics}
            emitTimerMetrics={emitTimerMetrics}
            ref={shakaRef}
            i18nStrings={videoGadgetI18nStrings}
          />
          }
          {transcriptContent &&
          <VideoTranscript
            contentId={`video-transcript-${id}`}
            transcriptContent={transcriptContent}
            transcriptType={videoCaptions || videoMetadata?.transcriptFileGetUrl ? 'vtt' : 'text'}
            videoElement={shakaRef.current?.videoElement}
            i18nStrings={videoGadgetI18nStrings}
          />
          }
        </div>
      </Theme>
    </GadgetContainer>
  );
});
VideoGadget.displayName = 'VideoGadget';
