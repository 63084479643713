import React, {FunctionComponent, RefObject, useCallback, useEffect, useState} from 'react';
import get from 'lodash/get';
import AppLayout, {appLayoutContentId} from '@amzn/meridian/app-layout';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Loader from '@amzn/meridian/loader';
import brandedLightTokens from '../../../theme/branded-light';
import {
  AssetModel,
  Course,
  CourseApi,
  CourseContextProps,
  CourseContextProvider,
  CoursePosition,
  CourseStatusUpdateResponse,
  DisplaySection,
  Gadget,
  GadgetLearnerState,
  InitialPosition,
  LearnerFeedback,
  Lesson,
  Logger,
  ProgressStatus,
  useCourseContext,
  GadgetConfig,
  CloudLabInfo,
} from '../../../context/course';
import {RecoilRoot, useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import Theme from '@amzn/meridian/theme';
import brandedDarkTokens from '../../../theme/branded-dark';
import './AuthoringUI.scss';
import {CourseAuthoringApi, CourseAuthoringContextProps, CourseAuthoringContextProvider} from '../../context';
import {
  currentAuthoringUISidebarState,
  currentCourseDraft,
  currentCourseDraftFullScreenGadgetId,
  currentCourseDraftGadgets,
  currentCourseDraftGadgetsLearnerState,
  currentCourseDraftLoaded,
  currentCourseDraftPosition,
  currentCourseDraftScrollToGadgetId,
  currentCourseDraftSkeleton,
  useAuthoringUISidebarState,
  useCurrentCourseDraft,
  useCurrentCourseDraftGadgetsLearnerState,
  useCurrentCourseDraftLesson,
  useCurrentCourseDraftPosition,
} from '../../state/recoil';
import TopNav from '../../../components/navigation/topnav';
import {SidebarState} from '../../../components/containers/course-player/models/PlayerUIModes';
import {LessonEditor} from '../lesson-editor';
import {GracefulError, GracefulErrorTheme} from '../../../components/errors/GracefulError';
import {ErrorBoundary} from 'react-error-boundary';
import {AuthoringActionHooks} from './models/AuthoringActionHooks';
import {dragDropManager} from '../drag-and-drop/dnd-utils';
import {DndProvider} from 'react-dnd';
import {LearnerActivity} from '../../../activity/models/learner-activities';
import {filter, findIndex, forEach, omit, remove, size} from 'lodash';
import {ToCBuilder} from '../toc';
import axios from 'axios';
import {ScrollToGadget} from '../../../components/containers/active-gadget/ScrollToGadget';
import {ActiveGadgetList} from '../../../components/containers/active-gadget/ActiveGadgetList';
import {CreateAssetRequest} from '../../context/models/CreateAssetRequest';
import {getGadgetsById, toCourseUpdateRequest} from '../../utils';
import {CourseUpdateRequest, LessonUpdate} from '../../context/models/CourseUpdateRequest';
import {AuthoringGadgetRenderer, DEFAULT_AUTHORING_GADGETS_REGISTRY} from '../gadgets/registry';
import {I18nStringsConfig, TopNavI18nStrings} from '../../../context/course/models/I18n';
import {LessonViewMode} from '../../../context/course/models/LessonViewMode';
import {getCloudLabEmbedInfo, mergeI18nStringsWithConfig} from '../../../utils/CourseUtils';

export interface ErrorFallbackProps {
  error: Error
}

/**
 * Course Player props
 */
export interface CourseAuthoringProps {
  /**
   * ID of the course draft to be used in this context
   */
  courseDraftId: string;

  /**
   * Course position set in the URL
   */
  initialPosition?: InitialPosition;

  /**
   * Course API implementation to be used
   */
  courseApi: CourseApi;

  /**
   * Course authoring API implementation to be used
   */
  courseAuthoringApi: CourseAuthoringApi;

  /**
   * Configured authoring actions hooks
   */
  authoringActionHooks: AuthoringActionHooks;

  /**
   * Handler for course position change updates (optional)
   * @param position new course position
   */
  onPositionChange?: (position: CoursePosition) => void;

  /**
   * Handler for course updates (optional)
   * @param course new course value
   */
  onCourseChange?: (course: Course) => void;

  /**
   * Callback function that is called if the course authoring UI experiences uncaught errors (outside any gadgets)
   * @param error the js Error object passed by React's error boundary code
   * @param info React's error info object
   */
  onError?: (error: Error, info: React.ErrorInfo) => void,

  /**
   * Optional function to render a fallback view in case there is an uncaught error somewhere in the course authoring.
   * @param props object containing the error object thrown
   */
  errorFallbackRender?: (props: ErrorFallbackProps) =>
    React.ReactElement<unknown, string | React.FunctionComponent | typeof React.Component> | null;

  /**
   * If provided, it overrides the gadget types with the specified components.
   */
  gadgetOverrides?: AuthoringGadgetRenderer;

  /**
   * Set of translation strings and functions for static text throughout the components
   */
  i18nStringsConfig?: I18nStringsConfig;

  /**
   * Optional Logger object that contains functions to log: "debug", "info", "warn", "error", "fatal"
   * The implementation of each function is optional, if any is left empty, we will call noop
   */
  logger?: Logger;
}

/**
 * TopNav HOC using Recoil State
 */
export const TopNavWithRecoilState: FunctionComponent<{
  onCourseExit: () => void,
  i18nStrings?: TopNavI18nStrings,
}> = ({onCourseExit, i18nStrings}) => {
  const [sidebarState, setSidebarState] = useRecoilState(currentAuthoringUISidebarState);
  const currentCourse = useCurrentCourseDraft();
  const coursePosition = useCurrentCourseDraftPosition();
  const setScrollToGadgetId = useSetRecoilState(currentCourseDraftScrollToGadgetId);

  const onLessonClick = useCallback(() => {
    setScrollToGadgetId({gadgetId: undefined, focus: true});
  }, [setScrollToGadgetId]);

  if (!currentCourse) {
    return null;
  }

  const courseExitButtonProps = sidebarState !== SidebarState.NO_SIDEBAR
    ? {buttonText:'Back to Your Courses', onClick:onCourseExit}
    : undefined;

  return (<TopNav
    lessons={currentCourse.lessons}
    coursePosition={coursePosition}
    sidebarState={sidebarState}
    onClickMenu={() => {
      setSidebarState(sidebarState === SidebarState.EXPANDED
        ? SidebarState.COLLAPSED
        : SidebarState.EXPANDED);
    }}
    courseExitButtonProps={courseExitButtonProps}
    onNextLessonClick={onLessonClick}
    onPreviousLessonClick={onLessonClick}
    i18nStrings={i18nStrings}
  />);
};

/**
 * Sidebar ToC Builder HOC using Recoil State
 */
export const SidebarWithRecoilState: FunctionComponent<{
  onLessonViewModeChanged: (viewMode: LessonViewMode) => void
}> = ({ onLessonViewModeChanged }) => {
  const sidebarState = useAuthoringUISidebarState();
  const currentCourseDraft = useCurrentCourseDraft();
  const coursePosition = useCurrentCourseDraftPosition();
  const setScrollToGadgetId = useSetRecoilState(currentCourseDraftScrollToGadgetId);

  if (!currentCourseDraft || sidebarState === SidebarState.COLLAPSED || sidebarState === SidebarState.NO_SIDEBAR) {
    return null;
  }

  return (<ToCBuilder
    course={currentCourseDraft}
    coursePosition={coursePosition}
    onLessonClick={() => {
      setScrollToGadgetId({gadgetId: undefined, focus: true});
    }}
    onGadgetClick={gadgetId => {
      setScrollToGadgetId({gadgetId, focus: true});
    }}
    onLessonViewModeChanged={onLessonViewModeChanged}
  />);
};

/**
 * Course Authoring component using Recoil state
 */
const CourseAuthoringUIWithRecoilState: FunctionComponent<CourseAuthoringProps> = (
  {
    courseDraftId,
    courseAuthoringApi,
    courseApi,
    initialPosition,
    authoringActionHooks,
    onPositionChange,
    onCourseChange,
    gadgetOverrides,
    i18nStringsConfig,
    logger,
  }: CourseAuthoringProps
) => {
  const isCurrentCourseDraftLoaded = useRecoilValue(currentCourseDraftLoaded);
  const setCourseSkeleton = useSetRecoilState(currentCourseDraftSkeleton);
  const setCourseDraftGadgets = useSetRecoilState(currentCourseDraftGadgets);
  const setCoursePosition = useSetRecoilState(currentCourseDraftPosition);
  const setScrollToGadgetId = useSetRecoilState(currentCourseDraftScrollToGadgetId);
  const setGadgetsState = useSetRecoilState(currentCourseDraftGadgetsLearnerState);
  const setCurrentFullScreenGadgetId = useSetRecoilState(currentCourseDraftFullScreenGadgetId);

  useEffect(() => {
    (async () => {
      const loadedCourseDraft = await courseAuthoringApi.loadCourse(courseDraftId);
      setCourseSkeleton(toCourseUpdateRequest(loadedCourseDraft));
      onCourseChange && onCourseChange(loadedCourseDraft);

      setCourseDraftGadgets(getGadgetsById(loadedCourseDraft));

      // If we get an initial position from the URL
      // Build CoursePosition object using those indexes and set it in the learner's state
      // (it may replace a previously stored position)
      let initialCoursePosition: CoursePosition;
      if(initialPosition) {
        const {
          lessonIndex,
          gadgetIndex
        } = initialPosition;
        initialCoursePosition = {
          displaySection: DisplaySection.LESSON,
          lessonId: get(loadedCourseDraft, `lessons[${lessonIndex - 1}].id`),
          // @ts-ignore
          gadgetId: gadgetIndex && get(loadedCourseDraft, `lessons[${lessonIndex - 1}].gadgets[${gadgetIndex && gadgetIndex - 1}].id`, ''),
          updatedAt: new Date()
        };
      } else {
        initialCoursePosition = {
          lessonId: loadedCourseDraft.lessons[0]?.id,
          gadgetId: loadedCourseDraft.lessons[0]?.gadgets[0]?.id,
          updatedAt: new Date(),
          displaySection: DisplaySection.LESSON,
        };
      }
      // Update recoil state
      setCoursePosition(initialCoursePosition);
      // If gadget specified, scroll to it
      setScrollToGadgetId({gadgetId: initialCoursePosition?.gadgetId, focus: true});
      // store userstate
      await courseApi.setCoursePosition(courseDraftId, initialCoursePosition);
    })();
  }, [courseAuthoringApi, courseDraftId, setCourseSkeleton, setCoursePosition, setScrollToGadgetId, initialPosition, onCourseChange, courseApi, setCourseDraftGadgets]);

  const updateContentTitle = useRecoilCallback(({ set, snapshot}) => async (contentId: string, title: string) => {
    const courseSkeleton = await snapshot.getPromise(currentCourseDraftSkeleton);

    const contents = [...courseSkeleton.contents];
    const contentIndex = findIndex(contents, ['id', contentId]);
    contents[contentIndex] = {
      ...contents[contentIndex],
      title
    };

    const updatedCourseSkeleton = {
      ...courseSkeleton,
      contents
    };

    // update state
    set(currentCourseDraftSkeleton, updatedCourseSkeleton);

    // persist using the API
    await courseAuthoringApi.updateCourse(courseDraftId, updatedCourseSkeleton);
  });

  const updateLessonIndex = useRecoilCallback(({ set, snapshot}) => async (lessonId: string, newIndex: number) => {
    const courseSkeleton = await snapshot.getPromise(currentCourseDraftSkeleton);

    const contents = [...courseSkeleton.contents];
    const fromIndex = findIndex(contents, ['id', lessonId]);
    const element = contents[fromIndex];
    contents.splice(fromIndex, 1);
    contents.splice(newIndex, 0, element);

    const updatedCourseSkeleton = {
      ...courseSkeleton,
      contents
    };

    // update state
    set(currentCourseDraftSkeleton, updatedCourseSkeleton);

    // persist using the API
    await courseAuthoringApi.updateCourse(courseDraftId, updatedCourseSkeleton);
  });

  const updateGadgetIndex = useRecoilCallback(({ set, snapshot}) => async (lessonId: string, gadgetId: string, newIndex: number) => {
    const courseSkeleton = await snapshot.getPromise(currentCourseDraftSkeleton);

    const contents = [...courseSkeleton.contents];
    const lessonIndex = findIndex(courseSkeleton.contents, ['id', lessonId]);
    const lesson = courseSkeleton.contents[lessonIndex] as LessonUpdate;
    const gadgetIds = [...lesson.gadgetIds];
    const fromIndex = findIndex(gadgetIds, it => it === gadgetId);
    const element = gadgetIds[fromIndex];
    gadgetIds.splice(fromIndex, 1);
    gadgetIds.splice(newIndex, 0, element);
    contents[lessonIndex] = {
      ...contents[lessonIndex],
      gadgetIds,
    } as LessonUpdate;

    const updatedCourseSkeleton = {
      ...courseSkeleton,
      contents
    };

    // update state
    set(currentCourseDraftSkeleton, updatedCourseSkeleton);

    // persist using the API
    await courseAuthoringApi.updateCourse(courseDraftId, updatedCourseSkeleton);
  });

  const removeLesson = useRecoilCallback(({ set, snapshot}) => async (lessonId: string) => {
    // remove content call
    await courseAuthoringApi.deleteContent(courseDraftId, lessonId);

    const courseDraft = await snapshot.getPromise(currentCourseDraft);
    const courseSkeleton = await snapshot.getPromise(currentCourseDraftSkeleton);
    const newContents = [...courseSkeleton.contents];

    // index of lesson we are removing
    const lessonIndex = findIndex(courseDraft.lessons, lesson => lesson.id === lessonId);

    // remove the lesson from the contents field
    const removedLesson = remove(newContents, lesson => lesson.id === lessonId);

    // update the course position if needed
    const coursePosition = await snapshot.getPromise(currentCourseDraftPosition);
    if (coursePosition && coursePosition.lessonId === lessonId) {
      const currentLessonIndex = Math.min(lessonIndex, size(newContents) - 1);
      if (currentLessonIndex >= 0) {
        set(currentCourseDraftPosition, {
          lessonId: newContents[currentLessonIndex]?.id || '',
          gadgetId: '',
          updatedAt: new Date(),
          displaySection: DisplaySection.LESSON,
        });
      } else {
        set(currentCourseDraftPosition, null);
      }
    }

    // update course skeleton state
    set(currentCourseDraftSkeleton, {
      ...courseSkeleton,
      contents: newContents
    });

    // remove gadgets in the lesson from state
    const gadgetIdsToDelete = (removedLesson as unknown as LessonUpdate).gadgetIds;
    set(currentCourseDraftGadgets, draftGadgets => {
      return {
        ...(omit(draftGadgets, gadgetIdsToDelete))
      };
    });

  }, []);

  const updateLessonViewMode = useRecoilCallback(({ set, snapshot}) => async (lessonViewMode: LessonViewMode) => {
    const courseSkeleton = await snapshot.getPromise(currentCourseDraftSkeleton);

    const updatedCourseSkeleton = {
      ...courseSkeleton,
      lessonViewMode
    } as CourseUpdateRequest;

    // update state
    set(currentCourseDraftSkeleton, updatedCourseSkeleton);

    // persist using the API
    await courseAuthoringApi.updateCourse(courseSkeleton.id, updatedCourseSkeleton);
  });

  const [authoringContextValue] = useState<CourseAuthoringContextProps>({
    enterGadgetFullEditMode(gadgetId: string): void {
      setCurrentFullScreenGadgetId(gadgetId);
    }, exitGadgetFullEditMode(): void {
      setCurrentFullScreenGadgetId(undefined);
    },
    async addLesson(): Promise<void> {
      const newLesson = await courseAuthoringApi.createContent(courseDraftId, 'LESSON', 'Untitled Lesson');
      setCourseSkeleton(currentCourseSkeleton => {
        const newContents = [...currentCourseSkeleton.contents, { ...newLesson, gadgetIds: []} as LessonUpdate];
        return {
          ...currentCourseSkeleton,
          contents: newContents
        };
      });
    },
    async addLessonGadgets(lessonId: string, gadgets: Pick<Gadget, 'type' | 'config'>[], index: number | undefined): Promise<void> {
      // create gadgets in the service, we are sent back the gadget IDs
      const createdGadgets = await courseAuthoringApi.createLessonGadgets(courseDraftId, lessonId, gadgets, index);

      // assign the created IDs to the gadgets and update the state
      setCourseDraftGadgets(draftGadgets => {
        const updatedDraftGadgets = {
          ...draftGadgets,
        };
        forEach(createdGadgets, (gadget, index) => {
          updatedDraftGadgets[gadget.id] = { ...gadgets[index], id: gadget.id} as Gadget;
        });
        return updatedDraftGadgets;
      });

      // update course skeleton state
      setCourseSkeleton(courseSkeleton => {
        const contents = [...courseSkeleton.contents];
        const contentIndex = findIndex(contents, ['id', lessonId]);
        const lesson = contents[contentIndex] as LessonUpdate;
        const newLessonGadgetIds = [...lesson.gadgetIds];
        newLessonGadgetIds.splice(index || 0, 0, ...(createdGadgets.map(it => it.id)));
        contents[contentIndex] = {
          ...lesson,
          gadgetIds: newLessonGadgetIds,
        } as LessonUpdate;
        return {
          ...courseSkeleton,
          contents
        };
      });
    },
    async removeGadget(lessonId: string, gadgetIds: string[]): Promise<void> {
      // remove gadget call
      await courseAuthoringApi.deleteLessonGadgets(courseDraftId, lessonId, gadgetIds);

      // update course skeleton state
      setCourseSkeleton(courseSkeleton => {
        const contents = [...courseSkeleton.contents];
        const contentIndex = findIndex(contents, ['id', lessonId]);
        const lesson = contents[contentIndex] as LessonUpdate;
        contents[contentIndex] = {
          ...lesson,
          gadgetIds: filter(lesson.gadgetIds, it => !gadgetIds.includes(it)),
        } as LessonUpdate;
        return {
          ...courseSkeleton,
          contents
        };
      });

      // remove the gadgets from the state
      setCourseDraftGadgets(draftGadgets => {
        return {
          ...(omit(draftGadgets, gadgetIds))
        };
      });
    },
    async removeLesson(lessonId: string, lessonIndex: number): Promise<void> {
      return removeLesson(lessonId);
    },
    async updateContentTitle(contentId: string, title: string): Promise<void> {
      return updateContentTitle(contentId, title);
    },
    async updateGadget<T extends GadgetConfig>(gadgetId: string, gadgetConfig: T): Promise<void> {
      await courseAuthoringApi.updateLessonGadget(courseDraftId, gadgetId, gadgetConfig);
      setCourseDraftGadgets(draftGadgets => {
        return {
          ...draftGadgets,
          [gadgetId]: {
            ...draftGadgets[gadgetId],
            config: gadgetConfig
          }
        };
      });
    },
    async updateGadgetIndex(lessonId: string, gadgetId: string, newIndex: number): Promise<void> {
      return updateGadgetIndex(lessonId, gadgetId, newIndex);
    },
    async updateLessonIndex(lessonId: string, newIndex: number): Promise<void> {
      return updateLessonIndex(lessonId, newIndex);
    },
    getCloudLabInfo(gadgetId: string): Promise<CloudLabInfo> {
      return courseAuthoringApi.getCloudLabInfo(courseDraftId, gadgetId);
    },
    previewDraftCourse(lessonIndex?: number): void {
      authoringActionHooks.previewDraftCourse(lessonIndex);
    },
    createAsset(createAssetRequest: CreateAssetRequest): Promise<AssetModel> {
      return courseAuthoringApi.createAsset(courseDraftId, createAssetRequest);
    },
    uploadFileAssetToS3(s3Location: string, file: File): Promise<void> {
      return axios.put(s3Location, file, {
        headers: {
          'Content-Type': file.type
        }
      });
    },
    startAssetProcessing(assetId: string): Promise<void> {
      return courseAuthoringApi.startAssetProcessing(courseDraftId, assetId);
    },
    createCourseRevision(courseDraftId: string): Promise<void> {
      return courseAuthoringApi.createCourseRevision(courseDraftId);
    }
  });

  const [learnerContextValue] = useState<CourseContextProps>({
    getAssetModel: async assetId => {
      return await courseAuthoringApi.getAssetModel(courseDraftId, assetId);
    },
    getCloudLabEmbedInfo: gadgetId => getCloudLabEmbedInfo(courseDraftId, gadgetId, courseApi),
    setCoursePosition: async position => {
      setCoursePosition(position);
      onPositionChange && onPositionChange(position);
      await courseApi.setCoursePosition(courseDraftId, position);
    },
    setGadgetLearnerState: async (gadgetId, gadgetType, gadgetLearnerState, persistState = true) => {
      let updatedGadgetState: GadgetLearnerState = gadgetLearnerState;
      if (persistState) {
        updatedGadgetState = await courseApi.setGadgetLearnerState(courseDraftId, gadgetId, gadgetType, gadgetLearnerState);
      }

      setGadgetsState(gadgetsState => { return {
        ...gadgetsState,
        [gadgetId]: updatedGadgetState,
      };});
    },
    submitGadgetLearnerStateAssessment: async (gadgetId, gadgetType, learnerState) => {
      const updatedGadgetState = await courseApi.submitGadgetLearnerStateAssessment(courseDraftId, gadgetId, gadgetType, learnerState);
      setGadgetsState(gadgetsState => { return {
        ...gadgetsState,
        [gadgetId]: updatedGadgetState
      };});
    }, emitLearnerActivity(activity: LearnerActivity): void {
      return;
    }, setCourseProgress(progress: ProgressStatus): Promise<CourseStatusUpdateResponse> {
      return Promise.resolve({certificateId: '',gadgetIds: [],statusTransitionSuccessful: true});
    }, setLessonProgress(lessonId: string, progress: ProgressStatus): void {
      return;
    }, submitLearnerFeedback(learnerFeedback: LearnerFeedback): Promise<void> {
      return Promise.resolve(undefined);
    },
    emitCounterMetrics: (metricName: string) => {
      console.log('emitCounterMetrics', metricName);
    },
    emitTimerMetrics: (metricName: string, timer: number) => {
      console.log('emitTimerMetrics', metricName, timer);
    },
    logger: logger
  });

  if (!isCurrentCourseDraftLoaded || !authoringContextValue) {
    return (
      <Theme tokens={brandedDarkTokens}>
        <Column height='100vh' alignmentHorizontal='center' alignmentVertical='center' spacing='large'>
          <Loader />
          <Text color='secondary'>Getting your course draft ready…</Text>
        </Column>
      </Theme>
    );
  }

  return (
    <CourseAuthoringContextProvider {...authoringContextValue}>
      <CourseContextProvider {...learnerContextValue} >
        <Theme tokens={brandedLightTokens}>
          <AppLayout
            headerComponent={TopNavWithRecoilState}
            sidebarComponent={SidebarWithRecoilState}
            backgroundColor={'alternateSecondary'}
            mainClassName='AuthoringUI__main'
          >
            <TopNavWithRecoilState
              onCourseExit={authoringActionHooks.onCourseDraftExit}
              i18nStrings={i18nStringsConfig?.topNav}
            />
            <SidebarWithRecoilState onLessonViewModeChanged={updateLessonViewMode} />
            <Box height='100%'>
              <LessonEditorWithRecoilState gadgetOverrides={gadgetOverrides} i18nStrings={i18nStringsConfig} />
            </Box>
          </AppLayout>
        </Theme>
      </CourseContextProvider>
    </CourseAuthoringContextProvider>
  );
};

export const LessonEditorWithRecoilState: FunctionComponent<{ gadgetOverrides?: AuthoringGadgetRenderer, i18nStrings?: I18nStringsConfig }> = ({gadgetOverrides, i18nStrings}) => {
  const courseDraftLesson = useCurrentCourseDraftLesson();
  const gadgetLearnerStates = useCurrentCourseDraftGadgetsLearnerState();
  const {setCoursePosition} = useCourseContext();
  const [scrollToGadgetId, setScrollToGadgetId] = useRecoilState(currentCourseDraftScrollToGadgetId);
  const scrollContainer = document.getElementById(appLayoutContentId);
  const coursePosition = useCurrentCourseDraftPosition();
  const lessonId = coursePosition?.lessonId;
  const fullScreenGadgetId = useRecoilValue(currentCourseDraftFullScreenGadgetId);

  const [gadgetRegistry, setGadgetsRegistry] = useState<AuthoringGadgetRenderer>(mergeI18nStringsWithConfig<AuthoringGadgetRenderer>(gadgetOverrides ? gadgetOverrides : DEFAULT_AUTHORING_GADGETS_REGISTRY, i18nStrings?.gadgets));

  useEffect(() => {
    setGadgetsRegistry(mergeI18nStringsWithConfig<AuthoringGadgetRenderer>(gadgetOverrides ? gadgetOverrides : DEFAULT_AUTHORING_GADGETS_REGISTRY, i18nStrings?.gadgets));
  }, [gadgetOverrides, i18nStrings?.gadgets]);

  const gadgetIds = courseDraftLesson?.gadgets?.map(gadget => gadget.id) || [];
  const gadgetRefsByGadgetId = gadgetIds.reduce((gadgetRefs, gadgetId) => {
    return {
      ...gadgetRefs,
      [gadgetId]: React.createRef<HTMLDivElement>()
    };
  }, {} as Record<string, RefObject<HTMLDivElement>>) || {};

  const onActiveGadgetChanged = useCallback((gadgetId?: string) => {
    if (gadgetId) {
      const newCoursePosition = {
        displaySection: DisplaySection.LESSON,
        lessonId,
        gadgetId: gadgetId,
        updatedAt: new Date()
      } as CoursePosition;
      setCoursePosition(newCoursePosition);
    }
  }, [setCoursePosition, lessonId]);

  const clearScrollToGadgetId = useCallback(() => {
    setScrollToGadgetId(undefined);
  }, [setScrollToGadgetId]);

  if (!courseDraftLesson) {
    return null;
  }

  const lessonWithLearnerState = {
    ...courseDraftLesson,
    gadgets: courseDraftLesson.gadgets.map(gadget => {
      return {
        ...gadget,
        learnerState: gadgetLearnerStates[gadget.id]
      };
    })
  } as Lesson;

  return (
    <ScrollToGadget
      gadgetRefsByGadgetId={gadgetRefsByGadgetId}
      scrollContainer={scrollContainer}
      scrollToGadgetId={scrollToGadgetId}
      clearScrollToGadgetId={clearScrollToGadgetId}
    >
      <ActiveGadgetList
        scrollContainer={scrollContainer}
        onActiveGadgetChanged={onActiveGadgetChanged}
        gadgetIds={gadgetIds}
      >
        <LessonEditor
          gadgetsRegistry={gadgetRegistry}
          lesson={lessonWithLearnerState}
          fullScreenGadgetId={fullScreenGadgetId}
          gadgetRefsByGadgetId={gadgetRefsByGadgetId}
        />
      </ActiveGadgetList>
    </ScrollToGadget>
  );
};

/**
 * Course player component
 */
export const CourseAuthoringUI: FunctionComponent<CourseAuthoringProps> = (
  courseAuthoringProps: CourseAuthoringProps
) => {
  const fallbackRender = () => {
    return <GracefulError
      additionalAction={{
        buttonText: 'Return to your courses',
        action: courseAuthoringProps.authoringActionHooks?.onCourseDraftExit
      }}
      theme={GracefulErrorTheme.dark}
      i18nStrings={courseAuthoringProps.i18nStringsConfig?.errors}
    />;
  };

  return (
    <RecoilRoot>
      <DndProvider manager={dragDropManager}>
        <div className='AuthoringUI'>
          <ErrorBoundary
            fallbackRender={courseAuthoringProps.errorFallbackRender || fallbackRender}
            onError={courseAuthoringProps.onError}
          >
            <CourseAuthoringUIWithRecoilState {...courseAuthoringProps} />
          </ErrorBoundary>
        </div>
      </DndProvider>
    </RecoilRoot>
  );
};
