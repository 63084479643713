/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import shaka from 'shaka-player/dist/shaka-player.ui';

/**
 * Extending Shaka UI FullscreenButton for adding new functionality
 */
export class FullscreenButton extends shaka.ui.FullscreenButton {

  static BUTTON_NAME = 'soju_fullscreen';
  static fullscreenTooltipMessage = 'Full screen (f)';

  static setFullScreenTooltipMessage(message: string) { this.fullscreenTooltipMessage = message; }

  button: HTMLButtonElement;

  constructor(parent: HTMLElement, controls: shaka.ui.Controls) {
    super(parent, controls);

    this.button = <HTMLButtonElement>parent.lastChild;
    this.updateTooltip();

    this.button.classList.add('enter-fullscreen');

    // @ts-ignore
    this.eventManager.listen(controls.getVideoContainer(), 'keydown', async e => {
      if (e.key == 'f' || e.key == 'F') {
        controls.toggleFullScreen();
      }
    });

    // @ts-ignore
    this.eventManager.listen(document, 'fullscreenchange', () => {
      this.updateTooltip();
      if (document.fullscreenElement) {
        this.button.classList.remove('enter-fullscreen');
        this.button.classList.add('exit-fullscreen');
      } else {
        this.button.classList.remove('exit-fullscreen');
        this.button.classList.add('enter-fullscreen');
      }
    });
  }

  updateTooltip() {
    this.button.ariaLabel = FullscreenButton.fullscreenTooltipMessage;
  }
}


export class FullscreenButtonFactory {
  create(rootElement: HTMLElement, controls: shaka.ui.Controls) {
    return new FullscreenButton(rootElement, controls);
  }
}

shaka.ui.Controls.registerElement(FullscreenButton.BUTTON_NAME, new FullscreenButtonFactory());
