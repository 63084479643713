import React, {FunctionComponent, useCallback, useState} from 'react';
import Row from '@amzn/meridian/row';
import Theme from '@amzn/meridian/theme';
import lightTokens from '../../../theme/branded-light';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import Modal, {ModalFooter} from '@amzn/meridian/modal';
import RadioButton from '@amzn/meridian/radio-button';
import {LessonViewMode} from '../../../context/course/models/LessonViewMode';
import Column from '@amzn/meridian/column';
import cx from 'classnames';
import './styles/lessonViewOptionsModal.scss';

const LESSON_VIEW_MODE_CONTENTS = {
  [LessonViewMode.All]: {
    label: 'Entire Lesson',
    imageSource: 'https://m.media-amazon.com/images/G/01/courses/soju-player/images/LearnerViewMode_ALL_2.svg',
    description: <>There is a page break between each lesson.</>,
    recommendation: <><strong>Recommended</strong> for shorter lessons with a mix of gadget types (e.g. text alongside a video).</>,
    note: null
  },
  [LessonViewMode.Section]: {
    label: 'Sections (default)',
    imageSource: 'https://m.media-amazon.com/images/G/01/courses/soju-player/images/LearnerViewMode_SECTION.svg',
    description: <>There is a page break for each <strong>Section</strong> gadget.</>,
    recommendation: <><strong>Recommended</strong> when you would like to break up long lessons and want to group multiple gadgets into one page.</>,
    note: <>If a lesson has no <strong>Sections</strong>, the entire lesson will appear on a single page.</>
  },
  [LessonViewMode.Gadget]: {
    label: 'Individual Gadgets',
    imageSource: 'https://m.media-amazon.com/images/G/01/courses/soju-player/images/LearnerViewMode_GADGET.svg',
    description: <>There is a page break for each gadget.</>,
    recommendation: <><strong>Recommended</strong> if each gadget stands alone (e.g. video lectures).</>,
    note: <>A <strong>Section</strong> gadget will appear alongside the next gadget (and not as a separate page).</>
  }
};

const lessonViewModes = Object.values(LessonViewMode) as LessonViewMode[];

/**
 * Lesson View Options Modal props
 */
export interface LessonViewOptionsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentOption?: LessonViewMode;
  onOptionSelected: (viewMode: LessonViewMode) => void;
}

export const LessonViewOptionsModal: FunctionComponent<LessonViewOptionsModalProps> = (
  {
    open,
    setOpen,
    currentOption,
    onOptionSelected
  }: LessonViewOptionsModalProps
) => {
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  const [checkedValue, setCheckedValue] = useState<LessonViewMode>(currentOption || LessonViewMode.All);
  const {description, recommendation, note} = LESSON_VIEW_MODE_CONTENTS[checkedValue];

  return (
    <Theme tokens={{
      ...lightTokens
    }}>
      <Modal
        open={open}
        title='Lesson view options'
        onClose={onClose}
        scrollContainer='viewport'
        closeLabel='Close'
        width='640px'
        bodySpacingInset={'400 400 600 400'}
        closeButtonPropagateClickEvent={true}
      >
        <Column height='500px'>
          <Row>
            <Text>
              Select how learners will view your lesson content. The option selected applies to all lessons in the course.
            </Text>
          </Row>
          <Row widths='fill'>
            {lessonViewModes.map(lessonViewMode => (
              <Column key={lessonViewMode}>
                <Row>
                  <RadioButton
                    checked={checkedValue === lessonViewMode}
                    onChange={setCheckedValue}
                    value={lessonViewMode}
                    name='lessonViewOption'
                  >
                    {LESSON_VIEW_MODE_CONTENTS[lessonViewMode].label}
                  </RadioButton>
                </Row>
                <div
                  onClick={() => setCheckedValue(lessonViewMode)}
                  className={cx('lessonViewOptions__image', checkedValue === lessonViewMode ? 'active' : undefined)}
                  aria-hidden
                >
                  <Row
                    alignmentHorizontal='center'
                    spacingInset='medium'
                  >
                    <img
                      src={LESSON_VIEW_MODE_CONTENTS[lessonViewMode].imageSource}
                      alt=''
                    />
                  </Row>
                </div>
              </Column>
            ))}
          </Row>
          <Column>
            {description && <Text>
              {description}
            </Text>}
            {recommendation && <Text>
              {recommendation}
            </Text>}
            {note && <Text>
              {note}
            </Text>}
          </Column>
        </Column>
        <ModalFooter>
          <Row spacing='medium' alignmentHorizontal='right'>
            <Button
              type='link'
              label='Cancel'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              label='Confirm'
              onClick={() => {
                setOpen(false);
                onOptionSelected(checkedValue);
              }}
            >
              Confirm
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </Theme>
  );
};
