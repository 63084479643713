/**
 * Possible lesson progress statuses
 */
export enum ProgressStatus {
  /**
   * Lesson or course has been completed
   */
  COMPLETED = 'COMPLETED',

  /**
   * Lesson or course is in progress
   */
  IN_PROGRESS = 'IN_PROGRESS',

  /**
   * Lesson or course has not been started
   */
  NOT_STARTED = 'NOT_STARTED'
}
