import {Course, CoursePosition, DisplaySection, Lesson, useCourseContext} from '../../../context/course';
import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import Theme from '@amzn/meridian/theme';
import blueDarkTokens from '@amzn/meridian-tokens/theme/blue-dark';
import brandedDarkTokens from '../../../theme/branded-dark';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Heading from '@amzn/meridian/heading';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import Menu, {MenuItem} from '@amzn/meridian/menu';
import brandedLightTokens from '../../../theme/branded-light';
import menuMeatballTokens from '@amzn/meridian-tokens/base/icon/menu-meatball';
import cogTokens from '@amzn/meridian-tokens/base/icon/cog';
import Text from '@amzn/meridian/text';
import {LessonToCSection} from './LessonToCSection';
import {useCourseAuthoringContext} from '../../context';
import {LessonViewOptionsModal} from './LessonViewOptionsModal';

import {last} from 'lodash';
import {DnDListItem} from '../drag-and-drop/DnDListItem';
import './styles/toc.scss';
import {LessonViewMode} from '../../../context/course/models/LessonViewMode';

export const LESSON_DRAG_ITEM_TYPE = '___Lesson___';

/**
 * ToC builder sidebar props
 */
export interface ToCBuilderProps {

  /**
   * Current course
   */
  course: Course;

  /**
   * Learner's position in the course
   */
  coursePosition: CoursePosition | null;

  /**
   * optional callback that is called when a lesson section is clicked
   * @param lessonId
   */
  onLessonClick?: (lessonId: string) => void;

  /**
   * optional callback that is called when a gadget section is clicked
   * @param gadgetId
   */
  onGadgetClick?: (gadgetId: string) => void;

  /**
   * callback to be called when the lessonViewMode is changed via the modal
   * @param viewMode
   */
  onLessonViewModeChanged: (viewMode: LessonViewMode) => void;
}

/**
 * ToC builder sidebar for the authoring UI
 */
export const ToCBuilder: FunctionComponent<ToCBuilderProps> = (
  {
    course: {
      id,
      title,
      lessons,
      lessonViewMode
    },
    coursePosition,
    onGadgetClick,
    onLessonClick,
    onLessonViewModeChanged
  }: ToCBuilderProps
) => {

  const lessonViewOptionsButtonRef = useRef();
  const [lessonViewOptionMenuOpen, setLessonViewOptionMenuOpen] = useState(false);
  const [lessonViewOptionModalOpen, setLessonViewOptionModalOpen] = useState(false);

  const findLessonIndex = useCallback(
    (lesson: Lesson) => {
      return lessons.indexOf(lesson);
    },
    [lessons],
  );

  const [canAddLesson, setCanAddLesson] = useState(true);

  const { setCoursePosition } = useCourseContext();

  // whenever the lessons reference changes, we enable the add lesson button if it was disabled
  // this is to prevent fast clicking on add lesson button
  useEffect(() => {
    setCanAddLesson(prevState => {
      if (!prevState && lessons.length > 0) {
        // in this case, we had disabled adding lesson, meaning we just added a lesson at the end,
        // and we need to set the position to it
        setCoursePosition({
          lessonId: last(lessons)?.id || '',
          gadgetId: '',
          updatedAt: new Date(),
          displaySection: DisplaySection.LESSON
        });
      }
      return true;
    });
  }, [lessons, setCoursePosition]);

  const { updateLessonIndex, addLesson, previewDraftCourse, createCourseRevision } = useCourseAuthoringContext();

  const onAddLesson = () => {
    setCanAddLesson(false);
    addLesson();
  };

  const saveCourse = () => {
    createCourseRevision(id);
  }

  const moveLesson = useCallback((lessonId: string, index: number) => {
    updateLessonIndex(lessonId, index);
  }, [updateLessonIndex]);

  return (
    <>
      <Theme
        tokens={{
          ...blueDarkTokens,
          ...brandedDarkTokens
        }}
      >
        <Column className='sidebar-container' spacingInset='300 400' heights={['fit', 'fill', 'fit']}>
          <Row className='sidebar-header' widths={['fill', 'fit']} ref={lessonViewOptionsButtonRef}>
            <Heading level={2} type='h100'>{title}</Heading>
            <Theme tokens={{...brandedLightTokens}}>
              <>
                <Button type='secondary' size='medium' onClick={() => setLessonViewOptionMenuOpen(true)}>
                  <Icon className='overflow-menu-icon' tokens={menuMeatballTokens}/>
                </Button>
                <Menu
                  anchorNode={lessonViewOptionsButtonRef.current}
                  open={lessonViewOptionMenuOpen}
                  position='bottom'
                  onClose={() => setLessonViewOptionMenuOpen(false)}
                >
                  <MenuItem aria-label='Lesson View Options' onClick={() => setLessonViewOptionModalOpen(true)}>
                    <Row widths={[24, 'fill']} spacing='400'>
                      <Icon tokens={cogTokens} /> <Text tag='span'>Lesson View Options...</Text>
                    </Row>
                  </MenuItem>
                </Menu>
              </>
            </Theme>
          </Row>

          <Column spacing='300' className='lessons-container' overflowY='scroll' spacingInset='none none 400 none'>
            <nav aria-label="Table of contents">
              <ul className='lesson-list' aria-label={`lessons in "${title}"`}>
                {lessons.map((lesson, index) => <li className='lesson-list-item' key={`toc-${lesson.id}`}>
                  <DnDListItem
                    dragType={LESSON_DRAG_ITEM_TYPE}
                    dropTypes={[LESSON_DRAG_ITEM_TYPE]}
                    item={{
                      id: lesson.id,
                      index
                    }}
                    onDrop={({id: draggedItemId}, dropIndex) => {
                      moveLesson(draggedItemId, dropIndex);
                      return Promise.resolve();
                    }}
                  >
                    <LessonToCSection
                      findLessonIndex={findLessonIndex}
                      moveLesson={moveLesson}
                      coursePosition={coursePosition}
                      onClick={onLessonClick}
                      onGadgetClick={onGadgetClick}
                      key={lesson.id}
                      lesson={lesson}
                      isExpanded={coursePosition?.lessonId === lesson.id}
                    />
                  </DnDListItem>
                </li>
                )}
              </ul>
              <Button type='link' onClick={onAddLesson} disabled={!canAddLesson}>
                <Icon tokens={plusTokens} /> Add a new lesson
              </Button>
            </nav>
          </Column>
          <Column alignmentVertical='bottom' alignmentHorizontal='center' spacingInset='large' spacing='small'>
            <Button type='secondary' minWidth='300px' onClick={previewDraftCourse} label='Proceed to preview the course'>
              Preview draft
            </Button>
            <Button minWidth='300px' onClick={saveCourse} label='Save course'>
              Save course
            </Button>
          </Column>
        </Column>

      </Theme>
      <LessonViewOptionsModal
        open={lessonViewOptionModalOpen}
        setOpen={setLessonViewOptionModalOpen}
        currentOption={lessonViewMode}
        onOptionSelected={onLessonViewModeChanged}
      />
    </>
  );
};
