import {AssetModel} from '../../authoring';
import {Quill} from 'react-quill';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import {Value} from 'react-quill/lib';

const quillDelta = Quill.import('delta');

export const getAssetUrl = async (delta: Value | undefined, getAssetModel: (assetId: string) => Promise<AssetModel | undefined>) => {
  return new quillDelta(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await Promise.all(delta
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .map(async (op: { insert: { image: string; }; }) => {
        if (typeof op.insert === 'object') {
          const opWithTransformedImage = cloneDeep(op);
          await getAssetModel(op.insert.image).then(assetModel => {
            if (assetModel?.location) {
              set(opWithTransformedImage, 'insert.image', assetModel?.location);
            }
          });
          return opWithTransformedImage;
        }
        return op;
      }))
  );
};
