import React, {FunctionComponent, useState, useRef} from 'react';
import AceEditor from 'react-ace';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import Box from '@amzn/meridian/box';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Input from '@amzn/meridian/input';
import Select, { SelectOption } from '@amzn/meridian/select';

import {useCourseAuthoringContext} from '../../../../context';
import {LabsGadgetConfig, TScript, LabChallenge} from '../models';
import {LabChallengeScripts} from './LabChallengeScripts';

import './styles/lab-challenge-setup.scss';

export type LabChallengeSetupProps = {
  gadgetId: string;
  activeChallengeId: string;
  config: LabsGadgetConfig;
};

export const LabChallengeSetup: FunctionComponent<LabChallengeSetupProps> = (
  props: LabChallengeSetupProps
) => {
  const {gadgetId, activeChallengeId, config} = props;
  const {
    id: challangeId,
    title = '',
    teaser = '',
    timeToCompleteInMinutes = 30,
    assignmentMarkDownText,
    challengeScripts
  } = find(config.challenges, ['id', activeChallengeId]) as LabChallenge;
  const {updateGadget} = useCourseAuthoringContext();

  const [challengeTitle, setChallengeTitle] = useState<string>(title);
  const [challengeTeaser, setChallengeTeaser] = useState<string>(teaser);
  const [timeToComplete, setTimeToComplete] = useState<number>(timeToCompleteInMinutes);
  const [markdownDescription, setMarkdownDescription] = useState<string>(assignmentMarkDownText);
  const editorRef = useRef<AceEditor>(null);

  // This builds the config object with the update state, needed when calling updateGadget
  const buildConfig = () => {
    const newConfig = cloneDeep(config);
    newConfig.isSubmit = false;
    return newConfig;
  };

  const updateChallengeTitle = (newTitle: string) => {
    setChallengeTitle(newTitle);
    const newConfig = buildConfig();
    const challengeIndex = findIndex(newConfig.challenges, ['id', challangeId]);
    newConfig.challenges[challengeIndex].title = newTitle;
    updateGadget(gadgetId, newConfig);
  };

  const updateChallengeTeaser = (newTeaser: string) => {
    setChallengeTeaser(newTeaser);
    const newConfig = buildConfig();
    const challengeIndex = findIndex(newConfig.challenges, ['id', challangeId]);
    newConfig.challenges[challengeIndex].teaser = newTeaser;
    updateGadget(gadgetId, newConfig);
  };

  const onTimeLimitChange = (newTime: number) => {
    setTimeToComplete(newTime);
    const newConfig = buildConfig();
    const challengeIndex = findIndex(newConfig.challenges, ['id', challangeId]);
    newConfig.challenges[challengeIndex].timeToCompleteInMinutes = newTime;
    updateGadget(gadgetId, newConfig);
  };

  const updateMarkdownDescription = (newDescription: string) => {
    setMarkdownDescription(newDescription);
    const newConfig = buildConfig();
    const challengeIndex = findIndex(newConfig.challenges, ['id', challangeId]);
    newConfig.challenges[challengeIndex].assignmentMarkDownText = newDescription;
    updateGadget(gadgetId, newConfig);
  };

  const onChallengeScriptChange = (newScript: TScript) => {
    const newConfig = buildConfig();
    const challengeIndex = findIndex(newConfig.challenges, ['id', challangeId]);
    const scriptIndex = findIndex(newConfig.challenges[challengeIndex].challengeScripts, ['action', newScript.action]);

    const newTrackScripts = [...(newConfig.challenges[challengeIndex].challengeScripts || [])];
    if(scriptIndex === -1) {
      newTrackScripts.push(newScript);
    } else {
      newTrackScripts[scriptIndex] = newScript;
    }
    newConfig.challenges[challengeIndex].challengeScripts = newTrackScripts;
    updateGadget(gadgetId, newConfig);
  };

  return (
    <Box>
      <Box spacingInset={'400 600'} backgroundColor='secondary'>
        <Heading level={2} type='h300'>Editing Exercise</Heading>
      </Box>
      <Row widths={['grid-6', 'grid-6']} height={'100%'} alignmentVertical='top'>
        <Box className='labChallengeSetup__trackConfig' height={'100%'} spacingInset='500 none'>
          <Column spacing={'500'} spacingInset={'400 600'}>
            <Input
              value={challengeTitle}
              onChange={updateChallengeTitle}
              size='small'
              placeholder='Enter value...'
              label='Exercise Title'
            />
            <Input
              value={challengeTeaser}
              onChange={updateChallengeTeaser}
              size='small'
              placeholder='Enter value...'
              label='Exercise Teaser'
            />
            <Select
              label='Time to complete challenge'
              value={timeToComplete}
              onChange={onTimeLimitChange}
            >
              <SelectOption value={30} label={'30 minutes'} />
              <SelectOption value={60} label={'60 minutes'} />
              <SelectOption value={90} label={'90 minutes'} />
              <SelectOption value={120} label={'120 minutes'} />
            </Select>
            
            <LabChallengeScripts
              challengeScripts={challengeScripts}
              onTrackScriptChange={onChallengeScriptChange}
            />
          </Column>
        </Box>
        <Box height={'100%'} spacingInset={'500 600'} >
          <Text htmlFor='labChallenge__markdownDescription'>Exercise assignment description</Text>
          <AceEditor
            name='labChallenge__markdownDescription'
            width={'100%'}
            mode="markdown"
            theme="github"
            ref={editorRef}
            minLines={10}
            maxLines={Infinity}
            onChange={updateMarkdownDescription}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={false}
            wrapEnabled={true}
            value={markdownDescription}
            setOptions={{
              minLines: 10,
              maxLines: Infinity,
              showLineNumbers: true
            }}/>
        </Box>
      </Row>
    </Box>
  );
};
export default LabChallengeSetup;
