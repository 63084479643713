import React, {FunctionComponent} from 'react';
import {BaseIcon} from './BaseIcon';

export const CertificateIcon: FunctionComponent = () => {
  return <BaseIcon width={25} height={27} color="currentColor">
    <rect x="4" y="5" width="9" height="2" rx="1" />
    <rect x="4" y="10" width="11" height="2" rx="1" />
    <path d="M25 11C25 13.2091 23.2091 15 21 15C18.7909 15 17 13.2091 17 11C17 8.79086 18.7909 7 21 7C23.2091 7 25 8.79086 25 11Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.126 12C17.5701 13.7252 19.1362 15 21 15C22.8638 15 24.4299 13.7252 24.874 12H17.126ZM17 15.4722V27L21 23.5L25 27V15.4722C23.9385 16.4223 22.5367 17 21 17C19.4633 17 18.0615 16.4223 17 15.4722Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 0H4C1.79086 0 0 1.79086 0 4V15C0 17.2091 1.79086 19 4 19H15V17H4C2.89543 17 2 16.1046 2 15V4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V5.08296C22.7179 5.2034 23.3926 5.45134 24 5.80269V4C24 1.79086 22.2091 0 20 0ZM24 8.35418C23.4789 7.76377 22.7862 7.32838 22 7.12602V10H24V8.35418ZM24 12H22V14.874C22.7862 14.6716 23.4789 14.2362 24 13.6458V12ZM23.7771 16.32C22.9467 16.7544 22.002 17 21 17C20.7855 17 20.5736 16.9887 20.3649 16.9668C20.2466 16.9886 20.1246 17 20 17H17V19H20C21.7466 19 23.2317 17.8806 23.7771 16.32Z" />
  </BaseIcon>;
};
