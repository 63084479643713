import React, {forwardRef} from 'react';
import {GadgetProps} from '../models';
import {GadgetConfig, GadgetLearnerState} from '../../../context/course';
import Heading from '@amzn/meridian/heading';
import Box from '@amzn/meridian/box';
import './styles/section-gadget.scss';
import {SectionGadgetI18nStrings} from '../../../context/course/models/I18n';

/**
 * Section gadget props
 */
export type SectionGadgetProps = GadgetProps<GadgetConfig, GadgetLearnerState, SectionGadgetI18nStrings>

export const SectionGadget = forwardRef<HTMLElement, SectionGadgetProps>(({
  id,
  config: {
    title
  }
}: SectionGadgetProps, ref) => {
  return (
    <Box
      className='SectionGadget'
      data-gadget-id={id}
      spacingInset='450 none'
      width='100%'
      maxWidth='673px'
    >
      <Heading ref={ref} tabIndex='-1' className='SectionGadget__heading' level={2} type='h500'>{title}</Heading>
    </Box>
  );
});
SectionGadget.displayName = 'SectionGadget';
