import { sojuConfig } from "../configuration/sojuConfigs";
import { Stage } from "../configuration/stage";

const configuration = sojuConfig(process.env.STAGE as Stage);
export const config = {
  // TODO: replace with OIDC and remove apiKey
  aws_appsync_graphqlEndpoint: configuration.proxyServiceEndpoint,
  aws_appsync_region: configuration.region,
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: configuration.apiKey,
};
