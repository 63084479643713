import React, {FunctionComponent, ReactNode, useEffect, useState} from 'react';

import {HideDownloadWarningStorageKey} from '../../link-warning/Constants';
import {getItemFromSessionStorage} from '../../../hooks/useSessionStorage';
import Link from '@amzn/meridian/link';
import Icon from '@amzn/meridian/icon';
import downloadSmallTokens from '@amzn/meridian-tokens/base/icon/download-small';
import AlertKnockoutSmallTokens from '@amzn/meridian-tokens/base/icon/alert-knockout';
import {AssetModel, useCourseContext} from '../../../context/course';
import {MarkdownGadgetI18nStrings, WithI18nStringsProps} from '../../../context/course/models/I18n';
import {DownloadLinkWarningModal} from '../../link-warning/DownloadLinkWarningModal';
import {formatBytes} from '../../../utils/FileUtils';

export interface DownloadLinkProps extends WithI18nStringsProps<MarkdownGadgetI18nStrings> {
  href: string,
  children: ReactNode | string
}

const renderStatus = (status: string, i18nStrings: MarkdownGadgetI18nStrings) => {
  switch (status) {
    case 'AVAILABLE':
      return <span> <Icon tokens={downloadSmallTokens}/></span>;
    case 'THREAT_DETECTED':
      return <span className='threat_detected_asset'> ({i18nStrings.assetWithThreatDetectedLabel} <Icon tokens={AlertKnockoutSmallTokens}/>)</span>;
    case 'NOT_READY':
      return <span className='asset_not_processed'> ({i18nStrings.assetWithProcessingLabel})</span>;
    case 'ERROR':
      return <span className='asset_error'> ({i18nStrings.assetWithErrorLabel})</span>;
  }
};

const DownloadLink: FunctionComponent<DownloadLinkProps> = (
  {
    href,
    children,
    i18nStrings
  }
) => {

  const {getAssetModel} = useCourseContext();
  const [assetModel, setAssetModel] = useState<AssetModel | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const onModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      const assetId = href.replace('asset/', '');
      try {
        const fetchedAssetModel = await getAssetModel(assetId);
        setAssetModel(fetchedAssetModel);
      } catch (e) {
        // To not show the link in learner mode if asset is not found or if any network issue occurs when fetching the asset
        setAssetModel({
          status: 'ERROR'
        } as AssetModel);
      }
    })();
  }, [getAssetModel, href]);

  const onClick = () => {
    if (assetModel?.status !== 'AVAILABLE' || !assetModel.location) {
      return;
    }
    const isDownloadWarningHidden = getItemFromSessionStorage(HideDownloadWarningStorageKey, false);
    if (!isDownloadWarningHidden) {
      setModalOpen(true);
    } else {
      window.open(assetModel.location, '_blank');
    }
  };

  // To not show the link in learner/preview mode, if it's status is not AVAILABLE, and also in author mode, until the asset has loaded in.
  if (!assetModel) {
    return null;
  }

  return <>
    <DownloadLinkWarningModal
      open={modalOpen}
      onClose={onModalClose}
      href={assetModel.location}
      warningMetadata={{
        fileName: assetModel.title,
        fileSize: (assetModel.size && formatBytes(assetModel.size, 1)) || undefined
      }}
      i18nStrings={i18nStrings.linkWarning}
    />
    <Link onClick={onClick}>
      <span className='link_text'>
        {children}
      </span>
      {assetModel?.status && renderStatus(assetModel.status, i18nStrings)}
    </Link>
  </>;
};

export default DownloadLink;
