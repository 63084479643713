import React, {forwardRef} from 'react';
import cx from 'classnames';
import Icon from '@amzn/meridian/icon';
import closeKnockoutTokens from '@amzn/meridian-tokens/base/icon/close-knockout';
import checkKnockoutTokens from '@amzn/meridian-tokens/base/icon/check-knockout';
import { ResponseGadgetI18nStrings, WithI18nStringsProps } from '../../../context/course/models/I18n';
import './styles/graded-response.scss';

export interface GradedResponseProps extends WithI18nStringsProps<ResponseGadgetI18nStrings> {
  response: string,
  correct: boolean,
  id: string
}

export const GradedResponse = forwardRef<HTMLInputElement, GradedResponseProps>((
  {
    response,
    correct,
    id,
    i18nStrings,
  }: GradedResponseProps,
  ref
) => {
  const classNames = cx(
    'GradedResponse__text',
    correct ? 'GradedResponse--correct' : 'GradedResponse--incorrect'
  );
  const iconClassNames = cx(
    'GradedResponse__icon',
    correct ? 'GradedResponse__icon--correct' : 'GradedResponse__icon--incorrect'
  );

  return <div className='GradedResponse'>
    <input
      id={id}
      ref={ref}
      aria-describedby={`${id}-correct-description`}
      type='text'
      readOnly
      aria-disabled
      aria-readonly
      value={response}
      className={classNames} />
    <span aria-hidden id={`${id}-correct-description`} className='GradedResponse--sr-only'>
      {correct ? i18nStrings.correctResponse : i18nStrings.incorrectResponse}
    </span>
    <span className={iconClassNames}>
      <Icon tokens={correct ? checkKnockoutTokens : closeKnockoutTokens} />
    </span>
  </div>;
});

GradedResponse.displayName = 'GradedResponse';
