import Quill from 'quill';
import { Action } from 'quill-blot-formatter';

export default class CustomDeleteAction extends Action {
  onCreate() {
    document.addEventListener('keyup', this.onKeyUp, true);
    this.formatter.quill.root.addEventListener('input', this.onKeyUp, true);
  }

  onDestroy() {
    document.removeEventListener('keyup', this.onKeyUp, true);
    this.formatter.quill.root.removeEventListener('input', this.onKeyUp, true);
  }

  onKeyUp = (e: KeyboardEvent) => {
    if (!this.formatter.currentSpec) {
      return;
    }

    // delete or backspace
    const eventTarget = e.target as HTMLElement;
    const isTextInput = eventTarget.tagName === 'INPUT' && (eventTarget as HTMLInputElement).type === 'text';


    // Do not delete if event came from a text input
    if (!isTextInput && (e.key === 'Backspace' || e.key === 'Delete')) {
      const formatterTarget = this.formatter.currentSpec.getTargetElement();
      const blot = formatterTarget && Quill.find(formatterTarget);
      if (blot) {
        blot.deleteAt(0);
      }
      this.formatter.hide();
    }
  };
}
