import { debounce } from "lodash";
import {
  AssetModel,
  AssetType,
  ContentType,
  Gadget,
  GadgetConfig,
  Lesson,
  VideoAssetMetadata,
} from "@amzn/soju-ui-framework";
import {
  CloudLabInfo,
  CourseAuthoringApi,
} from "@amzn/soju-ui-framework/dist/umd/authoring";
import { CreateAssetRequest } from "@amzn/soju-ui-framework/dist/umd/authoring/context/models/CreateAssetRequest";
import { CourseUpdateRequest } from "@amzn/soju-ui-framework/dist/umd/authoring/context/models/CourseUpdateRequest";
import {
  BaseCoursePlayerApi,
  LEARNER_SESSION_HEADER_NAME,
  SOJU_CUSTOMER_HEADER_NAME,
} from "./BaseCoursePlayerApi";
import { API, graphqlOperation } from "@aws-amplify/api";
import { getAsset } from "../graphql/queries";
import { Asset, CourseContent } from "../graphql/model";
import {
  createAsset,
  createCourseContent,
  createCourseRevision,
  createLessonGadgets,
  deleteContent,
  deleteLessonGadgets,
  processAsset,
  updateCourseDraft,
  updateLessonGadget,
} from "../graphql/mutations";
import { StringToRecordConverter } from "./utils/StringToRecordConverter";

const DEBOUNCE_TIME_IN_MS = 1000;

/**
 * Soju Midway protected Authoring API
 */
export class SojuCourseAuthoringApi
  extends BaseCoursePlayerApi
  implements CourseAuthoringApi
{
  async createContent(
    courseDraftId: string,
    type: string,
    title: string,
    parentId: string | undefined,
  ): Promise<Lesson> {
    const response = (await API.graphql(
      graphqlOperation(createCourseContent, {
        request: {
          courseDraftId: courseDraftId,
          title: title,
          parentId: parentId,
          contentType: type,
        },
      }),
      {
        ...this.getHeaders(),
      },
    )) as {
      data: {
        createCourseContent: CourseContent;
      };
      error: object;
    };
    return {
      ...response.data.createCourseContent,
      gadgets: [],
      contentType: ContentType[type],
    } as Lesson;
  }

  async createAsset(
    courseDraftId: string,
    createAssetRequest: CreateAssetRequest,
  ): Promise<AssetModel> {
    const response = (await API.graphql(
      graphqlOperation(createAsset, {
        request: {
          title: createAssetRequest.title,
          type: createAssetRequest.type,
          tags: createAssetRequest.tags,
          contentType: createAssetRequest.contentType,
        },
      }),
      {
        ...this.getHeaders(),
      },
    )) as {
      data: {
        createAsset: Asset;
      };
      error: object;
    };
    const assetModel: AssetModel = {
      id: response.data.createAsset.id || "",
      title: response.data.createAsset.title || "",
      size: response.data.createAsset.size || 0,
      type: response.data.createAsset.type as AssetType,
      contentType: response.data.createAsset.contentType || "",
      location: response.data.createAsset.location || "",
      status: response.data.createAsset.status as
        | "NEW"
        | "AVAILABLE"
        | "NOT_READY"
        | "THREAT_DETECTED"
        | "ERROR",
      tags: (response.data.createAsset.tags as string[]) || [],
      assetMetadata: StringToRecordConverter.convert(
        response.data.createAsset.assetMetadata,
      ),
    };
    return assetModel;
  }

  async createLessonGadgets(
    courseDraftId: string,
    lessonId: string,
    gadgets: Pick<Gadget, "type" | "config">[],
    index: number | undefined,
  ): Promise<Gadget[]> {
    const response = (await API.graphql(
      graphqlOperation(createLessonGadgets, {
        request: {
          courseDraftId: courseDraftId,
          lessonId: lessonId,
          index: index,
          gadgets: this.transformGadgets(gadgets),
        },
      }),
      {
        ...this.getHeaders(),
      },
    )) as {
      data: {
        createLessonGadgets: Gadget[];
      };
      error: object;
    };
    return [response.data.createLessonGadgets[0]];
  }

  async deleteContent(courseDraftId: string, contentId: string): Promise<void> {
    await API.graphql(
      graphqlOperation(deleteContent, {
        request: {
          courseDraftId: courseDraftId,
          contentId: contentId,
          deleteChildren: false,
        },
      }),
      {
        ...this.getHeaders(),
      },
    );
  }

  async deleteLessonGadgets(
    courseDraftId: string,
    lessonId: string,
    gadgetIds: string[],
  ): Promise<void> {
    await API.graphql(
      graphqlOperation(deleteLessonGadgets, {
        request: {
          courseDraftId: courseDraftId,
          lessonId: lessonId,
          gadgets: gadgetIds,
        },
      }),
      {
        ...this.getHeaders(),
      },
    );
  }

  async getAssetModel(
    courseDraftId: string,
    assetId: string,
  ): Promise<AssetModel> {
    const response = (await API.graphql(
      graphqlOperation(getAsset, {
        request: {
          assetId: assetId,
          isDraft: true,
        },
      }),
      {
        ...this.getHeaders(),
      },
    )) as unknown as {
      data: {
        getAsset: Asset;
      };
      error: object;
    };
    const assetModel: AssetModel = {
      id: response.data.getAsset.id || "",
      title: response.data.getAsset.title || undefined,
      size: response.data.getAsset.size || 0,
      type: response.data.getAsset.type as AssetType,
      contentType: response.data.getAsset.contentType || "",
      location: response.data.getAsset.location || undefined,
      status: response.data.getAsset.status as
        | "NEW"
        | "AVAILABLE"
        | "NOT_READY"
        | "THREAT_DETECTED"
        | "ERROR",
      tags: (response.data.getAsset.tags as string[]) || undefined,
      assetMetadata: StringToRecordConverter.convert(
        response.data.getAsset.assetMetadata,
      ),
    };
    if (assetModel.type === "VIDEO") {
      assetModel.assetMetadata = assetModel.assetMetadata
        ? this.toVideoAssetMetadata(assetId, assetModel.assetMetadata)
        : {};
    }
    return assetModel;
  }

  async startAssetProcessing(
    courseDraftId: string,
    assetId: string,
  ): Promise<void> {
    await API.graphql(
      graphqlOperation(processAsset, {
        request: {
          assetId: assetId,
        },
      }),
      {
        ...this.getHeaders(),
      },
    );
  }

  updateContent(courseDraftId: string, content: Lesson): Promise<void> {
    return Promise.resolve(undefined);
  }

  debouncedUpdateCourse = debounce(
    (courseDraftId: string, courseUpdateRequest: CourseUpdateRequest) => {
      return API.graphql(
        graphqlOperation(updateCourseDraft, {
          request: {
            courseDraftId: courseDraftId,
            title: courseUpdateRequest.title,
            contents: courseUpdateRequest.contents,
            lessonViewMode: courseUpdateRequest.lessonViewMode,
          },
        }),
        {
          ...this.getHeaders(),
        },
      );
    },
    DEBOUNCE_TIME_IN_MS,
  );

  async updateCourse(
    courseDraftId: string,
    courseUpdateRequest: CourseUpdateRequest,
  ): Promise<void> {
    await this.debouncedUpdateCourse(courseDraftId, courseUpdateRequest);
  }

  debouncedUpdateLessonGadget = debounce(
    (courseDraftId: string, gadgetId: string, config: GadgetConfig) => {
      return API.graphql(
        graphqlOperation(updateLessonGadget, {
          request: {
            courseDraftId: courseDraftId,
            gadgetId: gadgetId,
            config: JSON.stringify(config),
          },
        }),
        {
          ...this.getHeaders(),
        },
      );
    },
    DEBOUNCE_TIME_IN_MS,
  );

  async updateLessonGadget(
    courseDraftId: string,
    gadgetId: string,
    config: GadgetConfig,
  ): Promise<void> {
    await this.debouncedUpdateLessonGadget(courseDraftId, gadgetId, config);
  }

  async createCourseRevision(courseDraftId: string): Promise<void> {
    await API.graphql(
      graphqlOperation(createCourseRevision, {
        request: {
          courseId: courseDraftId,
        },
      }),
      {
        ...this.getHeaders(),
      },
    );
  }

  /**
   * Convert Soju video asset metadata to the Gadget expected metadata
   * @param assetId asset id
   * @param metadata metadata as stored in Soju
   * @private
   */
  private toVideoAssetMetadata(
    assetId: string,
    metadata: Record<string, unknown>,
  ) {
    return {
      hlsEgressEndpoint: metadata.hlsEgressEndpoint
        ? metadata.hlsEgressEndpoint
        : metadata.hlsUrl,
      dashEgressEndpoint: metadata.dashEgressEndpoint
        ? metadata.dashEgressEndpoint
        : metadata.hlsUrl,
      drmLicenseUrl: "/api/assets/drm_license",
      videoProcessingDbGuid: metadata.videoProcessingDbGuid,
      videoProcessingWorkflowStatus: metadata.videoProcessingWorkflowStatus,
      getDrmRequest: (
        drmType: string,
        licenseChallenge: string,
        contentId: string,
      ) => {
        const wrapped: Record<string, any> = {};
        wrapped.drmType = drmType;
        wrapped.licenseChallenge = licenseChallenge;
        wrapped.assetId = assetId;
        wrapped.contentId = contentId;
        return {
          headers: {
            Accept: drmType === "FAIRPLAY" ? "text/plain" : "application/json",
            [LEARNER_SESSION_HEADER_NAME]: this.learnerSession.learnerToken,
            [SOJU_CUSTOMER_HEADER_NAME]: this.learnerSession.resourceId,
            "Content-Type": "application/json",
            "cache-control": "no-cache",
            pragma: "no-cache",
          },
          body: JSON.stringify(wrapped),
        };
      },
    } as VideoAssetMetadata;
  }

  getCloudLabInfo(courseId: string, gadgetId: string): Promise<CloudLabInfo> {
    throw new Error("Not Implemented");
  }

  private transformGadgets(
    gadgets: Pick<Gadget, "type" | "config">[],
  ): { type: string; config: string }[] {
    return gadgets.map((gadget) => {
      return {
        type: gadget.type,
        config: JSON.stringify(gadget.config),
      };
    });
  }
}
