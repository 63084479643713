import Amplify, { MemoryStorage } from "@aws-amplify/core";
import { GandalfConfig } from "../../configuration/gandalf-config";

export function createUrlInterceptor(
  domain: string,
  signInUIDomain?: string,
  signOutUIDomain?: string,
) {
  return (url: string) => {
    const SELF = "_self";
    const loginRequestUrl = `https://${domain}/oauth2/authorize`;
    const logoutRequestUrl = `https://${domain}/logout`;
    let windowProxy;

    if (signInUIDomain && url.startsWith(loginRequestUrl)) {
      const signInUrl = url.replace(
        loginRequestUrl,
        `https://${signInUIDomain}/login`,
      );
      windowProxy = window.open(signInUrl, SELF);
    } else if (signOutUIDomain && url.startsWith(logoutRequestUrl)) {
      const signOutUrl = url.replace(
        logoutRequestUrl,
        `https://${signOutUIDomain}/logout`,
      );
      windowProxy = window.open(signOutUrl, SELF);
    } else {
      windowProxy = window.open(url, SELF);
    }
    return windowProxy
      ? Promise.resolve(windowProxy)
      : Promise.reject(new Error("Cannot open sign in window."));
  };
}

export function configureAmplify({ config }: { config: GandalfConfig }) {
  return Amplify.configure({
    Auth: {
      storage: MemoryStorage,
      region: config.Auth.region,
      userPoolId: config.Auth.userPoolId,
      userPoolWebClientId: config.Auth.userPoolWebClientId,
      oauth: {
        domain: config.Auth.oauth.domain,
        scope: config.Auth.oauth.scope,
        responseType: config.Auth.oauth.responseType,
        redirectSignIn: config.Auth.oauth.redirectSignIn,
        redirectSignOut: config.Auth.oauth.redirectSignOut,
        urlOpener: createUrlInterceptor(
          config.Auth.oauth.domain,
          config.Gandalf?.customSignInDomain,
          config.Gandalf?.customSignOutDomain,
        ),
      },
    },
  });
}

export default configureAmplify;
